import React, { useState } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { MdOutlineCloudUpload } from 'react-icons/md';

import classNames from '../utils/classNames';

const PlanningInputFile = ({ register, name, label, error, remove, change, defaultFile, accept }) => {
	const [fileName, setFileName] = useState('');

	return (
		<div>
			<label
				htmlFor={name}
				className="h-20 min-w-full max-w-2xl cursor-pointer rounded-lg border-gray-600 bg-gray-100 flex justify-between items-center"
			>
				<input
					{...register(name)}
					id={name}
					type="file"
					className="sr-only"
					accept={accept}
					onChange={(e) => {
						setFileName(e.target.files?.item(0)?.name);
						const file = e.target.files.item(0);
						if (file) {
							change(file);
						}
					}}
				/>
				{fileName ? (
					<span className="text-gray-500 px-4 overflow-hidden">{fileName}</span>
				) : (
					<span className="flex items-center gap-x-4 text-gray-500 px-4">
						{label ?? 'Selecionar arquivo'}
						<MdOutlineCloudUpload size={40} />
					</span>
				)}
				<button
					type="button"
					className={classNames(
						'px-8 rounded-lg h-full',
						'text-white bg-red-400',
						'hover:bg-red-500'
					)}
					onClick={() => {
						setFileName('');
						remove();
					}}
				>
					<FaTrashAlt size={20} />
				</button>
			</label>
			{!defaultFile ? null : (
				<a href={defaultFile} target="_blank" className="text-sm text-blue-400 underline" rel="noreferrer">
					Clique aqui para ver o arquivo
				</a>
			)}
			{error && <span className="text-red-400">{error}</span>}
		</div>
	);
};

export default PlanningInputFile;
