import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import FollowUpCard from '../../components/FollowUpCard';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import Menu from '../../components/Menu';
import PatientInfos from '../../components/PatientInfos';
import PlanningCard from '../../components/PlanningCard';
import api from '../../services/api';

function ClinicDetail() {
	const [loading, setLoading] = useState(false);
	const [patientData, setPatientData] = useState({});
	const [followUps, setFollowUps] = useState([]);
	const [plannings, setPlannings] = useState([]);

	const { addToast } = useToasts();
	const { push: historyPush } = useHistory();

	const { idpaciente } = useParams();

	async function loadData() {
		setLoading(true);

		try {
			const { data: history } = await api.get(`/dentist/patient-history/${idpaciente}`);

			setFollowUps(history.FollowUps.map((item, index) => ({ ...item, position: index + 1 })));
			setPlannings(history.Plannings.map((item, index) => ({ ...item, position: index + 1 })));
			delete history.FollowUps;
			delete history.Plannings;
			setPatientData(history);
		} catch (error) {
			addToast(error?.response?.data?.message ?? 'Erro ao carregar os dados do paciente', {
				appearance: 'error',
				autoDismiss: true,
			});

			historyPush('/clinicas');
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		loadData();
	}, []);

	return (
		<>
			<div className="flex">
				<Menu className="hidden xl:flex" />
				<div className="w-full flex flex-col">
					<Header textHeader={`Clínicas | ${patientData?.clinic?.name} | ${patientData?.name}`} />
					<main className="w-full flex flex-col py-4 px-14">
						{!patientData ? null : <PatientInfos patient={patientData} reload={() => loadData()} />}
						<div className="flex gap-y-6 justify-between  md:flex-row  my-6 flex-col-reverse">
							<div className="flex items-center text-2xl font-bold text-center sm:text-left">Histórico:</div>
						</div>
						<div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-3">
							{followUps.map(followUp => (
								<FollowUpCard
									key={followUp.id}
									followUp={{
										...followUp,
										patient: {
											name: patientData?.name,
											profile_image: patientData?.profile_image,
										},
										dentist: {
											clinic: {
												name: patientData?.clinic?.name,
											}
										}
									}}
								/>
							))}
							{plannings.map((planning, index) => (
								<PlanningCard
									key={planning.id}
									planning={planning}
									index={index}
								/>
							))}
						</div>
					</main >
				</div >
			</div>

			<Loader show={loading} />
		</>
	);
}

export default ClinicDetail;
