import { Disclosure } from '@headlessui/react';
import React, { useMemo, useState } from 'react';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { BsList, BsPlayCircle } from 'react-icons/bs';
import { IoMdCheckmarkCircleOutline, IoMdCloseCircleOutline } from 'react-icons/io';
import { MdOutlineLibraryBooks, MdOutlineMovie } from 'react-icons/md';
import { useParams } from 'react-router-dom';

import classNames from '../../utils/classNames';
import ProgressBar from '../ProgressBar';
import ModalAvaliacao from './ModalAvaliacao';

function SideBar({ isOpen, onMenuClick, updateProgress, state, dispatch, setVideoCompleted }) {
	const [isOpenAvaliacao, setIsOpenAvaliacao] = useState(false);
	const { id_module } = useParams();

	const progress = useMemo(() => {
		if (state.videos.length > 0) {
			const mappedVideos = state.videos?.map(v => v.progress);
			const progressSum = mappedVideos?.reduce((prev, curr) => prev + curr);
			return Math.floor(progressSum / mappedVideos?.length);
		}

		return 0;
	}, [state, state.videos, updateProgress]);

	return (
		<>
			<div className={classNames(
				'max-w-sm 2xl:max-w-lg flex flex-col bg-admin-grey-light',
				'transition-transform transform',
				isOpen ? 'translate-x-0 w-4/12' : 'w-8'
			)}>
				<div className="flex items-center gap-x-4 p-2">
					<button
						className={classNames(
							'flex items-center',
							'transition-transform transform',
							isOpen ? 'translate-x-0' : '-translate-x-2/3'
						)}
						onClick={() => onMenuClick()}
					>
						<div className="bg-primary rounded-md">
							<BsList size={30} color="#fff" />
						</div>
					</button>
					{isOpen && (
						<>
							<span className="text-primary text-sm">Progresso no curso</span>
							<ProgressBar progress={progress} />
						</>
					)}
				</div>
				{isOpen && (
					<div className="flex flex-col mt-10 bg-white h-full">
						<Disclosure defaultOpen>
							{({ open }) => (
								<>
									<Disclosure.Button
										disabled
										className={classNames(
											'flex justify-between w-min',
											'bg-primary text-white',
											'rounded-r-lg hover:bg-purple-200',
											'focus:outline-none shadow-md',
											'transform -translate-y-1/2'
										)}
									>
										<span className="text-2xl font-medium mr-6 px-4 py-3">Aulas</span>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											strokeWidth={3}
											stroke="currentColor"
											className={classNames(
												'h-full w-10 text-primary bg-white px-2',
												open ? 'rotate-180 transform' : '',
											)}
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												d="M19.5 8.25l-7.5 7.5-7.5-7.5"
											/>
										</svg>
									</Disclosure.Button>
									<Disclosure.Panel className="flex flex-col">
										<h4 className="px-4 text-2xl font-medium">Introdução</h4>
										<div className="flex flex-col mt-2">
											{state.videos?.lenght <= 0 ? (
												<p className=''>sem videos</p>
											) : state.videos?.map((video, index) => {
												return (
													<button
														key={video.id}
														type="button"
														className="flex items-center gap-x-2 py-2 px-4"
														onClick={() => {
															setVideoCompleted(false);
															dispatch({ type: 'SET_SELECTED', payload: index });
														}}
													>
														<MdOutlineMovie size={25} className="text-primary" />
														<p className="flex-1 text-left leading-4">{video.title}</p>
														{video.progress === 100 && <AiOutlineCheckCircle size={25} className="text-status-completed" />}
														{state.selectedVideo === index && <BsPlayCircle size={22} className="text-primary" />}
													</button>
												);
											})}
										</div>
										{!state.avaliacao && state.questions && progress === 100 && (
											<button
												type="button"
												onClick={() => setIsOpenAvaliacao(true)}
												className={classNames(
													'flex items-center gap-x-2 py-2 px-4 mt-4 mx-2',
													'bg-primary text-white rounded-md',
													'border-2 border-primary',
													'hover:bg-white hover:text-primary',
													'transition-colors duration-150'
												)}
											>
												<span className="flex-1 text-xl">Realizar avaliação</span>
												<MdOutlineLibraryBooks size={30} />
											</button>
										)}
										{state.avaliacao?.nota < 70 && (
											<>
												<p className="p-4 mt-4 text-lg border-t-2 leading-6">
													<span className="flex items-center justify-between">
														<span className="text-2xl">Que pena!</span>
														<IoMdCloseCircleOutline size={25} className="text-red-500" />
													</span>
													Você reprovou com uma nota de <strong className="text-2xl">{state.avaliacao.nota}</strong>.
													<br />
													Clique no botão abaixo para refazer a prova.
												</p>
												<button
													type="button"
													onClick={() => setIsOpenAvaliacao(true)}
													className={classNames(
														'flex items-center gap-x-2 py-2 px-4 mt-4 mx-2',
														'bg-primary text-white rounded-md',
														'border-2 border-primary',
														'hover:bg-white hover:text-primary',
														'transition-colors duration-150'
													)}
												>
													<span className="flex-1 text-xl">Refazer avaliação</span>
													<MdOutlineLibraryBooks size={30} />
												</button>
											</>
										)}
										{state.avaliacao?.nota >= 70 && (
											<p className="p-4 mt-4 text-lg border-t-2 leading-6">
												<span className="flex items-center justify-between">
													<span className="text-2xl">Parabéns!</span>
													<IoMdCheckmarkCircleOutline size={25} className="text-status-completed" />
												</span>
												<br />
												Você foi aprovado com uma nota de <strong className="text-2xl">{state.avaliacao.nota}</strong> pontos.
											</p>
										)}
									</Disclosure.Panel>
								</>
							)}
						</Disclosure>
					</div>
				)}
			</div>
			<ModalAvaliacao
				dispatch={dispatch}
				id_module={id_module}
				questions={state.questions}
				isOpen={isOpenAvaliacao}
				onClose={() => setIsOpenAvaliacao(false)}
			/>
		</>
	);
}

export default SideBar;
