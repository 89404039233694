import React from 'react';
import { Link } from 'react-router-dom';

import classNames from '../utils/classNames';

function ListClinicPatients({ list, isClinic = false }) {
	return (
		<>
			{list.length < 1 ?
				<div className="grid grid-cols-1 lg:grid-cols-2">
					<div className="mt-6 shadow-md rounded-4 w-full h-auto flex flex-col sm:flex-row px-4 py-2 gap-4 rounded-lg drop-shadow-lg">
						<p className='flex self-center place-self-center text-xl font-bold py-6'>Nenhum paciente encontrado.</p>
					</div>
				</div>
				:
				<>
					<div className="grid grid-cols-1 lg:grid-cols-2">
						{list.map(item => (
							<Link
								key={item.id}
								to={`/${isClinic ? 'clinica-paciente' : 'paciente'}/${item.id}`}
								className="mt-6 shadow-md rounded-4 w-full h-auto flex px-4 py-2 gap-4 rounded-lg drop-shadow-lg"
							>
								<div
									className={
										classNames(
											'bg-primary rounded-full h-32 w-32 sm:h-20 sm:w-20 md:mr-3 flex self-center justify-self-center',
											'bg-center bg-no-repeat bg-cover',
										)
									}
									style={{ backgroundImage: `url(${item.profile_image})` }}
								/>
								<div className="grid sm:grid-cols-2 gap-y-2 items-center  flex-1 justify-between">
									<div className="flex flex-col justify-evenly sm:justify-start">
										<p className="font-bold text-xl ml-2 self-start">{item.name}</p>
										<div className="flex ml-2">
											<strong className="text-sm">Clínica: </strong>
											<p className="text-sm ml-2">{item.clinic}</p>
										</div>
										<div className="flex ml-2">
											<p className="text-sm">Status: </p>
											<strong className={classNames('text-sm ml-2', item.is_active === 1 ? 'text-green-600' : 'text-red-500')}>
												{item.is_active === 1 ? 'Ativo' : 'Inativo'}
											</strong>
										</div>
									</div>
									<div className="flex justify-evenly sm:justify-end w-full md:self-end">
										<div className="flex flex-col gap-2 sm:flex-row-3 sm:self-end">
											<div className="flex items-center ml-2 sm:gap-x-2 sm:self-end">
												<p className="text-sm sm:text-base whitespace-nowrap">Dentista responsável: </p>
												<p className="text-sm sm:text-base whitespace-nowrap">{item.dentist.name}</p>
											</div>
											<div className="flex ml-2 gap-1 sm:gap-x-2 sm:self-end">
												<p className="text-sm sm:text-base">Planejamentos realizados: </p>
												<p className="text-sm sm:text-base">{item.realizados ?? 0}</p>
											</div>
											<div className="flex ml-2 gap-1 sm:gap-x-2 sm:self-end">
												<p className="text-sm sm:text-base">Planejamentos pendentes: </p>
												<p className="text-sm sm:text-base "> {item.pendentes ?? 0}</p>
											</div>
										</div>
									</div>
								</div>
							</Link>
						))}
					</div>
				</>
			}
		</>
	);
}

export default ListClinicPatients;
