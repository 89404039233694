import React, { useCallback, useEffect, useState } from 'react';
import { HiOutlineDocumentDownload } from 'react-icons/hi';
import { ImClock } from 'react-icons/im';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import Header from '../../components/Header';
import ListPhotos from '../../components/ListPhotos';
import Loader from '../../components/Loader';
import Menu from '../../components/Menu';
import ModalMoreInfoPlanning from '../../components/ModalMoreInfoPlanning';
import ModalUploadVideo from '../../components/ModalUploadVideo';
import { useAuth } from '../../contexts/auth';
import api from '../../services/api';
import classNames from '../../utils/classNames';
import { getAgeFromDate } from '../../utils/getAgeFromDate';
import getImagesFromObject from '../../utils/getImagesFromObject';
import { getPlanningStatusColor, parsePlanningStatus } from '../../utils/status';

function PlanningDetail() {
	const [planning, setPlanning] = useState();
	const [patient, setPatient] = useState();
	const [loading, setLoading] = useState(false);
	const [savingDesignation, setSavingDesignation] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [openModalVideo, setOpenModalVideo] = useState(false);
	const [files, setFiles] = useState([]);

	const { idplanejamento } = useParams();
	const { user, signOut } = useAuth();
	const { addToast } = useToasts();
	const { push: pushHistory } = useHistory();


	const loadData = useCallback(async () => {
		setLoading(true);

		try {
			const { data } = await api.get(`/plannings/${idplanejamento}`);

			setPatient(data?.patient);
			delete data.patient;

			const filesIndex = data?.answers?.findIndex(answer => answer.question === 'Arquivos');
			let allFiles = [];
			if (filesIndex > -1) {
				allFiles = data.answers[filesIndex].answer?.split(';') ?? [];
				data.answers.splice(filesIndex, 1);
			}
			if (allFiles.length == 1 && allFiles[0] === '') {
				allFiles = [];
			}
			setFiles(allFiles);
			setPlanning(data);

		} catch (error) {
			if (error.response?.status === 401) {
				addToast('Você não tem permissão para acessar esta página.', {
					appearance: 'error',
					autoDismiss: true
				});

				signOut();
			}

			if (error.response?.status === 404) {
				addToast(error.response?.data?.message || error?.message, {
					appearance: 'error',
					autoDismiss: true
				});

				pushHistory('/planejamentos');
				return;
			}

			addToast(error.response?.data?.message || error?.message || 'Erro ao carregar os detalhes do planejamento', {
				appearance: 'error',
				autoDismiss: true,
			});

			// await loadData();
		} finally {
			setLoading(false);
		}
	}, [idplanejamento]);

	useEffect(() => {
		loadData();
	}, []);

	const handleDesignate = useCallback(async () => {
		setSavingDesignation(true);
		try {
			const { data } = await api.post('/plannings/designate', {
				plannings: [+idplanejamento],
				consultantId: null,
			});

			addToast(data.message, {
				appearance: 'success',
				autoDismiss: true,
			});

			setPlanning(old => ({
				...old,
				consultant: undefined,
			}));
		} catch (err) {
			addToast(err.response?.data?.message || 'Erro ao designar os planejamentos', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setSavingDesignation(false);
		}
	}, []);

	return (
		<>
			<Loader show={loading} />
			<div className="flex">
				<Menu className="hidden xl:flex" />
				<div className="w-full flex flex-col">
					<Header textHeader={`Planejamentos | ${patient?.clinic?.name} | ${patient?.name}`} />
					<main className="w-full flex flex-col py-4 px-14">
						<div className="mt-6 min-w-full rounded-4 w-full h-auto flex flex-col xl:flex-row px-4 py-5 gap-4 rounded-lg shadow-md">
							<div
								className={
									classNames(
										'bg-primary rounded-full h-32 w-32 self-center',
										'bg-center bg-no-repeat bg-cover',
									)
								}
								style={{
									backgroundImage: `url(${patient?.profile_image})`,
								}}
							/>
							<div className="flex flex-col text-xl w-full flex-1 items-between px-4">
								<p className="font-bold self-start">{patient?.name}</p>
								<div className="flex gap-x-2">
									<p className="">Sexo:</p>
									<p>{patient?.gender}</p>
								</div>
								<div className="flex gap-x-2">
									<p className="">Idade:</p>
									<p>{getAgeFromDate(new Date(patient?.birth_date))}</p>
								</div>
								<div className="flex gap-x-2">
									<p className="">Dentista Responsável:</p>
									<p>{patient?.dentist?.user?.name}</p>
								</div>
							</div>
							<div className="flex flex-col text-xl  justify-evenly gap-1 px-4">
								<div className="flex gap-x-2">
									<p className="">Linha média:</p>
									<p>{patient?.upper_middle_line}</p>
								</div>
								<div className="flex gap-x-2">
									<p className="">Queixa principal:</p>
									<p>{patient?.complaint}</p>
								</div>
								{planning?.consultant?.name && (
									<div className="flex items-center gap-x-2">
										<strong className="text-purple-500">Consultor Responsável:</strong>
										<p className="">{planning?.consultant?.name}</p>
										{user?.role === 1 && (
											<button
												className={classNames(
													'text-white text-sm px-4 py-1 rounded-md hover:opacity-75',
													savingDesignation ? 'bg-gray-400' : 'bg-red-400'
												)}
												onClick={handleDesignate}
												disabled={savingDesignation}
											>
												{savingDesignation ? 'Desalocando...' : 'Desalocar'}
											</button>
										)}
									</div>
								)}
							</div>
							<a href={`/paciente/${patient?.id}`} className="flex self-end text-xl underline">
								Ver histórico
							</a>
						</div>
						<div className="flex gap-6 justify-between md:flex-row my-6 flex-col md:items-center">
							<div className="flex gap-6 justify-between md:flex-row flex-col">
								<div className="flex items-center text-xl font-bold text-center sm:text-left">
									{planning?.status === 'completed' ? (
										`Respondido em: ${new Date(planning?.answered_at).toLocaleString()}`
									) : (
										`Solicitado em: ${new Date(planning?.created_at).toLocaleString()}`
									)}
									<ImClock className="mx-2" size={26} />
								</div>
							</div>
							{/* {planning?.status === 'completed' && user?.role === 1 && (
								<>
									{planning?.PlanningsEvaluation?.length > 0 ? (
										<>
											<p className="text-xl font-bold sm:text-left">Planejamento já está em avaliação</p>
										</>
									) : (
										<button
											type="button"
											disabled={sendingRequest}
											className={classNames(
												'py-2 px-20 self-center',
												'text-center text-2xl rounded-full text-admin-bc-white',
												'transition-colors duration-300 hover:bg-primary',
												'bg-primary'
											)}
											onClick={() => requestEvaluation()}
										>
											{sendingRequest ? 'Solicitando....' : 'Solicitar avaliação'}
										</button>
									)}
								</>
							)} */}
							<div className={classNames(
								'py-2 px-12 text-xl text-white rounded-full',
								getPlanningStatusColor(planning?.status, patient?.is_active !== 1)
							)}>
								{parsePlanningStatus(planning?.status, patient?.is_active !== 1)}
							</div>
						</div>
						{(planning?.status === 'completed' && planning?.answers) &&
							<>
								<div className="flex flex-col shadow-2xl mb-28">
									<div className="w-full h-min py-4 text-white text-xl bg-status-completed px-10">
										PLANEJAMENTO RESPONDIDO
									</div>
									<div
										className="w-full mt-3 py-3 px-10 border-b-2 border-gray-500"
									>
										<strong className="text-3xl">Diagnóstico</strong>
									</div>
									{planning?.answers?.map((answer) => {
										return (
											<React.Fragment key={`${answer.question}-${answer.answer}`}>
												{console.log(answer)}
												{answer.question.includes('Aparatologia Sugerida') && (
													<div
														className="w-full mt-16 py-3 px-10 border-b-2 border-gray-500"
													>
														<strong className="text-3xl">Planejamento / Mecânica</strong>
													</div>
												)}
												<div
													className="text-xl grid grid-cols-1 md:grid-cols-5 border-b-2 border-gray-500"
												>
													<strong className="py-3 px-10">{answer.question}</strong>
													<p className="col-span-2 px-10 py-3 border-l-2 border-gray-500">{answer.answer}</p>
													<p className="col-span-2 px-10 py-3 border-l-2 border-gray-500">{answer.complement}</p>
												</div>
											</React.Fragment>
										);
									})}
									{files.length > 0 && (
										<div className="pl-10 my-12 ">
											<strong className="text-xl">ANEXOS:</strong>
											<div className="flex flex-row gap-8 mt-8">
												{files.map((file, i) => (
													<a key={i} href={file} target="_blank" className="text-xl flex flex-col gap-2" rel="noreferrer">
														<HiOutlineDocumentDownload size={60} className="flex self-center" />
														Arquivo {i + 1}
													</a>
												))}
											</div>
										</div>
									)}
								</div>
							</>
						}
						{planning?.status === 'completed' && planning.video_link && (
							<div className="grid grid-cols-1 shadow-md mb-14">
								<div className="w-full h-min py-4 text-white text-xl bg-status-completed px-10">
									RESPONDIDO
								</div>
								<div className="text-xl px-10 mt-12">
									<strong>Video</strong>
									<div className="my-10">
										<video controls>
											<source src={planning.video_link} type="video/mp4" />
										</video>
									</div>
								</div>
							</div>
						)}
						{planning?.status === 'more_info' && (
							<div className="grid grid-cols-1 shadow-md mb-14">
								<div className="w-full h-min py-4 text-white text-xl bg-admin-orange-more-info px-10">
									ALTERAÇÕES SOLICITADAS PELA EQUIPE ZT CONSULTORIA
								</div>
								<div className="text-3xl px-10 mt-12">
									<strong>Observações:</strong>
									<p className="my-10 whitespace-pre-wrap">
										{planning.more_info}
									</p>
								</div>
								{user?.role === 2 && (
									<Link
										to={`/atualizar-planejamento/${planning?.id}`}
										className={classNames(
											'mb-4 mx-4 py-2 px-16 place-self-end bg-primary-dark',
											'text-center text-xl lg:text-2xl rounded-lg text-admin-bc-white font-bold',
											'transition-colors duration-300 hover:bg-primary',
										)}
									>
										Atualizar planejamento
									</Link>
								)}
							</div>
						)}
						<div className="grid grid-cols-1 shadow-2xl">
							<div className="w-full h-min py-4 text-white text-xl bg-primary px-10">
								INFORMAÇÕES ENVIADAS PELO DENTISTA
							</div>
							<div className="text-xl px-10 mt-12">
								<strong>Dúvidas:</strong>
								<p>{planning?.doubts || 'Não possui dúvidas'}</p>
							</div>
							<div className="text-xl px-10 mt-12">
								<p>{planning?.observations || 'Não possui observações'}</p>
							</div>
							<div className="text-xl px-10 mt-12 pb-12 ">
								<strong>Fotos:</strong>
								<div className="flex flex-wrap gap-8 mt-8">
									<ListPhotos
										list={getImagesFromObject(planning ?? {}).map((image, index) => ({
											id: index,
											url: image,
										}))}
									/>
								</div>
							</div>
							<div className="flex justify-center items-center gap-x-8 mb-4">
								{planning?.status !== 'completed' ? (
									<>
										{['pending', 'doing'].includes(planning?.status) && user?.role !== 2 && (
											<>
												<Link
													to={`/responder-planejamento/${planning?.id}`}
													className="bg-primary-dark px-4 py-2 text-white text-center text-xl"
												>
													{planning?.status === 'doing' ? 'Continuar' : 'Realizar'} planejamento
												</Link>
												<button
													type="button"
													className="border-2 border-primary-dark px-4 py-2 text-primary-dark text-center text-xl"
													onClick={() => setOpenModalVideo(true)}
												>
													Realizado via Zoom
												</button>
											</>
										)}

										{user?.role !== 2 && (
											<button
												className="bg-red-500 px-4 py-2 text-white text-center text-xl"
												onClick={() => setOpenModal(true)}
											>
												Solicitar Alterações
											</button>
										)}
									</>
								) : (
									<>
										<Link
											to={`/responder-planejamento/${planning?.id}`}
											className="bg-primary-dark px-4 py-2 text-white text-center text-xl"
											onClick={e => {
												if (!confirm('Este processo irá apagar o anterior.\nVocê tem certeza?')) {
													e.preventDefault();
												}
											}}
										>
											Editar planejamento finalizado
										</Link>
										<button
											type="button"
											className="border-2 border-primary-dark px-4 py-2 text-primary-dark text-center text-xl"
											onClick={() => {
												if (confirm('Este processo irá apagar o anterior.\nVocê tem certeza?')) {
													setOpenModalVideo(true);
												}
											}}
										>
											Enviar novo vídeo
										</button>
									</>
								)}
							</div>
						</div>
					</main>
				</div>
			</div>

			<ModalUploadVideo id_planning={idplanejamento} isOpen={openModalVideo} onClose={() => setOpenModalVideo(false)} onFinish={() => pushHistory(`/planejamento/${idplanejamento}`)} />
			<ModalMoreInfoPlanning id_planning={idplanejamento} isOpen={openModal} onClose={() => setOpenModal(false)} />
		</>
	);
}

export default PlanningDetail;
