import React, { useState } from 'react';
import { BiImageAdd } from 'react-icons/bi';

function ImageInput({ getImage, register, registerOptions = {}, name, image, label, className, error, ...rest }) {
	const [preview, setPreview] = useState(image);

	return (
		<div className="flex flex-col">
			<label
				htmlFor={name}
				className={`w-40 h-40 bg-contain bg-no-repeat bg-center bg cursor-pointer max-w-2xl rounded-lg text-black border-gray-600 bg-gray-100 flex justify-center items-center relative ${className ?? ''}`}
				style={{ backgroundImage: `url(${preview})` }}
			>
				{label}
				<input
					{...rest}
					{...register(name, registerOptions)}
					onChange={evt => {
						const item = evt.target.files.item(0);
						if (item) {
							const url = URL.createObjectURL(item);
							setPreview(url);
							getImage?.(item);
							return;
						}

						setPreview(image);
					}}
					id={name}
					type="file"
					accept="image/jpg, image/jpeg, image/png"
					className="sr-only"
				/>
				<BiImageAdd size={40} className="text-gray-800 absolute bottom-2 right-2" />
			</label>
			{error && (
				<small className="text-red-400">{error}</small>
			)}
		</div>
	);
}

export default ImageInput;
