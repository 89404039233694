import { Tab } from '@headlessui/react';
import React, { useState } from 'react';
import { Fragment } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { AiOutlineSearch } from 'react-icons/ai';

import classNames from '../utils/classNames';

function SearchBar({ optionsList, getSearch }) {
	const [currentTab, setCurrentTab] = useState(0);

	return (
		<div className="flex flex-col-reverse px-3 lg:px-0 lg:flex-row bg-primary overflow-hidden rounded-lg mt-6 lg:justify-between lg:pr-10">
			<div className="flex h-full flex-wrap justify-center lg:justify-start ">
				<Tab.List className="flex flex-col md:flex-row" >
					{optionsList?.map((op, i) => (
						<Tab key={op.id} as={Fragment}>
							{({ selected }) => {
								return (
									<button
										type="button"
										onClick={evt => {
											op?.onClick?.(evt);
											setCurrentTab(i);
										}}
										className={classNames(
											'px-8 md:px-10 py-4 text-xl whitespace-pre-wrap text-admin-bc-white font-bold hover:drop-shadow-lg hover:bg-primary-dark',
											selected ? 'drop-shadow-lg bg-primary-dark' : ''
										)}
									>
										{op.option}
									</button>
								);
							}}
						</Tab>
					))}
				</Tab.List>
			</div>
			{getSearch && (
				<div className="flex h-full relative mg:justify-end">
					<DebounceInput
						debounceTimeout={600}
						placeholder="Pesquisar"
						className="my-4 flex-1 lg:flex w-10 lg:w-72 rounded-md self-center h-7 lg:my-1 lg:ml-4 lg:h-9 pl-3 text-black"
						onChange={evt => getSearch(evt.target.value, optionsList[currentTab].option)}
					/>
					<AiOutlineSearch size={24} className="absolute right-4 top-1/2 transform -translate-y-1/2 text-admin-bc-grey" />
				</div>
			)}
		</div>
	);
}

export default SearchBar;
