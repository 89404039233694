import { Tab } from '@headlessui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BiCalendarCheck, BiCalendarEdit, BiCalendarExclamation } from 'react-icons/bi';
import { IoMdClose } from 'react-icons/io';
import ReactSelect from 'react-select';
import { useToasts } from 'react-toast-notifications';

import dentesBaixo from '../../assets/images/dentesBaixo.png';
import dentesCima from '../../assets/images/dentesCima.png';
import dentesDireita from '../../assets/images/dentesDireita.png';
import dentesEsquerda from '../../assets/images/dentesEsquerda.png';
import dentesFrente from '../../assets/images/dentesFrente.png';
import fotoLateral from '../../assets/images/fotoLateral.png';
import fotoSeria from '../../assets/images/fotoSeria.png';
import fotoSorrindo from '../../assets/images/fotoSorrindo.png';
import raioxLateral from '../../assets/images/raioxLateral.png';
import raioxPanoramico from '../../assets/images/raioxPanoramico.png';
import CounterCards from '../../components/CounterCards';
import FollowUpCard from '../../components/FollowUpCard';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import Menu from '../../components/Menu';
import Modal from '../../components/Modal';
import SearchBar from '../../components/SearchBar';
import { useAuth } from '../../contexts/auth';
import api from '../../services/api';
import classNames from '../../utils/classNames';

function FollowUp() {
	const [pageTitle, setPageTitle] = useState('Pendentes');
	const [followUps, setFollowUps] = useState({});
	const [loading, setLoading] = useState(false);
	const [buttonType, setButtonType] = useState('create');
	const [clinicList, setClinicList] = useState([]);
	const [patientList, setPatientList] = useState([]);
	const [openModal, setOpenModal] = useState(false);
	const [patientId, setPatientId] = useState();
	const [filterData, setFilterData] = useState({
		search: undefined,
	});

	const { user, signOut } = useAuth();
	const { addToast } = useToasts();
	const { register, handleSubmit, reset } = useForm();

	useEffect(() => {
		async function loadData() {
			setLoading(true);
			try {
				const { data: listFollowUp } = await api.get('/followup', {
					params: {
						dentist_id: user?.role === 2 ? user.id : undefined,
						search: filterData?.search,
					}
				});
				let tempData = [];
				if (user?.role === 1) {
					const { data: resClinicList } = await api.get('/manager/clinics');
					tempData = resClinicList;
				}

				setClinicList(tempData);
				setFollowUps(listFollowUp); // .map((item, index) => ({ ...item, position: index + 1 })).reverse());
			} catch (error) {
				if (error.response?.status === 401) {
					addToast('Você não tem permissão para acessar esta página.', {
						appearance: 'error',
						autoDismiss: true
					});

					signOut();
				}

				addToast('Erro ao carregar a lista de acompanhamentos', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				setLoading(false);
			}
		}

		loadData();
	}, []);

	useEffect(() => {
		async function handleSearch() {
			try {
				const { data: listFollowUp } = await api.get('/followup', {
					params: {
						dentist_id: user?.role === 2 ? user.id : undefined,
						search: filterData?.search,
					}
				});

				setFollowUps(listFollowUp); // .map((item, index) => ({ ...item, position: index + 1 })).reverse());
			} catch (error) {
				if (error.response?.status === 401) {
					addToast('Você não tem permissão para acessar esta página.', {
						appearance: 'error',
						autoDismiss: true
					});

					signOut();
				}

				addToast('Erro ao carregar a lista de acompanhamentos', {
					appearance: 'error',
					autoDismiss: true,
				});
			}
		}

		handleSearch();
	}, [user, filterData]);

	const onSubmit = useCallback(async (data) => {
		setLoading(true);

		try {
			if (!patientId) {
				throw new Error('Nenhum paciente selecionado');
			}

			const totalSelected = Object.keys(data).filter(key => data[key] === true).length;
			if (totalSelected < 3) {
				addToast('Selecione pelo menos 3 fotos', {
					appearance: 'warning',
					autoDismiss: true,
				});
				return;
			}

			await api.post('/followup/', {
				...data,
				patient_id: patientId,
				// dentist_id: patientData?.dentist.id,
			});

			addToast('Acompanhamento solicitado!', {
				appearance: 'success',
				autoDismiss: true,
			});
			setOpenModal(false);
			setButtonType('create');
			setPatientId(undefined);
			reset();
			setFilterData({ search: undefined });
		} catch (err) {
			if (err.response?.status === 401) {
				addToast('Sessão expirada.', {
					appearance: 'error',
					autoDismiss: true
				});

				signOut();
				return;
			}

			addToast(err.response?.data?.message || err.message || 'Erro ao solicitar acompanhamento', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setLoading(false);
		}
	}, [patientId]);

	return (
		<>
			<div className="flex">
				<Menu className="hidden xl:flex" />
				<div className="w-full flex flex-col">
					<Header textHeader={`Acompanhamentos | ${pageTitle}`} />
					<main className="w-full flex flex-col py-4 px-14">
						<div className="flex flex-col-reverse lg:flex-row justify-between">
							<CounterCards
								cardList={[
									{
										id: '1',
										title: 'Enviado pela consultoria',
										counter: followUps?.pending?.total,
										priColor: 'bg-admin-bage-light',
										secColor: 'bg-admin-bage-default',
										Icon: BiCalendarEdit,
									},
									{
										id: '2',
										title: 'Enviado pelo dentista',
										counter: followUps?.completed?.total,
										priColor: 'bg-primary',
										secColor: 'bg-primary',
										Icon: BiCalendarExclamation,
									},
									{
										id: '3',
										title: 'Acompanhamentos finalizados',
										counter: followUps?.finished?.total,
										priColor: 'bg-green-500',
										secColor: 'bg-green-500',
										Icon: BiCalendarCheck,
									},
								]}
							/>
							{user?.role === 2 && (
								<a
									href="/solicitar-acompanhamento"
									className={classNames(
										'mt-10 py-3 px-8 self-start',
										'text-center text-xl rounded-lg text-admin-bc-white font-bold whitespace-nowrap',
										'transition-all duration-300 bg-primary hover:bg-opacity-70',
									)}
								>
									Solicitar Acompanhamento
								</a>
							)}
							{user?.role === 1 && (
								<>
									{buttonType === 'create' && (
										<button
											type="button"
											onClick={() => setButtonType('inputs')}
											className={classNames(
												'mt-10 py-3 px-8 self-start',
												'text-center text-xl rounded-lg text-admin-bc-white font-bold whitespace-nowrap',
												'transition-all duration-300 bg-primary hover:bg-opacity-70',
											)}
										>
											Solicitar Acompanhamento
										</button>
									)}
									{buttonType === 'inputs' && (
										<div className="flex flex-col gap-y-4 w-80">
											<ReactSelect
												options={clinicList.map(c => ({ value: c.id, label: c.name }))}
												placeholder="Selecione uma clínica"
												onChange={e => setPatientList(clinicList.find(c => c.id === e.value).Patients)}
											/>
											<ReactSelect
												options={patientList.map(c => ({ value: c.id, label: c.name }))}
												isDisabled={!patientList.length}
												placeholder="Selecione um paciente"
												onChange={v => setPatientId(v.value)}
											/>
											<button
												type="button"
												disabled={!patientId}
												onClick={() => setOpenModal(true)}
												className={classNames(
													'py-2 px-16',
													'text-center text-xl rounded-lg text-admin-bc-white font-bold',
													'transition-all duration-300',
													!patientId ? 'cursor-not-allowed bg-gray-400' : 'bg-primary hover:bg-opacity-70',
												)}
											>
												Continuar
											</button>
											<button
												type="button"
												onClick={() => {
													setButtonType('create');
													setPatientId(undefined);
													reset();
												}}
												className={classNames(
													'py-2 px-16',
													'text-center text-xl rounded-lg border border-primary text-primary font-bold',
													'transition-colors duration-300 hover:bg-primary hover:text-white',
												)}
											>
												Cancelar
											</button>
										</div>
									)}
								</>
							)}
						</div>
						<Tab.Group >
							<SearchBar
								getSearch={search => setFilterData(old => ({ ...old, search }))}
								optionsList={[
									{
										id: '1',
										option: 'Enviado pela consultoria',
										onClick: () => setPageTitle('Enviado pela consultoria'),
									},
									{
										id: '2',
										option: 'Enviado pelo dentista',
										onClick: () => setPageTitle('Enviado pelo dentista'),
									},
									{
										id: '3',
										option: 'Acompanhamentos finalizados',
										onClick: () => setPageTitle('Acompanhamentos finalizados'),
									},
								]}
							/>
							<Tab.Panel className="grid grid-cols-1 mt-8 lg:grid-cols-2 gap-x-4 gap-y-3">
								{followUps?.pending?.total <= 0 ? (
									<div className="col-span-1 lg:col-span-2 text-center mt-4">
										<strong className="text-2xl">Nenhum acompanhamento</strong>
									</div>
								) : followUps?.pending?.items?.map(followUp => (
									<FollowUpCard key={followUp.id} followUp={followUp} />
								))}
							</Tab.Panel>
							<Tab.Panel className="grid grid-cols-1 mt-8 lg:grid-cols-2 gap-x-4 gap-y-3">
								{followUps?.completed?.total <= 0 ? (
									<div className="col-span-1 lg:col-span-2 text-center mt-4">
										<strong className="text-2xl">Nenhum acompanhamento</strong>
									</div>
								) : followUps?.completed?.items?.map(followUp => (
									<FollowUpCard key={followUp.id} followUp={followUp} />
								))}
							</Tab.Panel>
							<Tab.Panel className="grid grid-cols-1 mt-8 lg:grid-cols-2 gap-x-4 gap-y-3">
								{followUps?.finished?.total <= 0 ? (
									<div className="col-span-1 lg:col-span-2 text-center mt-4">
										<strong className="text-2xl">Nenhum acompanhamento finalizado</strong>
									</div>
								) : followUps?.finished?.items?.map(followUp => (
									<FollowUpCard key={followUp.id} followUp={followUp} />
								))}
							</Tab.Panel>
						</Tab.Group>
					</main>
				</div>
			</div>

			<Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
				<div className="h-full w-full bg-white rounded-2xl overflow-hidden flex flex-col">
					<div className="w-auto h-auto bg-primary px-10 py-4 gap-x-28  flex flex-row items-center justify-between">
						<strong className="text-3xl w-full text-white">
							Selecione as fotos desejadas:
						</strong>
						<button onClick={() => setOpenModal(false)}>
							<IoMdClose size={35} className="flex text-white" />
						</button>
					</div>
					<div className="flex flex-col py-6 gap-y-10 justify-center px-10">
						<form onSubmit={handleSubmit(onSubmit)} className="flex  flex-col gap-y-6">
							<label htmlFor="observation" className="w-full mb-4">
								<span className="text-xl text-black">Observação</span>
								<textarea
									{...register('observation')}
									id="observation"
									rows={5}
									className="w-full border border-gray-900 rounded-md p-2 text-lg"
								/>
							</label>
							<p className="text-xl text-black">
								Selecione no *Mínimo três fotos
							</p>
							<div className="flex flex-col md:flex-row gap-6 text-2xl text-white">
								<label htmlFor="checkFF" className="w-min h-min p-2 bg-gray-100 relative rounded">
									<div className="h-36 w-36 bg-contain bg-center bg-no-repeat" style={{ backgroundImage: `url(${fotoSeria})` }} />
									<input
										{...register('hasFaceFrontal')}
										id="checkFF"
										className="absolute flex right-2 bottom-2 h-8 w-8"
										type="checkbox"
									/>
								</label>
								<label htmlFor="checkFL" className="w-min h-min p-2 bg-gray-100 relative rounded">
									<div className="h-36 w-36 bg-contain bg-center bg-no-repeat" style={{ backgroundImage: `url(${fotoLateral})` }} />
									<input
										{...register('hasFaceLateral')}
										id="checkFL"
										className="absolute flex right-2 bottom-2 h-8 w-8"
										type="checkbox"
									/>
								</label>
								<label htmlFor="checkFS" className="w-min h-min p-2 bg-gray-100 relative rounded">
									<div className="h-36 w-36 bg-contain bg-center bg-no-repeat" style={{ backgroundImage: `url(${fotoSorrindo})` }} />
									<input
										{...register('hasFaceSorrindo')}
										id="checkFS"
										className="absolute flex right-2 bottom-2 h-8 w-8"
										type="checkbox"
									/>
								</label>
							</div>
							<div className="flex flex-col md:flex-row gap-6 text-2xl text-white">
								<label htmlFor="checkDF" className="w-min h-min p-2 bg-gray-100 relative rounded">
									<div className="h-36 w-36 bg-contain bg-center bg-no-repeat" style={{ backgroundImage: `url(${dentesFrente})` }} />
									<input
										{...register('hasDentesFrontal')}
										id="checkDF"
										className="absolute flex right-2 bottom-2 h-8 w-8"
										type="checkbox"
									/>
								</label>
								<label htmlFor="checkDE" className="w-min h-min p-2 bg-gray-100 relative rounded">
									<div className="h-36 w-36 bg-contain bg-center bg-no-repeat" style={{ backgroundImage: `url(${dentesEsquerda})` }} />
									<input
										{...register('hasDentesEsquerda')}
										id="checkDE"
										className="absolute flex right-2 bottom-2 h-8 w-8"
										type="checkbox"
									/>
								</label>
								<label htmlFor="checkDD" className="w-min h-min p-2 bg-gray-100 relative rounded">
									<div className="h-36 w-36 bg-contain bg-center bg-no-repeat" style={{ backgroundImage: `url(${dentesDireita})` }} />
									<input
										{...register('hasDentesDireita')}
										id="checkDD"
										className="absolute flex right-2 bottom-2 h-8 w-8"
										type="checkbox"
									/>
								</label>
								<label htmlFor="checkDC" className="w-min h-min p-2 bg-gray-100 relative rounded">
									<div className="h-36 w-36 bg-contain bg-center bg-no-repeat" style={{ backgroundImage: `url(${dentesCima})` }} />
									<input
										{...register('hasDentesCima')}
										id="checkDC"
										className="absolute flex right-2 bottom-2 h-8 w-8"
										type="checkbox"
									/>
								</label>
								<label htmlFor="checkDB" className="w-min h-min p-2 bg-gray-100 relative rounded">
									<div className="h-36 w-36 bg-contain bg-center bg-no-repeat" style={{ backgroundImage: `url(${dentesBaixo})` }} />
									<input
										{...register('hasDentesBaixo')}
										id="checkDB"
										className="absolute flex right-2 bottom-2 h-8 w-8"
										type="checkbox"
									/>
								</label>
							</div>
							<div className="flex flex-col md:flex-row gap-6 text-2xl text-white">
								<label htmlFor="checkRXL" className="w-min h-min p-2 bg-gray-100 relative rounded">
									<div className="h-36 w-36 bg-contain bg-center bg-no-repeat" style={{ backgroundImage: `url(${raioxLateral})` }} />
									<input
										{...register('hasRaioxLateral')}
										id="checkRXL"
										className="absolute flex right-2 bottom-2 h-8 w-8"
										type="checkbox"
									/>
								</label>
								<label htmlFor="checkRXP" className="w-min h-min p-2 bg-gray-100 relative rounded">
									<div className="h-36 w-36 bg-contain bg-center bg-no-repeat" style={{ backgroundImage: `url(${raioxPanoramico})` }} />
									<input
										{...register('hasRaioxPanoramico')}
										id="checkRXP"
										className="absolute flex right-2 bottom-2 h-8 w-8"
										type="checkbox"
									/>
								</label>
							</div>
							<button
								type="submit"
								disabled={loading}
								className={classNames(
									'mt-10 py-2 px-20 self-center',
									'text-center text-2xl rounded text-admin-bc-white',
									'transition-colors duration-300 hover:bg-primary',
									loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-primary'
								)}
							>
								{loading ? 'Enviando...' : 'Enviar solicitação'}
							</button>
						</form>
					</div>
				</div>
			</Modal>

			{loading && <Loader show={true} />}
		</>
	);
}

export default FollowUp;
