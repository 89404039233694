import React, { useCallback, useEffect, useState } from 'react';
import { BsFileEarmarkPlus, BsListOl, BsPlayCircle } from 'react-icons/bs';
import { FaRegSave } from 'react-icons/fa';
import { FaPen, FaTrash } from 'react-icons/fa';
import { HiOutlineDocumentText } from 'react-icons/hi';
import { IoMdClose } from 'react-icons/io';
import { useToasts } from 'react-toast-notifications';

import Header from '../../components/Header';
import Loader from '../../components/Loader';
import Menu from '../../components/Menu';
import Modal from '../../components/Modal';
import ProgressBar from '../../components/ProgressBar';
import OrderList from '../../components/videoaulas/OrderList';
import { useAuth } from '../../contexts/auth';
import api from '../../services/api';
import classNames from '../../utils/classNames';

function VideoAulas() {
	const [modal, setModal] = useState({ isOpen: false, name: '', id: null });
	const [loading, setLoading] = useState(false);
	const [videos, setVideos] = useState([]);
	const [reload, setReload] = useState(false);
	const [isOrdering, setIsOrdering] = useState(false);
	const [newOrder, setNewOrder] = useState([]);

	const { signOut, user } = useAuth();
	const { addToast } = useToasts();

	const getVideos = useCallback(async () => {
		setLoading(true);

		try {
			const { data } = await api.get('/manager/videos');
			setVideos(data);
		} catch (err) {
			if (err.response?.status === 401) {
				addToast('Você não tem permissão para acessar esta página.', {
					appearance: 'error',
					autoDismiss: true,
				});

				signOut();
			}
			addToast(err.response?.data?.message ?? 'Erro ao buscar as videoaulas.', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		getVideos();
	}, [reload]);

	const handleDeleteDentist = useCallback(async () => {
		setLoading(true);

		try {
			await api.delete(`/manager/videos/${modal.id}`);

			addToast('Módulo removido', {
				appearance: 'warning',
				autoDismiss: true,
			});

			setModal({ isOpen: false, name: '' });
			setReload((old) => !old);
		} catch (err) {
			if (err.response?.status === 401) {
				signOut();
				return;
			}

			addToast(err.response?.data?.message ?? 'Erro ao deletar módulo.', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setLoading(false);
		}
	}, [modal.id]);

	const handleSaveOrder = useCallback(async () => {
		setLoading(true);

		try {
			await api.patch('/manager/videos/order', newOrder);

			addToast('Ordem dos módulos alterada', {
				appearance: 'success',
				autoDismiss: true,
			});
			setReload((old) => !old);
		} catch (err) {
			if (err.response?.status === 401) {
				signOut();
				return;
			}

			addToast(err.response?.data?.message ?? 'Erro ao ordenar módulos.', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setLoading(false);
		}
	}, [newOrder]);

	return (
		<>
			<div className="flex">
				<Menu className="hidden xl:flex" />
				<div className="w-full flex flex-col">
					<Header textHeader="Vídeoaulas" />
					<main className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 py-4 px-14 container">
						<div className="col-span-full mt-6 flex items-center justify-between gap-x-2">
							<div className="flex items-center gap-x-2">
								<BsPlayCircle size={30} color="#1E4E7B" />
								<h2 className="text-xl font-medium">NOSSOS CURSOS</h2>
							</div>
							{user.role === 1 && (
								<div className="flex gap-x-4">
									<button
										type="button"
										onClick={async () => {
											setIsOrdering(!isOrdering);
											if (isOrdering) {
												await handleSaveOrder();
											}
										}}
										className={classNames(
											'flex items-center gap-x-2 py-2 px-4',
											'bg-primary text-white rounded-md',
											'border-2 border-primary',
											'hover:bg-white hover:text-primary',
											'transition-colors duration-150'
										)}
									>
										<span className="flex-1 text-xl">
											{isOrdering ? 'Salvar ordem' : 'Editar ordem'}
										</span>
										{isOrdering ? (
											<FaRegSave size={30} />
										) : (
											<BsListOl size={30} />
										)}
									</button>
									<a
										href="/adicionar-modulo"
										className={classNames(
											'flex items-center gap-x-2 py-2 px-4',
											'bg-primary text-white rounded-md',
											'border-2 border-primary',
											'hover:bg-white hover:text-primary',
											'transition-colors duration-150'
										)}
									>
										<span className="flex-1 text-xl">Novo módulo</span>
										<BsFileEarmarkPlus size={30} />
									</a>
								</div>
							)}
						</div>
						{isOrdering ? (
							<OrderList
								videos={videos}
								getOrder={(order) => setNewOrder(order)}
							/>
						) : (
							videos.map((video) => {
								return (
									<div
										key={video.id}
										className="mt-6 shadow-md rounded-4 w-full h-auto flex flex-col py-2 gap-4 rounded-lg drop-shadow-lg relative"
									>
										{user.role === 1 && (
											<>
												<a
													href={`/avaliacao/${video.id}`}
													title="Prova deste módulo"
													className={classNames(
														'absolute top-4 right-24 p-2',
														'z-10 rounded-full bg-white',
														'group hover:bg-gray-700'
													)}
												>
													<HiOutlineDocumentText className="text-gray-700 group-hover:text-white" />
												</a>
												<a
													href={`/editar-modulo/${video.id}`}
													title="Editar curso"
													className={classNames(
														'absolute top-4 right-14 p-2',
														'z-10 rounded-full bg-white',
														'group hover:bg-gray-700'
													)}
												>
													<FaPen className="text-gray-700 group-hover:text-white" />
												</a>
												<button
													type="button"
													title="Deletar curso"
													className={classNames(
														'absolute top-4 right-4 p-2',
														'z-10 rounded-full bg-white',
														'group hover:bg-red-500'
													)}
													onClick={() =>
														setModal({
															isOpen: true,
															name: video.title,
															id: video.id,
														})
													}
												>
													<FaTrash className="text-red-500 group-hover:text-white" />
												</button>
											</>
										)}
										<a
											href={`/modulo/${video.id}`}
											className="bg-primary aspect-w-10 aspect-h-6 rounded-lg h-32 w-full h-w flex self-center justify-self-center cursor-pointer bg-center bg-cover bg-no-repeat"
											style={
												video.thumbnail
													? { backgroundImage: `url(${video.thumbnail})` }
													: {}
											}
										/>
										<div className="flex w-full flex-1 items-between px-4">
											<p
												className="font-bold text-2xl self-start"
												title={video.title}
											>
												{video.title.length > 25
													? `${video.title.substring(0, 25)}...`
													: video.title}
											</p>
										</div>
										<div className="px-4">
											<ProgressBar progress={video.progress} />
										</div>
									</div>
								);
							})
						)}
					</main>
				</div>
			</div>
			<Loader show={loading} />
			<Modal
				isOpen={modal.isOpen}
				onClose={() => setModal({ isOpen: false, name: '', id: null })}
			>
				<div className="h-full w-full bg-white rounded-2xl overflow-hidden flex flex-col">
					<div className="w-auto h-auto bg-red-500 px-10 py-4 gap-x-28  flex flex-row items-center justify-between">
						<strong className="text-3xl w-full text-white">
							Exluir módulo
						</strong>
						<button
							onClick={() => setModal({ isOpen: false, name: '', id: null })}
						>
							<IoMdClose size={60} className="flex text-white" />
						</button>
					</div>
					<div className="flex flex-col py-14 gap-y-14 justify-center px-10">
						<p className="text-3xl text-black text-center">
							Você tem certeza que deseja excluir este módulo?
							<br />
							<br />
							<small>&ldquo;{modal.name}&rdquo;</small>
						</p>
						<div className="flex flex-col md:flex-row gap-6 justify-evenly text-2xl text-white">
							<button
								className="px-16 py-4 bg-primary rounded-xl"
								onClick={() => setModal({ isOpen: false, name: '', id: null })}
							>
								Não
							</button>
							<button
								onClick={handleDeleteDentist}
								disabled={loading}
								className={classNames(
									'px-16 py-4  rounded-xl',
									loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-red-500'
								)}
							>
								{loading ? 'Removendo...' : 'Sim'}
							</button>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
}

export default VideoAulas;
