
import imageCompression from 'browser-image-compression';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BsCollectionPlay } from 'react-icons/bs';
import { IoIosPerson, IoMdClose } from 'react-icons/io';
import { MdClear, MdDisplaySettings, MdOutlineEditNote } from 'react-icons/md';
import { useToasts } from 'react-toast-notifications';

import { useAuth } from '../contexts/auth';
import api from '../services/api';
import classNames from '../utils/classNames';
import toBase64 from '../utils/toBase64';
import HeaderlessSlideOver from './HeadlessSlideOver';
import Modal from './Modal';
import ProgressBar from './ProgressBar';


function ListDentists({ list: dentists, isSelectabled, getValues, reload }) {
	const [open, setOpen] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [openModalProgress, setOpenModalProgress] = useState(false);
	const [selected, setSelected] = useState([]);
	const [previewDentistImage, setPreviewDentistImage] = useState();
	const [loading, setLoading] = useState(false);
	const [idDentist, setIdDentist] = useState();
	const [dentistIndex, setDentistIndex] = useState(0);

	const [image, setImage] = useState();
	const { signOut } = useAuth();
	const { addToast } = useToasts();
	const {
		register: registerAddDentist,
		handleSubmit: handleSubmitAddDentist,
		formState: { errors: errorsAddDentist },
		reset: resetAddDentist,
		setValue: setDentistValue,
	} = useForm();

	useEffect(() => {
		if (isSelectabled) {
			setSelected([]);
		}
		return () => {
			setSelected([]);
		};
	}, [isSelectabled]);

	function handleSelected(id) {
		const arr = [...selected];
		const index = arr.findIndex(v => v === id);
		if (index >= 0) {
			arr.splice(index, 1);
		} else {
			arr.push(id);
		}
		setSelected(arr);
		getValues(arr);
	}

	const onSubmitDentist = useCallback(async ({ ...formData }) => {
		if (!formData.id) {
			addToast('Dentista não encontrado', { appearance: 'error', autoDismiss: true });
			return;
		}

		setLoading(true);

		try {
			let img = formData.oldImage;
			const profile_image = image;
			if (profile_image) {
				const compressed = await imageCompression(profile_image, {
					maxSizeMB: 1,
					maxWidthOrHeight: 480,
					useWebWorker: true
				});

				img = await toBase64(compressed);
			}

			await api.put(`/manager/dentists/${formData.id}`, {
				...formData,
				profile_image: img,
			});

			addToast('Dentista salvo', {
				appearance: 'success',
				autoDismiss: true
			});

			setLoading(false);
			setOpen(false);
			setPreviewDentistImage(undefined);
			resetAddDentist();
			reload();
		} catch (err) {
			if (err.response?.status === 401) {
				signOut();
				return;
			}

			addToast(err.response?.data?.message ?? 'Erro ao slavar dentista.', {
				appearance: 'error',
				autoDismiss: true
			});
		} finally {
			setLoading(false);
		}
	}, [addToast, signOut, resetAddDentist, image]);

	const handleDeleteDentist = useCallback(async () => {
		setLoading(true);

		try {
			await api.delete(`/manager/dentists/${idDentist}`);

			addToast('Dentista removido(a)', {
				appearance: 'warning',
				autoDismiss: true
			});

			setLoading(false);
			setOpenModal(false);
			reload();
		} catch (err) {
			if (err.response?.status === 401) {
				signOut();
				return;
			}

			addToast(err.response?.data?.message ?? 'Erro ao deletar dentista.', {
				appearance: 'error',
				autoDismiss: true
			});
		} finally {
			setLoading(false);
		}
	}, [idDentist, reload]);

	return (
		<>
			{dentists.length < 1 ?
				<div key={dentists.id} className="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-4">
					<div className="mt-6 shadow-md rounded-4 w-full h-auto flex flex-col sm:flex-row px-4 py-2 gap-4 rounded-lg drop-shadow-lg">
						<p className='flex self-center place-self-center text-xl font-bold py-6'>Nenhum dentista encontrado.</p>
					</div>
				</div>
				:
				<>
					<div key={dentists.id} className="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-4">
						{dentists.map((item, idx) => (
							<button
								key={item.id}
								onClick={() => {
									if (isSelectabled) {
										handleSelected(item.id);
									} else {
										setSelected([]);
									}
								}}
								className={classNames(
									'mt-6 shadow-md rounded-4 w-full h-auto flex flex-col sm:flex-row px-4 py-2 gap-4 rounded-lg drop-shadow-lg',
									isSelectabled ? 'cursor-pointer' : 'cursor-default'
								)}
							>
								<div
									className={
										classNames(
											'bg-primary rounded-full h-32 w-32 sm:h-20 sm:w-20 md:mr-3 flex self-center justify-self-center',
											'bg-center bg-no-repeat bg-cover',
										)
									}
									style={{ backgroundImage: `url(${item.profile_image})` }}
								/>
								<div className="grid sm:grid-cols-2 gap-y-2 items-center flex-1 justify-between">
									<div className="flex flex-col justify-evenly sm:justify-start">
										<p className="font-bold text-xl ml-2 self-start">{item.name}</p>
										<p className="ml-2 self-start">{item.email}</p>
									</div>
									<div className="flex flex-col-reverse md:flex-col text-left justify-evenly w-full md:text-right text-xl">
										<div className="flex flex-row md:self-end ">
											<button
												onClick={() => {
													setDentistIndex(idx);
													setOpenModalProgress(true);
												}}
												title="Progresso nos cursos"
												className="mr-2"
											>
												<BsCollectionPlay size={25} />
											</button>
											<button
												onClick={() => {
													setPreviewDentistImage(item.profile_image);
													setDentistValue('oldImage', item.profile_image);
													setDentistValue('id', item.id);
													setDentistValue('name', item.name);
													setDentistValue('email', item.email);

													setOpen(true);
												}}
											>
												<MdOutlineEditNote size={30} className="self-center" />
											</button>
											<button onClick={() => {
												setIdDentist(item.id);
												setOpenModal(true);
											}}>
												<MdClear size={30} />
											</button>
										</div>
										{/* <div>
                      <p className="">{item.phone} </p>
                      <p className="">{item.address?.city} - {item.address?.state} </p>
                    </div> */}
									</div>
								</div>
							</button>
						))}
					</div>
					<HeaderlessSlideOver
						open={open}
						setOpen={setOpen}
						title="Menu"
						className="absolute"
						side="ri"
					>
						<div className="overflow-y-auto max-h-screen h-screen w-auto flex  flex-col bg-white">
							<div className="flex w-full py-10 flex-col h-24 text-center justify-center bg-primary text-3xl text-white">Editar Dentista</div>
							<div className=" w-auto flex p-10 flex-col gap-10 bg-white">
								<form onSubmit={handleSubmitAddDentist(onSubmitDentist)} className="gap-10 flex flex-col">
									<input {...registerAddDentist('id')} type="hidden" name="id" />
									<input {...registerAddDentist('oldImage')} type="hidden" name="oldImage" />
									<strong>Clique abaixo para selecionar a imagem do dentista</strong>
									<label
										htmlFor="profile_image"
										className={classNames(
											'w-28 h-28 place-self-center flex p-2 rounded-full border-2 border-primary bg-transparent',
											'bg-center bg-cover bg-no-repeat cursor-pointer'
										)}
										style={{ backgroundImage: `url(${previewDentistImage})` }}
									>
										{!previewDentistImage && (
											<IoIosPerson size={65} className="text-primary py-2" />
										)}
										<input
											{...registerAddDentist('profile_image')}
											id="profile_image"
											type="file"
											accept="image/png,image/jpg,image/jpeg,image/webp"
											className="sr-only"
											onChange={evt => {
												const file = evt.currentTarget.files.item(0);
												if (file) {
													const imageUrl = URL.createObjectURL(file);
													setPreviewDentistImage(imageUrl);
													setImage(file);
													return;
												}
												setImage(undefined);
												setPreviewDentistImage(undefined);
											}}
										/>
									</label>
									{errorsAddDentist.profile_image && (
										<small className="text-red-400 self-center">{errorsAddDentist.profile_image.message}</small>
									)}
									<div className="flex gap-4 flex-col">
										<strong>Nome:</strong>
										<input
											{...registerAddDentist('name')}
											placeholder="Digite o nome"
											className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11"
										/>
										{errorsAddDentist?.name && (
											<small className="text-red-400">{errorsAddDentist.name.message}</small>
										)}
									</div>
									<div className="flex gap-4 flex-col">
										<strong>E-mail:</strong>
										<input
											{...registerAddDentist('email')}
											type="email"
											placeholder="Digite o e-mail"
											className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11"
										/>
										{errorsAddDentist?.email && (
											<small className="text-red-400">{errorsAddDentist.email.message}</small>
										)}
									</div>
									<div className="flex gap-4 flex-col">
										<strong>Senha:</strong>
										<input
											{...registerAddDentist('password', { min: 'Digite no mínimo 6 caractéres' })}
											type="password"
											placeholder="Digite a senha"
											className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11"
										/>
										{errorsAddDentist?.password && (
											<small className="text-red-400">{errorsAddDentist.password.message}</small>
										)}
									</div>
									<button
										type="submit"
										disabled={loading}
										className={classNames(
											'w-full h-14 mt-6 rounded-lg text-3xl text-white',
											loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-primary'
										)}
									>
										{loading ? 'Salvando...' : 'Salvar Dentista'}
									</button>
								</form>
							</div>
						</div>
					</HeaderlessSlideOver>
					<Modal isOpen={openModal} onClose={() => setOpenModal(false)}  >
						<div className="h-full w-full bg-white rounded-2xl overflow-hidden flex flex-col">
							<div className="w-auto h-auto bg-red-500 px-10 py-4 gap-x-28  flex flex-row items-center justify-between">
								<strong className="text-3xl w-full text-white">
									Exluir Dentista
								</strong>
								<button onClick={() => setOpenModal(false)}>
									<IoMdClose size={40} className="flex text-white" />
								</button>
							</div>
							<div className="flex flex-col py-14 gap-y-32 justify-center px-10">
								<p className="text-3xl text-black text-center">
									Você tem certeza que deseja excluir este(a) dentista?
								</p>
								<div className="flex flex-col md:flex-row gap-6 justify-evenly text-2xl text-white">
									<button className="px-16 py-4 bg-primary rounded-xl" onClick={() => setOpenModal(false)}>
										Não
									</button>
									<button
										onClick={handleDeleteDentist}
										disabled={loading}
										className={classNames(
											'px-16 py-4  rounded-xl',
											loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-red-500'
										)}
									>
										{loading ? 'Removendo...' : 'Sim'}
									</button>
								</div>
							</div>
						</div>
					</Modal>
					<Modal isOpen={openModalProgress} onClose={() => setOpenModalProgress(false)}  >
						<div className="h-full w-full bg-white rounded-2xl overflow-hidden flex flex-col">
							<div className="w-auto h-auto px-10 py-4 gap-x-28  flex flex-row items-center justify-between">
								<strong className="text-3xl w-full">
									Progresso do dentista nos módulos:
								</strong>
								<button onClick={() => setOpenModalProgress(false)}>
									<IoMdClose size={40} />
								</button>
							</div>
							<div className="flex flex-col py-14 gap-y-8 justify-center px-10">
								{dentists[dentistIndex]?.moduleProgress?.map(m => (
									<div key={m.id} className="flex">
										<img
											src={m.thumbnail}
											className={
												classNames(
													'bg-primary rounded-md h-32 w-52 sm:h-20 sm:w-40 md:mr-3 flex self-center justify-self-center',
													'object-center object-cover',
												)
											}
										/>
										<div className="self-stretch flex-1 flex flex-col justify-evenly">
											<p className="">{m.title}</p>
											<ProgressBar progress={m.progress} />
										</div>
									</div>
								))}
							</div>
						</div>
					</Modal>
				</>
			}
		</>
	);
}

export default ListDentists;
