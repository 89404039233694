import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import PlanningInputFile from '../../components/PlanningInputFile';
import PlanningSelect from '../../components/PlanningSelect';
import classNames from '../../utils/classNames';

function Step7({ selected, questions, defaultValues, onSubmit: onSubmitProp, onBack, sending }) {

	const { control, register, handleSubmit, setValue, formState: { errors } } = useForm({ defaultValues });

	const [file1, setFile1] = useState();
	const [file2, setFile2] = useState();
	const [file3, setFile3] = useState();

	useEffect(() => {
		const filesArray = defaultValues.arquivos?.answer?.split(';');
		if (filesArray) {
			filesArray.forEach((file, i) => {
				if (i === 0) setFile1(file);
				if (i === 1) setFile2(file);
				if (i === 2) setFile3(file);
			});
		}
	}, []);

	const onSubmit = useCallback(data => {
		const files = [];

		if (file1) { files.push(file1); }
		if (file2) { files.push(file2); }
		if (file3) { files.push(file3); }

		onSubmitProp({
			files,
			observacoes: data.observacoes,
			tempo_previsto: data.tempo_previsto,
		});
	}, [selected, questions, onSubmitProp, file1, file2, file3]);

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={`${selected === 7 ? '' : 'hidden'}`}>
			<div className="grid grid-cols-1 mt-6 px-14 text-2xl gap-y-20 justify-items-center">
				<div className="w-full flex flex-col py-4 gap-4">
					<strong>Insira abaixo videos ou Documentos importantes:</strong>
					<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
						<PlanningInputFile
							register={register}
							name={'files.0.file'}
							label={'Selecionar arquivo 1'}
							remove={() => setValue('files.0.file', '')}
							defaultFile={typeof file1 === 'string' ? file1 : undefined}
							change={file => {
								if (file) {
									setFile1(file);
									return;
								}
								setFile1(undefined);
							}}
						/>
						<PlanningInputFile
							register={register}
							name={'files.1.file'}
							label={'Selecionar arquivo 2'}
							remove={() => setValue('files.1.file', '')}
							defaultFile={typeof file2 === 'string' ? file2 : undefined}
							change={file => {
								if (file) {
									setFile2(file);
									return;
								}
								setFile2(undefined);
							}}
						/>
						<PlanningInputFile
							register={register}
							name={'files.2.file'}
							label={'Selecionar arquivo 3'}
							remove={() => setValue('files.2.file', '')}
							defaultFile={typeof file3 === 'string' ? file3 : undefined}
							change={file => {
								if (file) {
									setFile3(file);
									return;
								}
								setFile3(undefined);
							}}
						/>
					</div>
				</div>
				<div className="flex flex-col gap-4 w-full">
					<strong className="">Preencha o campo abaixo com suas observações:</strong>
					<input
						{...register('observacoes.question')}
						type="hidden"
						defaultValue="Observações"
					/>
					<textarea
						{...register('observacoes.answer')}
						rows={10}
						placeholder="Insira aqui suas observações..."
						className="border-2 border-gray-300 rounded-md p-1"
					>
					</textarea>
					{errors.observacoes?.answer && (
						<small className="text-red-400">{errors.observacoes?.answer.message}</small>
					)}
				</div>
				<div className="grid grid-cols-2 w-full">
					<div className="col-span-full md:col-span-1">
						<PlanningSelect
							control={control}
							register={register}
							name="tempo_previsto"
							defaultComplement={defaultValues['tempo_previsto']}
							questions={questions['Tempo de tratamento previsto']}
							label="Tempo de tratamento previsto"
							errors={errors}
							setValue={setValue}
							isRequired='Campo Obrigatório'
						/>
					</div>
				</div>
			</div>
			<div className="flex md:flex-row flex-col justify-center gap-x-10">
				<button
					type="button"
					onClick={onBack}
					className={classNames(
						'mt-10 py-1 px-36 self-center border-2 border-primary-dark text-primary-dark',
						'text-center text-xl lg:text-2xl rounded-lg font-bold',
						'transition-colors duration-300 hover:bg-primary-dark hover:text-white'
					)}
				>
					Voltar
				</button>
				<button
					type="submit"
					disabled={sending}
					className={classNames(
						'mt-10 py-2 px-36 self-center',
						'text-center text-xl lg:text-2xl rounded-lg text-admin-bc-white font-bold',
						'transition-colors duration-300',
						sending ? 'bg-gray-400 cursor-wait' : 'bg-primary-dark hover:bg-primary'
					)}
				>
					{sending ? 'Enviando...' : 'Enviar'}
				</button>
			</div>
		</form>
	);
}

export default Step7;
