import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import PlanningSelect from '../../components/PlanningSelect';
import classNames from '../../utils/classNames';

function Step4({ selected, questions, defaultValues, sending, onNext, onBack }) {
	const { register, handleSubmit, control, setValue, formState: { errors } } = useForm({ defaultValues });

	const onSubmit = useCallback(data => {
		const pageAnswers = JSON.parse(JSON.stringify({
			aparatologia_sugerida: data.aparatologia_sugerida,
			correção_transversal: data.correção_transversal,
		}));
		onNext(pageAnswers);
	}, [selected, questions, onNext]);

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={`${selected === 4 ? '' : 'hidden'}`}>
			<div className="grid md:grid-cols-2 grid-cols-1 mt-6 text-2xl gap-y-20 justify-items-center">
				<PlanningSelect
					control={control}
					register={register}
					name="aparatologia_sugerida"
					defaultComplement={defaultValues['aparatologia_sugerida']}
					questions={questions['Aparatologia Sugerida']}
					label="Aparatologia Sugerida"
					errors={errors}
					setValue={setValue}
					isRequired='Campo Obrigatório'
				/>
				<PlanningSelect
					control={control}
					register={register}
					name="correção_transversal"
					defaultComplement={defaultValues['correção_transversal']}
					questions={questions['Correção Transversal']}
					label="Correção Transversal"
					errors={errors}
					setValue={setValue}
					isRequired='Campo Obrigatório'
				/>
			</div>
			<div className="flex md:flex-row flex-col justify-center gap-x-10">
				<button
					type="button"
					onClick={onBack}
					className={classNames(
						'mt-10 py-1 px-36 self-center border-2 border-primary-dark text-primary-dark',
						'text-center text-xl lg:text-2xl rounded-lg font-bold',
						'transition-colors duration-300 hover:bg-primary-dark hover:text-white'
					)}
				>
					Voltar
				</button>
				<button
					type="submit"
					disabled={sending}
					className={classNames(
						'mt-10 py-2 px-36 self-center',
						'text-center text-xl lg:text-2xl rounded-lg text-admin-bc-white font-bold',
						'transition-colors duration-300',
						sending ? 'bg-gray-400 cursor-wait' : 'bg-primary-dark hover:bg-primary'
					)}
				>
					Próximo
				</button>
			</div>
		</form>
	);
}

export default Step4;
