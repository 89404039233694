import React from 'react';
import { AiOutlineCheck } from 'react-icons/ai';

import classNames from '../utils/classNames';
import { getPlanningStatusColor, parsePlanningStatus } from '../utils/status';

function PlanningCard({ planning, isSelectabled, selected, index, handleSelected }) {
	const answered_at = typeof planning.answered_at === 'string' ? new Date(planning.answered_at) : planning.answered_at;
	const expires_in = typeof planning.expires_in === 'string' ? new Date(planning.expires_in) : planning.expires_in;
	const created_at = typeof planning.created_at === 'string' ? new Date(planning.created_at) : planning.created_at;

	return (
		<a
			key={planning.id}
			href={'/planejamento/' + planning.id}
			className="relative mt-6 shadow-md rounded-4 w-full h-auto flex flex-col sm:flex-row px-4 py-2 gap-4 rounded-lg drop-shadow-lg"
			onClick={evt => {
				if (isSelectabled) {
					evt.preventDefault();
				}
			}}
		>
			<div
				className="bg-cover bg-center rounded-full h-32 w-32 sm:h-20 sm:w-20 md:mr-3 flex self-center justify-self-center"
				style={{ backgroundImage: `url(${planning.patient.profile_image})` }}
			/>
			<div className="grid sm:grid-cols-2 gap-y-2 flex-1 justify-between">
				<div className="flex flex-col justify-evenly sm:justify-start">
					<p className="font-bold text-xl ml-2 self-start">{planning.patient.name}</p>
					<div className="flex ml-2">
						<strong className="text-sm">Clínica:</strong>
						<p className="text-sm ml-2">{planning.patient.clinic.name}</p>
					</div>
					{planning?.consultant?.name && (
						<div className="flex ml-2">
							<strong className="text-sm text-purple-500">Consultor Responsável:</strong>
							<p className="text-sm ml-2">{planning?.consultant?.name}</p>
						</div>
					)}
					{planning?.answered_by && !planning?.consultant && (
						<div className="flex ml-2">
							<strong className="text-sm text-primary">Responsável:</strong>
							<p className="text-sm ml-2">{planning?.answered_by}</p>
						</div>
					)}
				</div>
				<div className="flex flex-col sm:flex-row justify-evenly sm:justify-end w-full md:self-end">
					<div className="flex flex-col sm:flex-row-3 sm:self-end">
						<div className="flex ml-2 sm:self-end">
							<strong className="text-sm sm:text-base">Solicitado: </strong>
							<p className="text-sm sm:text-base ml-2">{created_at?.toLocaleDateString()}</p>
						</div>
						{planning.status !== 'more_info' && (
							<div className="flex ml-2 gap-1 sm:self-end">
								<strong className="text-sm sm:text-base">{planning.status === 'completed' ? 'Respondido' : 'Expira'}: </strong>
								<p className="text-sm sm:text-base">
									{
										planning.status === 'completed'
											? answered_at?.toLocaleDateString()
											: expires_in?.toLocaleDateString()
									}
								</p>
								<div
									className={classNames(
										'w-3 h-3 self-center rounded-full',
										expires_in < new Date() && planning.status !== 'completed'
											? 'bg-red-700'
											: planning.status === 'completed'
												? 'bg-status-completed'
												: 'bg-primary'
									)}
								/>
							</div>
						)}
						<div className="flex justify-center flex-row-reverse md:flex-row align-middle ml-2 text-sm gap-1 lg:self-end">
							<p className={classNames(
								'rounded-full py-1 px-2 text-xs text-white text-center',
								getPlanningStatusColor(planning?.status, planning?.patient?.is_active !== 1)
							)}>
								{parsePlanningStatus(planning?.status, planning?.patient?.is_active !== 1)}
							</p>
						</div>
					</div>
				</div>
			</div>
			{isSelectabled && (
				<button
					onClick={() => {
						if (isSelectabled) {
							handleSelected(planning.id);
						}
					}}
					className={classNames(
						'absolute sm:relative top-2 right-2'
					)}
				>
					{selected ? (
						<AiOutlineCheck size={32} className="p-1 ml-auto rounded-full bg-primary text-white drop-shadow-2xl" />
					) : (
						<div className="w-8 h-8 ml-auto mt-0 border border-solid border-primary-dark rounded-full" />
					)}
				</button>

			)}
		</a>
	);
}

export default PlanningCard;
