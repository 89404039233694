import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useToasts } from 'react-toast-notifications';

import api from '../services/api';
import { useAuth } from './auth';


const PlanningsContext = createContext({});

export function PlanningsProvider({ getConsultants = true, idConsultant, children }) {
	const [pageTitle, setPageTitle] = useState('Pendentes');
	const [buttonType, setButtonType] = useState('plan');
	const [selectedToDesignate, setSelectedToDesignate] = useState([]);
	const [loading, setLoading] = useState(true);
	const [listConsultor, setListConsultor] = useState([]);
	const [listClinics, setListClinics] = useState([]);
	const [consultantId, setConsultantId] = useState(null);
	const [counters, setCounters] = useState({
		inactive: 0,
		pending: 0,
		completed: 0,
		more_info: 0,
	});
	const [plannings, setPlannings] = useState({
		pending: { page: 0, pages: 0, perPage: 0, total: 0, plannings: [] },
		completed: { page: 0, pages: 0, perPage: 0, total: 0, plannings: [] },
		inactive: { page: 0, pages: 0, perPage: 0, total: 0, plannings: [] },
		more_info: { page: 0, pages: 0, perPage: 0, total: 0, plannings: [] },
	});

	const { user, signOut } = useAuth();
	const { addToast } = useToasts();
	const { push: pushHistory } = useHistory();

	const searchPlannings = useCallback(async ({
		status,
		consultantId,
		clinicId,
		dentistId,
		search,
		dateInit,
		dateEnd,
		dateType,
		page,
	}) => {
		setLoading(true);
		try {
			const { data } = await api.get('/plannings', {
				params: {
					status,
					consultantId,
					clinicId,
					dentistId,
					search,
					dateInit,
					dateEnd,
					dateType,
					page,
				}
			});

			data.plannings.map(item => {
				item.created_at = new Date(item.created_at);
				item.expires_in = new Date(item.expires_in);
				item.updated_at = new Date(item.updated_at);
				return item;
			});

			setPlannings(old => ({
				...old,
				[status ?? 'pending']: data
			}));
		} catch (err) {
			if (err.response?.status === 401) {
				addToast(err.response.data?.message || 'Você não tem permissão para acessar essa página', {
					appearance: 'error',
					autoDismiss: true,
				});

				signOut();
				pushHistory('/');
			}

			addToast(err.response?.data?.message || 'Erro ao carregar os planejamentos', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		async function getConsultantClinicsCounters() {
			try {
				if (user?.role === 1 && getConsultants) {
					const [
						{ data: resConsultants },
						{ data: resClinicas },
						{ data: resCounters },
					] = await Promise.all([
						api.get('/manager/consultants'),
						api.get('/manager/clinics'),
						api.get('/plannings/counters'),
					]);
					setListConsultor(resConsultants);
					setListClinics(resClinicas);
					setCounters(resCounters);
				} else {
					const { data } = await api.get('/plannings/counters', {
						params: { idConsultant }
					});
					setCounters(data);
				}
			} catch (err) {
				if (err.response?.status === 401) {
					addToast(err.response.data?.message || 'Você não tem permissão para acessar essa página', {
						appearance: 'error',
						autoDismiss: true,
					});

					signOut();
					pushHistory('/');
				}

				addToast(err.response?.data?.message || 'Erro ao carregar os consultores', {
					appearance: 'error',
					autoDismiss: true,
				});
			}
		}

		getConsultantClinicsCounters();
	}, []);

	const selectToDesignate = useCallback((id) => {
		const arr = [...selectedToDesignate];
		const index = arr.findIndex(v => v === id);

		if (index >= 0) {
			arr.splice(index, 1);
		} else {
			arr.push(id);
		}

		setSelectedToDesignate(arr);
	}, [selectedToDesignate]);

	const submitDesignatePlanning = useCallback(async () => {
		if (selectedToDesignate.length <= 0) {
			addToast('Selecione pelo menos um planejamento', {
				appearance: 'warning',
				autoDismiss: true,
			});
			return;
		}

		if (!consultantId) {
			addToast('Selecione um consultor', {
				appearance: 'warning',
				autoDismiss: true,
			});
			return;
		}

		setLoading(true);
		try {
			const { data } = await api.post('/plannings/designate', {
				plannings: selectedToDesignate,
				consultantId: +consultantId,
			});

			addToast(data.message, {
				appearance: 'success',
				autoDismiss: true,
			});

			setSelectedToDesignate([]);
			setButtonType('plan');
			setConsultantId(null);
			return true;
		} catch (err) {
			addToast(err.response?.data?.message || 'Erro ao designar os planejamentos', {
				appearance: 'error',
				autoDismiss: true,
			});
			setLoading(false);
			return false;
		}
	}, [selectedToDesignate, consultantId]);

	return (
		<PlanningsContext.Provider value={{
			plannings,
			pageTitle,
			buttonType,
			loading,
			listConsultor,
			listClinics,
			selectedToDesignate,
			counters,
			consultantId,
			setConsultantId,
			setPageTitle,
			setButtonType,
			searchPlannings,
			selectToDesignate,
			submitDesignatePlanning,
		}}>
			{children}
		</PlanningsContext.Provider>
	);
}

/**
 * Plannings context
 * @returns {{
 * 	plannings: {
 * 		pending: { page: number; pages: number; perPage: number; total: number; plannings: any[]; };
 * 		completed: { page: number; pages: number; perPage: number; total: number; plannings: any[]; };
 * 		inactive: { page: number; pages: number; perPage: number; total: number; plannings: any[]; };
 * 		more_info: { page: number; pages: number; perPage: number; total: number; plannings: any[]; };
 * 	};
 * 	pageTitle: string;
 * 	buttonType: string;
 * 	loading: boolean;
 * 	listConsultor: any[];
 * 	listClinics: any[];
 * 	counters: { inactive: number; pending: number; completed: number; more_info: number; }
 * 	consultantId: string | null;
 * 	setConsultantId: React.Dispatch<React.SetStateAction<string | null>>;
 * 	selectedToDesignate: any[]
 * 	setPageTitle: React.Dispatch<React.SetStateAction<string>>;
 * 	setButtonType: React.Dispatch<React.SetStateAction<string>>;
 * 	searchPlannings: ({ status, consultantId, clinicId, dentistId, search, dateInit, dateEnd, dateType, page }: any) => Promise<void>;
 * 	selectToDesignate: (id: number) => void;
 * 	submitDesignatePlanning: (consultantId: string) => Promise<void>;
 * }}
 */
export function usePlannings() {
	const context = useContext(PlanningsContext);
	if (!context) throw new Error('`usePlannings` must be used within PlanningsProvider');
	return context;
}
