import React from 'react';
import { BsPersonFill } from 'react-icons/bs';
import { FaUserNurse } from 'react-icons/fa';

import classNames from '../utils/classNames';

function ListClinics({ list }) {
	return (
		<>
			{list.length < 1 ?
				<div className="mt-6 shadow-md rounded-4 w-full h-auto flex flex-col sm:flex-row px-4 py-2 gap-4 rounded-lg drop-shadow-lg">
					<p className='flex self-center place-self-center text-xl font-bold py-6'>Nenhuma clinica encontrada.</p>
				</div>
				:
				<>
					{list.map(item => (
						<a
							key={item.id}
							href={`clinica/${item.id}`}
							className=" shadow-md rounded-4 w-full h-auto flex flex-col sm:flex-row px-4 py-2 gap-4 rounded-lg drop-shadow-lg"
						>

							<div
								className={
									classNames(
										'bg-primary rounded-full h-32 w-32 sm:h-20 sm:w-20 md:mr-3 flex self-center justify-self-center',
										'bg-center bg-no-repeat bg-cover',
									)
								}
								style={{ backgroundImage: `url(${item.image})` }}
							/>
							<div className="flex flex-col w-full flex-1 items-between px-4">
								<p className="font-bold text-2xl self-start">{item.name}</p>
								<div className="text-lg">
									<p className="self-start">{item.address.street}, {item.address.number} - {item.address.neighborhood} - {item.address.zipcode}</p>
									<p className="self-start">{item.address.city}, {item.address.state}</p>
								</div>
							</div>
							<div className="flex flex-col-reverse lg:flex-col justify-evenly items-end gap-1">
								<div className="flex flex-row gap-2 text-lg">
									<p>{item.users.length} Dentistas</p>
									<FaUserNurse size={25} className="text-green-400" />
								</div>
								<div className="flex flex-row gap-2 text-lg">
									<p>{item.Patients.length} Pacientes</p>
									<BsPersonFill size={25} className="text-purple-500" />
								</div>
							</div>
						</a>
					))}
				</>
			}
		</>
	);
}

export default ListClinics;
