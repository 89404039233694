import React from 'react';
import { BiMedal } from 'react-icons/bi';
import { Link as RouterLink, useHistory, withRouter } from 'react-router-dom';

import { useAuth } from '../contexts/auth';
import classNames from '../utils/classNames';
import dentistAccountLevel from '../utils/dentistAccountLevel';

const Link = withRouter(({ location, href, children }) => {
	return (
		<RouterLink
			to={href}
			className={classNames(
				'h-20 w-full px-4 flex items-center justify-center transition text-white hover:bg-primary-dark',
				`${
					location.pathname.includes(href.replace('/', ''))
						? 'bg-primary-dark'
						: ''
				}`
			)}
		>
			{children}
		</RouterLink>
	);
});

const Menu = ({ className }) => {
	const { user, signOut } = useAuth();
	const { push: historyPush } = useHistory();

	if (user?.role === 4) return null;

	return (
		<div
			className={classNames(
				'flex-col min-h-screen items-center text-2xl',
				'bg-primary',
				className
			)}
		>
			<div className="flex items-center gap-4 py-4 px-2">
				<img
					loading="lazy"
					src={user?.profile_image}
					alt={user?.name?.slice(/\s/)?.[0]}
					className="w-16 h-16 rounded-full object-cover object-center"
				/>
				<div className="flex flex-col text-white leading-5">
					<strong>{user?.name?.split(/\s/)[0]}</strong>
					<small>
						{user?.role === 1
							? 'Gestor'
							: user?.role === 2
							? 'Dentista'
							: 'Consultor'}
					</small>
				</div>
				{user?.role === 2 && (
					<BiMedal
						size={35}
						color={dentistAccountLevel(user.media).color}
						title={dentistAccountLevel(user.media).name}
					/>
				)}
			</div>
			<Link href="/planejamentos">Planejamentos</Link>
			{user?.role === 1 && <Link href="/clinicas">Clínicas</Link>}
			{user?.role === 2 && <Link href="/pacientes">Pacientes</Link>}
			<Link href="/videoaulas">Vídeoaulas</Link>
			{user?.role === 1 && <Link href="/consultores">Consultores</Link>}
			<Link href="/acompanhamentos">Acompanhamentos</Link>
			<Link href="/validacao">Validação</Link>
			<button
				type="button"
				className={classNames(
					'h-20 w-full px-4 flex items-center justify-center font-bold text-white transition-colors mt-auto',
					'bg-primary hover:bg-primary-dark'
				)}
				onClick={async () => {
					await signOut();
					historyPush('/');
				}}
			>
				Sair
			</button>
		</div>
	);
};

export default Menu;
