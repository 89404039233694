import { Document, Image, Link, Page, PDFViewer, StyleSheet, Text, View } from '@react-pdf/renderer';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import iconInsta from '../assets/images/icon-insta-pdf.png';
import iconPhone from '../assets/images/icon-phone-pdf.png';
import ztLogo from '../assets/images/logo_zt_header.png';
import Loader from '../components/Loader';
import api from '../services/api';

function toLocaleDate(date) {
	if (date) return new Date(date).toLocaleDateString();
	return new Date().toLocaleDateString();
}

function PDFPlannings() {
	const [plannings, setPlannings] = useState([]);
	const [loading, setLoading] = useState(true);
	const { state } = useLocation();
	const { push: historyPush } = useHistory();

	useEffect(() => {
		if (!state?.queryString) {
			return historyPush('/planejamentos');
		}

		async function loadData() {
			setLoading(true);
			try {
				const { data } = await api.get(`/plannings?${state.queryString}`);
				setPlannings(data.plannings);
			} catch (err) {
				historyPush('/planejamentos');
			} finally {
				setLoading(false);
			}
		}

		loadData();
	}, [state]);

	const formatDate = useCallback((date) => {
		const [year, month, day] = date.split('-');
		return `${day}/${month}/${year}`;
	}, []);

	const generateDateString = useMemo(() => {
		const searchParams = new URLSearchParams(state.queryString);
		// const { dateInit, dateEnd } = state;
		const dateInit = searchParams.get('dateInit');
		const dateEnd = searchParams.get('dateEnd');

		console.log({ dateInit, dateEnd });

		if (dateInit && dateEnd) {
			return `Período de ${formatDate(dateInit)} até ${formatDate(dateEnd)}`;
		}
		if (dateInit) {
			return `Período de ${formatDate(dateInit)} até ${toLocaleDate()}`;
		}
		if (dateEnd) {
			return `Período até ${formatDate(dateEnd)}`;
		}
		return `Período até ${new Date().toLocaleDateString()}`;
	}, [state]);

	if (loading) {
		return <Loader show={loading} fullScreen />;
	}

	return (
		<PDFViewer style={styles.viewer}>
			<Document
				title="Relatório de Planejamentos"
				author="ZT Consultoria Ortodôntica"
				creator="ZT Consultoria Ortodôntica"
				subject="Relatório de Planejamentos"
			>
				<Page size="A4" style={styles.page}>
					<View fixed>
						<View style={styles.headerLine} />
						<View style={styles.headerContent}>
							<Image style={styles.logo} src={ztLogo} />
							<View>
								<Link src="https://www.instagram.com/ortosolutions/" target="_blank" style={styles.contactView}>
									<Image style={styles.contactIcon} src={iconInsta} />
									<Text style={styles.contactText}>@ortosolutions</Text>
								</Link>
								<Link src="tel:+5555996224374" style={[styles.contactView, { marginTop: 5 }]}>
									<Image style={styles.contactIcon} src={iconPhone} />
									<Text style={styles.contactText}>(55) 99622-4374</Text>
								</Link>
							</View>
						</View>
					</View>

					<View style={styles.container}>
						<View style={styles.infosContainer}>
							<View style={styles.infoLeft}>
								<Text style={styles.title}>RELATÓRIO DE PLANEJAMENTOS</Text>
								<Text style={styles.infoText}>
									{generateDateString}
								</Text>
								{state.consultant && (
									<Text style={[styles.infoText, { marginTop: 5 }]}>
										Consultor {state.consultant}
									</Text>
								)}
							</View>
							<View style={styles.infoRight}>
								<Text style={styles.infoText}>Total: {plannings.length} Planejamento{plannings.length === 1 ? '' : 's'}</Text>
								<Text style={styles.infoStatus}>Planejamentos Realizados</Text>
							</View>
						</View>
						<View style={styles.tableHeader}>
							<Text style={styles.tableHeaderDate}>Data Solicitação</Text>
							<Text style={styles.tableHeaderDate}>Data Respondido</Text>
							<Text style={styles.tableHeaderText}>Clínica</Text>
							<Text style={styles.tableHeaderText}>Nome do Paciente</Text>
							<Text style={styles.tableHeaderText}>Dentista Responsável</Text>
							<Text style={styles.tableHeaderText}>Quem realizou o Planejamento?</Text>
						</View>

						{/* Begin of table content */}
						{plannings.map((planning, index) => (
							<View key={index} style={styles.tableCell}>
								<Text style={styles.tableCellDate}>
									{planning.created_at ? toLocaleDate(planning.created_at) : '-'}
								</Text>
								<Text style={styles.tableCellDate}>
									{planning.answered_at ? toLocaleDate(planning.answered_at) : '-'}
								</Text>
								<Text style={styles.tableCellText}>
									{planning.patient?.clinic?.name || 'Não informado'}
								</Text>
								<Text style={styles.tableCellText}>
									{planning.patient?.name || 'Não informado'}
								</Text>
								<Text style={styles.tableCellText}>
									{planning.dentist?.name || 'Não informado'}
								</Text>
								<Text style={styles.tableCellText}>
									{planning.answered_by || 'ZT Consultoria Ortodôntica'}
								</Text>
							</View>
						))}
						{/* End of table content */}

					</View>

					<View style={styles.footer} fixed>
						<View style={styles.paginationView}>
							<Text
								style={styles.paginationText}
								render={({ pageNumber, totalPages }) => (
									`Página ${pageNumber} de ${totalPages}`
								)}
							/>
						</View>
						<View style={styles.paginationViewTriangle} />
						<Link
							src='https://www.ztconsultoria.com.br'
							style={styles.footerLink}
						>
							www.ztconsultoria.com.br
						</Link>
					</View>
				</Page>
			</Document>
		</PDFViewer>
	);
}

const styles = StyleSheet.create({
	viewer: {
		width: '100vw',
		height: '100vh',
	},
	page: {
		flexDirection: 'column',
		backgroundColor: '#ffffff'
	},

	// Header
	headerLine: {
		padding: 12,
		backgroundColor: '#112538'
	},
	headerContent: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		margin: 20,
	},
	logo: {
		width: 175,
	},
	contactView: {
		flexDirection: 'row',
		textDecoration: 'none',
	},
	contactIcon: {
		marginRight: 5,
		width: 15,
	},
	contactText: {
		fontSize: 12,
		color: '#4B6E8F',
	},

	// Content
	container: {
		flexDirection: 'column',
		flex: 1,
		margin: 20,
	},
	infosContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'flex-end',
		marginBottom: 20,
	},
	infoLeft: {
		flexDirection: 'column',
		marginRight: 20,
	},
	infoRight: {
		flexDirection: 'column',
	},
	title: {
		fontSize: 18,
		fontWeight: 'bold',
		marginBottom: 10,
		color: '#112538',
	},
	infoText: {
		fontSize: 12,
		color: '#4B6E8F',
	},
	infoStatus: {
		paddingHorizontal: 10,
		paddingVertical: 5,
		marginTop: 5,
		fontSize: 12,
		color: '#ffffff',
		backgroundColor: '#6DCE0B',
		borderRadius: 50,
	},
	tableHeader: {
		flexDirection: 'row',
		alignItems: 'center',
		backgroundColor: '#4B6E8F',
		padding: 5,
		marginBottom: 5,
	},
	tableHeaderText: {
		fontSize: 8,
		width: '20%',
		color: '#ffffff',
		paddingHorizontal: 2,
	},
	tableHeaderDate: {
		fontSize: 8,
		width: '15%',
		color: '#ffffff',
		paddingHorizontal: 2,
	},
	tableCell: {
		flexDirection: 'row',
		alignItems: 'center',
		padding: 5,
	},
	tableCellText: {
		fontSize: 8,
		width: '20%',
		paddingHorizontal: 2,
	},
	tableCellDate: {
		fontSize: 8,
		width: '15%',
		paddingHorizontal: 2,
	},

	// Footer
	footer: {
		marginTop: 15,
		backgroundColor: '#4B6E8F',
		position: 'relative',
		padding: 5
	},
	footerLink: {
		color: '#ffffff',
		fontSize: 10,
		marginHorizontal: 'auto',
		textDecoration: 'none'
	},
	paginationView: {
		position: 'absolute',
		bottom: 0,
		left: 0,
		width: '20%',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		padding: 12,
		backgroundColor: '#112538',
		zIndex: 10
	},
	paginationViewTriangle: {
		position: 'absolute',
		bottom: -9,
		left: 90,
		height: 20,
		width: 65,
		backgroundColor: '#112538',
		transform: 'rotate(65deg)',
		borderBottomLeftRadius: 999,
		zIndex: 5,
	},
	paginationText: {
		color: '#ffffff',
		fontSize: 10,
		zIndex: 3
	}
});

export default PDFPlannings;
