import axios from 'axios';
import { parseCookies } from 'nookies';

let url;

// eslint-disable-next-line no-undef
switch (process.env.NODE_ENV) {
	case 'production':
		url = 'https://zt-consultoria-api.herokuapp.com';
		// url = 'https://zt-consultoria-api-dev.herokuapp.com';
		break;
	case 'development':
		// url = 'https://zt-consultoria-api-dev.herokuapp.com';
		url = 'http://localhost:3003';
		break;
	default:
		url = 'http://localhost:3003';
}

const api = axios.create({
	baseURL: url
});

const { '@zt.token': token } = parseCookies();

api.defaults.headers.common['Authorization'] = token;

export default api;
