import React from 'react';
import { useParams } from 'react-router-dom';

import ConsultantDetail from '../../components/templates/ConsultantDetail';
import { PlanningsProvider } from '../../contexts/plannings';

function Consultant() {
	const { idconsultor } = useParams();

	return (
		<PlanningsProvider getConsultants={false} idConsultant={idconsultor}>
			{idconsultor && <ConsultantDetail idConsultant={idconsultor} />}
		</PlanningsProvider>
	);
}

export default Consultant;
