import { Tab } from '@headlessui/react';
import React, { useEffect, useState } from 'react';
import { AiOutlineNumber } from 'react-icons/ai';
import {
	BiCalendarCheck,
	BiCalendarHeart,
	BiCalendarMinus,
	BiCalendarPlus,
	BiCalendarStar,
} from 'react-icons/bi';
import { useToasts } from 'react-toast-notifications';

import CounterCards from '../../components/CounterCards';
import AnswerEvaluationsModal from '../../components/evaluation/AnswerEvaluationsModal';
import EvaluationCard from '../../components/evaluation/Card';
import PanelSection from '../../components/evaluation/PanelSection';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import Menu from '../../components/Menu';
import SearchBar from '../../components/SearchBar';
import { useAuth } from '../../contexts/auth';
import api from '../../services/api';
import { getEvaluationStatusColor } from '../../utils/status';

function Evaluation() {
	const [pageTitle, setPageTitle] = useState('Pendentes');
	const [evaluations, setEvaluations] = useState([]);
	const [loading, setLoading] = useState(true);
	const [modalAnswer, setModalAnswer] = useState({ isOpen: false, selectedId: null });
	const [reload, setReload] = useState(false);

	const { user, signOut } = useAuth();
	const { addToast } = useToasts();

	useEffect(() => {
		async function loadData() {
			setLoading(true);
			try {
				const { data: listEvaluations } = await api.get('/evaluations');
				setEvaluations(listEvaluations);
			} catch (error) {
				if (error.response?.status === 401) {
					addToast('Você não tem permissão para acessar esta página.', {
						appearance: 'error',
						autoDismiss: true,
					});

					signOut();
				}

				addToast('Erro ao carregar a lista de avaliações', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				setLoading(false);
			}
		}

		loadData();
	}, [reload]);

	return (
		<>
			<Loader show={loading} />
			<div className="flex">
				<Menu className="hidden xl:flex" />
				<div className="w-full flex flex-col">
					<Header textHeader={`Avaliações | ${pageTitle}`} />
					<main className="w-full flex flex-col py-4 px-14">
						<div className="flex flex-col-reverse lg:flex-row justify-between">
							<CounterCards
								cardList={[
									{
										id: '1',
										title: 'A fotografar',
										counter: evaluations?.pending?.total,
										priColor: getEvaluationStatusColor('Pending'), // 'bg-admin-bage-light',
										secColor: getEvaluationStatusColor('Pending'), //'bg-admin-bage-default',
										Icon: BiCalendarPlus,
									},
									{
										id: '2',
										title: 'Fotografadas',
										counter: evaluations?.answered?.total,
										priColor: getEvaluationStatusColor('Answered'), // 'bg-primary',
										secColor: getEvaluationStatusColor('Answered'), //'bg-primary',
										Icon: BiCalendarCheck,
									},
									{
										id: '4',
										title: 'Validados',
										counter: evaluations?.validated?.total,
										priColor: getEvaluationStatusColor('Answered', true), // 'bg-green-500',
										secColor: getEvaluationStatusColor('Answered', true), //'bg-green-600',
										Icon: BiCalendarStar,
									},
									{
										id: '5',
										title: 'Encerrados',
										counter: evaluations?.finished?.total,
										priColor: getEvaluationStatusColor('Finished'), // 'bg-gray-600',
										secColor: getEvaluationStatusColor('Finished'), //'bg-gray-500',
										Icon: BiCalendarHeart,
									},
									{
										id: '6',
										title: 'Inativos',
										counter: evaluations?.inactive?.length,
										priColor: getEvaluationStatusColor('Inactive'), // 'bg-red-600',
										secColor: getEvaluationStatusColor('Inactive'), //'bg-red-500',
										Icon: BiCalendarMinus,
									},
									user.role === 2
										? {
											id: '7',
											title: 'Média',
											counter: evaluations?.answered?.media,
											priColor: 'bg-purple-500',
											secColor: 'bg-purple-700',
											Icon: AiOutlineNumber,
										}
										: undefined,
								]}
							/>
						</div>
						<Tab.Group>
							<SearchBar
								optionsList={[
									{
										id: '1',
										option: 'Pendentes',
										onClick: () => setPageTitle('Pendentes'),
									},
									{
										id: '2',
										option: 'Fotografadas',
										onClick: () => setPageTitle('Fotografadas'),
									},
									{
										id: '4',
										option: 'Validados',
										onClick: () => setPageTitle('Validados'),
									},
									{
										id: '4',
										option: 'Encerrados',
										onClick: () => setPageTitle('Encerrados'),
									},
									{
										id: '6',
										option: 'Inativos',
										onClick: () => setPageTitle('Inativos'),
									},
								]}
							/>
							<Tab.Panel>
								{evaluations?.pending?.total <= 0 ? (
									<div className="col-span-1 lg:col-span-2 text-center mt-4">
										<strong className="text-2xl">
											Nenhuma avaliação pendente
										</strong>
									</div>
								) : (
									<PanelSection
										evaluations={evaluations.pending}
										selectedEvaluationShow={(id) =>
											setModalAnswer({ isOpen: true, selectedId: id })
										}
									/>
								)}
							</Tab.Panel>
							<Tab.Panel>
								{evaluations?.answered?.total <= 0 ? (
									<div className="col-span-1 lg:col-span-2 text-center mt-4">
										<strong className="text-2xl">
											Nenhuma avaliação respondida
										</strong>
									</div>
								) : (
									<PanelSection evaluations={evaluations.answered} />
								)}
							</Tab.Panel>
							<Tab.Panel>
								{evaluations?.validated?.total <= 0 ? (
									<div className="col-span-1 lg:col-span-2 text-center mt-4">
										<strong className="text-2xl">
											Nenhuma avaliação validada
										</strong>
									</div>
								) : (
									<PanelSection evaluations={evaluations.validated} />
								)}
							</Tab.Panel>
							<Tab.Panel>
								{evaluations?.finished?.total <= 0 ? (
									<div className="col-span-1 lg:col-span-2 text-center mt-4">
										<strong className="text-2xl">
											Nenhuma avaliação finalizada
										</strong>
									</div>
								) : (
									<PanelSection evaluations={evaluations.finished} />
								)}
							</Tab.Panel>
							<Tab.Panel className="grid grid-cols-1 mt-8 lg:grid-cols-2 gap-x-4 gap-y-3">
								{evaluations?.inactive?.length <= 0 ? (
									<div className="col-span-1 lg:col-span-2 text-center mt-4">
										<strong className="text-2xl">
											Nenhuma avaliação inativa
										</strong>
									</div>
								) : (
									evaluations?.inactive?.map((e) => (
										<EvaluationCard
											key={e.id}
											evaluation={e}
										/>
									))
								)}
							</Tab.Panel>
						</Tab.Group>
					</main>
				</div>
			</div>

			<AnswerEvaluationsModal
				show={modalAnswer.isOpen}
				onClose={() => {
					setModalAnswer({ isOpen: false, selectedId: null });
				}}
				onUpdated={() => {
					setReload(old => !old);
				}}
			/>
		</>
	);
}

export default Evaluation;
