import React from 'react';
import { Redirect, Route as ReactDOMRoute } from 'react-router-dom';

import { useAuth } from '../contexts/auth';

function Route({ isPrivate, roleAccess = [1, 2, 3], component: Component, ...rest }) {
	const { user } = useAuth();

	return (
		<ReactDOMRoute
			{...rest}
			render={() => {
				if (isPrivate && (!user || !roleAccess.includes(user?.role))) return <Redirect to="/" />;
				return <Component />;
			}}
		/>
	);
}

export default Route;
