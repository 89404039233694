import React, { useCallback, useEffect, useState } from 'react';
import { FaPlayCircle } from 'react-icons/fa';
import { MdUpload } from 'react-icons/md';

import classNames from '../../utils/classNames';

function NewVideoInputs({ index, register, remove, error, preview }) {
	const [filePreview, setFilePreview] = useState();

	useEffect(() => {
		if (preview) {
			setFilePreview(preview);
		}
	}, [index, preview]);

	const handleChange = useCallback(
		(event) => {
			const file = event.currentTarget.files.item(0);
			if (file) {
				const url = URL.createObjectURL(file);
				setFilePreview(url);
			} else {
				setFilePreview(undefined);
			}
		},
		[index, preview]
	);

	return (
		<div className="p-4 shadow-lg rounded-md">
			<div className="w-full flex items-center justify-between">
				<h4 className="text-2xl font-bold">Vídeo {index + 1}</h4>
				<button
					type="button"
					onClick={() => remove(index)}
					className={classNames(
						'px-6 py-2 text-lg self-end',
						'rounded-lg text-admin-bc-white font-bold',
						'bg-red-400 cursor-pointer',
						'hover:bg-gray-700 transition-colors duration-150'
					)}
				>
					Remover
				</button>
			</div>
			<div className="flex items-center gap-x-8 mt-2">
				<label className="flex-1">
					Título
					<input
						{...register(`videos.${index}.title`, {
							required: 'Campo obrigatório',
						})}
						placeholder={`Digite aqui o título do vídeo ${index + 1}`}
						className="w-full py-2 rounded-lg border px-3"
					/>
					{error?.title && (
						<small className="text-red-500 text-sm">
							{error.title?.message}
						</small>
					)}
				</label>
				<div className="flex flex-col items-center gap-4">
					<strong className="">Selecione um vídeo:</strong>
					<label
						htmlFor={`video-${index}`}
						className={classNames(
							'h-40 w-60 cursor-pointer rounded-lg border-gray-600 grid place-content-center',
							filePreview ? 'bg-blue-200' : 'bg-gray-100'
						)}
					>
						<input
							{...register(`videos.${index}.video`, {
								required: filePreview ? false : 'Campo obrigatório',
							})}
							id={`video-${index}`}
							type="file"
							onChange={handleChange}
							accept="video/*"
							className="sr-only"
						/>
						{filePreview ? (
							<a href={filePreview} target="_blank" rel="noopener noreferrer">
								<FaPlayCircle size={46} className="text-primary" />
							</a>
						) : (
							<MdUpload size={40} className="text-gray-500" />
						)}
					</label>
					{error?.video && (
						<small className="text-red-500 text-sm">
							{error.video?.message}
						</small>
					)}
				</div>
			</div>
		</div>
	);
}

export default NewVideoInputs;
