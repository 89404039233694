import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import AnswerPlanning from '../pages/AnswerPlanning';
import Clinics from '../pages/Clinics';
import Clinic from '../pages/Clinics/Clinic';
import ClinicDetail from '../pages/Clinics/ClinicDetail';
import Consultants from '../pages/Consultants';
import Consultant from '../pages/Consultants/Consultant';
import Evaluations from '../pages/Evaluations';
import EvaluationDetail from '../pages/Evaluations/EvaluationDetail';
import FollowUps from '../pages/FollowUps';
import FollowUpDetail from '../pages/FollowUps/Detail';
import RequestFollowUp from '../pages/FollowUps/Request';
// import Initial from '../pages/Initial';
import Login from '../pages/Login';
import NotFound from '../pages/NotFound';
import Patients from '../pages/Patients';
import PatientDetail from '../pages/Patients/PatientDetail';
import PDFPlannings from '../pages/PDFPlannings';
import Plannings from '../pages/Plannings';
import PlanningDetail from '../pages/Plannings/PlanningDetail';
import RecoveryPassword from '../pages/RecoveryPassword';
import RequestPlanning from '../pages/RequestPlanning';
import Terms from '../pages/Terms';
import Avaliacao from '../pages/videoaulas/Avaliacao';
import NewVideo from '../pages/videoaulas/NewVideo';
import VideoAulas from '../pages/videoaulas/VideoAulas';
import VideoDetail from '../pages/videoaulas/VideoDetail';
import Route from './Route';

function index() {
	return (
		<BrowserRouter>
			<Switch>
				<Route exact path="/" component={Login} />
				{/* <Route exact path="/login" component={Login} /> */}
				<Route exact path="/recuperar-senha" component={RecoveryPassword} />
				<Route exact path="/pdf" component={PDFPlannings} />
				<Route exact path="/termos-de-uso" component={Terms} />
				<Route
					exact
					path="/planejamentos"
					isPrivate
					component={Plannings}
				/>
				<Route
					exact
					path="/planejamento/:idplanejamento"
					isPrivate
					component={PlanningDetail}
				/>
				<Route
					exact
					path="/responder-planejamento/:id_planejamento"
					isPrivate
					component={AnswerPlanning}
				/>
				<Route
					exact
					path="/solicitar-planejamento"
					isPrivate
					roleAccess={[2, 3]}
					component={RequestPlanning}
				/>
				<Route
					exact
					path="/atualizar-planejamento/:id_planejamento"
					isPrivate
					roleAccess={[2, 3]}
					component={RequestPlanning}
				/>
				<Route
					exact
					path="/planejamentos/relatorio"
					isPrivate
					component={PDFPlannings}
				/>

				<Route
					exact
					path="/validacao"
					isPrivate
					roleAccess={[1, 2, 3, 4]}
					component={Evaluations}
				/>
				<Route
					exact
					path="/validacao/:idEvaluation"
					isPrivate
					roleAccess={[1, 2, 3, 4]}
					component={EvaluationDetail}
				/>

				<Route
					exact
					path="/videoaulas"
					isPrivate
					component={VideoAulas}
				/>
				<Route
					exact
					path="/adicionar-modulo/"
					isPrivate
					roleAccess={[1]}
					component={NewVideo}
				/>
				<Route
					exact
					path="/editar-modulo/:id_module"
					isPrivate
					roleAccess={[1]}
					component={NewVideo}
				/>
				<Route
					exact
					path="/modulo/:id_module"
					isPrivate
					component={VideoDetail}
				/>
				<Route
					exact
					path="/avaliacao/:id_module"
					isPrivate
					component={Avaliacao}
				/>

				<Route
					exact
					path="/clinicas"
					isPrivate
					roleAccess={[1]}
					component={Clinics}
				/>
				<Route
					exact
					path="/clinica/:idclinica"
					isPrivate
					roleAccess={[1]}
					component={Clinic}
				/>
				<Route
					exact
					path="/clinica-paciente/:idpaciente"
					isPrivate
					roleAccess={[1]}
					component={ClinicDetail}
				/>

				<Route
					exact
					path="/pacientes"
					isPrivate
					component={Patients}
				/>
				<Route
					exact
					path="/paciente/:idpatient"
					isPrivate
					component={PatientDetail}
				/>

				<Route
					exact
					path="/consultores"
					isPrivate
					component={Consultants}
				/>
				<Route
					exact
					path="/consultor/:idconsultor"
					isPrivate
					component={Consultant}
				/>

				<Route
					exact
					path="/acompanhamentos"
					isPrivate
					component={FollowUps}
				/>
				<Route
					exact
					path="/acompanhamento/:id_grupo"
					isPrivate
					component={FollowUpDetail}
				/>
				<Route
					exact
					path="/solicitar-acompanhamento"
					isPrivate
					roleAccess={[2, 3]}
					component={RequestFollowUp}
				/>

				<Route component={NotFound} />
			</Switch>
		</BrowserRouter>
	);
}

export default index;
