import { destroyCookie, parseCookies, setCookie } from 'nookies';
import React from 'react';

import api from '../services/api';

const AuthContext = React.createContext({});

export function AuthProvider({ children }) {
	const [user, setUser] = React.useState(() => {
		const { '@zt.user': user } = parseCookies();
		if (user) {
			return JSON.parse(user);
		}

		return undefined;
	});
	const [token, setToken] = React.useState(() => {
		const { '@zt.token': token } = parseCookies();
		if (token) {
			return token;
		}

		return undefined;
	});

	async function signIn({ email, password }) {
		const { data } = await api.post('/auth/login', { email, password });

		setUser(data.user);
		setToken(data.token);

		api.defaults.headers.common['Authorization'] = data.token;

		setCookie(null, '@zt.user', JSON.stringify(data.user), {
			maxAge: 24 * 60 * 60 * 1000,
			path: '/',
		});
		setCookie(null, '@zt.token', data.token, {
			maxAge: 24 * 60 * 60 * 1000,
			path: '/'
		});

		return data.user;
	}

	function signOut() {
		destroyCookie(null, '@zt.user', {
			path: '/'
		});
		destroyCookie(null, '@zt.token', {
			path: '/'
		});
		setUser(undefined);
		setToken(undefined);
		api.defaults.headers.common['Authorization'] = undefined;
	}

	return (
		<AuthContext.Provider
			value={{
				user,
				token,
				signIn,
				signOut
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}

export function useAuth() {
	const ctx = React.useContext(AuthContext);
	if (!ctx) {
		throw new Error('useAuth must be used within an AuthProvider');
	}

	return ctx;
}

