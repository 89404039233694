import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import PlanningSelect from '../../components/PlanningSelect';
import classNames from '../../utils/classNames';
import { teeth } from './teeth';

function Step5({ selected, questions, defaultValues, sending, onNext, onBack }) {
	const [toothSelectedSagittal, setToothSelectedSagittal] = useState('');
	const { register, handleSubmit, control, setValue, getValues, formState: { errors } } = useForm({ defaultValues });

	const onSubmit = useCallback(data => {
		const pageAnswers = JSON.parse(JSON.stringify({
			superior_direito: data.superior_direito,
			superior_esquerdo: data.superior_esquerdo,
			inferior_direito: data.inferior_direito,
			inferior_esquerdo: data.inferior_esquerdo,
			implante_dos_dentes: data.implante_dos_dentes,
		}));
		onNext(pageAnswers);
	}, [selected, questions, onNext]);

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={`${selected === 5 ? '' : 'hidden'}`}>
			<div className="grid md:grid-rows grid-cols-1 mt-12  gap-y-4 text-2xl justify-items-center">
				<div className="flex flex-row gap-1">
					{[18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28].map((v, i) => (
						<Controller
							key={v}
							control={control}
							name={`sagittalCorrectionTeeth.${i}.tooth-${v}`}
							defaultValue={false}
							render={({ field: { onChange, ...field } }) => (
								<label
									title={`Dente número ${v}`}
									htmlFor={`teeth${v}`}
									className="h-20 w-16 cursor-pointer bg-contain bg-center bg-no-repeat"
									style={{
										backgroundImage: `url(${toothSelectedSagittal.includes(v) ? teeth.col[v] : teeth.pb[v]})`,
									}}
								>
									<input
										{...field}
										onChange={evt => {
											onChange(evt);
											const obj = [...getValues('teeth')].reduce((p, c) => ({ ...p, ...c }));
											setToothSelectedSagittal(Object.entries(obj).filter(v => !!v[1]).map(v => v[0]).join(', ').replace(/tooth-/g, ''));
										}}
										disabled
										id={`teeth${v}`}
										className="h-0 w-0"
										type="checkbox"
									/>
								</label>
							)}
						/>
					))}
				</div>
				<div className="flex flex-row gap-1">
					{[48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38].map((v, i) => (
						<Controller
							key={v}
							control={control}
							name={`sagittalCorrectionTeeth.${i}.tooth-${v}`}
							defaultValue={false}
							render={({ field: { onChange, ...field } }) => (
								<label
									title={`Dente número ${v}`}
									htmlFor={`teeth${v}`}
									className="h-20 w-16 cursor-pointer bg-contain bg-center bg-no-repeat"
									style={{
										backgroundImage: `url(${toothSelectedSagittal.includes(v) ? teeth.col[v] : teeth.pb[v]})`,
									}}
								>
									<input
										{...field}
										onChange={evt => {
											onChange(evt);
											const obj = [...getValues('teeth')].reduce((p, c) => ({ ...p, ...c }));
											setToothSelectedSagittal(Object.entries(obj).filter(v => !!v[1]).map(v => v[0]).join(', ').replace(/tooth-/g, ''));
										}}
										disabled
										id={`teeth${v}`}
										className="h-0 w-0"
										type="checkbox"
									/>
								</label>
							)}
						/>
					))}
				</div>
			</div>
			<div className="grid md:grid-cols-2 grid-cols-1 mt-12 gap-y-10 text-2xl justify-items-center">
				<PlanningSelect
					control={control}
					register={register}
					name="superior_direito"
					defaultComplement={defaultValues['superior_direito']}
					questions={questions['Correção Sagital - Superior Direito']}
					label="Correção Sagital - Superior Direito"
					errors={errors}
					setValue={setValue}
					isRequired='Campo Obrigatório'
					onChange={() => {
						setToothSelectedSagittal(old => [...old, 18, 17, 16, 15]);
					}}
				/>
				<PlanningSelect
					control={control}
					register={register}
					name="superior_esquerdo"
					defaultComplement={defaultValues['superior_esquerdo']}
					questions={questions['Correção Sagital - Superior Esquerdo']}
					label="Correção Sagital - Superior Esquerdo"
					errors={errors}
					setValue={setValue}
					isRequired='Campo Obrigatório'
					onChange={() => {
						setToothSelectedSagittal(old => [...old, 25, 26, 27, 28]);
					}}
				/>
				<PlanningSelect
					control={control}
					register={register}
					name="inferior_direito"
					defaultComplement={defaultValues['inferior_direito']}
					questions={questions['Correção Sagital - Inferior Direito']}
					label="Correção Sagital - Inferior Direito"
					errors={errors}
					setValue={setValue}
					isRequired='Campo Obrigatório'
					onChange={() => {
						setToothSelectedSagittal(old => [...old, 48, 47, 46, 45]);
					}}
				/>
				<PlanningSelect
					control={control}
					register={register}
					name="inferior_esquerdo"
					defaultComplement={defaultValues['inferior_esquerdo']}
					questions={questions['Correção Sagital - Inferior Esquerdo']}
					label="Correção Sagital - Inferior Esquerdo"
					errors={errors}
					setValue={setValue}
					isRequired='Campo Obrigatório'
					onChange={() => {
						setToothSelectedSagittal(old => [...old, 35, 36, 37, 38]);
					}}
				/>
			</div>
			<div className="w-full flex flex-col py-4 px-14 gap-4">
				<strong className="text-lg lg:text-xl">Implante dos dentes</strong>
				<input
					{...register('implante_dos_dentes.question')}
					type="hidden"
					defaultValue="Implante dos dentes"
				/>
				<textarea
					{...register('implante_dos_dentes.answer')}
					className="border-2 border-gray-300 rounded-md p-1"
				>
				</textarea>
				{errors.implante_dos_dentes?.answer && (
					<small className="text-red-400">{errors.implante_dos_dentes?.answer.message}</small>
				)}
			</div>
			<div className="flex md:flex-row flex-col justify-center gap-x-10">
				<button
					type="button"
					onClick={onBack}
					className={classNames(
						'mt-10 py-1 px-36 self-center border-2 border-primary-dark text-primary-dark',
						'text-center text-xl lg:text-2xl rounded-lg font-bold',
						'transition-colors duration-300 hover:bg-primary-dark hover:text-white'
					)}
				>
					Voltar
				</button>
				<button
					type="submit"
					disabled={sending}
					className={classNames(
						'mt-10 py-2 px-36 self-center',
						'text-center text-xl lg:text-2xl rounded-lg text-admin-bc-white font-bold',
						'transition-colors duration-300',
						sending ? 'bg-gray-400 cursor-wait' : 'bg-primary-dark hover:bg-primary'
					)}
				>
					Próximo
				</button>
			</div>
		</form>
	);
}

export default Step5;
