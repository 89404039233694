import imageCompression from 'browser-image-compression';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IoMdClose } from 'react-icons/io';
import { useToasts } from 'react-toast-notifications';

import dentesBaixo from '../../assets/images/dentesBaixo.png';
import dentesCima from '../../assets/images/dentesCima.png';
import dentesDireita from '../../assets/images/dentesDireita.png';
import dentesEsquerda from '../../assets/images/dentesEsquerda.png';
import dentesFrente from '../../assets/images/dentesFrente.png';
import fotoLateral from '../../assets/images/fotoLateral.png';
import fotoSeria from '../../assets/images/fotoSeria.png';
import fotoSorrindo from '../../assets/images/fotoSorrindo.png';
import raioxLateral from '../../assets/images/raioxLateral.png';
import raioxPanoramico from '../../assets/images/raioxPanoramico.png';
import api from '../../services/api';
import classNames from '../../utils/classNames';
import toBase64 from '../../utils/toBase64';
import ImageInput from '../ImageInput';
import Modal from '../Modal';

function AnswerEvaluationsModal({ show, onClose, onUpdated, evaluationId }) {
	const [sending, setSending] = useState(false);

	const { addToast } = useToasts();
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();

	const onSubmit = useCallback(
		async ({ totalConsults, dentistObs, ...data }) => {
			console.log({ totalConsults, dentistObs, ...data });
			setSending(true);

			try {
				let imagesBase64 = {};
				for (const key in data) {
					const file = data[key]?.item(0);

					if (file) {
						const compressed = await imageCompression(file, {
							maxSizeMB: 1,
							maxWidthOrHeight: 1920,
							useWebWorker: true,
						});
						const b64 = await toBase64(compressed);
						imagesBase64[key] = b64;
					} else {
						imagesBase64[key] = null;
					}
				}

				await api.put(`/evaluations/${evaluationId}`, {
					...imagesBase64,
					totalConsults,
					dentistObs,
				});

				addToast('Avaliação respondida', {
					appearance: 'success',
					autoDismiss: true,
				});
				onClose();
				reset();
				await new Promise((r) => setTimeout(r, 500));
				onUpdated();
			} catch (error) {
				addToast(error.response?.data?.message ?? 'Ocorreu um erro ao salvar', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				setSending(false);
			}
		},
		[evaluationId]
	);

	const onSubmitInactive = useCallback(async () => {
		setSending(true);

		try {
			await api.put(`/evaluations/${evaluationId}`, {
				inactive: true,
			});

			addToast('Avaliação respondida', {
				appearance: 'success',
				autoDismiss: true,
			});
			onClose();
			await new Promise((r) => setTimeout(r, 500));
			onUpdated();
		} catch (error) {
			addToast(error.response?.data?.message ?? 'Ocorreu um erro ao salvar', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setSending(false);
		}
	}, [evaluationId]);

	return (
		<Modal
			isOpen={show}
			onClose={() => {
				onClose();
				reset();
			}}
		>
			<div className="h-full w-full bg-white rounded-2xl overflow-hidden flex flex-col">
				<div className="w-auto h-auto bg-primary px-10 py-4 gap-x-28  flex flex-row items-center justify-between">
					<strong className="text-3xl w-full text-white">
						Selecione as fotos para enviar:
					</strong>
					<button
						onClick={() => {
							onClose();
							reset();
						}}
					>
						<IoMdClose size={35} className="flex text-white" />
					</button>
				</div>
				<div className="flex flex-col py-6 gap-y-10 justify-center px-10">
					<form
						onSubmit={handleSubmit(onSubmit)}
						className="flex  flex-col gap-y-6"
					>
						<div className="flex gap-6 flex-wrap">
							<div className="flex grid-cols-5 gap-x-6">
								<ImageInput
									register={register}
									name="faceFrontal"
									image={fotoSeria}
									error={errors.faceFrontal?.message}
								/>

								<ImageInput
									register={register}
									name="faceLateral"
									image={fotoLateral}
									error={errors.faceLateral?.message}
								/>

								<ImageInput
									register={register}
									name="faceSorrindo"
									image={fotoSorrindo}
									error={errors.faceSorrindo?.message}
								/>
							</div>
						</div>
						<div className="flex gap-6 flex-wrap mt-6">
							<div className="flex grid-cols-5 gap-x-6">
								<ImageInput
									register={register}
									name="dentesFrontal"
									image={dentesFrente}
									error={errors.dentesFrontal?.message}
								/>

								<ImageInput
									register={register}
									name="dentesEsquerda"
									image={dentesEsquerda}
									error={errors.dentesEsquerda?.message}
								/>

								<ImageInput
									register={register}
									name="dentesDireita"
									image={dentesDireita}
									error={errors.dentesDireita?.message}
								/>

								<ImageInput
									register={register}
									name="dentesCima"
									image={dentesCima}
									error={errors.dentesCima?.message}
								/>

								<ImageInput
									register={register}
									name="dentesBaixo"
									image={dentesBaixo}
									error={errors.dentesBaixo?.message}
								/>
							</div>
						</div>
						<div className="flex gap-6 flex-wrap mt-6">
							<div className="flex grid-cols-5 gap-x-6">
								<ImageInput
									register={register}
									name="raioxLateral"
									image={raioxLateral}
									error={errors.raioxLateral?.message}
								/>

								<ImageInput
									register={register}
									name="raioxPanoramico"
									image={raioxPanoramico}
									error={errors.raioxPanoramico?.message}
								/>
								<ImageInput
									register={register}
									name="optional1"
									label="Opcional 1"
								/>
								<ImageInput
									register={register}
									name="optional2"
									label="Opcional 2"
								/>
							</div>
						</div>
						<div className="flex flex-col mt-4 text-xl gap-x-4">
							<strong className="">Total de consultas</strong>
							<input
								{...register('totalConsults', {
									valueAsNumber: true,
								})}
								type="number"
								placeholder="Valor padrão é 1"
								className={classNames(
									'border border-primary p-2 rounded-md w-min'
								)}
							/>
						</div>
						<div className="flex flex-col mt-4 text-xl gap-x-4">
							<strong className="">Observações</strong>
							<textarea
								{...register('dentistObs')}
								placeholder="Escreva a sua observação aqui"
								className={classNames(
									'border border-primary p-2 rounded-md'
								)}
							/>
						</div>
						<div className="flex justify-evenly flex-1">
							<button
								type="button"
								disabled={sending}
								onClick={onSubmitInactive}
								className={classNames(
									'py-2 w-64',
									'text-center text-2xl rounded text-admin-bc-white',
									'transition-colors duration-300 hover:bg-red-200',
									sending ? 'bg-gray-400 cursor-not-allowed' : 'bg-red-400'
								)}
							>
								{sending ? 'Enviando...' : 'Paciente inativo'}
							</button>
							<button
								type="submit"
								disabled={sending}
								className={classNames(
									'py-2 w-64',
									'text-center text-2xl rounded text-admin-bc-white',
									'transition-colors duration-300 hover:bg-primary',
									sending
										? 'bg-gray-400 cursor-not-allowed'
										: 'bg-primary'
								)}
							>
								{sending ? 'Enviando...' : 'Responder'}
							</button>
						</div>
					</form>
				</div>
			</div>
		</Modal>
	);
}

export default AnswerEvaluationsModal;
