import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from '../../contexts/auth';
import { usePlannings } from '../../contexts/plannings';
import classNames from '../../utils/classNames';

function PlanningsActionButtons({ searchFilter }) {
	const { user } = useAuth();
	const { listConsultor, buttonType, setButtonType, setConsultantId } = usePlannings();

	const clearedSearchFilter = useMemo(() => {
		const searchParams = new URLSearchParams();
		Object.entries(searchFilter).map(([key, value]) => {
			if (value && value !== 'undefined' && value !== 'null') {
				searchParams.append(key, value);
			}
		});

		searchParams.append('perPage', 9999);
		return searchParams.toString();
	}, [searchFilter]);

	if (user?.role === 1) {
		if (buttonType === 'plan') return (
			<button
				type="button"
				onClick={() => setButtonType('inputs')}
				className={classNames(
					'mt-10 py-3 px-8 self-start',
					'text-center text-xl rounded-lg text-admin-bc-white font-bold whitespace-nowrap',
					'transition-all duration-300 bg-primary hover:bg-opacity-70',
				)}
			>
				Designar Planejamentos
			</button>
		);
		if (buttonType === 'report') return (
			<Link
				to={{
					pathname: '/planejamentos/relatorio',
					state: {
						// plannings: plannings.completed.plannings,
						// total: plannings.completed.total,
						// dateInit: searchFilter?.dateInit || undefined,
						// dateEnd: searchFilter?.dateEnd || undefined,
						queryString: clearedSearchFilter,
						consultant: listConsultor.find(c => c.id === +searchFilter?.consultantId)?.name,
					}
				}}
				className={classNames(
					'mt-10 py-3 px-8 self-start',
					'text-center text-xl rounded-lg text-admin-bc-white font-bold whitespace-nowrap',
					'transition-all duration-300 bg-primary hover:bg-opacity-70',
				)}
			>
				Gerar Relatório
			</Link>
		);
		if (buttonType === 'inputs') return (
			<div className="flex gap-4 lg:justify-start items-center flex-col mt-6 lg:right-0">
				<button
					type="button"
					// onClick={() => setButtonType('plan')}
					className={classNames(
						'py-2 px-16',
						'text-center text-xl rounded-lg border border-primary text-primary font-bold',
						'transition-colors duration-300 hover:bg-primary hover:text-white',
					)}
				>
					Cancelar
				</button>
				<select
					className="py-2 px-1 border border-solid rounded-lg bg-admin-bc-white text-primary border-primary text-lg focus:outline-none"
					onChange={event => setConsultantId(event.target.value)}
				>
					<option value="">Selecione o Consultor</option>
					{listConsultor?.map(c => (
						<option key={c.id} value={c.id}>{c.name}</option>
					))}
				</select>
			</div>
		);
	}

	if (user?.role === 2) {
		return (
			<Link to="/solicitar-planejamento" className="flex mt-6 justify-center lg:right-0">
				<p className="lg:w-80 h-20 lg:h-14 px-3 bg-primary flex items-center justify-center text-center text-xl lg:text-2xl rounded-lg text-admin-bc-white font-bold">
					Solicitar Planejamentos
				</p>
			</Link>
		);
	}

	return null;
}

export default PlanningsActionButtons;
