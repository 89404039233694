function dentistAccountLevel(media) {
	if (typeof media !== 'number') {
		return {
			name: 'Bronze',
			color: '#857025'
		};
	}

	if (media <= 1) {
		return {
			name: 'Bronze',
			color: '#857025'
		};
	}
	if (media <= 2) {
		return {
			name: 'Silver',
			color: '#c7c7c7'
		};
	}
	if (media <= 3) {
		return {
			name: 'Gold',
			color: '#d7b304'
		};
	}
	if (media <= 4) {
		return {
			name: 'Platinum',
			color: '#c5c5c5'
		};
	}

	return {
		name: 'Diamond',
		color: '#abfff0'
	};
}

export default dentistAccountLevel;