import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { MdOutlineEditNote } from 'react-icons/md';

import { useAuth } from '../contexts/auth';
import useUpdatePatientStatus from '../hooks/useUpdatePatientStatus';
import classNames from '../utils/classNames';
import { getAgeFromDate } from '../utils/getAgeFromDate';

function PatientInfos({ patient, onClickEdit, onClickDelete, reload }) {
	const { onSubmit, sending } = useUpdatePatientStatus();
	const { user } = useAuth();

	return (
		<div className="mt-6 shadow-md min-w-full rounded-4 w-full h-auto flex flex-col xl:flex-row px-4 py-5 gap-4 rounded-lg drop-shadow-lg">
			<div
				className="w-28 h-28 self-center rounded-full bg-cover bg-center bg-no-repeat"
				style={{ backgroundImage: `url(${patient.profile_image})` }}
			/>
			<div className="flex flex-col gap-2 text-lg flex-1 items-between px-4">
				<p className="font-bold text-xl self-start">{patient.name}</p>
				<div className="flex gap-2">
					<p className="">E-mail:</p>
					<p>{patient.email}</p>
				</div>
				<div className="flex gap-2">
					<p className="">Sexo:</p>
					<p>{patient.gender}</p>
				</div>
				<div className="flex gap-2">
					<p className="">Idade:</p>
					<p>{patient.birth_date ? getAgeFromDate(new Date(patient.birth_date)) : '-'}</p>
				</div>
				<div className="flex gap-2">
					<p className="">Queixa principal:</p>
					<p>{patient.complaint}</p>
				</div>
			</div>
			<div className="flex flex-col text-lg justify-evenly gap-2 flex-1 px-4">
				<div className="flex gap-2">
					<p className="">Linha média:</p>
					<p>{patient.upper_middle_line}</p>
				</div>
				<div className="flex gap-2">
					<p className="">Dentista Responsável:</p>
					<p>{patient.dentist?.name}</p>
				</div>
				<div className="flex gap-2">
					<p className="">Status do paciente:</p>
					<strong className={patient.is_active === 1 ? 'text-green-600' : 'text-red-500'}>
						{patient.is_active === 1 ? 'Ativo' : 'Inativo'}
					</strong>
				</div>
				{[1, 2].includes(user.role)}
				<button
					onClick={async () => {
						await onSubmit(patient.id, patient.is_active === 1 ? false : true);
						reload?.();
					}}
					disabled={sending}
					className={classNames(
						'px-4 py-2 text-white rounded-full self-start',
						'transition-opacity hover:opacity-75',
						sending ? 'bg-gray-400 cursor-default' : patient.is_active === 1 ? 'bg-red-500' : 'bg-green-600'
					)}
				>
					{sending ? 'Enviando...' : `${patient.is_active === 1 ? 'Desativar' : 'Reativar'} paciente`}
				</button>
			</div>
			{onClickEdit || onClickDelete ? (
				<div className="flex flex-col-reverse lg:flex-col gap-1">
					<div className="flex place-self-end">
						<button onClick={onClickEdit}>
							<MdOutlineEditNote size={28} className="self-center" />
						</button>
						<button onClick={onClickDelete}>
							<AiOutlineClose size={25} className="self-center " />
						</button>
					</div>
				</div>
			) : null}
		</div>
	);
}

export default PatientInfos;
