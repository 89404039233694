import { Tab } from '@headlessui/react';
import imageCompression from 'browser-image-compression';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineClose } from 'react-icons/ai';
import { IoIosPerson, IoMdClose } from 'react-icons/io';
import { MdOutlineEditNote } from 'react-icons/md';
import InputMask from 'react-input-mask-updated/lib/react-input-mask.development';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import Header from '../../components/Header';
import HeaderlessSlideOver from '../../components/HeadlessSlideOver';
import Loader from '../../components/Loader';
import Menu from '../../components/Menu';
import Modal from '../../components/Modal';
import PlanningPagination from '../../components/planning/PlanningPagination';
import PlanningsCounter from '../../components/planning/PlanningsCounter';
import PlanningCard from '../../components/PlanningCard';
import SearchBar from '../../components/SearchBar';
import { useAuth } from '../../contexts/auth';
import { usePlannings } from '../../contexts/plannings';
import api from '../../services/api';
import classNames from '../../utils/classNames';
import toBase64 from '../../utils/toBase64';


function ConsultantDetail({ idConsultant }) {
	const [loading, setLoading] = useState();
	const [consultant, setConsultant] = useState();
	const [open, setOpen] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [previewConsultantImage, setPreviewConsultantImage] = useState();
	const [image, setImage] = useState();
	const [searchFilter, setSearchFilter] = useState({
		consultantId: idConsultant,
		dentistId: undefined,
		clinicId: undefined,
		search: undefined,
		dateInit: undefined,
		dateEnd: undefined,
		status: undefined,
		dateType: 'Solicitacao',
	});

	const { push: historyPush } = useHistory();
	const { user, signOut } = useAuth();
	const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm();
	const { addToast } = useToasts();

	const {
		loading: loadingPlannings,
		plannings,
		pageTitle,
		buttonType,
		selectedToDesignate,
		selectToDesignate,
		setButtonType,
		setPageTitle,
		searchPlannings,
	} = usePlannings();

	useEffect(() => {
		if (!idConsultant) {
			historyPush('/consultores');
			return;
		}

		async function getData() {
			setLoading(true);

			try {
				const { data: { consultant } } = await api.get(`/manager/consultants/${idConsultant}`);

				setConsultant(consultant);

				setValue('name', consultant.name);
				setValue('email', consultant.user.email);
				setValue('phone', consultant.phone);
				setValue('city', consultant.city);
				setValue('oldImage', consultant.user.profile_image);
				setPreviewConsultantImage(consultant.user.profile_image);
			} catch (err) {
				if (err.response?.status === 401) {
					signOut();
					return;
				}

				addToast(err.response?.data?.message ?? 'Erro ao buscar as consultores.', {
					appearance: 'error',
					autoDismiss: true
				});
			} finally {
				setLoading(false);
			}
		}

		searchPlannings({
			status: 'pending',
			consultantId: idConsultant,
		});
		getData();
	}, []);

	const handleSearchConsultant = useCallback(async value => {
		setLoading(true);

		try {
			const { data: { consultant } } = await api.get(`/manager/consultants/${idConsultant}`, {
				params: {
					search: value,
				}
			});

			setConsultant(consultant);
		} catch (err) {
			if (err.response?.status === 401) {
				signOut();
				return;
			}

			addToast(err.response?.data?.message ?? 'Nenhum resultado encontrado.', {
				appearance: 'error',
				autoDismiss: true
			});
		} finally {
			setLoading(false);
		}
	}, []);

	const onSubmit = useCallback(async ({ ...formData }) => {
		setLoading(true);

		try {
			const profile_image = image;
			let img = formData.oldImage;
			if (profile_image) {
				const compressed = await imageCompression(profile_image, {
					maxSizeMB: 1,
					maxWidthOrHeight: 480,
					useWebWorker: true
				});

				img = await toBase64(compressed);
			}

			await api.put(`/manager/consultants/${idConsultant}`, {
				...formData,
				profile_image: img,
				consultant_id: idConsultant
			});

			addToast('Consultor(a) salva', {
				appearance: 'success',
				autoDismiss: true
			});

			handleSearchConsultant(undefined);
			setOpen(false);
			setPreviewConsultantImage(undefined);
			reset();
		} catch (err) {
			addToast(err.response?.data?.message ?? 'Erro ao cadastrar a Consultor(a).', {
				appearance: 'error',
				autoDismiss: true
			});
		} finally {
			setLoading(false);
		}
	}, [idConsultant, image]);

	const handleDelete = useCallback(async () => {
		setLoading(true);

		try {
			await api.delete(`/manager/consultants/${idConsultant}`);

			addToast('Clinica removida', {
				appearance: 'warning',
				autoDismiss: true
			});

			setLoading(false);
			setOpenModal(false);
			historyPush('/consultores');
		} catch (err) {
			if (err.response?.status === 401) {
				signOut();
				return;
			}

			addToast(err.response?.data?.message ?? 'Erro ao deletar consultor(a).', {
				appearance: 'error',
				autoDismiss: true
			});
		} finally {
			setLoading(false);
		}
	}, [idConsultant]);

	const handleFilter = useCallback((filter) => {
		if (filter) {
			setSearchFilter(old => ({ ...old, ...filter }));
		}
	}, [searchFilter]);

	return (
		<>
			<div className="flex">
				<Menu className="hidden xl:flex" />
				<div className="w-full flex flex-col">
					<Header textHeader={`Consultor | ${consultant?.name} | ${pageTitle}`} />
					<main className="w-full flex flex-col py-4 px-14">
						<div className="flex flex-col-reverse lg:flex-row justify-between mt-6" >
							<PlanningsCounter />
						</div>
						<div className="mt-6 shadow-md rounded-4 w-full h-auto flex flex-col xl:flex-row px-4 py-2 gap-4 rounded-lg drop-shadow-lg">
							<div
								className={
									classNames(
										'bg-primary rounded-full h-32 w-32 sm:h-20 sm:w-20 md:mr-3 flex self-center justify-self-center',
										'bg-center bg-no-repeat bg-cover',
									)
								}
								style={{ backgroundImage: `url(${consultant?.user?.profile_image})` }}
							/>
							<div className="flex flex-col h-full flex-1 items-between px-4">
								<p className="font-bold text-2xl self-start pt-5 justify-self-center">{consultant?.name}</p>
							</div>
							<div className="flex flex-col-reverse lg:flex-col justify-evenly gap-1">
								<div className="flex place-self-end">
									<button onClick={() => setOpen(true)}>
										<MdOutlineEditNote size={28} className="self-center" />
									</button>
									<button onClick={() => setOpenModal(true)}>
										<AiOutlineClose size={25} className="self-center " />
									</button>
								</div>
								<div className="flex flex-row gap-2 text-lg self-end">
									<p>{consultant?.phone}</p>
								</div>
								<div className="flex flex-row gap-2 text-lg self-end">
									<p>{consultant?.city}</p>
								</div>
							</div>
						</div>
						<Tab.Group defaultIndex={0} >
							<SearchBar
								getSearch={search => handleFilter({ search })}
								optionsList={[
									{
										id: '1',
										option: 'Pendentes',
										onClick: () => {
											setPageTitle('Pendentes');
											setButtonType('plan');
											if (plannings.pending.total <= 0) {
												handleFilter({ status: 'pending' });
												searchPlannings({ ...searchFilter, status: 'pending' });
											}
										}
									},
									{
										id: '2',
										option: 'Respondidos',
										onClick: () => {
											setPageTitle('Respondidos');
											setButtonType('report');
											if (plannings.completed.total <= 0) {
												handleFilter({ status: 'completed' });
												searchPlannings({ ...searchFilter, status: 'completed' });
											}
										}
									},
									{
										id: '3',
										option: 'Alterações Solicitadas ',
										onClick: () => {
											setPageTitle('Alterações Solicitadas');
											setButtonType('report');
											if (plannings.more_info.total <= 0) {
												handleFilter({ status: 'more_info' });
												searchPlannings({ ...searchFilter, status: 'more_info' });
											}
										}
									},
									{
										id: '4',
										option: 'Inativos',
										onClick: () => {
											setPageTitle('Inativos');
											setButtonType(null);
											if (plannings.inactive.total <= 0) {
												handleFilter({ status: 'inactive' });
												searchPlannings({ ...searchFilter, status: 'inactive' });
											}
										}
									},
								]}
							/>

							<Tab.Panels>
								<Tab.Panel className="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-4">
									{loadingPlannings ? (
										<div className="col-span-full flex items-center justify-center flex-1">
											<Loader show={true} fullScreen={false} />
										</div>
									) : (
										<>
											{plannings.pending.total <= 0 ? (
												<div className="col-span-1 lg:col-span-2 text-center mt-4">
													<strong className="text-2xl">Nenhum planejamento pendente</strong>
												</div>
											) : plannings.pending.plannings.map((item, index) => (
												<PlanningCard
													key={item.id}
													index={index}
													planning={item}
													isSelectabled={[1, 3].includes(user?.role) && buttonType === 'inputs'}
													selected={selectedToDesignate.includes(item.id)}
													handleSelected={() => selectToDesignate(item.id)}
												/>
											))}
											<div className="col-span-full flex justify-center mt-8">
												<PlanningPagination
													status="pending"
													searchFilter={searchFilter}
													page={plannings.pending.page}
													pages={plannings.pending.pages}
													perPage={plannings.pending.perPage}
													total={plannings.pending.total}
												/>
											</div>
										</>
									)}
								</Tab.Panel>
								<Tab.Panel className="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-4">
									{loadingPlannings ? (
										<div className="col-span-full flex items-center justify-center flex-1">
											<Loader show={true} fullScreen={false} />
										</div>
									) : (
										<>
											{plannings.completed.total <= 0 ? (
												<div className="col-span-1 lg:col-span-2 text-center mt-4">
													<strong className="text-2xl">Nenhum planejamento respondido</strong>
												</div>
											) : plannings.completed.plannings.map((item, index) => (
												<PlanningCard
													key={item.id}
													index={index}
													planning={item}
													selected={selectedToDesignate.includes(item.id)}
													handleSelected={() => selectToDesignate(item.id)}
												/>
											))}
											<div className="col-span-full flex justify-center mt-8">
												<PlanningPagination
													status="completed"
													searchFilter={searchFilter}
													page={plannings.completed.page}
													pages={plannings.completed.pages}
													perPage={plannings.completed.perPage}
													total={plannings.completed.total}
												/>
											</div>
										</>
									)}
								</Tab.Panel>
								<Tab.Panel className="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-4">
									{loadingPlannings ? (
										<div className="col-span-full flex items-center justify-center flex-1">
											<Loader show={true} fullScreen={false} />
										</div>
									) : (
										<>
											{plannings.more_info.total <= 0 ? (
												<div className="col-span-1 lg:col-span-2 text-center mt-4">
													<strong className="text-2xl">Nenhum planejamento solicitando mais alterações</strong>
												</div>
											) : plannings.more_info.plannings.map((item, index) => (
												<PlanningCard
													key={item.id}
													index={index}
													planning={item}
													selected={selectedToDesignate.includes(item.id)}
													handleSelected={() => selectToDesignate(item.id)}
												/>
											))}
											<div className="col-span-full flex justify-center mt-8">
												<PlanningPagination
													status="more_info"
													searchFilter={searchFilter}
													page={plannings.more_info.page}
													pages={plannings.more_info.pages}
													perPage={plannings.more_info.perPage}
													total={plannings.more_info.total}
												/>
											</div>
										</>
									)}
								</Tab.Panel>
								<Tab.Panel className="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-4">
									{loadingPlannings ? (
										<div className="col-span-full flex items-center justify-center flex-1">
											<Loader show={true} fullScreen={false} />
										</div>
									) : (
										<>
											{plannings.inactive.total <= 0 ? (
												<div className="col-span-1 lg:col-span-2 text-center mt-4">
													<strong className="text-2xl">Nenhum planejamento inativo</strong>
												</div>
											) : plannings.inactive.plannings.map((item, index) => (
												<PlanningCard
													key={item.id}
													index={index}
													planning={item}
													isSelectabled={false}
													selected={false}
													handleSelected={console.log}
												/>
											))}
											<div className="col-span-full flex justify-center mt-8">
												<PlanningPagination
													status="inactive"
													searchFilter={searchFilter}
													page={plannings.inactive.page}
													pages={plannings.inactive.pages}
													perPage={plannings.inactive.perPage}
													total={plannings.inactive.total}
												/>
											</div>
										</>
									)}
								</Tab.Panel>
							</Tab.Panels>
						</Tab.Group>
					</main>
				</div>
			</div>

			<Modal isOpen={openModal} onClose={() => setOpenModal(false)}  >
				<div className="h-full w-full bg-white rounded-2xl overflow-hidden flex flex-col">
					<div className="w-auto h-auto bg-red-500 px-10 py-4 gap-x-28  flex flex-row items-center justify-between">
						<strong className="text-3xl w-full text-white">
							Exluir Consultor
						</strong>
						<button onClick={() => setOpenModal(false)}>
							<IoMdClose size={60} className="flex text-white" />
						</button>
					</div>
					<div className="flex flex-col py-14 gap-y-32 justify-center px-10">
						<p className="text-3xl text-black text-center">
							Você tem certeza que deseja excluir este consultor(a)?
						</p>
						<div className="flex flex-col md:flex-row gap-6 justify-evenly text-2xl text-white">
							<button
								disabled={loadingPlannings}
								className={classNames(
									'px-16 py-4  rounded-xl',
									loadingPlannings ? 'bg-gray-400 cursor-not-allowed' : 'bg-primary'
								)}
								onClick={() => setOpenModal(false)}
							>
								Não
							</button>
							<button
								onClick={handleDelete}
								disabled={loadingPlannings}
								className={classNames(
									'px-16 py-4  rounded-xl',
									loadingPlannings ? 'bg-gray-400 cursor-not-allowed' : 'bg-red-500'
								)}
							>
								{loadingPlannings ? 'Removendo...' : 'Sim'}
							</button>
						</div>
					</div>
				</div>
			</Modal>
			<HeaderlessSlideOver
				open={open}
				setOpen={setOpen}
				title="Menu"
				className="absolute"
				side="ri"
			>
				<div className="overflow-y-auto max-h-screen h-screen w-auto flex  flex-col  bg-white">
					<div className="flex w-full py-10 flex-col h-24 text-center justify-center bg-primary text-3xl text-white">Adicionar Consultor</div>
					<div className=" w-auto flex p-10 flex-col gap-10 bg-white">
						<form onSubmit={handleSubmit(onSubmit)} className="gap-10 flex flex-col">
							<input {...register('oldImage')} type="hidden" name="oldImage" />
							<strong>Clique abaixo para selecionar a imagem do consultor</strong>
							<label
								htmlFor="profile_image"
								className={classNames(
									'w-28 h-28 place-self-center flex p-2 rounded-full border-2 border-primary bg-transparent',
									'bg-center bg-cover bg-no-repeat cursor-pointer'
								)}
								style={{ backgroundImage: `url(${previewConsultantImage})` }}
							>
								{!previewConsultantImage && (
									<IoIosPerson size={65} className="text-primary py-2" />
								)}
								<input
									{...register('profile_image')}
									id="profile_image"
									type="file"
									accept="image/png,image/jpg,image/jpeg,image/webp"
									className="sr-only"
									onChange={evt => {
										const file = evt.currentTarget.files.item(0);
										if (file) {
											const imageUrl = URL.createObjectURL(file);
											setPreviewConsultantImage(imageUrl);
											setImage(file);
											return;
										}
										setImage(undefined);
										setPreviewConsultantImage(undefined);
									}}
								/>
							</label>
							{errors.profile_image && (
								<small className="text-red-400 self-center">{errors.profile_image.message}</small>
							)}
							<div className="flex gap-4 flex-col">
								<strong>Nome:</strong>
								<input
									{...register('name', { required: 'Campo obrigatório' })}
									placeholder="Digite o nome"
									className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11"
								/>
								{errors?.name && (
									<small className="text-red-400">{errors.name.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>Telefone:</strong>
								<InputMask
									{...register('phone', { required: 'Campo obrigatório' })}
									mask={'(99) 99999-9999'}
									placeholder="Digite o nome"
									className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11"
								/>
								{errors?.phone && (
									<small className="text-red-400">{errors.phone.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>E-mail:</strong>
								<input
									{...register('email', { required: 'Campo obrigatório' })}
									type="email"
									placeholder="Digite o e-mail"
									className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11"
								/>
								{errors?.email && (
									<small className="text-red-400">{errors.email.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>Senha:</strong>
								<input
									{...register('password')}
									type="password"
									placeholder="Digite a senha"
									className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11"
								/>
								{errors?.password && (
									<small className="text-red-400">{errors.password.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>Cidade:</strong>
								<input
									{...register('city', { required: 'Campo obrigatório' })}
									placeholder="Digite o nome"
									className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11"
								/>
								{errors?.city && (
									<small className="text-red-400">{errors.city.message}</small>
								)}
							</div>
							<button
								type="submit"
								disabled={loadingPlannings}
								className={classNames(
									'w-full h-14 mt-6 rounded-lg text-3xl text-white',
									loadingPlannings ? 'bg-gray-400 cursor-not-allowed' : 'bg-primary'
								)}
							>
								{loadingPlannings ? 'Salvando...' : 'Salvar Consultor'}
							</button>
						</form>
					</div>
				</div>
			</HeaderlessSlideOver>
			<Loader show={loading} />
		</>
	);
}
export default ConsultantDetail;
