import { Tab } from '@headlessui/react';
import imageCompression from 'browser-image-compression';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BiBuildingHouse } from 'react-icons/bi';
import { FaClinicMedical } from 'react-icons/fa';
import { IoIosPeople } from 'react-icons/io';
import InputMask from 'react-input-mask-updated';
import { useToasts } from 'react-toast-notifications';

import CounterCards from '../../components/CounterCards';
import Header from '../../components/Header';
import HeaderlessSlideOver from '../../components/HeadlessSlideOver';
import ListClinics from '../../components/ListClinics';
import Loader from '../../components/Loader';
import Menu from '../../components/Menu';
import SearchBar from '../../components/SearchBar';
import { useAuth } from '../../contexts/auth';
import api from '../../services/api';
import classNames from '../../utils/classNames';
import toBase64 from '../../utils/toBase64';

function Clinics() {
	const [clinics, setClinis] = useState([]);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(true);
	const [previewClinicImage, setPreviewClinicImage] = useState();

	const [imageNew, setImage] = useState();
	const { signOut } = useAuth();
	const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm();
	const { addToast } = useToasts();

	useEffect(() => {
		async function loadData() {
			setLoading(true);

			try {
				const { data } = await api.get('/manager/clinics', {
					params: {
						with_address: true
					}
				});

				setClinis(data);
			} catch (err) {
				if (err.response?.status === 401) {
					signOut();
					return;
				}

				addToast(err.response?.data?.message ?? 'Erro ao buscar as clinicas.', {
					appearance: 'error',
					autoDismiss: true
				});
			} finally {
				setLoading(false);
			}
		}

		loadData();
	}, []);

	const handleSearch = useCallback(async value => {
		try {
			const { data } = await api.get('/manager/clinics', {
				params: {
					search: value,
					with_address: true
				}
			});
			setClinis(data);
		} catch (err) {
			if (err.response?.status === 401) {
				signOut();
				return;
			}

			addToast(err.response?.data?.message ?? 'Nenhum resultado encontrado.', {
				appearance: 'error',
				autoDismiss: true
			});
		}
	}, []);

	const handleCEP = async (evt) => {
		const cep = evt.target.value?.match(RegExp(/[0-9]/g))?.join('');

		if (cep?.length !== 8) {
			addToast('CEP inválido', {
				appearance: 'warning',
				autoDismiss: true,
			});
			return;
		}

		try {
			const res = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
			if (!res.ok) throw new Error('CEP inválido');
			const body = await res.json();

			setValue('address.street', body.logradouro);
			setValue('address.complement', body.complemento);
			setValue('address.neighborhood', body.bairro);
			setValue('address.city', body.localidade);
			setValue('address.state', body.uf);
		} catch (err) {
			addToast('CEP inválido', {
				appearance: 'warning',
				autoDismiss: true,
			});
		}
	};

	const onSubmit = useCallback(async ({ ...formData }) => {
		setLoading(true);

		try {
			const image = imageNew;
			const compressed = await imageCompression(image, {
				maxSizeMB: 1,
				maxWidthOrHeight: 480,
				useWebWorker: true
			});

			const img = await toBase64(compressed);

			await api.post('/manager/clinics', {
				...formData,
				image: img,
				address: {
					...formData.address,
					zipcode: formData.address.zipcode.match(RegExp(/[0-9]/g))?.join('')
				}
			});

			addToast('Clinica salva', {
				appearance: 'success',
				autoDismiss: true
			});

			handleSearch(undefined);
			setLoading(false);
			setOpen(false);
			reset();
			setPreviewClinicImage(undefined);
		} catch (err) {
			addToast(err.response?.data?.message ?? 'Erro ao cadastrar a clinica.', {
				appearance: 'error',
				autoDismiss: true
			});
		}
	}, [imageNew]);

	return (
		<>
			<div className="flex">
				<Menu className="hidden xl:flex" />
				<div className="w-full flex flex-col">
					<Header textHeader="Clínicas" />
					<main className="w-full flex flex-col py-4 px-14">
						<div className="flex flex-col-reverse sm:flex-row justify-between" >
							<CounterCards
								className=""
								cardList={[
									{
										id: '1',
										title: 'Total de Clínicas',
										counter: clinics.length,
										priColor: 'bg-primary',
										secColor: 'bg-primary',
										Icon: FaClinicMedical,
									},
									{
										id: '2',
										title: 'Total de Pacientes',
										counter: clinics.reduce((acc, clinic) => acc + clinic.Patients.length, 0),
										priColor: 'bg-admin-bage-light',
										secColor: 'bg-admin-bage-default',
										Icon: IoIosPeople,
									}
								]}
							/>
							<button
								type="button"
								onClick={() => setOpen(true)}
								className={classNames(
									'mt-10 py-3 px-8 self-start',
									'text-center text-xl rounded-lg text-admin-bc-white font-bold whitespace-nowrap',
									'transition-all duration-300 bg-primary hover:bg-opacity-70',
								)}
							>
								Adicionar Clínica
							</button>
						</div>
						<Tab.Group>
							<SearchBar
								getSearch={value => handleSearch(value)}
								optionsList={[
									{
										id: '1',
										option: 'Clínicas',
									},
								]}
							/>
							<Tab.Panel className={classNames(
								loading ? 'flex items-center justify-center' : 'grid',
								'grid-cols-1 mt-8 lg:grid-cols-2 gap-x-4 gap-y-3'
							)}>
								{loading ? (
									<p className="text-2xl font-bold animate-pulse">Carregando...</p>
								) : (
									<ListClinics list={clinics} />
								)}
							</Tab.Panel>
						</Tab.Group>
					</main>
				</div>
			</div>
			<HeaderlessSlideOver
				open={open}
				setOpen={setOpen}
				title="Menu"
				className="absolute"
				side="ri"
			>
				<div className="overflow-y-auto max-h-screen h-screen w-auto flex  flex-col  bg-white">
					<h3 className="flex w-full py-10 flex-col h-24 text-center justify-center bg-primary text-3xl text-white">
						Adicionar Clínica
					</h3>
					<div className=" w-auto flex p-10 flex-col gap-10 bg-white">
						<form onSubmit={handleSubmit(onSubmit)} className="gap-10 flex flex-col">
							<strong>Clique abaixo para selecionar a imagem da clínica</strong>
							<label
								htmlFor="image"
								className={classNames(
									'w-28 h-28 place-self-center flex p-2 rounded-full border-2 border-primary bg-transparent',
									'bg-center bg-cover bg-no-repeat cursor-pointer'
								)}
								style={{ backgroundImage: `url(${previewClinicImage})` }}
							>
								{!previewClinicImage && (
									<BiBuildingHouse size={65} className="text-primary py-2" />
								)}
								<input
									{...register('image', { required: 'Campo obrigatório' })}
									id="image"
									type="file"
									accept="image/png,image/jpg,image/jpeg,image/webp"
									className="sr-only"
									onChange={evt => {
										const file = evt.currentTarget.files.item(0);
										if (file) {
											const imageUrl = URL.createObjectURL(file);
											setPreviewClinicImage(imageUrl);
											setImage(file);
											return;
										}

										setImage(undefined);
										setPreviewClinicImage(undefined);
									}}
								/>
							</label>
							{errors.image && (
								<small className="text-red-400 self-center">{errors.image.message}</small>
							)}
							<div className="flex gap-4 flex-col">
								<strong>Nome da Clínica:</strong>
								<input {...register('name', { required: 'A imagem é obrigatório' })} placeholder="Digite aqui o nome da clínica" className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11" />
								{errors.name && (
									<small className="text-red-400">{errors.name.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>Email:</strong>
								<input {...register('email', { required: 'Campo obrigatório' })} type="email" placeholder="Digite aqui o seu Email" className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11" />
								{errors.email && (
									<small className="text-red-400">{errors.email.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>Telefone:</strong>
								<InputMask mask="(99) 99999-9999" {...register('phone', { required: 'Campo obrigatório' })} placeholder="Digite aqui o seu Telefone" className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11" />
								{errors.phone && (
									<small className="text-red-400">{errors.phone.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>Responsável pela clínica:</strong>
								<input {...register('responsible', { required: 'Campo obrigatório' })} placeholder="Digite aqui o nome do responsável" className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11" />
								{errors.responsible && (
									<small className="text-red-400">{errors.responsible.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>Cep:</strong>
								<InputMask
									mask="99999-999"
									{...register('address.zipcode', { required: 'Campo obrigatório' })}
									placeholder="Digite aqui o cep"
									className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11"
									onBlur={handleCEP}
								/>
								{errors.address?.zipcode && (
									<small className="text-red-400">{errors.address?.zipcode.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>Rua:</strong>
								<input {...register('address.street', { required: 'Campo obrigatório' })} placeholder="Digite aqui a rua" className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11" />
								{errors.address?.street && (
									<small className="text-red-400">{errors.address?.street.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>Número:</strong>
								<input {...register('address.number', { required: 'Campo obrigatório' })} type="number" placeholder="Digite aqui a rua" className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11" />
								{errors.address?.number && (
									<small className="text-red-400">{errors.address?.number.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>Complemento:</strong>
								<input {...register('address.complement')} placeholder="Digite aqui o complemento" className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11" />
							</div>
							<div className="flex gap-4 flex-col">
								<strong>Bairro:</strong>
								<input {...register('address.neighborhood', { required: 'Campo obrigatório' })} placeholder="Digite aqui o bairro" className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11" />
								{errors.address?.neighborhood && (
									<small className="text-red-400">{errors.address?.neighborhood.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>Cidade:</strong>
								<input {...register('address.city', { required: 'Campo obrigatório' })} placeholder="Digite aqui a cidade" className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11" />
								{errors.address?.city && (
									<small className="text-red-400">{errors.address?.city.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>UF:</strong>
								<InputMask mask="aa" {...register('address.state', { required: 'Campo obrigatório' })} placeholder="Digite aqui o UF" className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11" />
								{errors.address?.state && (
									<small className="text-red-400">{errors.address?.state.message}</small>
								)}
							</div>
							<button type="submit" className="w-full h-14 mt-6 bg-primary rounded-lg text-3xl text-white">Adicionar Clínica</button>
						</form>
					</div>
				</div>
			</HeaderlessSlideOver>
			<Loader show={loading} />
		</>
	);
}

export default Clinics;
