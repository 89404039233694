import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import classNames from '../../utils/classNames';
import { teeth } from './teeth';

function Step2({ selected, questions, defaultValues, sending, onNext, onBack }) {
	const [toothSelectedAbsent, setToothSelectedAbsent] = useState('');
	const { handleSubmit, control, getValues } = useForm({ defaultValues });

	useEffect(() => {
		if (defaultValues.teeth) {
			const obj = defaultValues?.teeth.reduce((p, c) => ({ ...p, ...c }));
			setToothSelectedAbsent(Object.entries(obj).filter(v => !!v[1]).map(v => v[0]).join(', ').replace(/tooth-/g, ''));
		}
	}, [defaultValues]);

	const onSubmit = useCallback(data => {
		const pageAnswers = JSON.parse(JSON.stringify({ teeth: data.teeth }));
		onNext(pageAnswers);
	}, [selected, questions, onNext]);

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={`${selected === 2 ? '' : 'hidden'}`}>
			<div className="grid md:grid-rows grid-cols-1 mt-12  gap-y-10 text-2xl justify-items-center">
				<div className="flex flex-row gap-0">
					{[18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28].map((v, i) => (
						<Controller
							key={v}
							control={control}
							name={`teeth.${i}.tooth-${v}`}
							defaultValue={false}
							render={({ field: { onChange, ...field } }) => (
								<label
									title={`Dente número ${v}`}
									htmlFor={`teeth${v}`}
									className="h-20 w-16 cursor-pointer bg-contain bg-center bg-no-repeat"
									style={{
										backgroundImage: `url(${toothSelectedAbsent.includes(v) ? teeth.pb[v] : teeth.col[v]})`,
									}}
								>
									<input
										{...field}
										onChange={evt => {
											onChange(evt);
											const obj = [...getValues('teeth')].reduce((p, c) => ({ ...p, ...c }));
											setToothSelectedAbsent(Object.entries(obj).filter(v => !!v[1]).map(v => v[0]).join(', ').replace(/tooth-/g, ''));
										}}
										id={`teeth${v}`}
										className="h-0 w-0"
										type="checkbox"
									/>
								</label>
							)}
						/>
					))}
				</div>
				<div className="flex flex-row gap-0">
					{[48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38].map((v, i) => (
						<Controller
							key={v}
							control={control}
							name={`teeth.${i}.tooth-${v}`}
							defaultValue={false}
							render={({ field: { onChange, ...rest } }) => (
								<label
									title={`Dente número ${v}`}
									htmlFor={`teeth${v}`}
									className="h-20 w-16 cursor-pointer bg-contain bg-center bg-no-repeat"
									style={{
										backgroundImage: `url(${toothSelectedAbsent.includes(v) ? teeth.pb[v] : teeth.col[v]})`,
									}}
								>
									<input
										{...rest}
										onChange={evt => {
											onChange(evt);
											const obj = [...getValues('teeth')].reduce((p, c) => ({ ...p, ...c }));
											setToothSelectedAbsent(Object.entries(obj).filter(v => !!v[1]).map(v => v[0]).join(', ').replace(/tooth-/g, ''));
										}}
										id={`teeth${v}`}
										className="h-0 w-0"
										type="checkbox"
									/>
								</label>
							)}
						/>
					))}
				</div>
				<div className="text-left justify-self-start ml-14">
					<strong>Resumo:</strong>
					<p>Dentes ausentes: {toothSelectedAbsent}</p>
				</div>
				<div className="flex md:flex-row flex-col justify-center gap-x-10">
					<button
						type="button"
						onClick={onBack}
						className={classNames(
							'mt-10 py-1 px-36 self-center border-2 border-primary-dark text-primary-dark',
							'text-center text-xl lg:text-2xl rounded-lg font-bold',
							'transition-colors duration-300 hover:bg-primary-dark hover:text-white'
						)}
					>
						Voltar
					</button>
					<button
						type="submit"
						disabled={sending}
						className={classNames(
							'mt-10 py-2 px-36 self-center',
							'text-center text-xl lg:text-2xl rounded-lg text-admin-bc-white font-bold',
							'transition-colors duration-300',
							sending ? 'bg-gray-400 cursor-wait' : 'bg-primary-dark hover:bg-primary'
						)}
					>
						Próximo
					</button>
				</div>
			</div>
		</form>
	);
}

export default Step2;
