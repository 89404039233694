import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';

import dentesBaixo from '../assets/images/dentesBaixo.png';
import dentesCima from '../assets/images/dentesCima.png';
import dentesDireita from '../assets/images/dentesDireita.png';
import dentesEsquerda from '../assets/images/dentesEsquerda.png';
import dentesFrente from '../assets/images/dentesFrente.png';
import fotoLateral from '../assets/images/fotoLateral.png';
import fotoSeria from '../assets/images/fotoSeria.png';
import fotoSorrindo from '../assets/images/fotoSorrindo.png';
import raioxLateral from '../assets/images/raioxLateral.png';
import raioxPanoramico from '../assets/images/raioxPanoramico.png';
import { useAuth } from '../contexts/auth';
import api from '../services/api';
import classNames from '../utils/classNames';
import toBase64 from '../utils/toBase64';
import wait from '../utils/wait';
import ImageInput from './ImageInput';

function AnswerFollowUp({ followUp, patientId }) {
	const [loading, setLoading] = useState(false);

	const { control, register, handleSubmit, formState: { errors } } = useForm();
	const { addToast } = useToasts();
	const { user, signOut } = useAuth();

	const onSubmit = useCallback(async (data) => {
		setLoading(true);

		try {
			const values = {
				faceFrontal: data.faceFrontal ? (await toBase64(data.faceFrontal.item(0))) : null,
				faceLateral: data.faceLateral ? (await toBase64(data.faceLateral.item(0))) : null,
				faceSorrindo: data.faceSorrindo ? (await toBase64(data.faceSorrindo.item(0))) : null,
				dentesFrontal: data.dentesFrontal ? (await toBase64(data.dentesFrontal.item(0))) : null,
				dentesEsquerda: data.dentesEsquerda ? (await toBase64(data.dentesEsquerda.item(0))) : null,
				dentesDireita: data.dentesDireita ? (await toBase64(data.dentesDireita.item(0))) : null,
				dentesCima: data.dentesCima ? (await toBase64(data.dentesCima.item(0))) : null,
				dentesBaixo: data.dentesBaixo ? (await toBase64(data.dentesBaixo.item(0))) : null,
				raioxLateral: data.raioxLateral ? (await toBase64(data.raioxLateral.item(0))) : null,
				raioxPanoramico: data.raioxPanoramico ? (await toBase64(data.raioxPanoramico.item(0))) : null,
				status: 'completed',
				followup_id: followUp.id,
				dentist_id: user.id,
				patient_id: patientId,
			};

			await api.post('/followup/answer', values);

			addToast('Acompanhamento repondido com sucesso!', {
				appearance: 'success',
				autoDismiss: true,
			});

			await wait(3000);
			window.location.reload();
		} catch (err) {
			if (err.response?.status === 401) {
				signOut();
				return;
			}

			addToast(err.response?.data.message ?? 'Erro ao atualizar acompanhamento', {
				appearance: 'error',
				autoDismiss: true
			});
		} finally {
			setLoading(false);
		}
	}, [user, followUp, patientId]);

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col mt-4">
			<div className="flex gap-8 flex-wrap">
				{followUp?.hasFaceFrontal && (
					<ImageInput
						register={register}
						registerOptions={{ required: 'Imagem obrigatória' }}
						name="faceFrontal"
						image={fotoSeria}
						error={errors.faceFrontal?.message}
					/>
				)}
				{followUp?.hasFaceLateral && (
					<ImageInput
						register={register}
						registerOptions={{ required: 'Imagem obrigatória' }}
						name="faceLateral"
						image={fotoLateral}
						error={errors.faceLateral?.message}
					/>
				)}
				{followUp?.hasFaceSorrindo && (
					<ImageInput
						register={register}
						registerOptions={{ required: 'Imagem obrigatória' }}
						name="faceSorrindo"
						image={fotoSorrindo}
						error={errors.faceSorrindo?.message}
					/>
				)}
				{followUp?.hasDentesFrontal && (
					<ImageInput
						register={register}
						registerOptions={{ required: 'Imagem obrigatória' }}
						name="dentesFrontal"
						image={dentesFrente}
						error={errors.dentesFrontal?.message}
					/>
				)}
				{followUp?.hasDentesEsquerda && (
					<ImageInput
						register={register}
						registerOptions={{ required: 'Imagem obrigatória' }}
						name="dentesEsquerda"
						image={dentesEsquerda}
						error={errors.dentesEsquerda?.message}
					/>
				)}
				{followUp?.hasDentesDireita && (
					<ImageInput
						register={register}
						registerOptions={{ required: 'Imagem obrigatória' }}
						name="dentesDireita"
						image={dentesDireita}
						error={errors.dentesDireita?.message}
					/>
				)}
				{followUp?.hasDentesCima && (
					<ImageInput
						register={register}
						registerOptions={{ required: 'Imagem obrigatória' }}
						name="dentesCima"
						image={dentesCima}
						error={errors.dentesCima?.message}
					/>
				)}
				{followUp?.hasDentesBaixo && (
					<ImageInput
						register={register}
						registerOptions={{ required: 'Imagem obrigatória' }}
						name="dentesBaixo"
						image={dentesBaixo}
						error={errors.dentesBaixo?.message}
					/>
				)}
				{followUp?.hasRaioxLateral && (
					<ImageInput
						register={register}
						registerOptions={{ required: 'Imagem obrigatória' }}
						name="raioxLateral"
						image={raioxLateral}
						error={errors.raioxLateral?.message}
					/>
				)}
				{followUp?.hasRaioxPanoramico && (
					<ImageInput
						register={register}
						registerOptions={{ required: 'Imagem obrigatória' }}
						name="raioxPanoramico"
						image={raioxPanoramico}
						error={errors.raioxPanoramico?.message}
					/>
				)}
			</div>

			<button
				type="submit"
				disabled={loading}
				className={classNames(
					'mx-auto px-24 py-2 mt-8 rounded-md',
					'text-2xl text-white font-bold bg-primary',
					'transition-colors duration-300',
					'hover:bg-primary-dark',
					loading ? 'bg-gray-400 cursor-not-allowed' : null
				)}
			>
				{loading ? 'Enviando...' : 'Responder'}
			</button>
		</form>
	);
}

export default AnswerFollowUp;
