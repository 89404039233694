import axios from 'axios';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { AiOutlineStar } from 'react-icons/ai';
import { BsArrowLeft, BsPlayCircle } from 'react-icons/bs';
import { MdOutlineDownload } from 'react-icons/md';
import Rating from 'react-rating';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import Header from '../../components/Header';
import Loader from '../../components/Loader';
import Menu from '../../components/Menu';
import SideBar from '../../components/videoaulas/SideBar';
import { useAuth } from '../../contexts/auth';
import { VIDEO_INITIAL_STATE, videoReducer } from '../../reducers/videoReducer';
import api from '../../services/api';
import classNames from '../../utils/classNames';
import wait from '../../utils/wait';


function VideoDetail() {
	const [videoCompleted, setVideoCompleted] = useState(false);
	const [isOpen, setIsOpen] = useState(true);
	const [loading, setLoading] = useState(true);
	const [updateProgress, setUpdateProgress] = useState(true);

	const [state, dispatch] = useReducer(videoReducer, VIDEO_INITIAL_STATE);
	const videoRef = useRef(null);
	const { id_module } = useParams();
	const history = useHistory();
	const { addToast } = useToasts();
	const { signOut } = useAuth();

	useEffect(() => {
		async function loadVideo() {
			setLoading(true);

			try {
				const { data } = await api.get(`/manager/videos/${id_module}`);

				dispatch({ type: 'SET_MODULE', payload: data });
				videoRef.current?.load();
			} catch (err) {
				addToast(err.response?.data?.message ?? 'Erro ao buscar módulo', {
					appearance: 'error',
					autoDismiss: true,
				});

				if (err.response?.status === 401) {
					signOut();
					return;
				}

				await wait(4000);

				history.goBack();
			} finally {
				setLoading(false);
			}
		}

		loadVideo();
	}, []);

	useEffect(() => {
		videoRef.current?.load();
	}, [state.selectedVideo]);

	useEffect(() => {
		const saveProgress = async () => {
			const video = state.videos[state.selectedVideo];

			if (!video) {
				addToast('Você não selecionou um video', {
					appearance: 'warning',
					autoDismiss: true,
				});
				return;
			}

			if (video.progress == 1) return;

			try {
				await api.put('/video/progress', {
					video_id: video.id,
					progress: 100,
					video_time: 0,
				});

				dispatch({ type: 'UPDATE_VIDEO_PROGRESS', payload: 100 });
				setUpdateProgress(old => !old);
			} catch (err) {
				if (axios.isAxiosError(err)) {
					addToast(err.response?.data?.message ?? 'Erro ao salvar progresso', {
						appearance: 'error',
						autoDismiss: true,
					});

					if (err.response.status === 401) {
						signOut();
					}

					return;
				}

				addToast('Ocorreu algum erro interno, tente novamente mais tarde', {
					appearance: 'error',
					autoDismiss: true,
				});
			}
		};

		if (videoCompleted === true) {
			saveProgress();
		}
	}, [videoCompleted]);

	const saveRating = async (rate) => {
		const video = state.videos[state.selectedVideo];

		if (!video) {
			addToast('Você não selecionou um video', {
				appearance: 'warning',
				autoDismiss: true,
			});
			return;
		}

		try {
			await api.post('/video/rate', {
				video_id: video.id,
				rate,
			});

			dispatch({ type: 'UPDATE_VIDEO_RATING', payload: rate });
		} catch (err) {
			if (axios.isAxiosError(err)) {
				addToast(err.response?.data?.message ?? 'Erro ao salvar avaliação', {
					appearance: 'error',
					autoDismiss: true,
				});

				if (err.response.status === 401) {
					signOut();
				}

				return;
			}

			addToast('Ocorreu algum erro interno, tente novamente mais tarde', {
				appearance: 'error',
				autoDismiss: true,
			});
		}
	};

	return (
		<>
			<div className="flex">
				<Menu className="hidden xl:flex" />
				<div className="w-full flex flex-col">
					<Header textHeader="Vídeoaulas" />
					<main className="flex flex-1 gap-x-12 pl-14 overflow-x-hidden">
						<div className={classNames('flex flex-col py-4', isOpen ? 'flex-1' : 'w-full')}>
							<div className="flex gap-x-4 items-center text-gray-400">
								<Link to="/videoaulas" className="flex items-center gap-x-2">
									<BsArrowLeft size={20} />
									Voltar
								</Link>
								<span>/</span>
								<span>{state?.title}</span>
							</div>
							<div className="mt-6 flex items-center gap-x-2">
								<BsPlayCircle size={30} color="#1E4E7B" />
								<h2 className="text-xl font-medium">{state?.title}</h2>
							</div>
							<div className="mt-6 flex flex-col gap-4 rounded-lg drop-shadow-2xl">
								{state.videos?.length > 0 && (
									<video
										ref={videoRef}
										controls
										controlsList="nodownload"
										preload="auto"
										onTimeUpdate={evt => {
											const { duration, currentTime } = evt.currentTarget;
											const timeToSave = duration - 120;

											if (currentTime > timeToSave) {
												setVideoCompleted(true);
											}
										}}
									>
										<source src={state.videos[state.selectedVideo].url} type="video/mp4" />
										Your browser does not support the video tag.
									</video>
								)}
							</div>
							<div className="flex items-center justify-between mt-6">
								<div className="flex items-center gap-x-4">
									<p className="text-2xl">Avalie esta aula</p>
									<div>
										<Rating
											stop={5}
											readonly={state.videos?.[state.selectedVideo]?.rating > 0}
											initialRating={state.videos?.[state.selectedVideo]?.rating || 0}
											fullSymbol={<AiOutlineStar size={30} color="#ffd700" />}
											emptySymbol={<AiOutlineStar size={30} color="#aaa" />}
											onChange={rate => saveRating(rate)}
										/>
									</div>
								</div>
								{/* <button type="button" className={classNames(
									'flex items-center gap-x-2 py-2 px-4',
									'bg-primary text-white rounded-md',
									'border-2 border-primary',
									'hover:bg-white hover:text-primary',
									'transition-colors duration-150'
								)}>
									<span className="flex-1 text-xl">Baixar material</span>
									<MdOutlineDownload size={30} />
								</button> */}
							</div>
							<div className="flex flex-col mt-6">
								<h3 className="text-3xl font-bold">Arquivos ({state.files?.length ?? '0'})</h3>
								{state.files?.map(file => (
									<div key={file.id} className="flex items-center justify-between border-b py-2">
										<p className="text-2xl whitespace-pre-wrap">{file.title}</p>
										<a
											type="button"
											download
											href={file.url}
											target="_blank"
											rel="noreferrer noopener"
											className={classNames(
												'flex items-center gap-x-2 py-2 px-4',
												'bg-primary text-white rounded-md',
												'border-2 border-primary',
												'hover:bg-white hover:text-primary',
												'transition-colors duration-150'
											)}>
											<span className="flex-1 text-xl">Baixar material</span>
											<MdOutlineDownload size={30} />
										</a>
									</div>
								))}

							</div>
						</div>

						{/* Sidebar */}
						<SideBar
							isOpen={isOpen}
							onMenuClick={() => setIsOpen(old => !old)}
							updateProgress={updateProgress}
							state={state}
							dispatch={dispatch}
							setVideoCompleted={setVideoCompleted}
						/>
					</main>
				</div>
			</div>
			<Loader show={loading} />
		</>
	);
}

export default VideoDetail;


