import React from 'react';
import { BiCalendarCheck, BiCalendarEdit, BiCalendarX } from 'react-icons/bi';

import { usePlannings } from '../../contexts/plannings';
import { getPlanningStatusColor } from '../../utils/status';
import CounterCards from '../CounterCards';

function PlanningsCounter() {
	const { plannings, counters } = usePlannings();
	return (
		<CounterCards
			className=""
			cardList={[
				{
					id: '1',
					title: 'Planejamentos\nPendentes',
					counter: counters.pending,
					secColor: getPlanningStatusColor('pending'),
					priColor: getPlanningStatusColor('pending'),
					Icon: BiCalendarEdit,
				},
				{
					id: '2',
					title: 'Planejamentos\nExpirados',
					counter: plannings.pending.plannings.filter(plan => plan.expires_in < new Date()).length,
					secColor: getPlanningStatusColor('expired'),
					priColor: getPlanningStatusColor('expired'),
					Icon: BiCalendarX,
				},
				{
					id: '3',
					title: 'Planejamentos\nRespondidos',
					counter: counters.completed,
					secColor: getPlanningStatusColor('completed'),
					priColor: getPlanningStatusColor('completed'),
					Icon: BiCalendarCheck,
				},
				{
					id: '4',
					title: 'Planejamentos\ncom Alterações',
					counter: counters.more_info,
					secColor: getPlanningStatusColor('more_info'),
					priColor: getPlanningStatusColor('more_info'),
					Icon: BiCalendarCheck,
				},
				{
					id: '5',
					title: 'Planejamentos\nInativos',
					counter: counters.inactive,
					secColor: getPlanningStatusColor('', true),
					priColor: getPlanningStatusColor('', true),
					Icon: BiCalendarX,
				},
			]}
		/>
	);
}

export default PlanningsCounter;
