// {
// 	"id": 1,
// 	"title": "Naruto",
// 	"description": "Naruto Chega pra salvar KONOHA após O ATAQUE DE PAIN",
// 	"thumbnail": "https://storage-zt-consultoria-dev.s3.amazonaws.com/prod/videoaulas/thumbnail-1651260711284.jpeg",
// 	"created_at": "2023-01-18T01:40:50.114Z",
// 	"videos": [
// 		{
// 			"id": 1,
// 			"title": "Naruto Chega pra salvar KONOHA após O ATAQUE DE PAIN",
// 			"url": "https://storage-zt-consultoria-dev.s3.amazonaws.com/prod/videoaulas/video-1651260678968.mp4",
// 			"created_at": "2023-01-18T01:42:17.525Z",
// 			"rating": 0,
// 			"progress": 0,
// 			"video_current_time": 0,
// 		}
// 	]
// }
const VIDEO_INITIAL_STATE = {
	id: 1,
	title: '',
	description: '',
	thumbnail: '',
	created_at: '',
	selectedVideo: 0,
	progress: 0,
	videos: []
};


function videoReducer(state, action) {
	switch (action.type) {
		case 'SET_MODULE':
			return { ...state, ...action.payload };

		case 'SET_VIDEOS':
			return {
				...state,
				videos: [...state.videos, action.payload]
			};

		case 'SET_SELECTED':
			return {
				...state,
				selectedVideo: action.payload,
			};

		case 'UPDATE_VIDEO_PROGRESS': {
			state.videos[state.selectedVideo].progress = action.payload;
			return state;
		}

		case 'UPDATE_VIDEO_RATING': {
			const videos = state.videos;
			videos[state.selectedVideo].rating = action.payload;
			return {
				...state,
				videos,
			};
		}

		case 'UPDATE_AVALIACAO':
			return {
				...state,
				avaliacao: { nota: action.payload }
			};


		default:
			return state;
	}
}

export { VIDEO_INITIAL_STATE, videoReducer };
