import React from 'react';

import classNames from '../utils/classNames';

function FollowUpCard({ followUp }) {
	function parseStatus(status) {
		switch (status) {
			case 'completed':
				return 'Respondido pela dentista';
			case 'finished':
				return 'Finalizado';
			default:
				return 'Respondido pelo consultoria';
		}
	}

	function getStatusColor(status) {
		switch (status) {
			case 'completed':
				return 'bg-status-completed';
			case 'finished':
				return 'bg-green-500';
			default:
				return 'bg-admin-orange-text';
		}
	}

	return (
		<a key={followUp.id}
			href={`/acompanhamento/${followUp.group_id}`}
			className="shadow-md rounded-4 w-full h-auto flex flex-col md:flex-row px-4 py-2 gap-4 bg-admin-bage-bgList rounded-lg drop-shadow-lg"
		>
			<div
				className="w-24 h-24 self-center rounded-full bg-cover bg-center bg-no-repeat"
				style={{ backgroundImage: `url(${followUp.patient.profile_image})` }}
			/>
			<div className="grid sm:grid-cols-2 gap-y-2 items-center  flex-1 justify-between">
				<div className="flex flex-col justify-evenly sm:justify-start">
					<strong className="flex text-xl self-end text-admin-orange-text">Acompanhamento</strong>
					<p className="font-bold text-xl ml-2 self-start">{followUp.patient.name}</p>
					<p className="text-sm ml-2">{followUp.dentist.clinic.name}</p>
				</div>
				<div className="flex flex-col sm:flex-row justify-evenly sm:justify-end w-full md:self-end">
					<div className="flex flex-col gap-2 sm:flex-row-3 sm:self-end">
						<div className="flex ml-2 sm:self-end">
							<strong className="text-sm sm:text-base">Data de Solicitação: </strong>
							<p className="text-sm sm:text-base ml-2">{new Date(followUp.created_at).toLocaleDateString()}</p>
						</div>
						<div className="flex ml-2 gap-1 sm:self-end">
							<strong className="text-sm sm:text-base">Expira em: </strong>
							<p className="text-sm sm:text-base">{new Date(followUp.expires_in).toLocaleDateString()}</p>
							<div className={classNames(
								'p-2 self-center rounded-full',
								new Date(followUp.expires_in) < new Date() ? 'bg-red-700' : 'bg-status-completed',
							)} />
							{/* {buttonCheck(followUp.situacao)} */}
						</div>
						<div className="flex justify-center flex-row-reverse md:flex-row align-middle ml-2 text-sm gap-1 sm:self-end">
							{/* {textCheck(followUp.situacao)} */}
							<p
								className={classNames(
									'rounded-full py-1 px-5 text-sm font-semibold text-white',
									getStatusColor(followUp.status)
								)}
							>
								{parseStatus(followUp.status)}
							</p>
							{/* <p className="ml-2 sm:self-end self-center">{followUp.position}º acompanhamento</p> */}
						</div>
					</div>
				</div>
			</div>
		</a>
	);
}

export default FollowUpCard;
