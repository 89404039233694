import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IoMdClose } from 'react-icons/io';
import { useToasts } from 'react-toast-notifications';

import dentesBaixo from '../assets/images/dentesBaixo.png';
import dentesCima from '../assets/images/dentesCima.png';
import dentesDireita from '../assets/images/dentesDireita.png';
import dentesEsquerda from '../assets/images/dentesEsquerda.png';
import dentesFrente from '../assets/images/dentesFrente.png';
import fotoLateral from '../assets/images/fotoLateral.png';
import fotoSeria from '../assets/images/fotoSeria.png';
import fotoSorrindo from '../assets/images/fotoSorrindo.png';
import raioxLateral from '../assets/images/raioxLateral.png';
import raioxPanoramico from '../assets/images/raioxPanoramico.png';
import api from '../services/api';
import classNames from '../utils/classNames';
import Modal from './Modal';

function ModalRequestFollowUp({ open, onClose, patientId, dentistId }) {
	const [loading, setLoading] = useState(false);

	const { register, handleSubmit, reset } = useForm();
	const { addToast } = useToasts();

	const onSubmit = useCallback(async (data) => {
		setLoading(true);

		try {
			const totalSelected = Object.keys(data).filter(key => data[key] === true).length;
			if (totalSelected < 3) {
				addToast('Selecione pelo menos 3 fotos', {
					appearance: 'warning',
					autoDismiss: true,
				});
				return;
			}

			await api.post('/followup/', {
				...data,
				patient_id: patientId,
				dentist_id: dentistId,
			});

			addToast('Acompanhamento solicitado!', {
				appearance: 'success',
				autoDismiss: true,
			});
			onClose();
			reset();
		} catch (err) {
			addToast(err.response?.data?.message || 'Erro ao solicitar acompanhamento', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setLoading(false);
		}
	}, [patientId, dentistId]);

	return (
		<Modal isOpen={open} onClose={() => onClose()}>
			<div className="h-full w-full bg-white rounded-2xl overflow-hidden flex flex-col">
				<div className="w-auto h-auto bg-primary px-10 py-4 gap-x-28  flex flex-row items-center justify-between">
					<strong className="text-3xl w-full text-white">
						Selecione as fotos desejadas:
					</strong>
					<button onClick={() => onClose()}>
						<IoMdClose size={35} className="flex text-white" />
					</button>
				</div>
				<div className="flex flex-col py-6 gap-y-10 justify-center px-10">
					<form onSubmit={handleSubmit(onSubmit)} className="flex  flex-col gap-y-6">
						<label htmlFor="observation" className="w-full mb-4">
							<span className="text-xl text-black">Observação</span>
							<textarea
								{...register('observation')}
								id="observation"
								rows={5}
								className="w-full border border-gray-900 rounded-md p-2 text-lg"
							/>
						</label>
						<p className="text-xl text-black">
							Selecione no *Mínimo três fotos
						</p>
						<div className="flex flex-col md:flex-row gap-6 text-2xl text-white">
							<label htmlFor="checkFF" className="w-min h-min p-2 bg-gray-100 relative rounded">
								<div className="h-36 w-36 bg-contain bg-center bg-no-repeat" style={{ backgroundImage: `url(${fotoSeria})` }} />
								<input
									{...register('hasFaceFrontal')}
									id="checkFF"
									className="absolute flex right-2 bottom-2 h-8 w-8"
									type="checkbox"
								/>
							</label>
							<label htmlFor="checkFL" className="w-min h-min p-2 bg-gray-100 relative rounded">
								<div className="h-36 w-36 bg-contain bg-center bg-no-repeat" style={{ backgroundImage: `url(${fotoLateral})` }} />
								<input
									{...register('hasFaceLateral')}
									id="checkFL"
									className="absolute flex right-2 bottom-2 h-8 w-8"
									type="checkbox"
								/>
							</label>
							<label htmlFor="checkFS" className="w-min h-min p-2 bg-gray-100 relative rounded">
								<div className="h-36 w-36 bg-contain bg-center bg-no-repeat" style={{ backgroundImage: `url(${fotoSorrindo})` }} />
								<input
									{...register('hasFaceSorrindo')}
									id="checkFS"
									className="absolute flex right-2 bottom-2 h-8 w-8"
									type="checkbox"
								/>
							</label>
						</div>
						<div className="flex flex-col md:flex-row gap-6 text-2xl text-white">
							<label htmlFor="checkDF" className="w-min h-min p-2 bg-gray-100 relative rounded">
								<div className="h-36 w-36 bg-contain bg-center bg-no-repeat" style={{ backgroundImage: `url(${dentesFrente})` }} />
								<input
									{...register('hasDentesFrontal')}
									id="checkDF"
									className="absolute flex right-2 bottom-2 h-8 w-8"
									type="checkbox"
								/>
							</label>
							<label htmlFor="checkDE" className="w-min h-min p-2 bg-gray-100 relative rounded">
								<div className="h-36 w-36 bg-contain bg-center bg-no-repeat" style={{ backgroundImage: `url(${dentesEsquerda})` }} />
								<input
									{...register('hasDentesEsquerda')}
									id="checkDE"
									className="absolute flex right-2 bottom-2 h-8 w-8"
									type="checkbox"
								/>
							</label>
							<label htmlFor="checkDD" className="w-min h-min p-2 bg-gray-100 relative rounded">
								<div className="h-36 w-36 bg-contain bg-center bg-no-repeat" style={{ backgroundImage: `url(${dentesDireita})` }} />
								<input
									{...register('hasDentesDireita')}
									id="checkDD"
									className="absolute flex right-2 bottom-2 h-8 w-8"
									type="checkbox"
								/>
							</label>
							<label htmlFor="checkDC" className="w-min h-min p-2 bg-gray-100 relative rounded">
								<div className="h-36 w-36 bg-contain bg-center bg-no-repeat" style={{ backgroundImage: `url(${dentesCima})` }} />
								<input
									{...register('hasDentesCima')}
									id="checkDC"
									className="absolute flex right-2 bottom-2 h-8 w-8"
									type="checkbox"
								/>
							</label>
							<label htmlFor="checkDB" className="w-min h-min p-2 bg-gray-100 relative rounded">
								<div className="h-36 w-36 bg-contain bg-center bg-no-repeat" style={{ backgroundImage: `url(${dentesBaixo})` }} />
								<input
									{...register('hasDentesBaixo')}
									id="checkDB"
									className="absolute flex right-2 bottom-2 h-8 w-8"
									type="checkbox"
								/>
							</label>
						</div>
						<div className="flex flex-col md:flex-row gap-6 text-2xl text-white">
							<label htmlFor="checkRXL" className="w-min h-min p-2 bg-gray-100 relative rounded">
								<div className="h-36 w-36 bg-contain bg-center bg-no-repeat" style={{ backgroundImage: `url(${raioxLateral})` }} />
								<input
									{...register('hasRaioxLateral')}
									id="checkRXL"
									className="absolute flex right-2 bottom-2 h-8 w-8"
									type="checkbox"
								/>
							</label>
							<label htmlFor="checkRXP" className="w-min h-min p-2 bg-gray-100 relative rounded">
								<div className="h-36 w-36 bg-contain bg-center bg-no-repeat" style={{ backgroundImage: `url(${raioxPanoramico})` }} />
								<input
									{...register('hasRaioxPanoramico')}
									id="checkRXP"
									className="absolute flex right-2 bottom-2 h-8 w-8"
									type="checkbox"
								/>
							</label>
						</div>
						<button
							type="submit"
							disabled={loading}
							className={classNames(
								'mt-10 py-2 px-20 self-center',
								'text-center text-2xl rounded text-admin-bc-white',
								'transition-colors duration-300 hover:bg-primary',
								loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-primary'
							)}
						>
							Enviar solicitação
						</button>
					</form>
				</div>
			</div>
		</Modal>
	);
}

export default ModalRequestFollowUp;
