import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import classNames from '../../utils/classNames';

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

function OrderList({ videos, getOrder }) {
	const [orderVideos, setOrderVideos] = useState(videos ?? []);

	useEffect(() => {
		getOrder(
			orderVideos.map((video, index) => ({ id: video.id, order: index }))
		);
	}, [orderVideos]);

	function onDragEnd(result) {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		const items = reorder(
			orderVideos,
			result.source.index,
			result.destination.index
		);

		setOrderVideos(items);
	}

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId="droppable">
				{(provided) => (
					<div
						{...provided.droppableProps}
						ref={provided.innerRef}
						className="col-span-full"
					>
						{orderVideos.map((video, index) => (
							<Draggable
								key={video.id}
								draggableId={`${video.id}`}
								index={index}
							>
								{(provided, snapshot) => (
									<div
										ref={provided.innerRef}
										{...provided.draggableProps}
										{...provided.dragHandleProps}
										style={provided.draggableProps.style}
										className={classNames(
											snapshot.isDragging
												? 'bg-primary text-white'
												: 'bg-gray-100 text-black',
											'flex gap-x-4 p-4 my-4'
										)}
									>
										<img
											src={video.thumbnail}
											alt="Thumbnail do módulo"
											className="w-32 h-24 object-cover object-center"
										/>
										<span className="text-2xl">{video.title}</span>
									</div>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
}

export default OrderList;
