import React, { useCallback, useState } from 'react';
import { IoMdClose, IoMdCloudUpload } from 'react-icons/io';
import { useToasts } from 'react-toast-notifications';

import { useAuth } from '../contexts/auth';
import api from '../services/api';
import classNames from '../utils/classNames';
import Modal from './Modal';

function AnswerFollowUpWithVideo({ id_followup, isOpen, onClose, onFinish }) {
	const [file, setFile] = useState();
	const [uploading, setUploading] = useState(false);
	const [uploadPercent, setUploadPercent] = useState(0);
	const [observation, setObservation] = useState();
	const [error, setError] = useState();

	const { token, signOut } = useAuth();
	const { addToast } = useToasts();

	const handleUpload = useCallback(async () => {
		setUploading(true);

		try {
			const { data: permissionData } = await api.post(
				'/followup/permission-upload',
				{
					contentType: file.type,
					followupId: id_followup,
				},
			);

			delete api.defaults.headers.common['Authorization'];

			await api.put(permissionData.url, file, {
				headers: {
					'Content-Type': file.type,
					'Content-Length': undefined,
				},
				onUploadProgress: progressEvent => setUploadPercent(() => Math.round((progressEvent.loaded * 100) / progressEvent.total)),
				timeout: 900000
			});

			// BUG: para mandar arquivo para o S3, tem que remover completamente o header "Authorization", se não volta o erro 400.
			api.defaults.headers.common['Authorization'] = token;

			await api.put(`/followup/answer-video/${id_followup}`, {
				video_link: permissionData.urlView,
				observation,
			});

			onClose();
			addToast('Vídeo enviado com sucesso!', { appearance: 'success', autoDismiss: true });
			onFinish();
		} catch (err) {
			if (err.response?.status === 401) {
				addToast(
					err.response?.data?.message || 'Você não tem permissão para fazer upload de vídeos!',
					{
						appearance: 'error',
						autoDismiss: true
					}
				);
				signOut();
				return;
			}

			addToast(err.response?.data?.message || 'Erro ao fazer upload de vídeo!', { appearance: 'error', autoDismiss: true });
		} finally {
			setUploading(false);
			setUploadPercent(0);
			setError(undefined);
		}
	}, [file]);

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<div className="h-full w-full bg-white rounded-2xl overflow-hidden flex flex-col">
				<div className="w-auto h-auto bg-primary px-10 py-4 gap-x-28  flex flex-row items-center justify-between">
					<strong className="text-3xl w-full text-white">
						Selecione o vídeo para upload
					</strong>
					<button onClick={onClose}>
						<IoMdClose size={35} className="flex text-white" />
					</button>
				</div>
				<div className="flex flex-col py-6 gap-y-10 justify-center px-10">
					<label htmlFor="file" className={classNames('w-full py-16 flex flex-col items-center cursor-pointer', 'bg-gray-100')}>
						<span className="text-2xl font-bold">{file ? file.name : 'Selecione um arquivo'}</span>
						<IoMdCloudUpload size={90} color="#212121" />
						<input
							type="file"
							id="file"
							accept="video/mp4"
							className='sr-only'
							onChange={e => {
								const file = e.target.files.item(0);
								setFile(file);
							}}
						/>
					</label>
					{error && <small className="text-red-400 text-base">{error}</small>}


					<div className="flex flex-col gap-4 w-full">
						<strong className="">Observações:</strong>
						<textarea
							placeholder="Insira aqui suas observações"
							className="h-80 min-w-full max-w-2xl border border-solid border-gray-400 rounded-lg p-2 bg-gray-100 flex justify-center items-center text-2xl"
							value={observation}
							onChange={e => setObservation(e.currentTarget.value)}
						/>
					</div>

					<button
						type="button"
						disabled={uploading}
						onClick={handleUpload}
						className={classNames(
							'mt-10 py-4 px-10 self-center',
							'text-center text-xl lg:text-2xl rounded-lg text-admin-bc-white font-bold',
							'transition-colors duration-300 hover:bg-blue-400',
							uploading ? 'bg-gray-400 cursor-not-allowed' : 'bg-primary'
						)}
					>
						{uploading ? `Enviando... (${uploadPercent}%)` : 'Enviar vídeo'}
					</button>
				</div>
			</div>
		</Modal>
	);
}

export default AnswerFollowUpWithVideo;
