import { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';

import { useAuth } from '../contexts/auth';
import api from '../services/api';

function useEvaluationQuestions() {
	const [questions, setQuestions] = useState([]);
	const { addToast } = useToasts();
	const { user, signOut } = useAuth();

	useEffect(() => {
		async function loadData() {
			try {
				const { data } = await api.get('/evaluations/questions');
				setQuestions(data.questions);
			} catch (err) {
				if (err.response?.status === 401) {
					addToast('Você não tem permissão para acessar esta página.', {
						appearance: 'error',
						autoDismiss: true,
					});

					signOut();
					return;
				}

				addToast('Erro ao carregar a lista de perguntas para avaliar', {
					appearance: 'error',
					autoDismiss: true,
				});
			}
		}

		if (user.role === 1 || user.role === 3) {
			loadData();
		}
	}, []);

	return { questions };
}

export default useEvaluationQuestions;
