// import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';

import Modal from './Modal';

function ListPhotosCompare({ leftImageTitle, rightImageTitle, list }) {
	const [showGallery, setShowGallery] = useState(false);
	const [idxSelected, setIdx] = useState();

	return (
		<>
			{list.length < 1 ?
				<div className="mt-6 shadow-md rounded-4 w-full h-auto flex flex-col xl:flex-row px-4 py-2 gap-4 rounded-lg drop-shadow-lg">
					<p className='flex self-center place-self-center text-xl font-bold py-6'>Nenhuma foto encontrada.</p>
				</div>
				:
				<>
					{list.map((img, index) => !img.thumb ? null : (
						<img
							key={index}
							loading="lazy"
							className="max-w-full max-h-full cursor-pointer w-40 h-40 object-cover object-center"
							src={img.thumb}
							alt={`Imagem solicitada ${index + 1}`}
							onClick={() => {
								setShowGallery(true);
								setIdx(index);
							}}
						/>
					))}
				</>
			}

			<Modal isOpen={showGallery} onClose={() => setShowGallery(false)}>
				<div className="min-h-screen w-full p-8 flex">
					<Carousel
						selectedItem={idxSelected}
						dynamicHeight={false}
						swipeable
						emulateTouch
						showIndicators={false}
						useKeyboardArrows
						showThumbs={false}
					>
						{list.map((item, index) => (
							<div key={index} className="flex items-center justify-evenly gap-x-4 h-full">
								{!item.initial ? null : (
									<div className="flex-1">
										<p className="text-white mb-4 text-lg">
											{leftImageTitle}
										</p>
										<img
											src={item.initial}
											alt=""
											style={{
												maxHeight: '75vh',
												maxWidth: !item.compare ? null : '45vw',
												width: '100%',
												objectFit: 'contain',
											}}
										/>
									</div>
								)}
								{!item.compare ? null : (
									<div className="flex-1">
										<p className="text-white mb-4 text-lg">
											{rightImageTitle}
										</p>
										<img
											src={item.compare}
											alt=""
											style={{
												maxHeight: '75vh',
												maxWidth: !item.initial ? null : '45vw',
												width: '100%',
												objectFit: 'contain',
											}}
										/>
									</div>
								)}
							</div>
						))}
					</Carousel>
				</div>
			</Modal>
		</>
	);
}

export default ListPhotosCompare;
