import { Tab } from '@headlessui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { IoIosSearch, IoMdClose } from 'react-icons/io';

import { useAuth } from '../../contexts/auth';
import { usePlannings } from '../../contexts/plannings';
import classNames from '../../utils/classNames';
import Header from '../Header';
import Loader from '../Loader';
import Menu from '../Menu';
import Modal from '../Modal';
import PlanningPagination from '../planning/PlanningPagination';
import PlanningsActionButtons from '../planning/PlanningsActionButtons';
import PlanningsCounter from '../planning/PlanningsCounter';
import PlanningCard from '../PlanningCard';
import SearchBar from '../SearchBar';

function PlanningsList() {
	const [openModal, setOpenModal] = useState(false);
	const [searchFilter, setSearchFilter] = useState({
		consultantId: undefined,
		dentistId: undefined,
		clinicId: undefined,
		search: undefined,
		dateInit: undefined,
		dateEnd: undefined,
		status: 'pending',
		dateType: 'Solicitacao',
	});

	const { user } = useAuth();

	const {
		loading,
		plannings,
		pageTitle,
		buttonType,
		listConsultor,
		listClinics,
		selectedToDesignate,
		selectToDesignate,
		submitDesignatePlanning,
		setButtonType,
		setPageTitle,
		searchPlannings,
	} = usePlannings();

	useEffect(() => {
		setSearchFilter(old => ({
			...old,
			consultantId: user?.role === 3 ? user.id : undefined,
			dentistId: user?.role === 2 ? user.id : undefined,
		}));

		searchPlannings({
			status: searchFilter.status,
			consultantId: user?.role === 3 ? user.id : user?.role === 1 ? searchFilter.consultantId : undefined,
			dentistId: user?.role === 2 ? user.id : undefined,
			clinicId: user?.role === 1 ? searchFilter.clinicId : undefined,
		});
	}, []);

	const handleFilter = useCallback((filter) => {
		if (filter) {
			setSearchFilter(old => ({ ...old, ...filter }));
		}
	}, [searchFilter]);

	return (
		<>
			<div className="flex">
				<Menu className="hidden xl:flex" />
				<div className="w-full flex flex-col">
					<Header textHeader={`Planejamentos | ${pageTitle}`} />
					<main className="w-full flex flex-col py-4 px-14">
						<div className="flex flex-col-reverse sm:flex-row justify-between ">
							<PlanningsCounter />
							<PlanningsActionButtons searchFilter={searchFilter} />
						</div >
						<div className="flex flex-col gap-2 lg:flex-row sm:justify-between">
							<div className="flex flex-col gap-2">
								<div className="flex items-center gap-2">
									<div className="p-2 bg-red-700 rounded-full" />
									<p className="text-2xl">Planejamento expirado</p>
								</div>
								<div className="flex items-center gap-2">
									<div className="p-2 bg-primary rounded-full" />
									<p className="text-2xl">Planejamento dentro do prazo</p>
								</div>
							</div>
							<div className="flex items-end gap-3">
								{user?.role === 1 && (
									<div className="flex flex-col gap-5">
										<select
											className="px-2 h-7 w-auto border-admin-bc-black border rounded-md"
											onChange={evt => {
												handleFilter({
													consultantId: evt.currentTarget?.value,
												});
											}}
										>
											<option value="">Selecione o Consultor</option>
											{listConsultor?.map(c => (
												<option key={c.id} value={c.id}>{c.name}</option>
											))}
										</select>
									</div>
								)}
								{user?.role === 1 && (
									<div className="flex flex-col gap-5">

										<select
											className="px-2 h-7 w-auto border-admin-bc-black border rounded-md"
											onChange={evt => {
												handleFilter({
													clinicId: evt.currentTarget?.value,
												});
											}}
										>
											<option value="">Selecione a Clínica</option>
											{listClinics?.map(c => (
												<option key={c.id} value={c.id}>{c.name}</option>
											))}
										</select>
									</div>
								)}
								<div className="flex flex-col gap-3">
									{pageTitle === 'Respondidos' && (
										<div className="flex gap-x-4">
											<span className="text-xl">Data de </span>
											<label htmlFor="dateType-resposta" className="flex gap-x-2">
												<input
													type="radio"
													id="dateType-resposta"
													value="Resposta"
													name="data_filtro"
													onChange={evt => {
														handleFilter({
															dateType: evt.currentTarget?.checked ? 'Resposta' : 'Solicitacao',
														});
													}}
												/>
												<span className="text-xl">Resposta</span>
											</label>
											<label htmlFor="dateType-solicitacao" className="flex gap-x-2">
												<input
													type="radio"
													id="dateType-solicitacao"
													value="Solicitacao"
													name="data_filtro"
													defaultChecked
													onChange={evt => {
														handleFilter({
															dateType: evt.currentTarget?.checked ? 'Solicitacao' : 'Resposta',
														});
													}}
												/>
												<span className="text-xl">Solicitação</span>
											</label>
										</div>
									)}
									<div className="flex flex-col sm:flex-row sm:items-end gap-3 self-center lg:self-end">

										<div className="flex gap-x-2">
											<p className="text-admin-bc-black text-base lg:text-xl">De:</p>
											<input
												type="date"
												placeholder="10/09/2021"
												className="px-2 h-7 w-auto border-admin-bc-black border rounded-md"
												onChange={evt => {
													handleFilter({
														dateInit: evt.currentTarget?.value,
													});
												}}
											/>
										</div>
										<div className="flex gap-x-2">
											<p className="text-admin-bc-black text-base lg:text-xl">Até:</p>
											<input
												type="date"
												placeholder="10/09/2021"
												className="px-2 h-7 w-auto border-admin-bc-black border rounded-md"
												onChange={evt => {
													handleFilter({
														dateEnd: evt.currentTarget?.value,
													});
												}}
											/>
										</div>
										<button
											type="button"
											onClick={() => searchPlannings(searchFilter)}
											className="bg-primary py-1 px-2 rounded"
										>
											<IoIosSearch size={20} color="#ffffff" />
										</button>
									</div>
								</div>
							</div>
						</div>

						<Tab.Group defaultIndex={0} >
							<SearchBar
								getSearch={search => setSearchFilter(old => ({ ...old, search }))}
								optionsList={[
									{
										id: '1',
										option: 'Pendentes',
										onClick: () => {
											setPageTitle('Pendentes');
											setButtonType('plan');
											if (plannings.pending.total <= 0) {
												// searchPlannings({ ...searchFilter, status: 'pending' });
												// setSearchFilter(old => ({ ...old, status: 'pending' }));
												handleFilter({ status: 'pending' });
												searchPlannings({ ...searchFilter, status: 'pending' });
											}
										}
									},
									{
										id: '2',
										option: 'Respondidos',
										onClick: () => {
											setPageTitle('Respondidos');
											setButtonType('report');
											if (plannings.completed.total <= 0) {
												// searchPlannings({ ...searchFilter, status: 'completed' });
												// setSearchFilter(old => ({ ...old, status: 'completed' }));
												handleFilter({ status: 'completed' });
												searchPlannings({ ...searchFilter, status: 'completed' });
											}
										}
									},
									{
										id: '3',
										option: 'Alterações Solicitadas ',
										onClick: () => {
											setPageTitle('Alterações Solicitadas');
											setButtonType('report');
											if (plannings.more_info.total <= 0) {
												// searchPlannings({ ...searchFilter, status: 'more_info' });
												// setSearchFilter(old => ({ ...old, status: 'more_info' }));
												handleFilter({ status: 'more_info' });
												searchPlannings({ ...searchFilter, status: 'more_info' });
											}
										}
									},
									{
										id: '4',
										option: 'Inativos',
										onClick: () => {
											setPageTitle('Inativos');
											setButtonType(null);
											if (plannings.inactive.total <= 0) {
												// searchPlannings({ ...searchFilter, status: 'inactive' });
												// setSearchFilter(old => ({ ...old, status: 'inactive' }));
												handleFilter({ status: 'inactive' });
												searchPlannings({ ...searchFilter, status: 'inactive' });
											}
										}
									},
								]}
							/>

							<Tab.Panels>
								<Tab.Panel className="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-4">
									{loading ? (
										<div className="col-span-full flex items-center justify-center flex-1">
											<Loader show={true} fullScreen={false} />
										</div>
									) : (
										<>
											{plannings.pending.total <= 0 ? (
												<div className="col-span-1 lg:col-span-2 text-center mt-4">
													<strong className="text-2xl">Nenhum planejamento pendente</strong>
												</div>
											) : plannings.pending.plannings.map((item, index) => (
												<PlanningCard
													key={item.id}
													index={index}
													planning={item}
													isSelectabled={[1, 3].includes(user?.role) && buttonType === 'inputs'}
													selected={selectedToDesignate.includes(item.id)}
													handleSelected={() => selectToDesignate(item.id)}
												/>
											))}
											<div className="col-span-full flex justify-center mt-8">
												<PlanningPagination
													status="pending"
													searchFilter={searchFilter}
													page={plannings.pending.page}
													pages={plannings.pending.pages}
													perPage={plannings.pending.perPage}
													total={plannings.pending.total}
												/>
											</div>
										</>
									)}
								</Tab.Panel>
								<Tab.Panel className="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-4">
									{loading ? (
										<div className="col-span-full flex items-center justify-center flex-1">
											<Loader show={true} fullScreen={false} />
										</div>
									) : (
										<>
											{plannings.completed.total <= 0 ? (
												<div className="col-span-1 lg:col-span-2 text-center mt-4">
													<strong className="text-2xl">Nenhum planejamento respondido</strong>
												</div>
											) : plannings.completed.plannings.map((item, index) => (
												<PlanningCard
													key={item.id}
													index={index}
													planning={item}
													selected={selectedToDesignate.includes(item.id)}
													handleSelected={() => selectToDesignate(item.id)}
												/>
											))}
											<div className="col-span-full flex justify-center mt-8">
												<PlanningPagination
													status="completed"
													searchFilter={searchFilter}
													page={plannings.completed.page}
													pages={plannings.completed.pages}
													perPage={plannings.completed.perPage}
													total={plannings.completed.total}
												/>
											</div>
										</>
									)}
								</Tab.Panel>
								<Tab.Panel className="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-4">
									{loading ? (
										<div className="col-span-full flex items-center justify-center flex-1">
											<Loader show={true} fullScreen={false} />
										</div>
									) : (
										<>
											{plannings.more_info.total <= 0 ? (
												<div className="col-span-1 lg:col-span-2 text-center mt-4">
													<strong className="text-2xl">Nenhum planejamento solicitando mais alterações</strong>
												</div>
											) : plannings.more_info.plannings.map((item, index) => (
												<PlanningCard
													key={item.id}
													index={index}
													planning={item}
													selected={selectedToDesignate.includes(item.id)}
													handleSelected={() => selectToDesignate(item.id)}
												/>
											))}
											<div className="col-span-full flex justify-center mt-8">
												<PlanningPagination
													status="more_info"
													searchFilter={searchFilter}
													page={plannings.more_info.page}
													pages={plannings.more_info.pages}
													perPage={plannings.more_info.perPage}
													total={plannings.more_info.total}
												/>
											</div>
										</>
									)}
								</Tab.Panel>
								<Tab.Panel className="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-4">
									{loading ? (
										<div className="col-span-full flex items-center justify-center flex-1">
											<Loader show={true} fullScreen={false} />
										</div>
									) : (
										<>
											{plannings.inactive.total <= 0 ? (
												<div className="col-span-1 lg:col-span-2 text-center mt-4">
													<strong className="text-2xl">Nenhum planejamento inativo</strong>
												</div>
											) : plannings.inactive.plannings.map((item, index) => (
												<PlanningCard
													key={item.id}
													index={index}
													planning={item}
													isSelectabled={false}
													selected={false}
													handleSelected={console.log}
												/>
											))}
											<div className="col-span-full flex justify-center mt-8">
												<PlanningPagination
													status="inactive"
													searchFilter={searchFilter}
													page={plannings.inactive.page}
													pages={plannings.inactive.pages}
													perPage={plannings.inactive.perPage}
													total={plannings.inactive.total}
												/>
											</div>
										</>
									)}
								</Tab.Panel>
							</Tab.Panels>
						</Tab.Group>

						{pageTitle === 'Pendentes' && buttonType === 'inputs' && (
							<button
								type="button"
								className={classNames(
									'mt-6 mx-auto px-16 py-4 rounded-md',
									'bg-primary text-admin-bc-white font-bold text-xl lg:text-2xl',
									'hover:bg-primary transition-colors',
								)}
								onClick={async () => {
									await submitDesignatePlanning();
									await searchPlannings(searchFilter);
								}}
							>
								Enviar
							</button>
						)}
					</main >
				</div >
			</div >

			<Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
				<div className="h-full  bg-white rounded-2xl overflow-hidden flex flex-col">
					<div className="w-auto h-auto bg-primary-dark px-10 py-4 gap-x-28  flex flex-row items-center justify-between">
						<strong className="text-3xl w-full text-white">
							Planejamento Designado com Sucesso!
						</strong>
						<button onClick={() => setOpenModal(false)}>
							<IoMdClose size={60} className="flex text-white" />
						</button>
					</div>
					<div className="flex flex-col py-14 gap-y-32 px-10">
						<p className="text-4xl text-black font-bold">
							Tudo Certo!
						</p>
						<p className="text-4xl text-black w-full">
							Seu planejamento foi designado e o dentista responsável já foi notificado.
						</p>
						<div className="flex flex-col md:flex-row gap-6 justify-evenly text-2xl text-white">
							<button className="px-16 py-4 bg-primary-dark rounded-xl " onClick={() => setOpenModal(false)}>
								OK
							</button>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
}

export default PlanningsList;
