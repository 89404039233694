
import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';

import classNames from '../utils/classNames';

function Modal({ isOpen, children, onClose, overlayClassName, childClassName }) {
	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog
				as="div"
				className="fixed inset-0 z-10 overflow-y-auto"
				onClose={onClose}
			>
				<div className="relative min-h-screen px-4 flex items-center justify-center">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className={classNames('fixed inset-0 bg-black bg-opacity-80', overlayClassName)} />
					</Transition.Child>
					{/* This element is to trick the browser into centering the modal contents. */}
					{/* <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span> */}
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<div className={classNames('z-10 max-h-full overflow-y-auto', childClassName)}>
							{children}
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
}
export default Modal;



