import React from 'react';
import { FaArrowLeft, FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <main className="w-screen h-screen flex flex-col items-center justify-center bg-gray-200 text-gray-700">
      <h1 className="text-8xl font-bold">404</h1>
      <p className="text-2xl mt-8">Página não encontrada</p>
      <Link to="/" className="text-lg sm:text-2xl mt-1 text-blue-600 flex items-center gap-2">
        <FaArrowLeft />Voltar para a página inicial <FaHome />
      </Link>
    </main>
  );
}

export default NotFound;
