import { Tab } from '@headlessui/react';
import imageCompression from 'browser-image-compression';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaUser } from 'react-icons/fa';
import { IoIosPeople } from 'react-icons/io';
import { useToasts } from 'react-toast-notifications';

import CounterCards from '../../components/CounterCards';
import Header from '../../components/Header';
import HeaderlessSlideOver from '../../components/HeadlessSlideOver';
import ListClinicPatients from '../../components/ListClinicPatients';
import Loader from '../../components/Loader';
import Menu from '../../components/Menu';
import SearchBar from '../../components/SearchBar';
import { useAuth } from '../../contexts/auth';
import api from '../../services/api';
import classNames from '../../utils/classNames';
import toBase64 from '../../utils/toBase64';

function Patients() {
	const [patients, setPatients] = useState([]);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [previewPatientImage, setPreviewPatientImage] = useState();

	const [imageNew, setImage] = useState();
	const { signOut } = useAuth();
	const { register, handleSubmit, formState: { errors } } = useForm();
	const { addToast } = useToasts();

	useEffect(() => {
		async function loadData() {
			setLoading(true);

			try {
				const { data } = await api.get('/dentist/get-patients');

				setPatients(data);
			} catch (err) {
				if (err.response?.status === 401) {
					signOut();
					return;
				}

				addToast(err.response?.data?.message ?? 'Erro ao buscar as pacientes.', {
					appearance: 'error',
					autoDismiss: true
				});
			} finally {
				setLoading(false);
			}
		}

		loadData();
	}, []);

	const handleSearch = useCallback(async value => {
		setLoading(true);

		try {
			const { data } = await api.get('/dentist/get-patients', {
				params: {
					search: value,
					with_address: true
				}
			});

			setPatients(data);
		} catch (err) {
			if (err.response?.status === 401) {
				signOut();
				return;
			}

			addToast(err.response?.data?.message ?? 'Nenhum resultado encontrado.', {
				appearance: 'error',
				autoDismiss: true
			});
		} finally {
			setLoading(false);
		}
	}, []);

	const onSubmit = useCallback(async (formData) => {
		setLoading(true);

		try {
			const image = imageNew;
			const compressed = await imageCompression(image, {
				maxSizeMB: 1,
				maxWidthOrHeight: 480,
				useWebWorker: true
			});

			const img = await toBase64(compressed);

			await api.post('/dentist/register-patients', {
				...formData,
				profile_image: img,

			});

			addToast('paciente salva', {
				appearance: 'success',
				autoDismiss: true
			});

			handleSearch(undefined);
			setLoading(false);
			setOpen(false);
		} catch (err) {
			addToast(err.response?.data?.message ?? 'Erro ao cadastrar a paciente.', {
				appearance: 'error',
				autoDismiss: true
			});
		} finally {
			setLoading(false);
		}
	}, [imageNew]);

	return (
		<>
			<div className="flex">
				<Menu className="hidden xl:flex" />
				<div className="w-full flex flex-col">
					<Header textHeader="Pacientes" />
					<main className="w-full flex flex-col py-4 px-14">
						<div className="flex flex-col-reverse  lg:flex-row justify-between" >
							<CounterCards className="" cardList={[
								{
									id: '1',
									title: 'Pacientes Ativos',
									counter: patients?.active?.length ?? 0,
									priColor: 'bg-primary',
									secColor: 'bg-primary',
									Icon: IoIosPeople,
								},
								{
									id: '2',
									title: 'Pacientes Inativos',
									counter: patients?.inactive?.length ?? 0,
									priColor: 'bg-red-500',
									secColor: 'bg-red-600',
									Icon: IoIosPeople,
								},
							]}
							/>
							<button
								onClick={() => setOpen(true)}
								className="flex mt-6 justify-center self-center lg:self-start lg:right-0 lg:w-80 w-60 h-20 lg:h-14 px-3 bg-primary items-center text-center text-xl lg:text-2xl rounded-lg text-admin-bc-white font-bold"
							>
								Adicionar Paciente
							</button>
						</div>
						<Tab.Group>
							<SearchBar
								getSearch={value => handleSearch(value)}
								optionsList={[
									{
										id: '1',
										option: 'Pacientes Ativos',
									},
									{
										id: '2',
										option: 'Pacientes Inativos',
									},
								]}
							/>
							<Tab.Panel className={classNames(
								loading ? 'flex items-center justify-center' : ''
							)}>
								{loading ? (
									<p className="text-2xl font-bold animate-pulse">Carregando...</p>
								) : (
									<ListClinicPatients
										list={patients?.active?.map(item => ({
											...item,
											clinic: item.clinic.name,
											dentist: {
												name: item?.dentist?.user?.name,
											},
											realizados: item?.Plannings?.filter(plan => plan.status === 'completed').length,
											pendentes: item?.Plannings?.filter(plan => plan.status === 'pending').length,
										})) ?? []}
									/>
								)}
							</Tab.Panel>
							<Tab.Panel className={classNames(
								loading ? 'flex items-center justify-center' : ''
							)}>
								{loading ? (
									<p className="text-2xl font-bold animate-pulse">Carregando...</p>
								) : (
									<ListClinicPatients
										list={patients?.inactive?.map(item => ({
											...item,
											clinic: item.clinic.name,
											dentist: {
												name: item?.dentist?.user?.name,
											},
											realizados: item?.Plannings?.filter(plan => plan.status === 'completed').length,
											pendentes: item?.Plannings?.filter(plan => plan.status === 'pending').length,
										})) ?? []}
									/>
								)}
							</Tab.Panel>
						</Tab.Group>
					</main>
				</div>
			</div>
			<HeaderlessSlideOver
				open={open}
				setOpen={setOpen}
				title="Menu"
				className="absolute"
				side="ri"
			>
				<div className="overflow-y-auto max-h-screen h-screen w-auto flex  flex-col  bg-white">
					<div className="flex w-full py-10 flex-col h-24 text-center justify-center bg-primary text-3xl text-white">
						Adicionar Paciente
					</div>
					<div className=" w-auto flex p-10 flex-col gap-10 bg-white">
						<form onSubmit={handleSubmit(onSubmit)} className="gap-10 flex flex-col">
							<strong>Clique abaixo para selecionar a imagem do paciente</strong>
							<label
								htmlFor="image"
								className={classNames(
									'w-28 h-28 place-self-center flex p-2 rounded-full border-2 border-primary bg-transparent',
									'bg-center bg-cover bg-no-repeat cursor-pointer'
								)}
								style={{ backgroundImage: `url(${previewPatientImage})` }}
							>
								{!previewPatientImage && (
									<FaUser size={65} className="text-primary py-2" />
								)}
								<input
									{...register('image', { required: 'Campo obrigatório' })}
									id="image"
									type="file"
									accept="image/png,image/jpg,image/jpeg,image/webp"
									className="sr-only"
									onChange={evt => {
										const file = evt.currentTarget.files.item(0);
										if (file) {
											const imageUrl = URL.createObjectURL(file);
											setPreviewPatientImage(imageUrl);
											setImage(file);
											return;
										}

										setImage(undefined);
										setPreviewPatientImage(undefined);
									}}
								/>
							</label>
							{errors.image && (
								<small className="text-red-400 self-center">{errors.image.message}</small>
							)}
							<div className="flex gap-4 flex-col">
								<strong>Nome:</strong>
								<input {...register('name', { required: 'Campo obrigatório' })} placeholder="Digite aqui o nome" className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11" />
								{errors.name && (
									<small className="text-red-400">{errors.name.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>E-mail do paciente:</strong>
								<input {...register('email', { required: 'Campo obrigatório' })} type="email" placeholder="Digite aqui o e-mail" className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11" />
								{errors.email && (
									<small className="text-red-400">{errors.email.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>Senha do paciente (Será alterada pelo paciente depois):</strong>
								<input {...register('password', { required: 'Campo obrigatório' })} type="password" placeholder="Digite aqui a senha" className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11" />
								{errors.password && (
									<small className="text-red-400">{errors.password.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>Data de nascimento:</strong>
								<input {...register('birth_date', { required: 'Campo obrigatório' })} type="date" className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11" />
								{errors.birth_date && (
									<small className="text-red-400">{errors.birth_date.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>Gênero:</strong>
								<select
									{...register('gender', { required: 'Campo obrigatório' })}
									className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11"
								>
									<option disabled>Selecione o gênero</option>
									<option value="M">Masculino</option>
									<option value="F">Feminino</option>
									<option value="O">Outro</option>
								</select>
								{errors.gender && (
									<small className="text-red-400">{errors.gender.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>Linha média superior:</strong>
								<input {...register('upper_middle_line', { required: 'Campo obrigatório' })} placeholder="Digite aqui linha média superior" className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11" />
								{errors.upper_middle_line && (
									<small className="text-red-400">{errors.upper_middle_line.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>Queixa principal:</strong>
								<textarea
									{...register('complaint', { required: 'Campo obrigatório' })}
									placeholder="Digite aqui a queixa principal"
									rows={4}
									className="md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11"
								/>
								{errors.complaint && (
									<small className="text-red-400">{errors.complaint.message}</small>
								)}
							</div>
							<button
								disabled={loading}
								type="submit"
								className={classNames(
									'w-full h-14 mt-6 rounded-lg text-3xl text-white',
									loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-primary'
								)}
							>
								Adicionar Paciente
							</button>
						</form>
					</div>
				</div>
			</HeaderlessSlideOver>
			<Loader show={loading} />
		</>
	);
}

export default Patients;
