import React from 'react';

import classNames from '../utils/classNames';

function ListConsultants({ list }) {

	return (
		<>
			{list.length < 1 ?
				<div className="mt-6 shadow-md rounded-4 w-full h-auto flex flex-col xl:flex-row px-4 py-2 gap-4 rounded-lg drop-shadow-lg">
					<p className='flex self-center place-self-center text-xl font-bold py-6'>Nenhum consultor encontrado.</p>
				</div>
				:
				<>
					{list.map(item => (
						<a key={item.id} href={`consultor/${item.id}`} className=" shadow-md rounded-4 w-full h-auto flex flex-col items-center xl:flex-row px-4 py-2 gap-4 rounded-lg drop-shadow-lg">
							<div
								className={
									classNames(
										'bg-primary rounded-full h-32 w-32 sm:h-20 sm:w-20 md:mr-3 flex self-center justify-self-center',
										'bg-center bg-no-repeat bg-cover',
									)
								}
								style={{ backgroundImage: `url(${item.user.profile_image})` }}
							/>
							<p className="font-bold text-2xl flex-1">{item.name}</p>
							<div className="flex flex-col-reverse lg:flex-col justify-evenly gap-1">
								<div className="flex flex-row gap-2 text-lg">
									<p>{item.phone}</p>
								</div>
								<div className="flex flex-row gap-2 text-lg">
									<p>{item.city}</p>
								</div>
							</div>
						</a>
					))}
				</>
			}
		</>
	);
}

export default ListConsultants;
