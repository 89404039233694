import React from 'react';
import { ToastProvider } from 'react-toast-notifications';

import { AuthProvider } from './auth';

function AppProvider({ children }) {
	return (
		<ToastProvider>
			<AuthProvider>
				{children}
			</AuthProvider>
		</ToastProvider>
	);
}

export default AppProvider;
