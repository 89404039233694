import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { IoMdClose } from 'react-icons/io';
import { useToasts } from 'react-toast-notifications';

import { useAuth } from '../../contexts/auth';
import api from '../../services/api';
import classNames from '../../utils/classNames';
import Modal from '../Modal';

function ModalAvaliacao({ dispatch, id_module, questions, isOpen, onClose }) {
	const [sending, setSending] = useState(false);
	const [nota, setNota] = useState();

	const { register, handleSubmit, watch, reset } = useForm();
	const { signOut } = useAuth();
	const { addToast } = useToasts();


	const onSubmit = async (data) => {
		if (data.questions.filter(q => !q.answer).length > 0) {
			addToast('Responda todas as perguntas!', { appearance: 'error', autoDismiss: true });
			return;
		}

		setSending(true);

		try {
			const { data: responseData } = await api.post(`/manager/avaliacao/answer/${id_module}`, {
				answers: data.questions,
			});

			setNota(responseData.nota);
			dispatch({ type: 'UPDATE_AVALIACAO', payload: responseData.nota });
		} catch (err) {
			if (err.response?.status === 401) {
				addToast(
					err.response?.data?.message || 'Você não tem permissão para responder esta prova!',
					{
						appearance: 'error',
						autoDismiss: true
					}
				);
				signOut();
				return;
			}

			addToast(err.response?.data?.message || 'Erro ao responder a prova!', { appearance: 'error', autoDismiss: true });
		} finally {
			setSending(false);
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<div className="h-full w-full bg-white rounded-2xl overflow-hidden flex flex-col my-6">
				<div className="w-auto h-auto bg-primary p-10 gap-x-28 flex flex-row items-center justify-between">
					<strong className="text-3xl w-full text-white">
						Avaliação
					</strong>
					<button onClick={onClose}>
						<IoMdClose size={35} className="flex text-white" />
					</button>
				</div>
				{nota !== undefined ? (
					<div className="flex flex-col py-6 gap-y-8 items-center px-10">
						<h4 className="text-4xl">
							Você foi{' '}
							<span
								style={{
									color: nota >= 70 ? '#59cf33' : '#fd4f4f',
								}}
							>
								{nota >= 70 ? 'aprovado' : 'reprovado'}
							</span>{' '}
							com a seguinte nota
						</h4>
						<div className="flex gap-x-10">
							<strong className="text-8xl">{nota}</strong>
							{/* {nota >= 70 ? <IoMdCheckmarkCircleOutline size={100} color="#59cf33" /> : <IoMdCloseCircleOutline size={100} color="#fd4f4f" />} */}
						</div>
						<button
							type="button"
							onClick={() => {
								if (nota <= 70) {
									reset();
									setNota(undefined);
								} else {
									onClose();
								}
							}}
							className={classNames(
								'mt-4 py-4 px-10 self-center',
								'text-center text-xl lg:text-2xl rounded-lg text-admin-bc-white font-bold',
								'transition-colors duration-300 hover:bg-blue-400',
								'bg-primary'
							)}
						>
							{nota >= 70 ? 'Ok' : 'Refazer a prova'}
						</button>
					</div>
				) : (
					<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col py-6 gap-y-8 justify-center px-10">
						{questions?.map((q, i) => (
							<fieldset key={q.value}>
								<strong className="text-xl">{q.value}</strong>
								<div className="flex flex-col gap-y-4 mt-2">
									{q.answers.map((a, j) => (
										<label key={a.value} htmlFor={`${i}-${j}`} className="ml-4 flex items-center gap-x-2">
											<input type="hidden" {...register(`questions.${i}.question`, { value: q.id })} />
											<input
												{...register(`questions.${i}.answer`)}
												type="radio"
												id={`${i}-${j}`}
												value={a.id}
											/>
											<span className="">{a.value}</span>
										</label>
									))}
								</div>
							</fieldset>
						))}

						<button
							type="submit"
							disabled={sending}
							className={classNames(
								'mt-10 py-4 px-10 self-center',
								'text-center text-xl lg:text-2xl rounded-lg text-admin-bc-white font-bold',
								'transition-colors duration-300 hover:bg-blue-400',
								sending ? 'bg-gray-400 cursor-not-allowed' : 'bg-primary'
							)}
						>
							{sending ? 'Enviando...' : 'Enviar avaliação'}
						</button>
					</form>
				)}
			</div>
		</Modal>
	);
}

export default ModalAvaliacao;
