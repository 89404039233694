import React from 'react';

import PlanningsList from '../../components/templates/PlanningList';
import { PlanningsProvider } from '../../contexts/plannings';

function Plannings() {
	return (
		<PlanningsProvider>
			<PlanningsList />
		</PlanningsProvider>
	);
}

export default Plannings;
