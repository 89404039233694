import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IoMdClose } from 'react-icons/io';
import { useToasts } from 'react-toast-notifications';

import { useAuth } from '../../contexts/auth';
import api from '../../services/api';
import classNames from '../../utils/classNames';
import Modal from '../Modal';
import PlanningInputFile from '../PlanningInputFile';

function SendFeedback({ open, onClose, onUpdated, evaluationId, isFinished }) {
	const [sending, setSending] = useState(false);
	const [file, setFile] = useState(null);
	const [uploadPercent, setUploadPercent] = useState(0);

	const { token, signOut } = useAuth();
	const { addToast } = useToasts();
	const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm();

	const onSubmit = useCallback(async ({ feedback, video, returnToDentist }) => {
		setSending(true);

		try {
			let feedbackVideo = undefined;
			const file = video ? video.item(0) : null;
			if (file) {
				console.log({ file });
				const { data: permissionData } = await api.post(
					'/evaluations/permission-upload',
					{
						contentType: file.type,
						evaluationId,
					},
				);

				delete api.defaults.headers.common['Authorization'];

				await api.put(permissionData.url, file, {
					headers: {
						'Content-Type': file.type,
						'Content-Length': undefined,
					},
					onUploadProgress: progressEvent => setUploadPercent(() => Math.round((progressEvent.loaded * 100) / progressEvent.total)),
					timeout: 900000
				});

				// BUG: para mandar arquivo para o S3, tem que remover completamente o header "Authorization", se não volta o erro 400.
				api.defaults.headers.common['Authorization'] = token;
				feedbackVideo = permissionData.urlView;
			}

			await api.put(`/evaluations/feedback/${evaluationId}`, {
				feedback,
				feedbackVideo,
				returnToDentist,
			});

			onClose();
			await onUpdated();
		} catch (err) {
			if (err.response?.status === 401) {
				addToast('Sessão expirada', {
					appearance: 'error',
					autoDismiss: true
				});
				signOut();

				return;
			}

			api.defaults.headers.common['Authorization'] = token;
			addToast(err.response?.data.message || 'Erro ao salvar', {
				appearance: 'error',
				autoDismiss: true
			});
		} finally {
			setSending(false);
		}
	}, []);

	return (
		<Modal isOpen={open} onClose={onClose} childClassName="sm:w-full sm:max-w-5xl">
			<div className="h-full w-full bg-white rounded-2xl overflow-hidden flex flex-col">
				<div className="w-auto h-auto bg-primary px-10 py-4 gap-x-28  flex flex-row items-center justify-between">
					<strong className="text-3xl w-full text-white">
						Enviar feedback:
					</strong>
					<button
						onClick={() => {
							onClose();
							reset();
						}}
					>
						<IoMdClose size={35} className="flex text-white" />
					</button>
				</div>
				<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col mt-4 text-xl p-4">
					<strong className="">Video</strong>
					<PlanningInputFile
						register={register}
						name="video"
						label="Selecionar arquivo"
						accept="video/*"
						remove={() => setValue('video', '')}
						defaultFile={typeof file === 'string' ? file : undefined}
						change={file => {
							if (file) {
								setFile(file);
								return;
							}
							setFile(null);
						}}
					/>

					<strong className="mt-6">Feedback</strong>
					<textarea
						{...register('feedback', {
							required: 'Campo obrigatório'
						})}
						rows={8}
						placeholder="Escreva seu feedback aqui"
						className={classNames(
							'border border-primary p-2 rounded-md'
						)}
					/>
					{!errors.feedback ? null : (
						<small className="text-red-500">{errors.feedback.message}</small>
					)}

					{isFinished ? null : (
						<>
							<label htmlFor="returnToDentist" className="mt-6 flex items-center gap-x-2">
								<input
									{...register('returnToDentist')}
									type="checkbox"
									id="returnToDentist"
								/>
								<span>Retornar ao dentista</span>
							</label>
							{!errors.returnToDentist ? null : (
								<small className="text-red-500">{errors.returnToDentist.message}</small>
							)}
						</>
					)}

					<button
						type="submit"
						disabled={sending}
						className={classNames(
							'py-2 w-64 self-center mt-6',
							'text-center text-2xl rounded text-admin-bc-white',
							'transition-colors duration-300 hover:bg-primary',
							sending
								? 'bg-gray-400 cursor-not-allowed'
								: 'bg-primary'
						)}
					>
						{sending ? `Enviando... (${uploadPercent}%)` : 'Enviar'}
					</button>
				</form>
			</div>
		</Modal>
	);
}

export default SendFeedback;
