import React, { useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';

import { usePlannings } from '../../contexts/plannings';

/**
 * @param {{
 * 	page: number;
 * 	pages: number;
 * 	perPage: number;
 * 	total: number;
 * }} props
 */
function PlanningPagination({ status, searchFilter, page, pages, perPage, total }) {
	const pageLinks = new Array(pages).fill(1).map((p, i) => p + i);
	const { searchPlannings } = usePlannings();

	const handlePageClick = async (event) => {
		searchPlannings({ ...searchFilter, status, page: event.selected + 1 });
	};

	return pageLinks.length === 1 ? null : (
		<div className=''>
			<ReactPaginate
				breakLabel="..."
				forcePage={page - 1}
				pageCount={pages}
				renderOnZeroPageCount={null}
				onPageChange={handlePageClick}

				nextLabel={<FaChevronRight className="flex-1" />}
				previousLabel={<FaChevronLeft className="flex-1" />}
				pageClassName="rounded overflow-hidden"
				pageLinkClassName="flex items-center justify-center h-10 w-10 shadow-sm bg-gray-200 hover:bg-blue-400 hover:text-white"
				previousClassName="rounded overflow-hidden"
				previousLinkClassName="flex items-center justify-center h-10 w-10 shadow-sm bg-gray-200 hover:bg-blue-400 hover:text-white"
				nextClassName="rounded overflow-hidden"
				nextLinkClassName="flex items-center justify-center h-10 w-10 shadow-sm bg-gray-200 hover:bg-blue-400 hover:text-white"
				breakClassName="rounded overflow-hidden"
				breakLinkClassName="flex items-center justify-center h-10 w-10 shadow-sm bg-gray-200 hover:bg-blue-400 hover:text-white"
				containerClassName="flex items-center gap-x-4"
				activeLinkClassName="bg-blue-400 text-white cursor-default"
			/>
			{/* {pageLinks.map((currentPage, index) => (
				<button
					key={index}
					disabled={page === currentPage}
					className={classNames(
						'h-10 w-10 rounded shadow-sm',
						page === currentPage
							? 'bg-blue-400 text-white'
							: 'bg-gray-200 hover:bg-blue-400 hover:text-white'
					)}
					onClick={() => searchPlannings({ ...searchFilter, status, page: currentPage })}
				>
					{currentPage}
				</button>
			))} */}
		</div>
	);
}

export default PlanningPagination;
