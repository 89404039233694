import React, { useEffect, useState } from 'react';
import { AiOutlineStar } from 'react-icons/ai';
import { BsHandThumbsDown } from 'react-icons/bs';
import Rating from 'react-rating';

import { useAuth } from '../../contexts/auth';
import classNames from '../../utils/classNames';

function Questions({ title, questions = [], defaultAnswers, getAnswers }) {
	const [answers, setAnswers] = useState(
		defaultAnswers ??
			(() => {
				let obj = {};

				questions.forEach((q) => {
					obj[q.id] = null;
				});

				return obj;
			})
	);

	const { user } = useAuth();

	useEffect(() => {
		getAnswers?.(answers);
	}, [answers, getAnswers]);

	return (
		<div className="p-4 flex flex-col gap-y-4">
			<strong className="text-xl">{title}</strong>

			{questions.map((question) => (
				<div key={question.id} className="flex items-center gap-x-4">
					<div className="">
						<button
							title="Nota 0"
							className={classNames(
								'text-gray-500 hover:text-red-500 mr-1',
								answers?.[question.id] === 0 ? 'text-red-500' : null
							)}
							onClick={() =>
								setAnswers((old) => ({ ...old, [question.id]: 0 }))
							}
						>
							<BsHandThumbsDown size={15} />
						</button>
						<Rating
							stop={5}
							readonly={![1, 3].includes(user.role)}
							initialRating={answers?.[question.id]}
							fullSymbol={<AiOutlineStar size={20} color="#ffd700" />}
							emptySymbol={<AiOutlineStar size={20} color="#aaa" />}
							onClick={(rate) =>
								setAnswers((old) => ({ ...old, [question.id]: rate }))
							}
							className="self-center"
						/>
					</div>
					<p className="">{question.question}</p>
				</div>
			))}
		</div>
	);
}

export default Questions;
