import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ImClock } from 'react-icons/im';
import { IoMdClose } from 'react-icons/io';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import dentesBaixo from '../../assets/images/dentesBaixo.png';
import dentesCima from '../../assets/images/dentesCima.png';
import dentesDireita from '../../assets/images/dentesDireita.png';
import dentesEsquerda from '../../assets/images/dentesEsquerda.png';
import dentesFrente from '../../assets/images/dentesFrente.png';
import fotoLateral from '../../assets/images/fotoLateral.png';
import fotoSeria from '../../assets/images/fotoSeria.png';
import fotoSorrindo from '../../assets/images/fotoSorrindo.png';
import raioxLateral from '../../assets/images/raioxLateral.png';
import raioxPanoramico from '../../assets/images/raioxPanoramico.png';
import AnswerFollowUp from '../../components/AnswerFollowUp';
import AnswerFollowUpWithVideo from '../../components/AnswerFollowUpWithVideo';
import Dropdown from '../../components/Dropdown';
import Header from '../../components/Header';
import ListPhotos from '../../components/ListPhotos';
import Loader from '../../components/Loader';
import Menu from '../../components/Menu';
import Modal from '../../components/Modal';
import { useAuth } from '../../contexts/auth';
import api from '../../services/api';
import classNames from '../../utils/classNames';
import { getAgeFromDate } from '../../utils/getAgeFromDate';

function parseStatus(status) {
	switch (status) {
		case 'completed':
			return 'Respondido pela dentista';
		case 'finished':
			return 'Finalizado';
		default:
			return 'Respondido pelo consultoria';
	}
}

function getStatusColor(status) {
	switch (status) {
		case 'completed':
			return 'bg-status-completed';
		case 'finished':
			return 'bg-green-500';
		default:
			return 'bg-admin-orange-text';
	}
}

function FollowUpDetail() {
	const [loading, setLoading] = useState(false);
	const [isSendingFinish, setIsSendingFinish] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [openModalVideo, setOpenModalVideo] = useState(false);
	const [patientData, setPatientData] = useState();
	const [patientFollowUpHistory, setPatientFollowUpHistory] = useState([]);

	const { user, signOut } = useAuth();
	const { id_grupo } = useParams();
	const { addToast } = useToasts();
	const { push: historyPush } = useHistory();
	const { register, handleSubmit, reset } = useForm();

	const loadData = useCallback(async () => {
		setLoading(true);

		try {
			const { data } = await api.get(`/followup/${id_grupo}`);

			setPatientData(data.patient);
			setPatientFollowUpHistory(data.followUps);
		} catch (err) {
			console.log(err);
			if (err.response?.status === 401) {
				signOut();
				return;
			}

			addToast(err.response?.data.message ?? 'Erro ao buscar acompanhamento', {
				appearance: 'error',
				autoDismiss: true,
			});

			historyPush('/acompanhamentos');
		} finally {
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		loadData();
	}, []);

	const onSubmitNewFollowUp = useCallback(
		async (data) => {
			setLoading(true);

			try {
				const totalSelected = Object.keys(data).filter(
					(key) => data[key] === true
				).length;
				if (totalSelected < 1) {
					addToast('Selecione pelo menos 1 foto', {
						appearance: 'warning',
						autoDismiss: true,
					});
					return;
				}

				await api.post('/followup/', {
					...data,
					followup_id: patientFollowUpHistory[0]?.group_id,
					patient_id: patientData?.id,
					dentist_id: patientData?.dentist.id,
				});

				addToast('Acompanhamento solicitado!', {
					appearance: 'success',
					autoDismiss: true,
				});
				setOpenModal(false);
				reset();
				await loadData();
			} catch (err) {
				addToast(
					err.response?.data?.message || 'Erro ao solicitar acompanhamento',
					{
						appearance: 'error',
						autoDismiss: true,
					}
				);
			} finally {
				setLoading(false);
			}
		},
		[patientData, patientFollowUpHistory]
	);

	const handleFinishFollowUp = useCallback(async () => {
		setIsSendingFinish(true);

		try {
			const { data } = await api.put(
				`/followup/${patientFollowUpHistory?.[0]?.group_id}/finish`
			);
			if (data.finished) {
				addToast('Acompanhamento finalizado!', {
					appearance: 'success',
					autoDismiss: true,
				});
				loadData();
			}
		} catch (err) {
			console.log(err);
			if (err.response?.status === 401) {
				signOut();
				return;
			}

			addToast(
				err.response?.data.message ?? 'Erro ao finalizar acompanhamento',
				{
					appearance: 'error',
					autoDismiss: true,
				}
			);

			historyPush('/acompanhamentos');
		} finally {
			setIsSendingFinish(false);
		}
	}, [patientFollowUpHistory?.[0]?.group_id, loadData]);

	const getImagesPlaceholder = useCallback(
		(images) => {
			let imgs = [];
			if (images?.hasFaceFrontal) {
				imgs = [
					...imgs,
					{
						id: Math.random(),
						url: fotoSeria,
					},
				];
			}
			if (images?.hasFaceLateral) {
				imgs = [
					...imgs,
					{
						id: Math.random(),
						url: fotoLateral,
					},
				];
			}
			if (images?.hasFaceSorrindo) {
				imgs = [
					...imgs,
					{
						id: Math.random(),
						url: fotoSorrindo,
					},
				];
			}
			if (images?.hasDentesFrontal) {
				imgs = [
					...imgs,
					{
						id: Math.random(),
						url: dentesFrente,
					},
				];
			}
			if (images?.hasDentesEsquerda) {
				imgs = [
					...imgs,
					{
						id: Math.random(),
						url: dentesEsquerda,
					},
				];
			}
			if (images?.hasDentesDireita) {
				imgs = [
					...imgs,
					{
						id: Math.random(),
						url: dentesDireita,
					},
				];
			}
			if (images?.hasDentesCima) {
				imgs = [
					...imgs,
					{
						id: Math.random(),
						url: dentesCima,
					},
				];
			}
			if (images?.hasDentesBaixo) {
				imgs = [
					...imgs,
					{
						id: Math.random(),
						url: dentesBaixo,
					},
				];
			}
			if (images?.hasRaioxLateral) {
				imgs = [
					...imgs,
					{
						id: Math.random(),
						url: raioxLateral,
					},
				];
			}
			if (images?.hasRaioxPanoramico) {
				imgs = [
					...imgs,
					{
						id: Math.random(),
						url: raioxPanoramico,
					},
				];
			}

			return imgs;
		},
		[patientFollowUpHistory]
	);

	const getImages = useCallback(
		(images) => {
			let imgs = [];
			if (images?.faceFrontal) {
				imgs = [
					...imgs,
					{
						id: Math.random(),
						url: images.faceFrontal,
					},
				];
			}
			if (images?.faceLateral) {
				imgs = [
					...imgs,
					{
						id: Math.random(),
						url: images.faceLateral,
					},
				];
			}
			if (images?.faceSorrindo) {
				imgs = [
					...imgs,
					{
						id: Math.random(),
						url: images.faceSorrindo,
					},
				];
			}
			if (images?.dentesFrontal) {
				imgs = [
					...imgs,
					{
						id: Math.random(),
						url: images.dentesFrontal,
					},
				];
			}
			if (images?.dentesEsquerda) {
				imgs = [
					...imgs,
					{
						id: Math.random(),
						url: images.dentesEsquerda,
					},
				];
			}
			if (images?.dentesDireita) {
				imgs = [
					...imgs,
					{
						id: Math.random(),
						url: images.dentesDireita,
					},
				];
			}
			if (images?.dentesCima) {
				imgs = [
					...imgs,
					{
						id: Math.random(),
						url: images.dentesCima,
					},
				];
			}
			if (images?.dentesBaixo) {
				imgs = [
					...imgs,
					{
						id: Math.random(),
						url: images.dentesBaixo,
					},
				];
			}
			if (images?.raioxLateral) {
				imgs = [
					...imgs,
					{
						id: Math.random(),
						url: images.raioxLateral,
					},
				];
			}
			if (images?.raioxPanoramico) {
				imgs = [
					...imgs,
					{
						id: Math.random(),
						url: images.raioxPanoramico,
					},
				];
			}

			return imgs;
		},
		[patientFollowUpHistory]
	);

	return (
		<>
			<div className="flex">
				<Menu className="hidden xl:flex" />
				<div className="w-full flex flex-col">
					<Header textHeader={`Acompanhamentos | ${patientData?.name}`} />
					<main className="w-full flex flex-col py-4 px-14">
						<div className="grid grid-cols-1 shadow-md">
							<div className="mt-6 shadow-md min-w-full rounded-4 w-full h-auto flex flex-col xl:flex-row px-4 py-5 gap-4 rounded-lg drop-shadow-lg">
								<div
									className="w-28 h-28 self-center rounded-full bg-cover bg-center bg-no-repeat"
									style={{
										backgroundImage: `url(${patientData?.profile_image})`,
									}}
								/>
								<div className="flex flex-col gap-2 text-lg w-full flex-1 items-between px-4">
									<p className="font-bold text-xl self-start">
										{patientData?.name}
									</p>
									<div className="flex gap-2">
										<p className="">E-mail:</p>
										<p>{patientData?.email}</p>
									</div>
									<div className="flex gap-2">
										<p className="">Sexo:</p>
										<p>{patientData?.gender}</p>
									</div>
									<div className="flex gap-2">
										<p className="">Idade:</p>
										<p>
											{patientData?.birth_date
												? getAgeFromDate(new Date(patientData?.birth_date))
												: '-'}
										</p>
									</div>
									<div className="flex gap-2">
										<p className="">Queixa principal:</p>
										<p>{patientData?.complaint}</p>
									</div>
								</div>
								<div className="flex flex-col text-lg justify-evenly gap-2 px-4">
									<div className="flex gap-2">
										<p className="">Linha média:</p>
										<p>{patientData?.upper_middle_line}</p>
									</div>
									<div className="flex gap-2">
										<p className="">Dentista Responsável:</p>
										<p>{patientData?.dentist?.name}</p>
									</div>
								</div>
							</div>
							<div className="text-xl px-10 mt-4 pb-12">
								<strong>Fotos iniciais:</strong>
								<div className="flex flex-wrap gap-8 w-full mt-2">
									<ListPhotos list={getImages(patientData?.planning)} />
								</div>
							</div>
						</div>
						<div className="flex gap-6 justify-between md:flex-row my-6 flex-col">
							<div className="flex gap-6 justify-between md:flex-row flex-col">
								{patientFollowUpHistory.length > 0 && (
									<div className="flex items-center text-xl font-bold text-center sm:text-left">
										{patientFollowUpHistory?.[0]?.status === 'pending'
											? `Solicitado em: ${new Date(
													patientFollowUpHistory[
														patientFollowUpHistory.length - 1
													]?.created_at
											  ).toLocaleString()}`
											: `Respondido em: ${new Date(
													patientFollowUpHistory[0]?.created_at
											  ).toLocaleString()}`}
										<ImClock className="ml-2" size={26} />
									</div>
								)}
							</div>
							<div className="flex flex-col md:flex-row gap-4">
								<div
									className={classNames(
										'flex items-center py-2 px-12 text-xl text-white rounded-full',
										getStatusColor(patientFollowUpHistory?.[0]?.status)
									)}
								>
									{parseStatus(patientFollowUpHistory?.[0]?.status)}
								</div>

								{patientFollowUpHistory?.[0]?.status !== 'finished' && (
									<Dropdown
										options={[
											[1, 3].includes(user?.role) && {
												text: 'Mais informações',
												action: () => setOpenModal(true),
											},
											[1, 3].includes(user?.role) && {
												text: 'Responder com video',
												action: () => setOpenModalVideo(true),
											},
											{
												text: 'Finalizar acompanhamento',
												action: handleFinishFollowUp,
												disabled: isSendingFinish,
											},
										]}
									/>
								)}
							</div>
						</div>

						{patientFollowUpHistory.map((followUp) => {
							if (followUp.status === 'completed') {
								return (
									<div
										key={followUp.id}
										className="grid grid-cols-1 shadow-md mb-28"
									>
										<div className="w-full h-min py-4 text-white text-xl bg-primary px-10 flex items-center justify-between">
											<span>INFORMAÇÕES ENVIADAS PELO DENTISTA</span>
											<span>
												{new Date(followUp.created_at).toLocaleString()}
											</span>
										</div>
										<div className="text-xl px-10 mt-12">
											<strong>Observações:</strong>
											<p>{followUp.observation ?? 'Sem observações'}</p>
										</div>
										<div className="text-xl px-10 mt-12 pb-12 ">
											<strong>Fotos:</strong>
											<div className="grid grid-cols-5 gap-8 mt-12">
												<ListPhotos list={getImages(followUp)} />
											</div>
										</div>
									</div>
								);
							}

							return (
								<div
									key={followUp.id}
									className="grid grid-cols-1 shadow-md mb-28"
								>
									<div className="w-full h-min py-4 text-white text-xl bg-admin-orange-text px-10 flex items-center justify-between">
										<span>INFORMAÇÕES ENVIADAS PELA CONSULTORIA</span>
										<span>
											{new Date(followUp.created_at).toLocaleString()}
										</span>
									</div>
									<div className="text-xl px-10 mt-12">
										<strong>Observações:</strong>
										<p>{followUp.observation ?? 'Sem observações'}</p>
									</div>
									{!followUp.video_link ? null : (
										<div className="text-xl px-10 mt-12 pb-12 ">
											<strong>Video:</strong>
											<video
												src={followUp.video_link}
												controls
												className="w-full"
											></video>
										</div>
									)}
									<div className="text-xl px-10 mt-12 pb-12 ">
										<strong>Fotos:</strong>
										{user?.role === 2 && followUp.status === 'pending' ? (
											<AnswerFollowUp
												key={followUp.id}
												followUp={followUp}
												patientId={patientData?.id}
											/>
										) : (
											<div className="grid grid-cols-5 gap-8 mt-12">
												<ListPhotos list={getImagesPlaceholder(followUp)} />
											</div>
										)}
									</div>
								</div>
							);
						})}
					</main>
				</div>
			</div>

			<Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
				<div className="h-full w-full bg-white rounded-2xl overflow-hidden flex flex-col">
					<div className="w-auto h-auto bg-primary px-10 py-4 gap-x-28  flex flex-row items-center justify-between">
						<strong className="text-xl w-full text-white">
							Selecione as fotos desejadas:
						</strong>
						<button onClick={() => setOpenModal(false)}>
							<IoMdClose size={35} className="flex text-white" />
						</button>
					</div>
					<div className="flex flex-col py-6 gap-y-10 justify-center px-10">
						<form
							onSubmit={handleSubmit(onSubmitNewFollowUp)}
							className="flex flex-col gap-y-6"
						>
							<label htmlFor="observation" className="w-full mb-4">
								<span className="text-xl text-black">Observação</span>
								<textarea
									{...register('observation')}
									id="observation"
									rows={5}
									className="w-full border border-gray-900 rounded-md p-2 text-lg"
								/>
							</label>
							<p className="text-xl text-black">
								Selecione no *Mínimo três fotos
							</p>
							<div className="flex flex-col md:flex-row gap-6 text-2xl text-white">
								<label
									htmlFor="checkFF"
									className="w-min h-min p-2 bg-gray-400 relative rounded"
								>
									<div
										className="h-36 w-36 bg-contain bg-center bg-no-repeat"
										style={{ backgroundImage: `url(${fotoSeria})` }}
									/>
									<input
										{...register('hasFaceFrontal')}
										id="checkFF"
										className="absolute flex right-2 bottom-2 h-8 w-8"
										type="checkbox"
									/>
								</label>
								<label
									htmlFor="checkFL"
									className="w-min h-min p-2 bg-gray-400 relative rounded"
								>
									<div
										className="h-36 w-36 bg-contain bg-center bg-no-repeat"
										style={{ backgroundImage: `url(${fotoLateral})` }}
									/>
									<input
										{...register('hasFaceLateral')}
										id="checkFL"
										className="absolute flex right-2 bottom-2 h-8 w-8"
										type="checkbox"
									/>
								</label>
								<label
									htmlFor="checkFS"
									className="w-min h-min p-2 bg-gray-400 relative rounded"
								>
									<div
										className="h-36 w-36 bg-contain bg-center bg-no-repeat"
										style={{ backgroundImage: `url(${fotoSorrindo})` }}
									/>
									<input
										{...register('hasFaceSorrindo')}
										id="checkFS"
										className="absolute flex right-2 bottom-2 h-8 w-8"
										type="checkbox"
									/>
								</label>
							</div>
							<div className="flex flex-col md:flex-row gap-6 text-2xl text-white">
								<label
									htmlFor="checkDF"
									className="w-min h-min p-2 bg-gray-400 relative rounded"
								>
									<div
										className="h-36 w-36 bg-contain bg-center bg-no-repeat"
										style={{ backgroundImage: `url(${dentesFrente})` }}
									/>
									<input
										{...register('hasDentesFrontal')}
										id="checkDF"
										className="absolute flex right-2 bottom-2 h-8 w-8"
										type="checkbox"
									/>
								</label>
								<label
									htmlFor="checkDE"
									className="w-min h-min p-2 bg-gray-400 relative rounded"
								>
									<div
										className="h-36 w-36 bg-contain bg-center bg-no-repeat"
										style={{ backgroundImage: `url(${dentesEsquerda})` }}
									/>
									<input
										{...register('hasDentesEsquerda')}
										id="checkDE"
										className="absolute flex right-2 bottom-2 h-8 w-8"
										type="checkbox"
									/>
								</label>
								<label
									htmlFor="checkDD"
									className="w-min h-min p-2 bg-gray-400 relative rounded"
								>
									<div
										className="h-36 w-36 bg-contain bg-center bg-no-repeat"
										style={{ backgroundImage: `url(${dentesDireita})` }}
									/>
									<input
										{...register('hasDentesDireita')}
										id="checkDD"
										className="absolute flex right-2 bottom-2 h-8 w-8"
										type="checkbox"
									/>
								</label>
								<label
									htmlFor="checkDC"
									className="w-min h-min p-2 bg-gray-400 relative rounded"
								>
									<div
										className="h-36 w-36 bg-contain bg-center bg-no-repeat"
										style={{ backgroundImage: `url(${dentesCima})` }}
									/>
									<input
										{...register('hasDentesCima')}
										id="checkDC"
										className="absolute flex right-2 bottom-2 h-8 w-8"
										type="checkbox"
									/>
								</label>
								<label
									htmlFor="checkDB"
									className="w-min h-min p-2 bg-gray-400 relative rounded"
								>
									<div
										className="h-36 w-36 bg-contain bg-center bg-no-repeat"
										style={{ backgroundImage: `url(${dentesBaixo})` }}
									/>
									<input
										{...register('hasDentesBaixo')}
										id="checkDB"
										className="absolute flex right-2 bottom-2 h-8 w-8"
										type="checkbox"
									/>
								</label>
							</div>
							<div className="flex flex-col md:flex-row gap-6 text-2xl text-white">
								<label
									htmlFor="checkRXL"
									className="w-min h-min p-2 bg-gray-400 relative rounded"
								>
									<div
										className="h-36 w-36 bg-contain bg-center bg-no-repeat"
										style={{ backgroundImage: `url(${raioxLateral})` }}
									/>
									<input
										{...register('hasRaioxLateral')}
										id="checkRXL"
										className="absolute flex right-2 bottom-2 h-8 w-8"
										type="checkbox"
									/>
								</label>
								<label
									htmlFor="checkRXP"
									className="w-min h-min p-2 bg-gray-400 relative rounded"
								>
									<div
										className="h-36 w-36 bg-contain bg-center bg-no-repeat"
										style={{ backgroundImage: `url(${raioxPanoramico})` }}
									/>
									<input
										{...register('hasRaioxPanoramico')}
										id="checkRXP"
										className="absolute flex right-2 bottom-2 h-8 w-8"
										type="checkbox"
									/>
								</label>
							</div>
							<button
								type="submit"
								disabled={loading}
								className={classNames(
									'mt-10 py-4 px-10 self-center',
									'text-center text-xl lg:text-2xl rounded-lg text-admin-bc-white font-bold',
									'transition-colors duration-300 hover:bg-primary',
									loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-primary'
								)}
							>
								Enviar solicitação
							</button>
						</form>
					</div>
				</div>
			</Modal>

			<AnswerFollowUpWithVideo
				isOpen={openModalVideo}
				onClose={() => setOpenModalVideo(false)}
				id_followup={patientFollowUpHistory?.[0]?.id}
				onFinish={async () => {
					setOpenModalVideo(false);
					loadData();
				}}
			/>
			<Loader show={loading} />
		</>
	);
}
export default FollowUpDetail;
