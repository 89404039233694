import { Menu, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { FaChevronDown } from 'react-icons/fa';

import classNames from '../utils/classNames';

export default function Dropdown({ options = [] }) {
	return (
		<div className="text-right">
			<Menu as="div" className="relative inline-block text-left">
				<div>
					<Menu.Button className="inline-flex w-full justify-center rounded-md bg-primary px-4 py-2 font-medium text-white hover:bg-opacity-70 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
						Ações
						<FaChevronDown
							className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
							aria-hidden="true"
						/>
					</Menu.Button>
				</div>
				<Transition
					as={Fragment}
					enter="transition ease-out duration-100"
					enterFrom="transform opacity-0 scale-95"
					enterTo="transform opacity-100 scale-100"
					leave="transition ease-in duration-75"
					leaveFrom="transform opacity-100 scale-100"
					leaveTo="transform opacity-0 scale-95"
				>
					<Menu.Items className="absolute right-0 mt-2 origin-top-right divide-y bg-white divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
						{options.flatMap(opt => !opt ? [] : [(
							<div key={opt.text} className="px-1 py-1 ">
								<Menu.Item>
									{({ active }) => (
										<button
											className={classNames(
												active ? 'bg-primary text-white' : 'text-gray-900',
												'group flex w-full items-center rounded-md px-2 py-2 whitespace-nowrap'
											)}
											onClick={opt.action}
											disabled={!!opt.disabled}
										>
											{opt.text}
										</button>
									)}
								</Menu.Item>
							</div>
						)])}
					</Menu.Items>
				</Transition>
			</Menu>
		</div>
	);
}

