import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import Header from '../../components/Header';
import ListPhotos from '../../components/ListPhotos';
import Loader from '../../components/Loader';
import Menu from '../../components/Menu';
import Modal from '../../components/Modal';
import { PlanningBreadCrumb } from '../../components/PlanningBreadCrumb';
import { useAuth } from '../../contexts/auth';
import api from '../../services/api';
import classNames from '../../utils/classNames';
import { getAgeFromDate } from '../../utils/getAgeFromDate';
import getImagesFromObject from '../../utils/getImagesFromObject';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import Step7 from './Step7';

function AnswerPlanning() {
	const [loading, setLoading] = useState(true);
	const [selected, setSelected] = useState(1);
	const [showMore, setShowMore] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [images, setImages] = useState([]);
	const [questions, setQuestions] = useState();
	const [values, setValues] = useState({});
	const [progressBar] = useState(0);
	const [sending, setSending] = useState(false);
	const [patient, setPatient] = useState();

	const { id_planejamento } = useParams();
	const { signOut, token } = useAuth();
	const { addToast } = useToasts();
	const { push: pushHistory } = useHistory();

	useEffect(() => {
		async function loadData() {
			setLoading(true);
			try {
				const { data } = await api.get(`/plannings/answering/${id_planejamento}`);
				// const { data: dataAnswers } = await api.get('/plannings/questions');

				if (data.planning.status === 'completed') {
					addToast((
						<div className="flex flex-col">
							<strong>CUIDADO!</strong>
							<p>Planejamento já finalizado!</p>
						</div>
					), {
						appearance: 'warning',
					});
					// pushHistory(`/planejamento/${id_planejamento}`);

					// return;
				}

				setImages(getImagesFromObject(data.planning));

				const tempObj = {};
				const objKeys = Object.keys(data.questions);
				for (const key of objKeys) {
					const entries = Object.entries(data.questions[key].optgroups);
					tempObj[key] = entries;
				}

				setQuestions(tempObj);
				setPatient({ ...data.planning?.patient, doubts: data.planning?.doubts });
				setValues(data.answers);
				setLoading(false);
			} catch (err) {
				if (err.response?.status === 401) {
					addToast('Sessão expirada', {
						appearance: 'error',
						autoDismiss: true
					});
					signOut();
				}
			}
		}

		loadData();
	}, []);

	const submitPlanning = useCallback(async (data, finished, next) => {
		try {
			setSending(true);
			// const pTeeth = Array.isArray(data.teeth) ? data.teeth : [];
			// const teethReduce = pTeeth.reduce((p, c) => ({ ...p, ...c }), 0);
			// const teeth = Object.entries(teethReduce).filter(v => !!v[1]).map(v => v[0]).join(', ').replace(/tooth-/g, 'Dente ');

			const files = data?.files || [];

			// delete data.files;
			let filesUrl = [];

			if (files.length > 0) {
				for (let i = 0; i < files.length; i++) {
					let file = files[i];
					if (typeof file === 'string') {
						filesUrl.push(file);
						continue;
					}
					// file = file?.item(0);

					if (file) {
						const { data: permissionData } = await api.post(
							'/plannings/permission-upload',
							{
								contentType: file.type,
								planningId: id_planejamento,
							},
						);

						delete api.defaults.headers.common['Authorization'];

						await api.put(permissionData.url, file, {
							headers: {
								'Content-Type': file.type,
								'Content-Length': undefined,
							},
							// onUploadProgress: progressEvent => setProgressBar(() => Math.round((progressEvent.loaded * 100) / progressEvent.total)),
							// timeout: 900000
						});

						// BUG: para mandar arquivo para o S3, tem que remover completamente o header "Authorization", se não volta o erro 400.
						api.defaults.headers.common['Authorization'] = token;
						filesUrl.push(permissionData.urlView);
					}
				}
			}

			// return;
			await api.put(`/plannings/answer/${id_planejamento}`, {
				finished,
				answer: {
					...data,
					files: filesUrl.length === 0 ? undefined : filesUrl,
					sagittalCorrectionTeeth: undefined
				}
			});

			if (!finished) {
				setSelected(next);
			}
		} catch (err) {
			if (err.response?.status === 401) {
				addToast('Sessão expirada', {
					appearance: 'error',
					autoDismiss: true
				});
				signOut();

				return;
			}

			api.defaults.headers.common['Authorization'] = token;
			addToast(err.response?.data.message || 'Erro ao salvar', {
				appearance: 'error',
				autoDismiss: true
			});
		} finally {
			setSending(false);
		}
	}, [values]);

	if (loading) return <Loader show={loading} />;
	return (
		<div className="flex">
			<Menu className="hidden xl:flex" />
			<div className="w-full flex flex-col">
				<Header textHeader="Preencher planejamentos" />
				<main className="w-full flex flex-col py-4 md:px-14 px-2">
					<div className="grid grid-cols-1 shadow-md">
						{showMore && (
							<>
								<div className="mt-6 shadow-md min-w-full rounded-4 w-full h-auto flex flex-col xl:flex-row px-4 py-5 gap-4 rounded-lg drop-shadow-lg">
									<div
										className="w-28 h-28 self-center rounded-full bg-cover bg-center bg-no-repeat"
										style={{ backgroundImage: `url(${patient?.profile_image})` }}
									/>
									<div className="flex flex-col gap-2 text-lg w-full flex-1 items-between px-4">
										<p className="font-bold text-xl self-start">{patient?.name}</p>
										<div className="flex gap-2">
											<p className="">Sexo:</p>
											<p>{patient?.gender}</p>
										</div>
										<div className="flex gap-2">
											<p className="">Idade:</p>
											<p>{patient?.birth_date ? getAgeFromDate(new Date(patient?.birth_date)) : '-'}</p>
										</div>
										<div className="flex gap-2">
											<p className="">Queixa principal:</p>
											<p>{patient?.complaint}</p>
										</div>
									</div>
									<div className="flex flex-col text-lg justify-evenly gap-2 px-4">
										<div className="flex gap-2">
											<p className="">Linha média:</p>
											<p>{patient?.upper_middle_line}</p>
										</div>
										<div className="flex gap-2">
											<p className="">Dentista Responsável:</p>
											<p>{patient?.dentist?.name}</p>
										</div>
									</div>
								</div>
								<div className="text-xl px-10 mt-4 overflow-hidden">
									<strong>Dúvidas</strong>
									<p>{patient?.doubts || '-'}</p>
								</div>
							</>
						)}
						<div className="text-xl px-10 mt-4 pb-12">
							<strong>Fotos:</strong>
							<div className="flex flex-wrap gap-8 w-full mt-2">
								<ListPhotos list={images.map((url, i) => ({ id: i, url }))} />
							</div>
						</div>
						<div className="col-span-full flex justify-center">
							<button
								type="button"
								className="px-8 py-4 text-gray-600"
								onClick={() => setShowMore(old => !old)}
							>
								Ver {showMore ? 'menos' : 'mais'}
							</button>
						</div>
					</div>
					<PlanningBreadCrumb
						page={selected}
						setPage={setSelected}
					/>
					<h2 className="px-14 my-4 text-4xl font-bold text-primary-dark">
						{selected <= 3 ? 'Diagnóstico' : 'Planejamento / Mecânica'}
					</h2>
					{questions && (
						<div>
							<Step1
								selected={selected}
								questions={questions}
								defaultValues={values}
								sending={sending}
								onNext={async data => {
									setValues(old => ({ ...old, ...data }));
									await submitPlanning(data, false, 2);
								}}
							/>


							<Step2
								selected={selected}
								questions={questions}
								defaultValues={values}
								sending={sending}
								onNext={async data => {
									setValues(old => ({ ...old, ...data }));
									await submitPlanning(data, false, 3);
								}}
								onBack={() => setSelected(1)}
							/>


							<Step3
								selected={selected}
								questions={questions}
								defaultValues={values}
								sending={sending}
								onNext={async data => {
									setValues(old => ({ ...old, ...data }));
									await submitPlanning(data, false, 4);
								}}
								onBack={() => setSelected(2)}
							/>


							<Step4
								selected={selected}
								questions={questions}
								defaultValues={values}
								sending={sending}
								onNext={async data => {
									setValues(old => ({ ...old, ...data }));
									await submitPlanning(data, false, 5);
								}}
								onBack={() => setSelected(3)}
							/>


							<Step5
								selected={selected}
								questions={questions}
								defaultValues={values}
								sending={sending}
								onNext={async data => {
									setValues(old => ({ ...old, ...data }));
									await submitPlanning(data, false, 6);
								}}
								onBack={() => setSelected(4)}
							/>


							<Step6
								selected={selected}
								questions={questions}
								defaultValues={values}
								sending={sending}
								onNext={async data => {
									if (!data.cirurgia_ortignotica.answer) {
										data.cirurgia_ortignotica.answer = '';
									}
									setValues(old => ({ ...old, ...data }));
									await submitPlanning(data, false, 7);
								}}
								onBack={() => setSelected(5)}
							/>


							<Step7
								selected={selected}
								questions={questions}
								defaultValues={values}
								onSubmit={async data => {
									setValues(old => ({ ...old, ...data }));
									await submitPlanning(data, true);

									addToast('Planejamento finalizado com sucesso', {
										appearance: 'success',
										autoDismiss: true
									});

									setOpenModal(true);
								}}
								progressBar={progressBar}
								sending={sending}
								onBack={() => setSelected(6)}
							/>

							<Modal isOpen={openModal} onClose={() => setOpenModal(true)}  >
								<div className="h-full w-full bg-white rounded-2xl overflow-hidden flex flex-col">
									<div className="w-auto h-auto bg-primary-dark px-10 py-8 gap-x-14  flex flex-row items-center justify-between">
										<strong className="text-3xl w-full text-white">
											Planejamento Enviado com seucesso!
										</strong>
									</div>
									<div className="flex flex-col py-6 gap-y-10 justify-center px-10">
										<p className="text-3xl text-black">
											Tudo certo!
										</p>
										<p className="text-3xl text-black">
											Seu planejamento foi respondido e o
											<br />dentista responsável já foi notificado.
										</p>
										<button
											onClick={() => pushHistory(`/planejamento/${id_planejamento}`)}
											className={classNames(
												'mt-10 py-2 px-36 self-center',
												'text-center text-xl lg:text-2xl rounded-lg text-admin-bc-white font-bold',
												'transition-colors duration-300 hover:bg-blue-400',
												sending ? 'bg-gray-400 cursor-not-allowed' : 'bg-primary-dark'
											)}
										>
											Ok
										</button>
									</div>
								</div>
							</Modal>
						</div>
					)}
				</main >
			</div >
		</div >

	);
}
export default AnswerPlanning;
