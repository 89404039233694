/* This example requires Tailwind CSS v2.0+ */
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import React from 'react';
import { Fragment } from 'react';


function HeaderlessSlideOver({ open, setOpen, title: _title, side = 'left', closeButtonSide = 'left', children }) {

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="absolute inset-0" onClose={() => setOpen(false)}>
        <div className="absolute inset-0 ">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-black bg-opacity-80 w-screen transition-opacity" />
          </Transition.Child>
          <div className={`fixed inset-y-0 max-w-full ${side === 'left' ? 'left-0' : 'right-0'}`}>
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom={side === 'left' ? '-translate-x-full' : 'translate-x-full'}
              enterTo={side === 'left' ? 'translate-x-0' : 'translate-x-0'}
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom={side === 'left' ? 'translate-x-0' : 'translate-x-0'}
              leaveTo={side === 'left' ? '-translate-x-full' : 'translate-x-full'}
            >
              <div className="relative w-min overflow-y-auto">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <button
                    type="button"
                    className={`absolute top-1 ${closeButtonSide}-2 rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white`}
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close panel</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </Transition.Child>
                {children}
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default HeaderlessSlideOver;
