import imageCompression from 'browser-image-compression';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaUser } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import FollowUpCard from '../../components/FollowUpCard';
import Header from '../../components/Header';
import HeaderlessSlideOver from '../../components/HeadlessSlideOver';
import Loader from '../../components/Loader';
import Menu from '../../components/Menu';
import Modal from '../../components/Modal';
import ModalRequestFollowUp from '../../components/ModalRequestFollowUp';
import PatientInfos from '../../components/PatientInfos';
import PlanningCard from '../../components/PlanningCard';
import { useAuth } from '../../contexts/auth';
import api from '../../services/api';
import classNames from '../../utils/classNames';
import toBase64 from '../../utils/toBase64';

function PatientDetail() {
	const [modalDelete, setModalDelete] = useState(false);
	const [modalEdit, setModalEdit] = useState(false);
	const [modalFollowUp, setModalFollowUp] = useState(false);
	const [loading, setLoading] = useState(false);
	const [patientData, setPatientData] = useState();
	const [previewPatientImage, setPreviewPatientImage] = useState();
	const [followUps, setFollowUps] = useState([]);
	const [plannings, setPlannings] = useState([]);

	const [image, setImage] = useState();
	const { signOut } = useAuth();
	const { idpatient } = useParams();
	const { addToast } = useToasts();
	const { push: historyPush } = useHistory();
	const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();

	const loadData = useCallback(async () => {
		setLoading(true);

		try {
			const { data: history } = await api.get(`/dentist/patient-history/${idpatient}`);

			setFollowUps(history.FollowUps.map((item, index) => ({ ...item, position: index + 1 })));
			setPlannings(history.Plannings.map((item, index) => ({ ...item, position: index + 1 })));
			delete history.FollowUps;
			delete history.Plannings;

			setPatientData(history);
			setPreviewPatientImage(history.profile_image);
			setValue('oldImage', history.profile_image);
			setValue('name', history.name);
			setValue('email', history.email);
			setValue('phone', history.phone);
			setValue('birth_date', history.birth_date?.slice(0, 10));
			setValue('gender', history.gender?.slice(0, 1));
			setValue('upper_middle_line', history.upper_middle_line);
			setValue('complaint', history.complaint);
		} catch (err) {
			if (err.response?.status === 401) {
				addToast(err.response?.data.message ?? 'Você não tem permissão para acessar esta página!', {
					appearance: 'error',
					autoDismiss: true
				});
				signOut();
				return;
			}

			addToast(err.response?.data.message ?? 'Erro ao buscar paciente', {
				appearance: 'error',
				autoDismiss: true
			});

			historyPush('/pacientes');
		} finally {
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		loadData();
	}, []);

	const onSubmit = useCallback(async (formData) => {
		setLoading(true);

		try {
			let img = formData.oldImage;
			const profile_image = image;
			if (profile_image) {
				const compressed = await imageCompression(profile_image, {
					maxSizeMB: 1,
					maxWidthOrHeight: 480,
					useWebWorker: true
				});

				img = await toBase64(compressed);
			}

			await api.put(`/dentist/update-patients/${idpatient}`, {
				...formData,
				profile_image: img
			});

			addToast('Paciente salvo', {
				appearance: 'success',
				autoDismiss: true
			});

			loadData();
			setLoading(false);
			setModalEdit(false);
			reset();
			setPreviewPatientImage(undefined);
		} catch (err) {
			if (err.response?.status === 401) {
				signOut();
				return;
			}

			addToast(err.response?.data?.message ?? 'Erro ao salvar paciente.', {
				appearance: 'error',
				autoDismiss: true
			});
		} finally {
			setLoading(false);
		}
	}, [idpatient, image]);

	const handleDeletePatient = useCallback(async () => {
		setLoading(true);

		try {
			await api.delete(`/dentist/delete-patients/${idpatient}`);

			addToast('Paciente removido', {
				appearance: 'warning',
				autoDismiss: true
			});

			setLoading(false);
			setModalDelete(false);
			historyPush('/pacientes');
		} catch (err) {
			if (err.response?.status === 401) {
				signOut();
				return;
			}

			addToast(err.response?.data?.message ?? 'Erro ao deletar o paciente.', {
				appearance: 'error',
				autoDismiss: true
			});
		} finally {
			setLoading(false);
		}
	}, [idpatient]);

	return (
		<>
			<div className="flex">
				<Menu className="hidden xl:flex" />
				<div className="w-full flex flex-col">
					<Header textHeader={`Clínica | ${patientData?.name}`} />
					<main className="w-full flex flex-col py-4 px-14">
						{!patientData ? null : (
							<PatientInfos
								patient={patientData}
								onClickEdit={() => setModalEdit(true)}
								onClickDelete={() => setModalDelete(true)}
								reload={() => loadData()}
							/>
						)}
						<div className="flex gap-y-6 justify-between  md:flex-row  my-6 flex-col-reverse">
							<div className="flex items-center text-2xl font-bold text-center sm:text-left">Histórico:</div>
							{patientData?.is_active === 0 ? null : (
								<div className="flex gap-x-4">
									{/* <button
										type="button"
										onClick={() => setModalFollowUp(true)}
										className="h-max w-min sm:w-max px-7 py-2 text-2xl text-center font-bold text-white bg-primary rounded-lg"
									>
										Solicitar acompanhamento
									</button> */}
									<Link
										to={`/solicitar-planejamento?id_patient=${patientData?.id}`}
										className="h-max w-min sm:w-max px-7 py-2 text-2xl text-center font-bold text-white bg-primary rounded-lg"
									>
										Solicitar planejamento
									</Link>
								</div>
							)}
						</div>
						{followUps.length <= 0 && plannings.length <= 0 ? (
							<div className="grid grid-cols-1 lg:grid-cols-2 gap-3 mt-8">
								<p className="col-span-2 text-lg font-bold">Nenhum histórico encontrado.</p>
							</div>
						) : (
							<div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-3 mt-4">
								{followUps.map(followUp => (
									<FollowUpCard
										key={followUp.id}
										followUp={{
											...followUp,
											patient: {
												name: patientData?.name,
												profile_image: patientData?.profile_image,
											},
											dentist: {
												clinic: {
													name: patientData?.clinic?.name,
												}
											}
										}}
									/>
								))}
								{plannings.map((planning, index) => (
									<PlanningCard
										key={planning.id}
										planning={planning}
										index={index}
									/>
								))}
							</div>
						)}
					</main>
				</div>
			</div>

			<Modal isOpen={modalDelete} onClose={() => setModalDelete(false)}  >
				<div className="h-full w-full bg-white rounded-2xl overflow-hidden flex flex-col">
					<div className="w-auto h-auto bg-red-500 px-10 py-4 gap-x-28  flex flex-row items-center justify-between">
						<strong className="text-3xl w-full text-white">
							Exluir Paciente
						</strong>
						<button onClick={() => setModalDelete(false)}>
							<IoMdClose size={60} className="flex text-white" />
						</button>
					</div>
					<div className="flex flex-col py-14 gap-y-32 justify-center px-10">
						<p className="text-3xl text-black text-center">
							Você tem certeza que deseja excluir este paciente?
						</p>
						<div className="flex flex-col md:flex-row gap-6 justify-evenly text-2xl text-white">
							<button
								disabled={loading}
								className={classNames(
									'px-16 py-4  rounded-xl',
									loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-primary'
								)}
								onClick={() => setModalDelete(false)}
							>
								Não
							</button>
							{/* func excluir consultor ----------------------- */}
							<button
								onClick={handleDeletePatient}
								disabled={loading}
								className={classNames(
									'px-16 py-4  rounded-xl',
									loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-red-500'
								)}
							>
								{loading ? 'Removendo...' : 'Sim'}
							</button>
						</div>
					</div>
				</div>
			</Modal>

			<HeaderlessSlideOver
				open={modalEdit}
				setOpen={setModalEdit}
				title="Menu"
				className="absolute"
				side="ri"
			>
				<div className="overflow-y-auto max-h-screen h-screen w-auto flex  flex-col  bg-white">
					<div className="flex w-full py-10 flex-col h-24 text-center justify-center bg-primary text-3xl text-white">Editar Paciente</div>
					<div className=" w-auto flex p-10 flex-col gap-10 bg-white">
						<form onSubmit={handleSubmit(onSubmit)} className="gap-10 flex flex-col">
							<input {...register('oldImage')} type="hidden" name="oldImage" />
							<strong>Clique abaixo para selecionar a imagem do paciente</strong>
							<label
								htmlFor="profile_image"
								className={classNames(
									'w-28 h-28 place-self-center flex p-2 rounded-full border-2 border-primary bg-transparent',
									'bg-center bg-cover bg-no-repeat cursor-pointer'
								)}
								style={{ backgroundImage: `url(${previewPatientImage})` }}
							>
								{!previewPatientImage && (
									<FaUser size={65} className="text-primary py-2" />
								)}
								<input
									{...register('profile_image')}
									id="profile_image"
									type="file"
									accept="image/png,image/jpg,image/jpeg,image/webp"
									className="sr-only"
									onChange={evt => {
										const file = evt.currentTarget.files.item(0);
										if (file) {
											const imageUrl = URL.createObjectURL(file);
											setPreviewPatientImage(imageUrl);
											setImage(file);
											return;
										}

										setImage(undefined);
										setPreviewPatientImage(undefined);
									}}
								/>
							</label>
							{errors.profile_image && (
								<small className="text-red-400 self-center">{errors.profile_image.message}</small>
							)}
							<div className="flex gap-4 flex-col">
								<strong>Nome:</strong>
								<input {...register('name', { required: 'Campo obrigatório' })} placeholder="Digite aqui o nome" className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11" />
								{errors.name && (
									<small className="text-red-400">{errors.name.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>Nome:</strong>
								<input {...register('name', { required: 'Campo obrigatório' })} placeholder="Digite aqui o nome" className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11" />
								{errors.name && (
									<small className="text-red-400">{errors.name.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>E-mail do paciente:</strong>
								<input {...register('email', { required: 'Campo obrigatório' })} type="email" placeholder="Digite aqui o e-mail" className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11" />
								{errors.email && (
									<small className="text-red-400">{errors.email.message}</small>
								)}
								<div className="flex gap-4 flex-col">
									<strong>Senha do paciente (Será alterada pelo paciente depois):</strong>
									<input {...register('password', { required: 'Campo obrigatório' })} type="password" placeholder="Digite aqui a senha" className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11" />
									{errors.password && (
										<small className="text-red-400">{errors.password.message}</small>
									)}
								</div>
							</div>
							<div className="flex gap-4 flex-col">
								<strong>Data de nascimento:</strong>
								<input {...register('birth_date', { required: 'Campo obrigatório' })} type="date" className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11" />
								{errors.birth_date && (
									<small className="text-red-400">{errors.birth_date.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>Gênero:</strong>
								<select
									{...register('gender', { required: 'Campo obrigatório' })}
									className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11"
								>
									<option disabled>Selecione o gênero</option>
									<option value="M">Masculino</option>
									<option value="F">Feminino</option>
									<option value="O">Outro</option>
								</select>
								{errors.gender && (
									<small className="text-red-400">{errors.gender.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>Linha média superior:</strong>
								<input {...register('upper_middle_line', { required: 'Campo obrigatório' })} placeholder="Digite aqui linha média superior" className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11" />
								{errors.upper_middle_line && (
									<small className="text-red-400">{errors.upper_middle_line.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>Queixa principal:</strong>
								<textarea
									{...register('complaint', { required: 'Campo obrigatório' })}
									placeholder="Digite aqui a queixa principal"
									rows={4}
									className="md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11"
								/>
								{errors.complaint && (
									<small className="text-red-400">{errors.complaint.message}</small>
								)}
							</div>
							<button
								disabled={loading}
								type="submit"
								className={classNames(
									'w-full h-14 mt-6 rounded-lg text-3xl text-white',
									loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-primary'
								)}
							>
								Salvar
							</button>
						</form>
					</div>
				</div>
			</HeaderlessSlideOver>

			<ModalRequestFollowUp
				open={modalFollowUp}
				onClose={() => setModalFollowUp(false)}
				patientId={idpatient}
				dentistId={patientData?.dentist.id}
			/>

			<Loader show={loading} />
		</>
	);
}

export default PatientDetail;
