import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IoMdClose } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { useAuth } from '../contexts/auth';
import api from '../services/api';
import classNames from '../utils/classNames';
import Modal from './Modal';

function ModalMoreInfoPlanning({ id_planning, isOpen, onClose }) {
	const [loading, setLoading] = useState(false);

	const { register, handleSubmit, formState: { errors } } = useForm();
	const { addToast } = useToasts();
	const { push: pushHistory } = useHistory();
	const { signOut } = useAuth();

	const onSubmit = useCallback(async ({ more_info }) => {
		setLoading(true);

		try {
			await api.put(`/plannings/${id_planning}`, { more_info });

			addToast('Solicitação enviada!', { appearance: 'success', autoDismiss: true });
			await new Promise(resolve => setTimeout(resolve, 2000));
			location.reload();
		} catch (err) {
			if (err.response?.status === 401) {
				addToast('Você não tem permissão para realizar essa ação!', { appearance: 'error', autoDismiss: true });
				signOut();
				pushHistory('/');
			}

			addToast(err.response?.data?.message || 'Erro ao enviar solicitação!', { appearance: 'error', autoDismiss: true });
		} finally {
			setLoading(false);
		}
	}, [id_planning]);

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<div className="h-full w-full bg-white rounded-2xl overflow-hidden flex flex-col">
				<div className="w-auto h-auto bg-red-500 px-10 py-4 gap-x-28  flex flex-row items-center justify-between">
					<strong className="text-3xl w-full text-white">
						Informe as alterações
					</strong>
					<button onClick={onClose}>
						<IoMdClose size={35} className="flex text-white" />
					</button>
				</div>
				<div className="flex flex-col py-6 gap-y-10 justify-center px-10">
					<form onSubmit={handleSubmit(onSubmit)} className="flex  flex-col gap-y-6">
						<label htmlFor="more_info" className="w-full mb-4">
							<span className="text-xl text-black">Observação</span>
							<textarea
								{...register('more_info', { required: 'Campo obrigatório' })}
								id="more_info"
								rows={5}
								className="w-full border border-gray-900 rounded-md p-2 text-lg"
							/>
							{errors.more_info && <span className="text-red-600">{errors.more_info.message}</span>}
						</label>

						<button
							type="submit"
							disabled={loading}
							className={classNames(
								'mt-10 py-4 px-10 self-center',
								'text-center text-xl lg:text-2xl rounded-lg text-admin-bc-white font-bold',
								'transition-colors duration-300 hover:bg-red-400',
								loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-red-500'
							)}
						>
							{loading ? 'Enviando...' : 'Enviar solicitação'}
						</button>
					</form>
				</div>
			</div>
		</Modal>
	);
}

export default ModalMoreInfoPlanning;
