import React from 'react';

export default function ProgressBar({ progress = 0 }) {
	return (
		<div className="w-full bg-gray-200 rounded-full h-6 dark:bg-gray-700 relative">
			<span className="absolute left-1/2 translate-x-1/2">{progress}%</span>
			<div className="bg-status-completed h-6 rounded-full" style={{ width: `${progress}%` }}></div>
		</div>
	);
}
