import React from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineMail } from 'react-icons/ai';

import imgLogo from '../assets/images/logo_zt_header.png';

function RecoveryPassword() {
	const { register, handleSubmit, formState: { errors } } = useForm();
	const onSubmit = data => {
		return data;
	};

	return (
		<main className="min-h-screen w-ful flex items-center justify-center">
			<div className=" flex-1 h-screen bg-gradient-to-br from-primary-dark via-primary to-primary-dark
			 hidden md:flex justify-center items-center">
				<div className="h-72 w-3/6 rounded-2xl bg-admin-bc-white flex flex-col justify-center items-center" >
					<img loading="lazy" src={imgLogo} className="w-full " alt="imagem logo zt" />
					<p className="text-4xl font-bold text-primary-dark mt-8">GESTORES</p>
				</div>
			</div>
			<div className="flex-1 flex justify-center h-screen bg-no-repeat bg-cover bg-right-top">
				<div className=" py-6 w-4/6 flex-col flex justify-center" >
					<form onSubmit={handleSubmit(onSubmit)}>
						<h1 className="mb-8 self-center text-4xl font-bold inline-block ">RECUPERAÇÃO DE SENHA</h1>
						<p className="text-2xl mb-8">Digite seu e-mail abaixo para recuperar a senha.</p>
						<div>
							<p className="ml-4 text-admin-bc-grey">Email</p>
							<div className="relative flex">
								<AiOutlineMail className="absolute mt-7 ml-4 text-admin-bc-grey" size={24} />
								<input {...register('email', { required: true })} placeholder="Email" className="h-20 w-full rounded-3xl border-solid border-admin-bc-lightGrey border-2 pl-14" />
							</div>
							{errors.email && <span className="ml-4 text-admin-bc-red">Email em branco.</span>}
						</div>
						<button type="submit" className="my-8 bg-gradient-to-r from-primary-dark to-primary rounded-lg h-16 w-full text-3xl font-bold text-admin-bc-white">ENVIAR</button>
						<a href="/" className="font-bold my-5 text-xl">Voltar</a>
					</form>
				</div>
			</div>
		</main>
	);
}

export default RecoveryPassword;
