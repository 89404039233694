import { Tab } from '@headlessui/react';
import React from 'react';

import SearchBar from '../SearchBar';
import EvaluationCard from './Card';

function PanelSection({ evaluations }) {
	return (
		<Tab.Group>
			<SearchBar
				optionsList={[
					{
						id: '2',
						option: '1° Ano',
					},
					{
						id: '4',
						option: '2° Ano',
					},
					{
						id: '5',
						option: '3 Ano',
					},
				]}
			/>
			<Tab.Panel className="grid grid-cols-1 mt-8 lg:grid-cols-2 gap-x-4 gap-y-3">
				{evaluations?.year1?.length <= 0 && (
					<div className="col-span-1 lg:col-span-2 text-center mt-4">
						<strong className="text-2xl">Nenhuma avaliação do 1° ano</strong>
					</div>
				)}
				{evaluations?.year1?.map(e => (
					<EvaluationCard key={e.id} evaluation={e} />
				))}
			</Tab.Panel>
			<Tab.Panel className="grid grid-cols-1 mt-8 lg:grid-cols-2 gap-x-4 gap-y-3">
				{evaluations?.year2?.length <= 0 && (
					<div className="col-span-1 lg:col-span-2 text-center mt-4">
						<strong className="text-2xl">Nenhuma avaliação do 2° ano</strong>
					</div>
				)}
				{evaluations?.year2?.map(e => (
					<EvaluationCard key={e.id} evaluation={e} />
				))}
			</Tab.Panel>
			<Tab.Panel className="grid grid-cols-1 mt-8 lg:grid-cols-2 gap-x-4 gap-y-3">
				{evaluations?.year3?.length <= 0 && (
					<div className="col-span-1 lg:col-span-2 text-center mt-4">
						<strong className="text-2xl">Nenhuma avaliação do 3° ano</strong>
					</div>
				)}
				{evaluations?.year3?.map(e => (
					<EvaluationCard key={e.id} evaluation={e} />
				))}
			</Tab.Panel>
		</Tab.Group>
	);
}
export default PanelSection;
