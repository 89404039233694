import React, { useCallback, useEffect, useState } from 'react';
import { MdUpload } from 'react-icons/md';

import classNames from '../../utils/classNames';

function NewFileInputs({ index, register, remove, error, preview }) {
	const [filePreview, setFilePreview] = useState();

	useEffect(() => {
		if (preview) {
			setFilePreview(preview);
		}
	}, [index, preview]);

	const handleChange = useCallback(
		(event) => {
			const file = event.currentTarget.files.item(0);
			if (file) {
				const name = file.name;
				setFilePreview(name);
			} else {
				setFilePreview(undefined);
			}
		},
		[index, preview]
	);

	return (
		<div className="p-4 shadow-lg rounded-md h-full">
			<div className="w-full flex items-center justify-between">
				<h4 className="text-2xl font-bold">Arquivo {index + 1}</h4>
				<button
					type="button"
					onClick={() => remove(index)}
					className={classNames(
						'px-6 py-2 text-lg self-end',
						'rounded-lg text-admin-bc-white font-bold',
						'bg-red-400 cursor-pointer',
						'hover:bg-gray-700 transition-colors duration-150'
					)}
				>
					Remover
				</button>
			</div>
			<div className="flex flex-col gap-y-8 mt-2">
				<label className="flex-1">
					Título
					<input
						{...register(`files.${index}.title`, {
							required: 'Campo obrigatório',
						})}
						placeholder={`Digite aqui o título do arquivo ${index + 1}`}
						className="w-full py-2 rounded-lg border px-3"
					/>
					{error?.title && (
						<small className="text-red-500 text-sm">
							{error.title?.message}
						</small>
					)}
				</label>
				<div className="flex flex-col items-center gap-4">
					<strong className="">Selecione um arquivo:</strong>
					<label
						htmlFor={`file-${index}`}
						className={classNames(
							'py-6 px-2 w-80 max-w-xs cursor-pointer rounded-lg border-gray-600 grid place-content-center',
							filePreview ? 'bg-blue-200' : 'bg-gray-100'
						)}
						style={{ wordBreak: 'break-all' }}
					>
						<input
							{...register(`files.${index}.file`, {
								required: filePreview ? false : 'Campo obrigatório',
							})}
							id={`file-${index}`}
							type="file"
							onChange={handleChange}
							accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/vnd.openxmlformats-officedocument.presentationml.presentation"
							className="sr-only"
						/>
						{filePreview ? filePreview : (
							<MdUpload size={40} className="text-gray-500" />
						)}
					</label>
					{error?.file && (
						<small className="text-red-500 text-sm">
							{error.file?.message}
						</small>
					)}
				</div>
			</div>
		</div>
	);
}

export default NewFileInputs;
