import { Tab } from '@headlessui/react';
import imageCompression from 'browser-image-compression';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineClose } from 'react-icons/ai';
import { BiBuildingHouse, BiCalendarCheck, BiCalendarEdit } from 'react-icons/bi';
import { FaUserNurse } from 'react-icons/fa';
import { IoIosPeople, IoIosPerson, IoMdClose } from 'react-icons/io';
import { MdOutlineEditNote } from 'react-icons/md';
import InputMask from 'react-input-mask-updated';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import CounterCards from '../../components/CounterCards';
import Header from '../../components/Header';
import HeaderlessSlideOver from '../../components/HeadlessSlideOver';
import ListClinicPatients from '../../components/ListClinicPatients';
import ListDentists from '../../components/ListDentists';
import Loader from '../../components/Loader';
import Menu from '../../components/Menu';
import Modal from '../../components/Modal';
import PlanningCard from '../../components/PlanningCard';
import SearchBar from '../../components/SearchBar';
import { useAuth } from '../../contexts/auth';
import api from '../../services/api';
import classNames from '../../utils/classNames';
import { getPlanningStatusColor } from '../../utils/status';
import toBase64 from '../../utils/toBase64';

function Clinic() {
	const [pageTitle, setPageTitle] = useState('Pendente');
	const [buttonType, setButtonType] = useState('');
	const [openModal, setOpenModal] = useState(false);
	const [open, setOpen] = useState(false);
	const [addDentist, setAddDentist] = useState(false);
	const [loading, setLoading] = useState(true);
	const [clinicData, setClinicData] = useState();
	const [previewClinicImage, setPreviewClinicImage] = useState();
	const [previewDentistImage, setPreviewDentistImage] = useState();
	const [plannings, setPlannings] = useState([]);

	const [imageNew, setImage] = useState();
	const { signOut } = useAuth();
	const { idclinica } = useParams();
	const { addToast } = useToasts();
	const { push: historyPush } = useHistory();
	const { register, handleSubmit, formState: { errors }, setValue } = useForm();
	const {
		register: registerAddDentist,
		handleSubmit: handleSubmitAddDentist,
		formState: { errors: errorsAddDentist },
		reset: resetAddDentist,
	} = useForm();

	useEffect(() => {
		async function loadData() {
			setLoading(true);
			try {
				const [
					{ data },
					{ data: resPlannings },
				] = await Promise.all([
					api.get(`/manager/clinics/${idclinica}`, {
						params: { with_address: true },
					}),
					api.get('/plannings/', {
						params: {
							clinicId: idclinica,
							perPage: 9999,
						}
					}),
				]);

				setClinicData(data);
				setPlannings(resPlannings?.plannings?.map(plan => ({
					...plan,
					created_at: new Date(plan.created_at),
					expires_in: new Date(plan.expires_in),
				})));

				setValue('name', data?.name);
				setValue('email', data?.email);
				setValue('phone', data?.phone);
				setValue('responsible', data?.responsible);
				setValue('oldImage', data?.image);
				setPreviewClinicImage(data?.image);
				setValue('address.zipcode', data?.address.zipcode);
				setValue('address.street', data?.address?.street);
				setValue('address.number', data?.address?.number);
				setValue('address.complement', data?.address?.complement);
				setValue('address.neighborhood', data?.address?.neighborhood);
				setValue('address.city', data?.address?.city);
				setValue('address.state', data?.address?.state);
			} catch (err) {
				if (err.response?.status === 401) {
					signOut();
					return;
				}

				addToast(err.response?.data.message ?? 'Erro ao buscar clinica', {
					appearance: 'error',
					autoDismiss: true
				});

				historyPush('/clinicas');
			} finally {
				setLoading(false);
			}
		}

		loadData();
	}, []);

	const handleSearch = useCallback(async (search, _currentTab) => {
		try {
			const [{ data }, { data: resPlannings }] = await Promise.all([
				api.get(`/manager/clinics/${idclinica}`, {
					params: { with_address: true, search },
				}),
				api.get('/plannings/', {
					params: {
						clinicId: idclinica,
						perPage: 9999,
						search,
					}
				})
			]);

			setClinicData(data);
			setPlannings(resPlannings.map(plan => ({
				...plan,
				created_at: new Date(plan.created_at),
				expires_in: new Date(plan.expires_in),
			})));

			setValue('name', data.name);
			setValue('email', data.email);
			setValue('phone', data.phone);
			setValue('responsible', data.responsible);
			setValue('address.zipcode', data.address.zipcode);
			setValue('address.street', data?.address?.street);
			setValue('address.number', data?.address?.number);
			setValue('address.complement', data?.address?.complement);
			setValue('address.neighborhood', data?.address?.neighborhood);
			setValue('address.city', data?.address?.city);
			setValue('address.state', data?.address?.state);
		} catch (err) {
			if (err.response?.status === 401) {
				signOut();
				return;
			}

			addToast(err.response?.data?.message ?? 'Nenhum resultado encontrado.', {
				appearance: 'error',
				autoDismiss: true
			});
		}
	}, []);

	const onSubmit = useCallback(async ({ ...formData }) => {
		setLoading(true);

		try {
			let img = formData.oldImage;
			const image = imageNew;
			if (image) {
				const compressed = await imageCompression(image, {
					maxSizeMB: 1,
					maxWidthOrHeight: 480,
					useWebWorker: true
				});

				img = await toBase64(compressed);
			}

			await api.put(`/manager/clinics/${idclinica}`, {
				...formData,
				image: img,
				address: {
					...formData.address,
					zipcode: formData.address.zipcode.match(RegExp(/[0-9]/g))?.join('')
				}
			});

			addToast('Clinica salva', {
				appearance: 'success',
				autoDismiss: true
			});

			handleSearch();
			setLoading(false);
			setOpen(false);
		} catch (err) {
			if (err.response?.status === 401) {
				signOut();
				return;
			}

			addToast(err.response?.data?.message ?? 'Erro ao salvar a clinica.', {
				appearance: 'error',
				autoDismiss: true
			});
		} finally {
			setLoading(false);
		}
	}, [idclinica, imageNew]);

	const handleDeleteClinic = useCallback(async () => {
		setLoading(true);

		try {
			await api.delete(`/manager/clinics/${idclinica}`);

			addToast('Clinica removida', {
				appearance: 'warning',
				autoDismiss: true
			});

			setLoading(false);
			setOpenModal(false);
			historyPush('/clinicas');
		} catch (err) {
			if (err.response?.status === 401) {
				signOut();
				return;
			}

			addToast(err.response?.data?.message ?? 'Erro ao deletar clinica.', {
				appearance: 'error',
				autoDismiss: true
			});
		} finally {
			setLoading(false);
		}
	}, [idclinica]);

	const onSubmitDentist = useCallback(async ({ ...formData }) => {
		setLoading(true);

		try {
			let img = formData.oldImage;
			const profile_image = imageNew;
			if (profile_image) {
				const compressed = await imageCompression(profile_image, {
					maxSizeMB: 1,
					maxWidthOrHeight: 480,
					useWebWorker: true
				});

				img = await toBase64(compressed);
			}

			await api.post('/manager/dentists', {
				...formData,
				profile_image: img,
				clinic_id: idclinica
			});

			addToast('Dentista salvo', {
				appearance: 'success',
				autoDismiss: true
			});

			handleSearch();
			setLoading(false);
			setAddDentist(false);
			resetAddDentist();
			setPreviewDentistImage(undefined);
		} catch (err) {
			if (err.response?.status === 401) {
				signOut();
				return;
			}

			addToast(err.response?.data?.message ?? 'Erro ao deletar dentista.', {
				appearance: 'error',
				autoDismiss: true
			});
		} finally {
			setLoading(false);
		}
	}, [idclinica, imageNew]);

	const handleCEP = useCallback(async (evt) => {
		const cep = evt.target.value?.match(RegExp(/[0-9]/g))?.join('');

		if (cep?.length !== 8) {
			addToast('CEP inválido', {
				appearance: 'warning',
				autoDismiss: true,
			});
			return;
		}

		try {
			const res = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
			if (!res.ok) throw new Error('CEP inválido');
			const body = await res.json();

			setValue('address.street', body.logradouro);
			setValue('address.complement', body.complemento);
			setValue('address.neighborhood', body.bairro);
			setValue('address.city', body.localidade);
			setValue('address.state', body.uf);
		} catch (err) {
			addToast('CEP inválido', {
				appearance: 'warning',
				autoDismiss: true,
			});
		}
	}, []);

	return (
		<>
			<div className="flex">
				<Menu className="hidden xl:flex" />
				<div className="w-full flex flex-col">
					<Header textHeader={`Clínica | ${clinicData?.name} | ${pageTitle}`} />
					<main className="w-full flex flex-col py-4 px-14">
						<div className="mt-6 shadow-md rounded-4 w-full h-auto flex flex-col md:flex-row px-4 py-2 gap-4 rounded-lg drop-shadow-lg">
							<div
								className={
									classNames(
										'bg-primary rounded-full h-32 w-32 sm:h-20 sm:w-20 md:mr-3 flex self-center justify-self-center',
										'bg-center bg-no-repeat bg-cover',
									)
								}
								style={{ backgroundImage: `url(${clinicData?.image})` }}
							/>
							<div className="flex flex-col w-full flex-1 items-between px-4">
								<p className="font-bold text-2xl self-start">{clinicData?.name}</p>
								<div className="text-lg">
									<p className="self-start">{clinicData?.address?.street}, {clinicData?.address?.number}{clinicData?.address?.complement ? `, ${clinicData?.address?.complement}` : ''} - {clinicData?.address?.neighborhood} - {clinicData?.address?.zipcode}</p>
									<p className="self-start">{clinicData?.address?.city}, {clinicData?.address?.state}</p>
								</div>
							</div>
							<div className="flex flex-col-reverse md:flex-col gap-1">
								<div className="flex place-self-end">
									<button onClick={() => setOpen(true)}>
										<MdOutlineEditNote size={28} className="self-center" />
									</button>
									<button onClick={() => setOpenModal(true)}>
										<AiOutlineClose size={25} className="self-center " />
									</button>
								</div>
							</div>
						</div>

						<div className="flex flex-col-reverse md:flex-row justify-between mt-6" >
							<CounterCards className="" cardList={[
								{
									id: '2',
									title: 'Planejamentos\nPendentes',
									counter: plannings.filter(plan => ['pending', 'doing'].includes(plan.status)).length,
									priColor: getPlanningStatusColor('pending'),
									secColor: getPlanningStatusColor('pending'),
									Icon: BiCalendarEdit,
								},
								{
									id: '1',
									title: 'Planejamentos\nRespondidos',
									counter: plannings.filter(plan => plan.status === 'completed').length,
									priColor: getPlanningStatusColor('completed'),
									secColor: getPlanningStatusColor('completed'),
									Icon: BiCalendarCheck,
								},
								{
									id: '22',
									title: 'Planejamentos\ncom Alterações',
									counter: plannings.filter(plan => plan.status === 'more_info').length,
									priColor: getPlanningStatusColor('more_info'),
									secColor: getPlanningStatusColor('more_info'),
									Icon: BiCalendarEdit,
								},
								{
									id: '3',
									title: 'Total de Dentistas',
									counter: clinicData?.dentists?.length ?? 0,
									priColor: 'bg-admin-red-light',
									secColor: 'bg-admin-red-light',
									Icon: FaUserNurse,
								},
								{
									id: '4',
									title: 'Pacientes Ativos',
									counter: clinicData?.Patients?.filter(p => p.is_active === 1)?.length ?? 0,
									priColor: 'bg-admin-orange-light',
									secColor: 'bg-admin-orange-light',
									Icon: IoIosPeople,
								},
								{
									id: '5',
									title: 'Pacientes Inativos',
									counter: clinicData?.Patients?.filter(p => p.is_active === 0)?.length ?? 0,
									priColor: 'bg-red-500',
									secColor: 'bg-red-500',
									Icon: IoIosPeople,
								},
							]} />

							{buttonType === 'dentista' && (
								<button
									type="button"
									onClick={() => setAddDentist(true)}
									className={classNames(
										'mt-10 py-3 px-8 self-start',
										'text-center text-xl rounded-lg text-admin-bc-white font-bold whitespace-nowrap',
										'transition-all duration-300 bg-primary hover:bg-opacity-70',
									)}
								>
									Adicionar Dentista
								</button>
							)}
						</div>
						<Tab.Group >
							<SearchBar
								getSearch={handleSearch}
								optionsList={[
									{
										id: '1',
										option: 'Planejamentos\nPendentes',
										onClick: () => {
											setPageTitle('Pendentes');
											setButtonType('');
										}
									},
									{
										id: '2',
										option: 'Planejamentos\nRespondidos',
										onClick: () => {
											setPageTitle('Respondidos');
											setButtonType('');
										}
									},
									{
										id: '22',
										option: 'Planejamentos\ncom Alterações',
										onClick: () => {
											setPageTitle('Com alterações');
											setButtonType('');
										}
									},
									{
										id: '3',
										option: 'Dentistas',
										onClick: () => {
											setPageTitle('Dentistas');
											setButtonType('dentista');
										}
									},
									{
										id: '4',
										option: 'Pacientes Ativos',
										onClick: () => {
											setPageTitle('Pacientes Ativos');
											setButtonType('');
										}
									},
									{
										id: '5',
										option: 'Pacientes Inativos',
										onClick: () => {
											setPageTitle('Pacientes Inativos');
											setButtonType('');
										}
									},
								]} />
							<Tab.Panels>
								<Tab.Panel className="grid grid-cols-1 lg:grid-cols-2 gap-3">
									{plannings.filter(plan => ['pending', 'doing'].includes(plan.status)).length <= 0 ? (
										<div className="mt-6 shadow-md rounded-4 w-full h-auto flex flex-col xl:flex-row px-4 py-2 gap-4 rounded-lg drop-shadow-lg">
											<p className='flex self-center place-self-center text-xl font-bold py-6'>Nenhum planejamento encontrado.</p>
										</div>
									) : plannings.filter(plan => ['pending', 'doing'].includes(plan.status)).map((item, index) => (
										<PlanningCard
											key={item.id}
											index={index}
											planning={item}
										/>
									))}
								</Tab.Panel>
								<Tab.Panel className="grid grid-cols-1 lg:grid-cols-2 gap-3">
									{plannings.filter(plan => plan.status === 'completed').length <= 0 ? (
										<div className="mt-6 shadow-md rounded-4 w-full h-auto flex flex-col xl:flex-row px-4 py-2 gap-4 rounded-lg drop-shadow-lg">
											<p className='flex self-center place-self-center text-xl font-bold py-6'>Nenhum planejamento encontrado.</p>
										</div>
									) : plannings.filter(plan => plan.status === 'completed').map((item, index) => (
										<PlanningCard
											key={item.id}
											index={index}
											planning={item}
										/>
									))}
								</Tab.Panel>
								<Tab.Panel className="grid grid-cols-1 lg:grid-cols-2 gap-3">
									{plannings.filter(plan => plan.status === 'more_info').length <= 0 ? (
										<div className="mt-6 shadow-md rounded-4 w-full h-auto flex flex-col xl:flex-row px-4 py-2 gap-4 rounded-lg drop-shadow-lg">
											<p className='flex self-center place-self-center text-xl font-bold py-6'>Nenhum planejamento encontrado.</p>
										</div>
									) : plannings.filter(plan => plan.status === 'more_info').map((item, index) => (
										<PlanningCard
											key={item.id}
											index={index}
											planning={item}
										/>
									))}
								</Tab.Panel>
								<Tab.Panel>
									<ListDentists
										reload={() => handleSearch()}
										list={clinicData?.dentists ?? []}
									/>
								</Tab.Panel>
								<Tab.Panel>
									<ListClinicPatients
										isClinic
										list={clinicData?.Patients?.filter(p => p.is_active === 1)?.map(item => ({
											...item,
											clinic: clinicData.name,
											realizados: item?.Plannings?.filter(plan => plan.status === 'completed').length,
											pendentes: item?.Plannings?.filter(plan => plan.status === 'pending').length,
										})) ?? []}
									/>
								</Tab.Panel>
								<Tab.Panel>
									<ListClinicPatients
										isClinic
										list={clinicData?.Patients?.filter(p => p.is_active === 0)?.map(item => ({
											...item,
											clinic: clinicData.name,
											realizados: item?.Plannings?.filter(plan => plan.status === 'completed').length,
											pendentes: item?.Plannings?.filter(plan => plan.status === 'pending').length,
										})) ?? []}
									/>
								</Tab.Panel>
							</Tab.Panels>
						</Tab.Group>
					</main>
				</div>
			</div>


			<Modal isOpen={openModal} onClose={() => setOpenModal(false)}  >
				<div className="h-full w-full bg-white rounded-2xl overflow-hidden flex flex-col">
					<div className="w-auto h-auto bg-red-500 px-10 py-4 gap-x-28  flex flex-row items-center justify-between">
						<strong className="text-3xl w-full text-white">
							Exluir Clínica
						</strong>
						<button onClick={() => setOpenModal(false)}>
							<IoMdClose size={60} className="flex text-white" />
						</button>
					</div>
					<div className="flex flex-col py-14 gap-y-32 justify-center px-10">
						<p className="text-3xl text-black text-center">
							Você tem certeza que deseja excluir esta clínica?
						</p>
						<div className="flex flex-col md:flex-row gap-6 justify-evenly text-2xl text-white">
							<button
								disabled={loading}
								className={classNames(
									'px-16 py-4  rounded-xl',
									loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-primary'
								)}
								onClick={() => setOpenModal(false)}
							>
								Não
							</button>
							<button
								onClick={handleDeleteClinic}
								disabled={loading}
								className={classNames(
									'px-16 py-4  rounded-xl',
									loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-red-500'
								)}
							>
								{loading ? 'Removendo...' : 'Sim'}
							</button>
						</div>
					</div>
				</div>
			</Modal>
			<HeaderlessSlideOver
				open={open}
				setOpen={setOpen}
				title="Menu"
				className="absolute"
				side="ri"
			>
				<div className="overflow-y-auto max-h-screen h-screen w-auto flex  flex-col  bg-white">
					<div className="flex w-full py-10 flex-col h-24 text-center justify-center bg-primary text-3xl text-white">
						Editar Clínica
					</div>
					<div className=" w-auto flex p-10 flex-col gap-10 bg-white">
						<form onSubmit={handleSubmit(onSubmit)} className="gap-10 flex flex-col">
							<input {...register('oldImage')} type="hidden" name="oldImage" />
							<strong>Clique abaixo para selecionar a imagem da clínica</strong>
							<label
								htmlFor="image"
								className={classNames(
									'w-28 h-28 place-self-center flex p-2 rounded-full border-2 border-primary bg-transparent',
									'bg-center bg-cover bg-no-repeat cursor-pointer'
								)}
								style={{ backgroundImage: `url(${previewClinicImage})` }}
							>
								{!previewClinicImage && (
									<BiBuildingHouse size={65} className="text-primary py-2" />
								)}
								<input
									{...register('image')}
									id="image"
									type="file"
									accept="image/png,image/jpg,image/jpeg,image/webp"
									className="sr-only"
									onChange={evt => {
										const file = evt.currentTarget.files.item(0);
										if (file) {
											const imageUrl = URL.createObjectURL(file);
											setPreviewClinicImage(imageUrl);
											setImage(file);
											return;
										}
										setImage(undefined);
										setPreviewClinicImage(undefined);
									}}
								/>
							</label>
							{errors.image && (
								<small className="text-red-400 self-center">{errors.image.message}</small>
							)}
							<div className="flex gap-4 flex-col">
								<strong>Nome da Clínica:</strong>
								<input {...register('name', { required: 'Campo obrigatório' })} placeholder="Digite aqui o nome da clínica" className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11" />
								{errors.name && (
									<small className="text-red-400">{errors.name.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>Email:</strong>
								<input {...register('email', { required: 'Campo obrigatório' })} type="email" placeholder="Digite aqui o seu Email" className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11" />
								{errors.email && (
									<small className="text-red-400">{errors.email.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>Telefone:</strong>
								<InputMask mask="(99) 99999-9999" {...register('phone', { required: 'Campo obrigatório' })} placeholder="Digite aqui o seu Telefone" className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11" />
								{errors.phone && (
									<small className="text-red-400">{errors.phone.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>Responsável pela clínica:</strong>
								<input {...register('responsible', { required: 'Campo obrigatório' })} placeholder="Digite aqui o nome do responsável" className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11" />
								{errors.responsible && (
									<small className="text-red-400">{errors.responsible.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>Cep:</strong>
								<InputMask
									mask="99999-999"
									{...register('address.zipcode', { required: 'Campo obrigatório' })}
									placeholder="Digite aqui o cep"
									className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11"
									onBlur={handleCEP}
								/>
								{errors.address?.zipcode && (
									<small className="text-red-400">{errors.address?.zipcode.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>Rua:</strong>
								<input {...register('address.street', { required: 'Campo obrigatório' })} placeholder="Digite aqui a rua" className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11" />
								{errors.address?.street && (
									<small className="text-red-400">{errors.address?.street.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>Número:</strong>
								<input {...register('address.number', { required: 'Campo obrigatório' })} type="number" placeholder="Digite aqui a rua" className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11" />
								{errors.address?.number && (
									<small className="text-red-400">{errors.address?.number.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>Complemento:</strong>
								<input {...register('address.complement')} placeholder="Digite aqui o complemento" className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11" />
							</div>
							<div className="flex gap-4 flex-col">
								<strong>Bairro:</strong>
								<input {...register('address.neighborhood', { required: 'Campo obrigatório' })} placeholder="Digite aqui o bairro" className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11" />
								{errors.address?.neighborhood && (
									<small className="text-red-400">{errors.address?.neighborhood.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>Cidade:</strong>
								<input {...register('address.city', { required: 'Campo obrigatório' })} placeholder="Digite aqui a cidade" className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11" />
								{errors.address?.city && (
									<small className="text-red-400">{errors.address?.city.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>UF:</strong>
								<InputMask mask="aa" {...register('address.state', { required: 'Campo obrigatório' })} placeholder="Digite aqui o UF" className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11" />
								{errors.address?.state && (
									<small className="text-red-400">{errors.address?.state.message}</small>
								)}
							</div>
							<button
								type="submit"
								disabled={loading}
								className={classNames(
									'w-full h-14 mt-6 rounded-lg text-3xl text-white',
									loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-primary'
								)}
							>
								{loading ? 'Salvando...' : 'Salvar Clínica'}
							</button>
						</form>
					</div>
				</div>
			</HeaderlessSlideOver>
			<HeaderlessSlideOver
				open={addDentist}
				setOpen={setAddDentist}
				title="Menu"
				className="absolute"
				side="ri"
			>
				<div className="overflow-y-auto max-h-screen h-screen w-auto flex  flex-col  bg-white">
					<div className="flex w-full py-10 flex-col h-24 text-center justify-center bg-primary text-3xl text-white">
						Adicionar Dentista
					</div>
					<div className=" w-auto flex p-10 flex-col gap-10 bg-white">
						<form onSubmit={handleSubmitAddDentist(onSubmitDentist)} className="gap-10 flex flex-col">
							<input {...registerAddDentist('oldImage')} type="hidden" name="oldImage" />
							<strong>Clique abaixo para selecionar a imagem do dentista</strong>
							<label
								htmlFor="profile_image"
								className={classNames(
									'w-28 h-28 place-self-center flex p-2 rounded-full border-2 border-primary bg-transparent',
									'bg-center bg-cover bg-no-repeat cursor-pointer'
								)}
								style={{ backgroundImage: `url(${previewDentistImage})` }}
							>
								{!previewDentistImage && (
									<IoIosPerson size={65} className="text-primary py-2" />
								)}
								<input
									{...registerAddDentist('profile_image', { required: 'Campo obrigatório' })}
									id="profile_image"
									type="file"
									accept="image/png,image/jpg,image/jpeg,image/webp"
									className="sr-only"
									onChange={evt => {
										const file = evt.currentTarget.files.item(0);
										if (file) {
											const imageUrl = URL.createObjectURL(file);
											setPreviewDentistImage(imageUrl);
											setImage(file);
											return;
										}

										setImage(undefined);
										setPreviewDentistImage(undefined);
									}}
								/>
							</label>
							{errorsAddDentist.profile_image && (
								<small className="text-red-400 self-center">{errorsAddDentist.profile_image.message}</small>
							)}
							<div className="flex gap-4 flex-col">
								<strong>Nome:</strong>
								<input
									{...registerAddDentist('name', { required: 'Campo obrigatório' })}
									placeholder="Digite o nome"
									className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11"
								/>
								{errorsAddDentist?.name && (
									<small className="text-red-400">{errorsAddDentist.name.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>E-mail:</strong>
								<input
									{...registerAddDentist('email', { required: 'Campo obrigatório' })}
									type="email"
									placeholder="Digite o e-mail"
									className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11"
								/>
								{errorsAddDentist?.email && (
									<small className="text-red-400">{errorsAddDentist.email.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>Senha:</strong>
								<input
									{...registerAddDentist('password', { required: 'Campo obrigatório' })}
									type="password"
									placeholder="Digite a senha"
									className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11"
								/>
								{errorsAddDentist?.password && (
									<small className="text-red-400">{errorsAddDentist.password.message}</small>
								)}
							</div>
							<button
								type="submit"
								disabled={loading}
								className={classNames(
									'w-full h-14 mt-6 rounded-lg text-3xl text-white',
									loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-primary'
								)}
							>
								{loading ? 'Salvando...' : 'Salvar Dentista'}
							</button>
						</form>
					</div>
				</div>
			</HeaderlessSlideOver>
			<Loader show={loading} />
		</>
	);
}

export default Clinic;
