function imagesToCompare(imagesThumbnail, initialImages, imagesToCompare) {
	const images = {};

	for (const key in imagesThumbnail) {
		const item = imagesThumbnail[key];
		if (typeof item === 'string' && item.match(/\.(jpeg|jpg|gif|png|webp)$/)) {
			images[key] = { thumb: item };
		}
	}

	for (const key in initialImages) {
		const item = initialImages[key];
		if (typeof item === 'string' && item.match(/\.(jpeg|jpg|gif|png|webp)$/)) {
			if (images[key]) {
				images[key].initial = item;
			} else {
				images[key] = { initial: item };
			}
		}
	}

	for (const key in imagesToCompare) {
		const item = imagesToCompare[key];
		if (typeof item === 'string' && item.match(/\.(jpeg|jpg|gif|png|webp)$/)) {
			if (images[key]) {
				images[key].compare = item;
			} else {
				images[key] = { compare: item };
			}
		}
	}

	return Object.values(images);
}

export { imagesToCompare };
