function getImagesFromObject(object = {}) {
	const images = [];

	for (const key in object) {
		const item = object[key];
		if (typeof item === 'string' && item.match(/\.(jpeg|jpg|gif|png|webp)$/)) {
			images.push(item);
		}
	}

	return images;
}

export default getImagesFromObject;
