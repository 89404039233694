import React from 'react';

function CounterCards({ className, cardList }) {
	return (
		<>
			<div className={`flex flex-wrap w-full gap-x-6 ${className}`}>
				{cardList?.filter(c => !!c)?.map(v => (
					<div key={v.id} className="flex justify-center flex-wrap lg:flex-row items-center lg:w-auto my-6 gap-5 lg:gap-16 ">
						<div className={`h-52 lg:w-44 flex-initial ${v.priColor} bg-opacity-60 flex flex-col items-center rounded-xl drop-shadow-2xl gap-3 p-2`}>
							<div className={`h-16 w-20 rounded-xl flex items-center justify-center ${v.secColor}`}>
								<v.Icon size={45} className="text-admin-bc-white " />
							</div>
							<p className="text-admin-bc-white font-bold whitespace-pre-wrap-wrap text-lg text-center">{v.title}</p>
							<p className="text-admin-bc-white font-bold text-2xl">{v.counter}</p>
						</div>
					</div>
				))}
			</div>
		</>
	);
}

export default CounterCards;
