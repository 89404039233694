import React, { useState } from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';

import imgLogo from '../assets/images/logo_zt_header.png';
import { useAuth } from '../contexts/auth';
import classNames from '../utils/classNames';
import HeaderlessSlideOver from './HeadlessSlideOver';
import Menu from './Menu';

function Header({ textHeader }) {
	const [open, setOpen] = useState(false);

	const { user, signOut } = useAuth();
	const { push: historyPush } = useHistory();

	return (
		<div className="h-min w-full flex items-center justify-between py-1 px-4 xl:px-10 bg-gradient-to-r from-admin-grey-light to-admin-bc-white drop-shadow-2xl static">
			<div className=" flex gap-4 items-center justify-center">
				{user?.role === 4 ? null : (
					<button className="w-16 h-16 xl:hidden" onClick={() => setOpen(true)} >
						<AiOutlineMenu size={30} />
					</button>
				)}
				<p className="text-base text-center xl:w-auto xl:text-left w-full font-bold md:text-2xl">
					{textHeader}
				</p>
			</div>
			{user?.role === 4 ? null : (
				<HeaderlessSlideOver
					open={open}
					setOpen={setOpen}
					title="Menu"
					className="absolute"
					closeButtonSide="right"
				>
					<Menu className="flex" />
				</HeaderlessSlideOver>
			)}
			<div className="flex items-center gap-x-4">
				<img loading="lazy" src={imgLogo} className="w-60 mb-0 hidden lg:block " alt="imagem logo zt" />
				{user?.role !== 4 ? null : (
					<button
						type="button"
						className={classNames(
							'w-full px-4 py-2 flex items-center justify-center font-bold text-xl',
							' rounded-md transition-opacity hover:opacity-70'
						)}
						onClick={async () => {
							await signOut();
							historyPush('/');
						}}
					>
						Sair
					</button>
				)}
			</div>
		</div >
	);
}
export default Header;
