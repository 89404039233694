import React from 'react';

import imgLogo from '../assets/images/logo_zt_header.png';
import classNames from '../utils/classNames';
// import Modal from './Modal';

function Loader({ show, fullScreen = true }) {
	return !show ? null : (
		// <Modal isOpen={show} onClose={() => { }} overlayClassName="bg-white bg-opacity-100">
		<div className={classNames(
			fullScreen
				? 'fixed inset-0 z-50 grid place-content-center overflow-hidden bg-white bg-opacity-100'
				: ''
		)}>
			<div className="flex flex-col w-full max-w-xs bg-white p-4 rounded-md animate-pulse">
				<img loading="lazy" src={imgLogo} className="w-full" alt="imagem logo zt" />
				<strong className="text-xl mx-auto mt-4 text-gray-600">Carregando...</strong>
			</div>
		</div>
		// </Modal>
	);
}

export default Loader;
