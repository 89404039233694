import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import PlanningSelect from '../../components/PlanningSelect';
import classNames from '../../utils/classNames';

function Step3({ selected, questions, defaultValues, sending, onNext, onBack }) {
	const { register, handleSubmit, control, setValue, formState: { errors } } = useForm({ defaultValues });

	const onSubmit = useCallback(data => {
		const pageAnswers = JSON.parse(JSON.stringify({
			assimetria_plano_oclusal: data.assimetria_plano_oclusal,
			dentes_supranumerarios: data.dentes_supranumerarios,
			mesio_inclinacao_dentes: data.mesio_inclinacao_dentes,
			extrusao_dentes: data.extrusao_dentes,
			discrepancia_volume_dentario: data.discrepancia_volume_dentario,
			recessoes_gengivais_dentes: data.recessoes_gengivais_dentes,
			diastemas_entre_dentes: data.diastemas_entre_dentes,
		}));
		onNext(pageAnswers);
	}, [selected, questions, onNext]);

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={`${selected === 3 ? '' : 'hidden'}`}>
			<div className="grid md:grid-cols-2 grid-cols-1 mt-6 text-2xl gap-y-20 justify-items-center">
				<PlanningSelect
					control={control}
					register={register}
					name="assimetria_plano_oclusal"
					defaultComplement={defaultValues['assimetria_plano_oclusal']}
					questions={questions['Assimetria de Plano Oclusal']}
					label="Assimetria de Plano Oclusal"
					errors={errors}
					setValue={setValue}
					isRequired='Campo Obrigatório'
				/>

				<div className="w-full flex flex-col py-4 px-14 gap-4">
					<strong>Dentes Supranumerários</strong>
					<input
						{...register('dentes_supranumerarios.question')}
						type="hidden"
						defaultValue="Dentes Supranumerários"
					/>
					<textarea
						{...register('dentes_supranumerarios.answer')}
						className="border-2 border-gray-300 rounded-md p-1"
					>
					</textarea>
					{errors.dentes_supranumerarios?.answer && (
						<small className="text-red-400">{errors.dentes_supranumerarios?.answer.message}</small>
					)}
				</div>

				<div className="w-full flex flex-col py-4 px-14 gap-4">
					<strong>Mesio Inclinação dos Dentes</strong>
					<input
						{...register('mesio_inclinacao_dentes.question')}
						type="hidden"
						defaultValue="Mesio Inclinação dos Dentes"
					/>
					<textarea
						{...register('mesio_inclinacao_dentes.answer')}
						className="border-2 border-gray-300 rounded-md p-1"
					>
					</textarea>
					{errors.mesio_inclinacao_dentes?.answer && (
						<small className="text-red-400">{errors.mesio_inclinacao_dentes?.answer.message}</small>
					)}
				</div>

				<div className="w-full flex flex-col py-4 px-14 gap-4">
					<strong>Extrusão dentária dos dentes</strong>
					<input
						{...register('extrusao_dentes.question')}
						type="hidden"
						defaultValue="Extrusão Dentária dos Dentes"
					/>
					<textarea
						{...register('extrusao_dentes.answer')}
						className="border-2 border-gray-300 rounded-md p-1"
					>
					</textarea>
					{errors.extrusao_dentes?.answer && (
						<small className="text-red-400">{errors.extrusao_dentes?.answer.message}</small>
					)}
				</div>

				<div className="w-full flex flex-col py-4 px-14 gap-4">
					<strong>Discrepância de Volume Dentário</strong>
					<input
						{...register('discrepancia_volume_dentario.question')}
						type="hidden"
						defaultValue="Discrepância de Volume Dentário"
					/>
					<textarea
						{...register('discrepancia_volume_dentario.answer')}
						className="border-2 border-gray-300 rounded-md p-1"
					>
					</textarea>
					{errors.discrepancia_volume_dentario?.answer && (
						<small className="text-red-400">{errors.discrepancia_volume_dentario?.answer.message}</small>
					)}
				</div>

				<div className="w-full flex flex-col py-4 px-14 gap-4">
					<strong>Recessões Gengivais dos Dentes</strong>
					<input
						{...register('recessoes_gengivais_dentes.question')}
						type="hidden"
						defaultValue="Recessões Gengivais dos Dentes"
					/>
					<textarea
						{...register('recessoes_gengivais_dentes.answer')}
						className="border-2 border-gray-300 rounded-md p-1"
					>
					</textarea>
					{errors.recessoes_gengivais_dentes?.answer && (
						<small className="text-red-400">{errors.recessoes_gengivais_dentes?.answer.message}</small>
					)}
				</div>

				<div className="w-full flex flex-col py-4 px-14 gap-4">
					<strong>Diastemas Entre os Dentes</strong>
					<input
						{...register('diastemas_entre_dentes.question')}
						type="hidden"
						defaultValue="Diastemas Entre os Dentes"
					/>
					<textarea
						{...register('diastemas_entre_dentes.answer')}
						className="border-2 border-gray-300 rounded-md p-1"
					>
					</textarea>
					{errors.diastemas_entre_dentes?.answer && (
						<small className="text-red-400">{errors.diastemas_entre_dentes?.answer.message}</small>
					)}
				</div>
			</div>
			<div className="flex md:flex-row flex-col justify-center gap-x-10">
				<button
					type="button"
					onClick={onBack}
					className={classNames(
						'mt-10 py-1 px-36 self-center border-2 border-primary-dark text-primary-dark',
						'text-center text-xl lg:text-2xl rounded-lg font-bold',
						'transition-colors duration-300 hover:bg-primary-dark hover:text-white'
					)}
				>
					Voltar
				</button>
				<button
					type="submit"
					disabled={sending}
					className={classNames(
						'mt-10 py-2 px-36 self-center',
						'text-center text-xl lg:text-2xl rounded-lg text-admin-bc-white font-bold',
						'transition-colors duration-300',
						sending ? 'bg-gray-400 cursor-wait' : 'bg-primary-dark hover:bg-primary'
					)}
				>
					Próximo
				</button>
			</div>
		</form>
	);
}

export default Step3;
