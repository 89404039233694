import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { FaArrowRight } from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import * as yup from 'yup';

import bgImage from '../assets/images/bg-orto.jpg';
import imgLogo from '../assets/images/logo-orto-branco.png';
import { useAuth } from '../contexts/auth';
import classNames from '../utils/classNames';

const schema = yup.object().shape({
	email: yup.string().email('Email inválido').required('Email é obrigatório'),
	password: yup.string().required('Senha é obrigatório'),
});

function Login() {
	const [loading, setLoading] = useState(false);

	const history = useHistory();
	const { addToast } = useToasts();
	const { user, signIn } = useAuth();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({ resolver: yupResolver(schema) });

	useEffect(() => {
		if (user) {
			if (user.role === 1 || user.role === 3) {
				history.push('/planejamentos');
			} else if (user.role === 2) {
				history.push('/pacientes');
			} else if (user.role === 4) {
				history.push('/validacao');
			}
		}
	}, []);

	const onSubmit = async (data) => {
		setLoading(true);

		try {
			const loggedUser = await signIn(data);
			let message = 'Login realizado com sucesso!';

			if (loggedUser.role === 1) {
				message = 'Você logou como gestor';
			}
			if (loggedUser.role === 2) {
				message = 'Você logou como dentista';
			}
			if (loggedUser.role === 3) {
				message = 'Você logou como consultor';
			}
			if (loggedUser.role === 4) {
				message = 'Você logou como paciente';
			}

			history.push('/planejamentos');

			addToast(message, {
				appearance: 'success',
				autoDismiss: true,
			});
		} catch (err) {
			console.log(err);
			addToast(
				err.response?.data?.message || 'Ocorreu um erro ao realizar o login',
				{
					appearance: 'error',
					autoDismiss: true,
				}
			);
		} finally {
			setLoading(false);
		}
	};

	return (
		<main className="min-h-screen flex items-center justify-center">
			<div
				className="flex-1 h-screen hidden md:flex items-center justify-end"
				style={{
					backgroundImage: `url(${bgImage})`,
					backgroundPosition: 'center',
					backgroundSize: 'cover',
					borderRadius: '0 60px 60px 0',
				}}
			>
				<img
					loading="lazy"
					src={imgLogo}
					className="w-3/5 mx-24"
					alt="imagem logo zt"
				/>
			</div>
			<div className={classNames(
				'flex-1 flex items-center h-screen bg-white',
				'px-6 text-gray-600',
			)}>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className="mx-auto md:mx-24 max-w-md w-full"
				>
					<h1 className="text-4xl font-extrabold mb-4">
						Faça seu login.
					</h1>
					<p className="mb-8 text-lg">
						Basta preencher seus dados abaixo
					</p>
					<div className="mb-8">
						<p className="font-bold text-xl mb-2">E-mail</p>
						<input
							{...register('email')}
							placeholder="email@email.com"
							className="border rounded-xl px-4 py-5 w-full text-xl"
						/>
						{errors.email && (
							<small className="text-admin-bc-red">
								{errors.email.message}
							</small>
						)}
					</div>
					<div className="">
						<div className="flex justify-between text-xl mb-2">
							<p className="font-bold">Senha</p>
							<Link
								className="block text-right text-primary text-xl"
								to="/recuperar-senha"
							>
								Esqueceu a senha?
							</Link>
						</div>
						<input
							type="password"
							{...register('password')}
							placeholder="•••••••••••••••"
							className="border rounded-xl px-4 py-5 w-full text-xl"
						/>
						{errors.password && (
							<small className="text-admin-bc-red">
								{errors.password.message}
							</small>
						)}
					</div>
					<label htmlFor="keep-logged" className="mt-4 flex items-center gap-x-2">
						<input id="keep-logged" type="checkbox" defaultChecked className="" />
						<span className="select-none">Manter conectado</span>
					</label>
					<button
						type="submit"
						disabled={loading}
						className={classNames(
							'mt-4 px-8 rounded-lg h-16 w-full text-xl',
							'bg-primary text-white flex justify-between items-center',
							loading ? 'cursor-not-allowed' : null,
						)}
					>
						<span>{loading ? 'ENTRANDO...' : 'ENTRAR'}</span>
						{loading ? <AiOutlineLoading3Quarters className="animate-spin" /> : <FaArrowRight />}
					</button>
					{/* <Link
						className="block text-center mt-8 text-xl"
						to="/termos-de-uso"
						target="_blank"
					>
						Termos de uso
					</Link>
					<Link
						className="block text-center mt-4 text-xl"
						to="/politica-de-privacidade"
					>
						Política de privacidade
					</Link> */}
				</form>
			</div>
		</main>
	);
}

export default Login;
