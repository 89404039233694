import imageCompression from 'browser-image-compression';
import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useFieldArray, useForm } from 'react-hook-form';
import { MdUpload } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import Header from '../../components/Header';
import Loader from '../../components/Loader';
import Menu from '../../components/Menu';
import NewFileInputs from '../../components/videoaulas/NewFileInputs';
import NewVideoInputs from '../../components/videoaulas/NewVideoInputs';
import { useAuth } from '../../contexts/auth';
import api from '../../services/api';
import classNames from '../../utils/classNames';
import toBase64 from '../../utils/toBase64';
import wait from '../../utils/wait';

// const reorder = (list, startIndex, endIndex) => {
// 	const result = Array.from(list);
// 	const [removed] = result.splice(startIndex, 1);
// 	result.splice(endIndex, 0, removed);

// 	return result;
// };

function NewVideo() {
	const [loading, setLoading] = useState(false);
	const [progressBar, setProgressBar] = useState(0);
	const [thumbPreview, setThumbPreview] = useState();
	const [titleChars, setTitleChars] = useState(0);
	const [image, setImage] = useState();
	const [videosPreview, setVideosPreview] = useState([]);
	const [filesPreview, setFilesPreview] = useState([]);

	const { signOut } = useAuth();
	const {
		control,
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm();
	const {
		fields: fieldsVideo,
		append: appendVideo,
		remove: removeVideo,
		move: moveVideo
	} = useFieldArray({
		control,
		name: 'videos',
	});
	const {
		fields: fieldsFile,
		append: appendFile,
		remove: removeFile,
	} = useFieldArray({
		control,
		name: 'files',
	});
	const history = useHistory();
	const { id_module } = useParams();
	const { addToast } = useToasts();

	useEffect(() => {
		async function loadVideo() {
			setLoading(true);
			try {
				const { data } = await api.get(`/manager/videos/${id_module}`);

				setValue('title', data.title);
				setValue('description', data.description);
				setValue('category', data.category);
				setValue('oldThumb', data.thumbnail);
				let tempVideos = [];
				data.videos.forEach((video) => {
					tempVideos.push({ title: video.title, video: video.url });
					appendVideo({ title: video.title, video: video.url });
				});
				let tempFiles = [];
				data.files?.forEach((file) => {
					tempFiles.push({ title: file.title, url: file.url });
					appendFile({ title: file.title, file: file.url });
				});
				setVideosPreview(tempVideos);
				setFilesPreview(tempFiles);
				setThumbPreview(data.thumbnail);
			} catch (err) {
				if (err.response?.status === 401) {
					addToast('Você não tem permissão para acessar esta página.', {
						appearance: 'error',
						autoDismiss: true,
					});

					signOut();
				}

				addToast(err.response?.data?.message ?? 'Essa videoaula não existe', {
					appearance: 'error',
					autoDismiss: true,
				});

				await wait(4000);

				history.goBack();
			} finally {
				setLoading(false);
			}
		}

		if (id_module) {
			loadVideo();
		}
	}, []);

	function onDragEnd(result) {
		if (!result.destination) {
			return;
		}

		moveVideo(result.source.index, result.destination.index);
	}

	const onSubmit = useCallback(
		async (data) => {
			console.log(data);
			if (data.videos.length < 1) {
				addToast('Adicione pelo menos 1 video', {
					appearance: 'error',
					autoDismiss: true,
				});
				return;
			}

			setLoading(true);
			const token = api.defaults.headers.common['Authorization'];

			try {
				let videos = [];
				for (let i = 0; i < data.videos.length; i++) {
					const currentVideo = data.videos[i];
					if (currentVideo) {
						if (typeof currentVideo.video === 'string') {
							videos.push({
								title: currentVideo.title,
								url: currentVideo.video,
								order: i,
							});
							continue;
						}

						const video = currentVideo.video.item(0);

						if (!video && videosPreview[i]) {
							videos.push({
								title: currentVideo.title,
								url: videosPreview[i].video,
								order: i,
							});
							continue;
						}

						const { data: permissionData } = await api.post(
							'/manager/permission-upload',
							{
								contentType: video?.type,
							}
						);

						delete api.defaults.headers.common['Authorization'];

						await api.put(permissionData.url, video, {
							headers: {
								'Content-Type': video.type,
								'Content-Length': video.size,
							},
							onUploadProgress: (progressEvent) =>
								setProgressBar(() =>
									Math.round((progressEvent.loaded * 94) / progressEvent.total)
								),
							timeout: 900000,
						});

						// BUG: para mandar arquivo para o S3, tem que remover completamente o header "Authorization", se não volta o erro 400.
						api.defaults.headers.common['Authorization'] = token;
						videos.push({
							title: currentVideo.title,
							url: permissionData.urlView,
							order: i,
						});
					}
				}

				let files = [];
				for (let i = 0; i < data.files.length; i++) {
					const currentFile = data.files[i];
					if (currentFile) {
						if (typeof currentFile.file === 'string') {
							files.push({
								title: currentFile.title,
								url: currentFile.file,
								order: i,
							});
							continue;
						}

						const file = currentFile.file.item(0);

						if (!file && filesPreview[i]) {
							files.push({
								title: currentFile.title,
								url: filesPreview[i].file,
								order: i,
							});
							continue;
						}

						const { data: permissionData } = await api.post(
							'/manager/permission-upload',
							{
								contentType: file.type,
								name: file.name,
							}
						);

						delete api.defaults.headers.common['Authorization'];

						await api.put(permissionData.url, file, {
							headers: {
								'Content-Type': file.type,
								'Content-Length': file.size,
							},
							onUploadProgress: (progressEvent) =>
								setProgressBar(() =>
									Math.round((progressEvent.loaded * 94) / progressEvent.total)
								),
							timeout: 900000,
						});

						// BUG: para mandar arquivo para o S3, tem que remover completamente o header "Authorization", se não volta o erro 400.
						api.defaults.headers.common['Authorization'] = token;
						files.push({
							title: currentFile.title,
							url: permissionData.urlView,
							order: i,
						});
					}
				}

				let thumbnail = data.oldThumb;
				if (image) {
					setProgressBar(95);
					const compressed = await imageCompression(image, {
						maxSizeMB: 1,
						maxWidthOrHeight: 1920,
						useWebWorker: true,
					});
					thumbnail = await toBase64(compressed);
					setProgressBar(97);
				}

				if (id_module) {
					await api.put(`/manager/videos/${id_module}`, {
						...data,
						videos,
						files,
						thumbnail,
					});
				} else {
					await api.post('/manager/videos/', {
						...data,
						videos,
						files,
						thumbnail,
					});
				}

				addToast('Curso salvo com sucesso!', {
					appearance: 'success',
					autoDismiss: true,
				});

				setProgressBar(100);
				await wait(4000);

				history.push('/videoaulas');
			} catch (err) {
				addToast(err.response?.data?.message ?? 'Erro ao salvar videoaula', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				api.defaults.headers.common['Authorization'] = token;
				setLoading(false);
			}
		},
		[id_module, image, videosPreview]
	);

	return (
		<>
			<div className="flex">
				<Menu className="hidden xl:flex" />
				<div className=" w-full flex flex-col">
					<Header textHeader="Vídeoaulas | Adicionar módulo" />
					<form
						onSubmit={handleSubmit(onSubmit)}
						className="w-full flex flex-col  py-4 px-14 gap-4"
					>
						{id_module && (
							<>
								<input type="hidden" {...register('oldThumb')} />
							</>
						)}
						<div className="flex flex-col lg:flex-row gap-4 text-3xl">
							<div className="flex flex-col items-center gap-4 w-full">
								<strong className="">Selecione uma capa:</strong>
								<label
									htmlFor="thumbnail"
									className={classNames(
										'h-80 w-full cursor-pointer lg:max-w-2xl rounded-lg border-gray-600 bg-gray-100 flex justify-center items-center',
										'bg-center bg-cover bg-no-repeat'
									)}
									style={
										thumbPreview
											? { backgroundImage: `url(${thumbPreview})` }
											: undefined
									}
								>
									<input
										{...register('thumbnail', {
											required: thumbPreview ? false : 'Campo obrigatório',
										})}
										id="thumbnail"
										type="file"
										accept="image/png,image/jpg,image/jpeg,image/webp"
										className="sr-only"
										onChange={(event) => {
											const file = event.target.files.item(0);
											if (file) {
												const url = URL.createObjectURL(file);
												setThumbPreview(url);
												setImage(file);
												// URL.revokeObjectURL(url);
											} else {
												setImage(undefined);
												setThumbPreview(undefined);
											}
										}}
									/>
									{!thumbPreview && (
										<MdUpload size={40} className="text-gray-500" />
									)}
								</label>
								{errors.thumbnail && (
									<small className="text-red-500 text-sm">
										{errors.thumbnail.message}
									</small>
								)}
							</div>
						</div>
						<div className="grid grid-cols-full gap-6 text-2xl">
							<div className="col-span-full sm:col-span-1 flex flex-col gap-4">
								<strong className="">
									Nome do módulo:{' '}
									<small>
										({titleChars} caractere{titleChars !== 1 ? 's' : ''})
									</small>
								</strong>
								<input
									{...register('title', { required: 'Campo obrigatório' })}
									placeholder="Digite aqui o nome do módulo"
									className="w-full py-2 rounded-lg border px-3"
									onChange={(evt) => {
										const val = evt.target.value;
										if (val.length > 100) {
											evt.target.value = val.substring(0, 100);
										}

										setTitleChars(evt.target.value.length);
									}}
								/>
								{errors.title && (
									<small className="text-red-500 text-sm">
										{errors.title.message}
									</small>
								)}
							</div>
							<div className="col-span-full flex flex-col gap-4">
								<strong className="">Descrição do vídeo:</strong>
								<textarea
									{...register('description', {
										required: 'Campo obrigatório',
									})}
									rows={6}
									placeholder="Digite aqui a descrição do vídeo"
									className="border border-solid border-gray-400 rounded-lg p-2 flex justify-center items-center"
								/>
							</div>
							{errors.description && (
								<small className="text-red-500 text-sm">
									{errors.description.message}
								</small>
							)}
						</div>
						{/* Divider */}
						<div className="grid-cols-full h-0.5 bg-gray-400 my-8"></div>
						{/* Divider */}
						<div className="grid-cols-full flex flex-col gap-y-4">
							<div className="flex-1 flex items-center justify-between">
								<h3 className="text-2xl">Arquivos</h3>
								<button
									type="button"
									onClick={() => appendFile({ url: '' })}
									className={classNames(
										'h-16 px-3 w-48 text-2xl leading-5',
										'rounded-lg text-admin-bc-white',
										'bg-primary cursor-pointer'
									)}
								>
									Adicionar arquivo
								</button>
							</div>
							<div className="flex flex-wrap gap-8">
								{fieldsFile.map((field, index) => (
									<div key={field.id} className="my-4 bg-gray-100">
										<NewFileInputs
											key={field.id}
											index={index}
											register={register}
											remove={() => {
												removeFile(index);
												setFilesPreview(filesPreview.slice(index, 1));
											}}
											error={errors.files?.[index]}
											preview={filesPreview[index]?.url}
										/>
									</div>
								))}
							</div>
						</div>
						{/* Divider */}
						<div className="grid-cols-full h-0.5 bg-gray-400 my-8"></div>
						{/* Divider */}
						<div className="grid-cols-full flex flex-col gap-y-4">
							<div className="flex-1 flex items-center justify-between">
								<h3 className="text-2xl">Videos</h3>
								<button
									type="button"
									onClick={() => appendVideo({ title: '', video: '' })}
									className={classNames(
										'h-16 px-3 w-48 text-2xl leading-5',
										'rounded-lg text-admin-bc-white',
										'bg-primary cursor-pointer'
									)}
								>
									Adicionar video
								</button>
							</div>
							<DragDropContext onDragEnd={onDragEnd}>
								<Droppable droppableId="droppable">
									{(provided) => (
										<div
											{...provided.droppableProps}
											ref={provided.innerRef}
											className="w-full"
										>
											{fieldsVideo.map((field, index) => (
												<Draggable
													key={field.id}
													draggableId={`${field.id}`}
													index={index}
												>
													{(provided, snapshot) => (
														<div
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															style={provided.draggableProps.style}
															className={classNames(
																'my-4',
																snapshot.isDragging
																	? 'bg-primary text-white'
																	: 'bg-gray-100 text-black'
															)}
														>
															<NewVideoInputs
																key={field.id}
																index={index}
																register={register}
																remove={() => {
																	removeVideo(index);
																	setVideosPreview(videosPreview.slice(index, 1));
																}}
																error={errors.videos?.[index]}
																preview={videosPreview[index]?.video}
															/>
														</div>
													)}
												</Draggable>
											))}
										</div>
									)}
								</Droppable>
							</DragDropContext>
						</div>
						<button
							type="submit"
							disabled={loading}
							className={classNames(
								'self-center',
								'lg:w-80 h-14 lg:h-14 px-3 w-48',
								'flex items-center justify-center gap-x-4 text-center text-xl lg:text-2xl',
								'rounded-lg text-admin-bc-white font-bold',
								loading
									? 'bg-gray-500 cursor-not-allowed'
									: 'bg-primary cursor-pointer'
							)}
						>
							<span>Salvar módulo</span>
							{loading && <span>{progressBar}%</span>}
						</button>
					</form>
				</div>
			</div>
			<Loader show={loading} />
		</>
	);
}

export default NewVideo;
