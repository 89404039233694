import imageCompression from 'browser-image-compression';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { useToasts } from 'react-toast-notifications';

import dentesBaixo from '../assets/images/dentesBaixo.png';
import dentesCima from '../assets/images/dentesCima.png';
import dentesDireita from '../assets/images/dentesDireita.png';
import dentesEsquerda from '../assets/images/dentesEsquerda.png';
import dentesFrente from '../assets/images/dentesFrente.png';
import fotoLateral from '../assets/images/fotoLateral.png';
import fotoSeria from '../assets/images/fotoSeria.png';
import fotoSorrindo from '../assets/images/fotoSorrindo.png';
import raioxLateral from '../assets/images/raioxLateral.png';
import raioxPanoramico from '../assets/images/raioxPanoramico.png';
import Header from '../components/Header';
import ImageInput from '../components/ImageInput';
import Loader from '../components/Loader';
import Menu from '../components/Menu';
import Modal from '../components/Modal';
import { useAuth } from '../contexts/auth';
import api from '../services/api';
import classNames from '../utils/classNames';
import toBase64 from '../utils/toBase64';

function RequestPlanning() {
	const [selected, setSelected] = useState(1);
	const [openModal, setOpenModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loadingContent, setLoadingContent] = useState(false);
	const [images, setImages] = useState({});
	const [patients, setPatients] = useState([]);
	const [selectedPatient, setSelectedPatient] = useState(null);

	const { id_planejamento } = useParams();
	const { search } = useLocation();
	const { register, handleSubmit } = useForm();
	const {
		register: registerImage,
		handleSubmit: handleSubmitImage,
		formState: { errors: errorsImage }
	} = useForm({ defaultValues: { ...images } });
	const { signOut } = useAuth();
	const { addToast } = useToasts();
	const { push: pushHistory } = useHistory();

	useEffect(() => {
		async function loadDataPlanning() {
			setLoading(true);

			try {
				const { data } = await api.get(`/plannings/${id_planejamento}`);

				setSelectedPatient(data.patient);
				setImages({
					faceFrontal: data?.faceFrontal,
					faceLateral: data?.faceLateral,
					faceSorrindo: data?.faceSorrindo,
					dentesBaixo: data?.dentesBaixo,
					dentesCima: data?.dentesCima,
					dentesDireita: data?.dentesDireita,
					dentesEsquerda: data?.dentesEsquerda,
					dentesFrontal: data?.dentesFrontal,
					raioxLateral: data?.raioxLateral,
					raioxPanoramico: data?.raioxPanoramico,
					optional1: data?.optional1,
					optional2: data?.optional2,
				});
				setSelected(2);
			} catch (err) {
				if (err.response?.status === 401) {
					addToast('Voce não tem permissão para acessar essa página', { appearance: 'error', autoDismiss: true });
					signOut();
					pushHistory('/');
					return;
				}

				addToast('Ocorreu um erro ao carregar os dados', { appearance: 'error', autoDismiss: true });
				pushHistory(`/planejamento/${id_planejamento}`);
				return;
			} finally {
				setLoading(false);
			}
		}

		async function loadDataPatient(idPatient) {
			setLoading(true);

			try {
				const { data } = await api.get(`/dentist/get-patients/${idPatient}`);
				setSelectedPatient({
					...data,
					dentist: {
						user: {
							...data.dentist
						}
					}
				});
				setSelected(2);
			} catch (err) {
				if (err.response?.status === 401) {
					addToast('Voce não tem permissão para acessar essa página', { appearance: 'error', autoDismiss: true });
					signOut();
					pushHistory('/');
					return;
				}

				addToast('Ocorreu um erro ao carregar o paciente', { appearance: 'error', autoDismiss: true });
			} finally {
				setLoading(false);
			}
		}

		const promises = [];

		if (id_planejamento) {
			promises.push(loadDataPlanning());
		}

		const idPatient = new URLSearchParams(search).get('id_patient');
		if (idPatient) {
			promises.push(loadDataPatient(idPatient));
		}

		setLoadingContent(true);
		Promise.all(promises).then(() => setLoadingContent(false));
	}, []);

	const onSubmitImages = useCallback(async (data) => {
		// setImages((old) => ({ ...old, ...data }));
		setSelected(3);
	}, [images]);

	const onSubmit = useCallback(async (data) => {
		setLoading(true);

		try {
			let imagesBase64 = {};
			for (const key in images) {
				const file = images[key];

				if (file) {
					if (typeof file === 'string') {
						imagesBase64[key] = file;
						continue;
					}

					const compressed = await imageCompression(file, {
						maxSizeMB: 1,
						maxWidthOrHeight: 1920,
						useWebWorker: true
					});
					const b64 = await toBase64(compressed);
					imagesBase64[key] = b64;
				} else {
					imagesBase64[key] = null;
				}
			}

			const values = {
				...data,
				...imagesBase64,
				dentist_id: selectedPatient.dentist.user.id,
				patient_id: selectedPatient.id
			};

			if (id_planejamento) {
				await api.put(`/plannings/answer-more-info/${id_planejamento}`, values);
				addToast('Planejamento atualizado com sucesso', { appearance: 'success', autoDismiss: true });
				await new Promise((resolve) => setTimeout(resolve, 2000));
				pushHistory(`/planejamento/${id_planejamento}`);
			} else {
				await api.post('/plannings', values);
				setOpenModal(true);
				addToast('Plano salvo com sucesso', { appearance: 'success', autoDismiss: true });
			}
		} catch (error) {
			addToast(
				error.response?.data?.message ?? 'Ocorreu um erro ao salvar o plano',
				{
					appearance: 'error',
					autoDismiss: true
				}
			);
		} finally {
			setLoading(false);
		}
	}, [images, selectedPatient]);

	const retrievePatients = useCallback(async (searchParam) => {
		try {
			const { data } = await api.get('/dentist/get-patients', {
				params: { search: searchParam }
			});

			setPatients(data.active);
			return data.active.map((patient, index) => ({
				value: index,
				label: patient.name,
			}));
		} catch (err) {
			if (err.response?.status === 401) {
				addToast('Sessão expirada, faça login novamente', {
					appearance: 'warning',
					autoDismiss: true,
				});
				signOut();
			}

			setPatients([]);
			return [];
		}
	}, []);

	return (
		<>
			{!loadingContent && (
				<div className="flex">
					<Menu className="hidden xl:flex" />
					<div className="w-full flex flex-col">
						<Header textHeader={'Planejamentos | Solicitar Planejamento'} />
						<main className="w-full flex flex-col py-4 md:px-14 px-2">
							{selected !== 1 && (
								<div className="mt-6 shadow-md min-w-full rounded-4 w-full h-auto flex flex-col xl:flex-row px-4 py-5 gap-4 rounded-lg drop-shadow-lg">
									<div
										className="w-40 h-40 place-self-center flex p-2 rounded-full border-2 border-primary bg-cover bg-center"
										style={{ backgroundImage: `url(${selectedPatient?.profile_image})` }}
									/>
									<div className="flex flex-col gap-4 text-2xl w-full flex-1 items-between px-4">
										<p className="font-bold self-start">{selectedPatient?.name}</p>
										<div className="flex gap-2">
											<p className="">Sexo:</p>
											<p>{selectedPatient?.gender}</p>
										</div>
										<div className="flex gap-2">
											<p className="">Idade:</p>
											<p>{new Date(selectedPatient?.birth_date).toLocaleDateString()}</p>
										</div>
										<div className="flex gap-2">
											<p className="">Dentista Responsável:</p>
											<p>{selectedPatient?.dentist?.user?.name}</p>
										</div>
									</div>
									<div className="flex flex-col text-2xl  justify-evenly gap-1 px-4">
										<div className="flex gap-2">
											<p className="">Linha média:</p>
											<p>{selectedPatient?.upper_middle_line}</p>
										</div>
										<div className="flex gap-2">
											<p className="">Queixa principal:</p>
											<p>{selectedPatient?.complaint}</p>
										</div>
									</div>
								</div>
							)}
							<div className="grid grid-cols-1 relative mt-6 drop-shadow-2xl justify">
								<div className="w-9/12 justify-self-center mt-5 h-0.5 bg-primary-dark absolute" />
								<div className="grid grid-cols-4 justify-items-center z-10 text-xl">
									<div className="h-auto w-auto flex flex-col ">
										<button
											className={`w-10 h-10 self-center rounded-full border-2 border-primary-dark ${selected >= 1 ? 'bg-primary-dark' : ' bg-white cursor-default'}`}
											onClick={() => {
												if (selected >= 1) {
													setSelected(1);
												}
											}}
										></button>
										<p className="mt-4">Buscar paciente</p>
									</div>
									<div className="h-auto w-auto flex flex-col">
										<button
											className={`w-10 h-10 self-center rounded-full border-2 border-primary-dark ${selected >= 2 ? 'bg-primary-dark' : ' bg-white cursor-default'}`}
											onClick={() => {
												if (selected >= 2) {
													setSelected(2);
												}
											}}
										></button>
										<p className="mt-4">Adicionar Fotos</p>
									</div>
									<div className="h-auto w-auto flex flex-col">
										<button
											className={`w-10 h-10 self-center rounded-full border-2 border-primary-dark ${selected >= 3 ? 'bg-primary-dark' : ' bg-white cursor-default'}`}
											onClick={() => {
												if (selected >= 3) {
													setSelected(3);
												}
											}}
										></button>
										<p className="mt-4">Adicionar Observações</p>
									</div>
									<div className="h-auto w-auto flex flex-col">
										<button
											className={`w-10 h-10 self-center rounded-full border-2 border-primary-dark ${selected >= 4 ? 'bg-primary-dark' : ' bg-white cursor-default'}`}
											onClick={() => {
												if (selected >= 4) {
													setSelected(4);
												}
											}}
										></button>
										<p className="mt-4">Confirmação de envio</p>
									</div>
								</div>
							</div>
							{/* ------------- */}
							<div className={`${selected === 1 ? '' : 'hidden'}`}>
								<div className="flex flex-col justify-center mt-20">
									<div className="relative w-full md:w-1/2 p-2 mx-auto">
										<AsyncSelect
											placeholder="Buscar paciente"
											loadOptions={retrievePatients}
											onChange={({ value }) => {
												setSelectedPatient(patients[value]);
											}}
											styles={{
												control: (provided) => ({
													...provided,
													borderColor: '#1E4E7B',
													borderRadius: 8,
													borderWidth: '1.5px',
													fontSize: '1.4rem',
												}),
												loadingIndicator: (base) => ({
													...base,
													color: '#1E4E7B',
												})
											}}
										/>
									</div>
									<button
										type='button'
										onClick={() => setSelected(2)}
										className={classNames(
											'mt-20 mx-auto lg:w-80 h-14 lg:h-12 px-3 w-48 bg-primary-dark flex items-center justify-center text-center text-xl lg:text-2xl rounded-lg text-admin-bc-white font-bold',
											!selectedPatient && 'opacity-50 cursor-not-allowed'
										)}
										disabled={!selectedPatient}
									>
										Próximo
									</button>
								</div>
							</div>
							<div>

							</div>
							<div className={`${selected === 2 ? '' : 'hidden'}`}>
								<form onSubmit={handleSubmitImage(onSubmitImages)}>
									<div className="flex flex-col md:flex-row gap-6 text-2xl text-white mt-6">
										<div className='flex grid-cols-5 gap-x-6'>
											<ImageInput
												register={registerImage}
												registerOptions={{ required: images?.faceFrontal ? undefined : 'Campo obrigatório' }}
												error={errorsImage.faceFrontal?.message}
												name="faceFrontal"
												image={images?.faceFrontal ?? fotoSeria}
												getImage={img => {
													setImages(old => ({ ...old, faceFrontal: img }));
												}}
											/>
											<ImageInput
												register={registerImage}
												registerOptions={{ required: images?.faceLateral ? undefined : 'Campo obrigatório' }}
												error={errorsImage.faceLateral?.message}
												name="faceLateral"
												image={images?.faceLateral ?? fotoLateral}
												getImage={img => setImages(old => ({ ...old, faceLateral: img }))}
											/>
											<ImageInput
												register={registerImage}
												registerOptions={{ required: images?.faceSorrindo ? undefined : 'Campo obrigatório' }}
												error={errorsImage.faceSorrindo?.message}
												name="faceSorrindo"
												image={images?.faceSorrindo ?? fotoSorrindo}
												getImage={img => setImages(old => ({ ...old, faceSorrindo: img }))}
											/>
										</div>
									</div>
									<div className="flex flex-col md:flex-row gap-6 text-2xl text-white mt-6">
										<div className='flex grid-cols-5 gap-x-6'>
											<ImageInput
												register={registerImage}
												registerOptions={{ required: images?.dentesFrontal ? undefined : 'Campo obrigatório' }}
												error={errorsImage.dentesFrontal?.message}
												name="dentesFrontal"
												image={images?.dentesFrontal ?? dentesFrente}
												getImage={img => setImages(old => ({ ...old, dentesFrontal: img }))}
											/>
											<ImageInput
												register={registerImage}
												registerOptions={{ required: images?.dentesDireita ? undefined : 'Campo obrigatório' }}
												error={errorsImage.dentesDireita?.message}
												name="dentesDireita"
												image={images?.dentesDireita ?? dentesDireita}
												getImage={img => setImages(old => ({ ...old, dentesDireita: img }))}
											/>
											<ImageInput
												register={registerImage}
												registerOptions={{ required: images?.dentesEsquerda ? undefined : 'Campo obrigatório' }}
												error={errorsImage.dentesEsquerda?.message}
												name="dentesEsquerda"
												image={images?.dentesEsquerda ?? dentesEsquerda}
												getImage={img => setImages(old => ({ ...old, dentesEsquerda: img }))}
											/>
											<ImageInput
												register={registerImage}
												registerOptions={{ required: images?.dentesCima ? undefined : 'Campo obrigatório' }}
												error={errorsImage.dentesCima?.message}
												name="dentesCima"
												image={images?.dentesCima ?? dentesCima}
												getImage={img => setImages(old => ({ ...old, dentesCima: img }))}
											/>
											<ImageInput
												register={registerImage}
												registerOptions={{ required: images?.dentesBaixo ? undefined : 'Campo obrigatório' }}
												error={errorsImage.dentesBaixo?.message}
												name="dentesBaixo"
												image={images?.dentesBaixo ?? dentesBaixo}
												getImage={img => setImages(old => ({ ...old, dentesBaixo: img }))}
											/>
										</div>
									</div>
									<div className="flex flex-col md:flex-row gap-6 text-2xl text-white mt-6">
										<div className='flex grid-cols-5 gap-x-6'>
											<ImageInput
												register={registerImage}
												registerOptions={{ required: images?.raioxLateral ? undefined : 'Campo obrigatório' }}
												error={errorsImage.raioxLateral?.message}
												name="raioxLateral"
												image={images?.raioxLateral ?? raioxPanoramico}
												getImage={img => setImages(old => ({ ...old, raioxLateral: img }))}
											/>
											<ImageInput
												register={registerImage}
												registerOptions={{ required: images?.raioxPanoramico ? undefined : 'Campo obrigatório' }}
												error={errorsImage.raioxPanoramico?.message}
												name="raioxPanoramico"
												image={images?.raioxPanoramico ?? raioxLateral}
												getImage={img => setImages(old => ({ ...old, raioxPanoramico: img }))}
											/>
											<ImageInput
												register={registerImage}
												name="optional1"
												label="Opcional 1"
												image={images?.optional1}
												getImage={img => setImages(old => ({ ...old, optional1: img }))}
											/>
											<ImageInput
												register={registerImage}
												name="optional2"
												label="Opcional 2"
												image={images?.optional2}
												getImage={img => setImages(old => ({ ...old, optional2: img }))}
											/>
										</div>
									</div>
									<div className="grid md:grid-rows grid-cols-1 mt-12  gap-y-10 text-2xl justify-items-center">
										<div className="flex md:flex-row flex-col justify-center gap-x-10">
											{!id_planejamento && !search.includes('id_patient') && (
												<button type="button" onClick={() => setSelected(1)} className="flex justify-center mt-10" >
													<p className="lg:w-80 h-14 lg:h-12 px-3 w-48 border-2 border-primary-dark flex items-center justify-center text-center text-xl lg:text-2xl rounded-lg text-primary-dark font-bold">Voltar</p>
												</button>
											)}
											<button type="submit" className="flex justify-center mt-10" >
												<p className="lg:w-80 h-14 lg:h-12 px-3 w-48 bg-primary-dark flex items-center justify-center text-center text-xl lg:text-2xl rounded-lg text-admin-bc-white font-bold">Próximo</p>
											</button>
										</div>
									</div>
								</form>
							</div>
							<div className={`${selected === 3 ? '' : 'hidden'}`}>
								<form onSubmit={handleSubmit(onSubmit)}>
									<div className="grid grid-cols-1 mt-6 text-2xl gap-y-20 justify-items-center">
										<div className="flex flex-col gap-4 w-full">
											<strong className="">Dúvidas:</strong>
											<textarea {...register('doubts')} placeholder="Insira aqui suas dúvidas" className="h-80 min-w-full max-w-2xl border border-solid border-gray-400 rounded-lg p-2 bg-gray-100 flex justify-center items-center text-2xl" />
										</div>
										<div className="flex flex-col gap-4 w-full">
											<strong className="">Observações:</strong>
											<textarea {...register('observations')} placeholder="Insira aqui suas observações" className="h-80 min-w-full max-w-2xl border border-solid border-gray-400 rounded-lg p-2 bg-gray-100 flex justify-center items-center text-2xl" />
										</div>
									</div>
									<div className="flex md:flex-row flex-col justify-center gap-x-10">
										<button type="button" onClick={() => setSelected(2)} className="flex justify-center mt-10" >
											<p className="lg:w-80 h-14 lg:h-12 px-3 w-48 border-2 border-primary-dark flex items-center justify-center text-center text-xl lg:text-2xl rounded-lg text-primary-dark font-bold">Voltar</p>
										</button>
										<button
											type='submit'
											className={classNames(
												'mt-10 lg:w-80 h-14 lg:h-12 px-3 w-48 bg-primary-dark flex items-center justify-center text-center text-xl lg:text-2xl rounded-lg text-admin-bc-white font-bold',
												loading && 'opacity-50 cursor-not-allowed'
											)}
											disabled={loading}
										>
											{loading ? 'Enviando...' : 'Enviar solicitação'}
										</button>
									</div>
								</form>
							</div>
						</main>
					</div>
				</div>
			)}

			<Modal isOpen={openModal} onClose={() => setOpenModal(true)}>
				<div className="h-full w-full bg-white rounded-2xl overflow-hidden flex flex-col">
					<div className="w-auto h-auto bg-primary-dark px-10 py-4 gap-x-14  flex flex-row items-center justify-between">
						<strong className="text-3xl w-full text-white">
							Solicitação enviada com sucesso!
						</strong>
					</div>
					<div className="flex flex-col py-6 gap-y-10 justify-center px-10">
						<p className="text-3xl text-black">
							Tudo certo!
							<br />
							<br />
							Aguarde o nosso retorno!
							<br />
							<br />

							A sua solicitação será analisada e respondida pela nossa equipe.
						</p>
						<button
							onClick={() => {
								pushHistory('/planejamentos');
							}}
							className="flex justify-center mt-10"
						>
							<p
								className="lg:w-60 h-12 lg:h-12 px-3 w-28 bg-primary-dark flex items-center justify-center text-center text-xl lg:text-2xl rounded-lg text-admin-bc-white font-bold ">
								Ok
							</p>
						</button>
					</div>
				</div>
			</Modal>

			<Loader show={loading} />;
		</>
	);
}

export default RequestPlanning;
