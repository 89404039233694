import { Tab } from '@headlessui/react';
import imageCompression from 'browser-image-compression';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaUserNurse } from 'react-icons/fa';
import InputMask from 'react-input-mask-updated/lib/react-input-mask.development';
import { useToasts } from 'react-toast-notifications';

import CounterCards from '../../components/CounterCards';
import Header from '../../components/Header';
import HeaderlessSlideOver from '../../components/HeadlessSlideOver';
import ListConsultants from '../../components/ListConsultants';
import Loader from '../../components/Loader';
import Menu from '../../components/Menu';
import SearchBar from '../../components/SearchBar';
import { useAuth } from '../../contexts/auth';
import api from '../../services/api';
import classNames from '../../utils/classNames';
import toBase64 from '../../utils/toBase64';

function Consultants() {
	const [consultants, setConsultants] = useState([]);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [previewConsultantImage, setPreviewConsultantImage] = useState();
	const [image, setImage] = useState();

	const { signOut } = useAuth();
	const { register, handleSubmit, reset, formState: { errors } } = useForm();
	const { addToast } = useToasts();

	useEffect(() => {
		async function getVideos() {
			setLoading(true);

			try {
				const { data } = await api.get('/manager/consultants');

				setConsultants(data);
			} catch (err) {
				if (err.response?.status === 401) {
					signOut();
					return;
				}

				addToast(err.response?.data?.message ?? 'Erro ao buscar as consultores.', {
					appearance: 'error',
					autoDismiss: true
				});
			} finally {
				setLoading(false);
			}
		}

		getVideos();
	}, []);

	const handleSearch = useCallback(async value => {
		setLoading(true);

		try {
			const { data } = await api.get('/manager/consultants', {
				params: {
					search: value,
				}
			});
			setConsultants(data);
		} catch (err) {
			if (err.response?.status === 401) {
				signOut();
				return;
			}

			addToast(err.response?.data?.message ?? 'Nenhum resultado encontrado.', {
				appearance: 'error',
				autoDismiss: true
			});
		} finally {
			setLoading(false);
		}
	}, []);

	const onSubmit = useCallback(async ({ ...formData }) => {
		setLoading(true);

		try {
			const profile_image = image;
			if (!profile_image) {
				throw new Error('Foto obrigatória.');
			}
			const compressed = await imageCompression(profile_image, {
				maxSizeMB: 1,
				maxWidthOrHeight: 480,
				useWebWorker: true
			});

			const img = await toBase64(compressed);

			await api.post('/manager/consultants', {
				...formData,
				profile_image: img,
			});

			addToast('Consultor(a) salva', {
				appearance: 'success',
				autoDismiss: true
			});

			handleSearch(undefined);
			setOpen(false);
			setPreviewConsultantImage(undefined);
			reset();
		} catch (err) {
			addToast(err.response?.data?.message ?? 'Erro ao cadastrar Consultor. Verifique os dados informados.', {
				appearance: 'error',
				autoDismiss: true
			});
		} finally {
			setLoading(false);
		}
	}, [image]);

	return (
		<>
			<div className="flex">
				<Menu className="hidden xl:flex" />
				<div className="w-full flex flex-col">
					<Header textHeader="Consultores" />
					<main className="w-full flex flex-col py-4 px-14">
						<div className="flex flex-col-reverse lg:flex-row justify-between" >
							<CounterCards className="" cardList={[
								{
									id: '1',
									title: 'Consultores',
									counter: consultants.length,
									priColor: 'bg-primary',
									secColor: 'bg-primary',
									Icon: FaUserNurse,
								},
							]} />
							<button onClick={() => setOpen(true)} className="flex mt-6 justify-center self-center lg:self-start lg:right-0 lg:w-80 w-60 h-20 lg:h-14 px-3 bg-primary  items-center  text-center text-xl lg:text-2xl rounded-lg text-admin-bc-white font-bold">Adicionar Consultor</button>
						</div>
						<Tab.Group >
							<SearchBar
								getSearch={handleSearch}
								optionsList={[
									{
										id: '1',
										option: 'Consultores',
									},
								]}
							/>
							<Tab.Panel className="grid grid-cols-1 mt-8 lg:grid-cols-2 gap-x-4 gap-y-3">
								{loading ? (
									<div className="col-span-1 lg:col-span-2 flex justify-center my-8 text-2xl font-bold">
										<p>Carregando...</p>
									</div>
								) : (
									<ListConsultants list={consultants ?? []} />

								)}
							</Tab.Panel>
						</Tab.Group>
					</main>
				</div>
			</div>

			<HeaderlessSlideOver
				open={open}
				setOpen={setOpen}
				title="Menu"
				className="absolute"
				side="ri"
			>
				<div className="overflow-y-auto max-h-screen h-screen w-auto flex  flex-col  bg-white">
					<div className="flex w-full py-10 flex-col h-24 text-center justify-center bg-primary text-3xl text-white">Adicionar Consultor</div>
					<div className=" w-auto flex p-10 flex-col gap-10 bg-white">
						<form onSubmit={handleSubmit(onSubmit)} className="gap-10 flex flex-col">
							<input {...register('oldImage')} type="hidden" name="oldImage" />
							<strong>Clique abaixo para selecionar a imagem do consultor</strong>
							<label
								htmlFor="profile_image"
								className={classNames(
									'w-28 h-28 place-self-center flex p-2 rounded-full border-2 border-primary bg-transparent',
									'bg-center bg-cover bg-no-repeat cursor-pointer'
								)}
								style={{ backgroundImage: `url(${previewConsultantImage})` }}
							>
								{!previewConsultantImage && (
									<FaUserNurse size={65} className="text-primary py-2" />
								)}
								<input
									{...register('profile_image', { required: 'Campo obrigatório' })}
									id="profile_image"
									type="file"
									accept="image/png,image/jpg,image/jpeg,image/webp"
									className="sr-only"
									onChange={evt => {
										const file = evt.currentTarget.files.item(0);
										if (file) {
											const imageUrl = URL.createObjectURL(file);
											setPreviewConsultantImage(imageUrl);
											setImage(file);
											// setValue('profile_image', [file]);
											return;
										}
										setImage(undefined);
										setPreviewConsultantImage(undefined);
									}}
								/>
							</label>
							{errors.profile_image && (
								<small className="text-red-400 self-center">{errors.profile_image.message}</small>
							)}
							<div className="flex gap-4 flex-col">
								<strong>Nome:</strong>
								<input
									{...register('name', { required: 'Campo obrigatório' })}
									placeholder="Digite o nome"
									className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11"
								/>
								{errors?.name && (
									<small className="text-red-400">{errors.name.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>Telefone:</strong>
								<InputMask
									{...register('phone', { required: 'Campo obrigatório' })}
									mask={'(99) 99999-9999'}
									placeholder="Digite o nome"
									className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11"
								/>
								{errors?.phone && (
									<small className="text-red-400">{errors.phone.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>E-mail:</strong>
								<input
									{...register('email', { required: 'Campo obrigatório' })}
									type="email"
									placeholder="Digite o e-mail"
									className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11"
								/>
								{errors?.email && (
									<small className="text-red-400">{errors.email.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>Senha:</strong>
								<input
									{...register('password', { required: 'Campo obrigatório' })}
									type="password"
									placeholder="Digite a senha"
									className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11"
								/>
								{errors?.password && (
									<small className="text-red-400">{errors.password.message}</small>
								)}
							</div>
							<div className="flex gap-4 flex-col">
								<strong>Cidade:</strong>
								<input
									{...register('city', { required: 'Campo obrigatório' })}
									placeholder="Digite o nome"
									className="h-16 md:w-96 rounded-lg border-solid border-admin-bc-lightGrey border-2 px-11"
								/>
								{errors?.city && (
									<small className="text-red-400">{errors.city.message}</small>
								)}
							</div>
							<button
								type="submit"
								disabled={loading}
								className={classNames(
									'w-full h-14 mt-6 rounded-lg text-3xl text-white',
									loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-primary'
								)}
							>
								{loading ? 'Salvando...' : 'Adicionar Consultor'}
							</button>
						</form>
					</div>
				</div>
			</HeaderlessSlideOver>
			<Loader show={loading} />
		</>
	);
}

export default Consultants;
