
export function parseEvaluationStatus(status, rated = false) {
	if (status === 'Answered' && rated) {
		return 'Validado';
	}
	switch (status) {
		case 'Pending':
			return 'Pendente';
		case 'Answered':
			return 'Fotografado';
		case 'Request':
			return 'Solicitado';
		case 'Inactive':
			return 'Inativo';
		case 'Finished':
			return 'Encerrado';
		default:
			return 'Pendente';
	}
}

export function getEvaluationStatusColor(status, rated = false) {
	if (status === 'Answered' && rated) {
		return 'bg-green-500';
	}
	switch (status) {
		case 'Pending':
			return 'bg-admin-orange-text';
		case 'Answered':
			return 'bg-status-completed';
		case 'Request':
			return 'bg-status-answered';
		case 'Inactive':
			return 'bg-red-500';
		case 'Finished':
			return 'bg-gray-400';
		default:
			return 'bg-admin-orange-text';
	}
}

export function parsePlanningStatus(status, inactive = false) {
	if (inactive) return 'Paciente inativo';
	switch (status) {
		case 'completed':
			return 'Respondido';
		case 'more_info':
			return 'Aguardando informações';
		case 'doing':
			return 'Em andamento';
		default:
			return 'Pendente';
	}
}

export function getPlanningStatusColor(status, inactive = false) {
	if (inactive) return 'bg-gray-400';
	switch (status) {
		case 'completed':
			return 'bg-status-completed';
		case 'more_info':
			return 'bg-yellow-400';
		case 'doing':
			return 'bg-status-answered';
		case 'expired':
			return 'bg-red-500';
		default:
			return 'bg-admin-orange-text';
	}
}
