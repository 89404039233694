import { useCallback, useState } from 'react';
import { useToasts } from 'react-toast-notifications';

import api from '../services/api';

function useUpdatePatientStatus() {
	const [sending, setSending] = useState(false);

	const { addToast } = useToasts();

	const onSubmit = useCallback(async (idPatient, status) => {
		setSending(true);

		try {
			await api.put(`/dentist/update-patients/${idPatient}`, {
				is_active: status,
			});

			addToast(`Status do paciente alterado para "${status ? 'Ativo' : 'Inativo'}"`, {
				appearance: 'success',
				autoDismiss: true,
			});
		} catch (error) {
			addToast(error.response?.data?.message ?? 'Ocorreu um erro ao reativar o paciente', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setSending(false);
		}
	}, []);

	return {
		onSubmit,
		sending
	};
}

export default useUpdatePatientStatus;