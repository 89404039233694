import React from 'react';

export const PlanningBreadCrumb = ({ page, setPage }) => {
	return (
		<div className="grid grid-cols-1 relative mt-6 drop-shadow-2xl justify">
			<div className="w-10/12 justify-self-center mt-5 h-0.5 bg-primary-dark absolute" />
			<div className="grid grid-cols-7 justify-items-center z-10 text-xl">
				<div className="h-auto w-auto flex flex-col ">
					<button
						className={`w-10 h-10 self-center rounded-full border-2 border-primary-dark ${page >= 1 ? 'bg-primary-dark' : ' bg-white cursor-default'}`}
						onClick={() => {
							if (page >= 1) {
								setPage(1);
							}
						}}
					></button>
					<p className="mt-4">Padrão facial</p>
					<p>/ Perfil do Lábios</p>
					<p>/ Assimetria dos Lábios</p>
					<p>/ Assimetria Facial</p>
					<p>/ Mordida Cruzada</p>
					<p>/ Mordida mm Brodie</p>
					<p>/ Linha Média Superior</p>
					<p>/ Classificação Dentária</p>
					<p>/ Mordida Aberta</p>
					<p>/ Apinhamento</p>
					<p>/ Giros Dentários</p>
				</div>
				<div className="h-auto w-auto flex flex-col">
					<button
						className={`w-10 h-10 self-center rounded-full border-2 border-primary-dark ${page >= 2 ? 'bg-primary-dark' : ' bg-white cursor-default'}`}
						onClick={() => {
							if (page >= 2) {
								setPage(2);
							}
						}}
					></button>
					<p className="mt-4">Ausências Dentárias</p>
				</div>
				<div className="h-auto w-auto flex flex-col">
					<button
						className={`w-10 h-10 self-center rounded-full border-2 border-primary-dark ${page >= 3 ? 'bg-primary-dark' : ' bg-white cursor-default'}`}
						onClick={() => {
							if (page >= 3) {
								setPage(3);
							}
						}}
					></button>
					<p className="mt-4">Assimetria de Plano Oclusal</p>
					<p>/ Dentes Supranumerários</p>
					<p>/ Mesio Inclinação dos Dentes</p>
					<p>/ Discrepância de Volume Dentário</p>
					<p>/ Recessões Gengivais</p>
					<p>/ Diastemas Entre os Dentes</p>
				</div>
				<div className="h-auto w-auto flex flex-col">
					<button
						className={`w-10 h-10 self-center rounded-full border-2 border-primary-dark ${page >= 4 ? 'bg-primary-dark' : ' bg-white cursor-default'}`}
						onClick={() => {
							if (page >= 4) {
								setPage(4);
							}
						}}
					></button>
					<p className="mt-4">Aparatologia Sugerida</p>
					<p>/ Correção Transversal</p>
				</div>
				<div className="h-auto w-auto flex flex-col">
					<button
						className={`w-10 h-10 self-center rounded-full border-2 border-primary-dark ${page >= 5 ? 'bg-primary-dark' : ' bg-white cursor-default'}`}
						onClick={() => {
							if (page >= 5) {
								setPage(5);
							}
						}}
					></button>
					<p className="mt-4">Correção Sagital</p>
				</div>
				<div className="h-auto w-auto flex flex-col">
					<button
						className={`w-10 h-10 self-center rounded-full border-2 border-primary-dark ${page >= 6 ? 'bg-primary-dark' : ' bg-white cursor-default'}`}
						onClick={() => {
							if (page >= 6) {
								setPage(6);
							}
						}}
					></button>
					<p className="mt-4">Correção Vertical</p>
					<p>/ Cirurgia Ortoginática</p>
				</div>
				<div className="h-auto w-auto flex flex-col">
					<button
						className={`w-10 h-10 self-center rounded-full border-2 border-primary-dark ${page >= 7 ? 'bg-primary-dark' : ' bg-white cursor-default'}`}
						onClick={() => {
							if (page >= 7) {
								setPage(7);
							}
						}}
					></button>
					<p className="mt-4">Observações</p>
				</div>
			</div>
		</div>
	);
};
