import infcol31 from '../../assets/images/dentes/inferiorcol/31.jpg';
import infcol32 from '../../assets/images/dentes/inferiorcol/32.jpg';
import infcol33 from '../../assets/images/dentes/inferiorcol/33.jpg';
import infcol34 from '../../assets/images/dentes/inferiorcol/34.jpg';
import infcol35 from '../../assets/images/dentes/inferiorcol/35.jpg';
import infcol36 from '../../assets/images/dentes/inferiorcol/36.jpg';
import infcol37 from '../../assets/images/dentes/inferiorcol/37.jpg';
import infcol38 from '../../assets/images/dentes/inferiorcol/38.jpg';
import infcol41 from '../../assets/images/dentes/inferiorcol/41.jpg';
import infcol42 from '../../assets/images/dentes/inferiorcol/42.jpg';
import infcol43 from '../../assets/images/dentes/inferiorcol/43.jpg';
import infcol44 from '../../assets/images/dentes/inferiorcol/44.jpg';
import infcol45 from '../../assets/images/dentes/inferiorcol/45.jpg';
import infcol46 from '../../assets/images/dentes/inferiorcol/46.jpg';
import infcol47 from '../../assets/images/dentes/inferiorcol/47.jpg';
import infcol48 from '../../assets/images/dentes/inferiorcol/48.jpg';
import infpb31 from '../../assets/images/dentes/inferiorpb/31.jpg';
import infpb32 from '../../assets/images/dentes/inferiorpb/32.jpg';
import infpb33 from '../../assets/images/dentes/inferiorpb/33.jpg';
import infpb34 from '../../assets/images/dentes/inferiorpb/34.jpg';
import infpb35 from '../../assets/images/dentes/inferiorpb/35.jpg';
import infpb36 from '../../assets/images/dentes/inferiorpb/36.jpg';
import infpb37 from '../../assets/images/dentes/inferiorpb/37.jpg';
import infpb38 from '../../assets/images/dentes/inferiorpb/38.jpg';
import infpb41 from '../../assets/images/dentes/inferiorpb/41.jpg';
import infpb42 from '../../assets/images/dentes/inferiorpb/42.jpg';
import infpb43 from '../../assets/images/dentes/inferiorpb/43.jpg';
import infpb44 from '../../assets/images/dentes/inferiorpb/44.jpg';
import infpb45 from '../../assets/images/dentes/inferiorpb/45.jpg';
import infpb46 from '../../assets/images/dentes/inferiorpb/46.jpg';
import infpb47 from '../../assets/images/dentes/inferiorpb/47.jpg';
import infpb48 from '../../assets/images/dentes/inferiorpb/48.jpg';
import supcol11 from '../../assets/images/dentes/superiorcol/11.jpg';
import supcol12 from '../../assets/images/dentes/superiorcol/12.jpg';
import supcol13 from '../../assets/images/dentes/superiorcol/13.jpg';
import supcol14 from '../../assets/images/dentes/superiorcol/14.jpg';
import supcol15 from '../../assets/images/dentes/superiorcol/15.jpg';
import supcol16 from '../../assets/images/dentes/superiorcol/16.jpg';
import supcol17 from '../../assets/images/dentes/superiorcol/17.jpg';
import supcol18 from '../../assets/images/dentes/superiorcol/18.jpg';
import supcol21 from '../../assets/images/dentes/superiorcol/21.jpg';
import supcol22 from '../../assets/images/dentes/superiorcol/22.jpg';
import supcol23 from '../../assets/images/dentes/superiorcol/23.jpg';
import supcol24 from '../../assets/images/dentes/superiorcol/24.jpg';
import supcol25 from '../../assets/images/dentes/superiorcol/25.jpg';
import supcol26 from '../../assets/images/dentes/superiorcol/26.jpg';
import supcol27 from '../../assets/images/dentes/superiorcol/27.jpg';
import supcol28 from '../../assets/images/dentes/superiorcol/28.jpg';
import suppb11 from '../../assets/images/dentes/superiorpb/11.jpg';
import suppb12 from '../../assets/images/dentes/superiorpb/12.jpg';
import suppb13 from '../../assets/images/dentes/superiorpb/13.jpg';
import suppb14 from '../../assets/images/dentes/superiorpb/14.jpg';
import suppb15 from '../../assets/images/dentes/superiorpb/15.jpg';
import suppb16 from '../../assets/images/dentes/superiorpb/16.jpg';
import suppb17 from '../../assets/images/dentes/superiorpb/17.jpg';
import suppb18 from '../../assets/images/dentes/superiorpb/18.jpg';
import suppb21 from '../../assets/images/dentes/superiorpb/21.jpg';
import suppb22 from '../../assets/images/dentes/superiorpb/22.jpg';
import suppb23 from '../../assets/images/dentes/superiorpb/23.jpg';
import suppb24 from '../../assets/images/dentes/superiorpb/24.jpg';
import suppb25 from '../../assets/images/dentes/superiorpb/25.jpg';
import suppb26 from '../../assets/images/dentes/superiorpb/26.jpg';
import suppb27 from '../../assets/images/dentes/superiorpb/27.jpg';
import suppb28 from '../../assets/images/dentes/superiorpb/28.jpg';

const teeth = {
  col: {
    11: supcol11,
    12: supcol12,
    13: supcol13,
    14: supcol14,
    15: supcol15,
    16: supcol16,
    17: supcol17,
    18: supcol18,
    21: supcol21,
    22: supcol22,
    23: supcol23,
    24: supcol24,
    25: supcol25,
    26: supcol26,
    27: supcol27,
    28: supcol28,
    48: infcol48,
    47: infcol47,
    46: infcol46,
    45: infcol45,
    44: infcol44,
    43: infcol43,
    42: infcol42,
    41: infcol41,
    31: infcol31,
    32: infcol32,
    33: infcol33,
    34: infcol34,
    35: infcol35,
    36: infcol36,
    37: infcol37,
    38: infcol38,
  },
  pb: {
    11: suppb11,
    12: suppb12,
    13: suppb13,
    14: suppb14,
    15: suppb15,
    16: suppb16,
    17: suppb17,
    18: suppb18,
    21: suppb21,
    22: suppb22,
    23: suppb23,
    24: suppb24,
    25: suppb25,
    26: suppb26,
    27: suppb27,
    28: suppb28,
    48: infpb48,
    47: infpb47,
    46: infpb46,
    45: infpb45,
    44: infpb44,
    43: infpb43,
    42: infpb42,
    41: infpb41,
    31: infpb31,
    32: infpb32,
    33: infpb33,
    34: infpb34,
    35: infpb35,
    36: infpb36,
    37: infpb37,
    38: infpb38,
  },
};

export { teeth };