import axios from 'axios';
import React, { useCallback, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { useToasts } from 'react-toast-notifications';

import { useAuth } from '../../contexts/auth';
import api from '../../services/api';
import classNames from '../../utils/classNames';
import Modal from '../Modal';
import Questions from './Questions';

function RateEvaluation({
	evaluationId,
	year,
	open,
	onClose,
	questions,
	onUpdated,
}) {
	const [answers, setAnswers] = useState({});
	const [sending, setSending] = useState(false);

	const { signOut } = useAuth();
	const { addToast } = useToasts();

	const saveRating = useCallback(async () => {
		setSending(true);
		try {
			let error = null;
			Object.values(answers).forEach(a => {
				if (a === null) {
					error = 'Responda todas as perguntas';
				}
			});

			if (error) {
				addToast(error, {
					appearance: 'error',
					autoDismiss: true,
				});
				setSending(false);
				return;
			}

			await api.put(`/evaluations/rating/${evaluationId}`, { answers });

			addToast('Avaliação salva', {
				appearance: 'success',
				autoDismiss: true,
			});

			await onUpdated?.();
			onClose();
			setAnswers({});
			setSending(false);
		} catch (err) {
			setSending(false);
			if (axios.isAxiosError(err)) {
				addToast(err.response?.data?.message ?? 'Erro ao salvar avaliação', {
					appearance: 'error',
					autoDismiss: true,
				});

				if (err.response.status === 401) {
					signOut();
				}

				return;
			}

			addToast('Ocorreu algum erro interno, tente novamente mais tarde', {
				appearance: 'error',
				autoDismiss: true,
			});
		}
	}, [answers]);


	return (
		<Modal isOpen={open} onClose={onClose}>
			<div className="h-full w-full bg-white rounded-2xl overflow-hidden flex flex-col pb-4">
				<div className="w-auto h-auto bg-primary px-10 py-4 gap-x-28  flex flex-row items-center justify-between">
					<strong className="text-3xl w-full text-white">
						Avaliar validação
					</strong>
					<button onClick={() => onClose()}>
						<IoMdClose size={35} className="flex text-white" />
					</button>
				</div>
				{year !== 1 ? null : (
					<Questions
						title="Primeiro ano"
						questions={questions?.filter(q => q.year === 1)}
						getAnswers={answers => setAnswers(answers)}
						defaultAnswers={answers}
					/>
				)}
				{year !== 2 ? null : (
					<Questions
						title="Segundo ano"
						questions={questions?.filter(q => q.year === 2)}
						getAnswers={answers => setAnswers(answers)}
						defaultAnswers={answers}
					/>
				)}
				{year !== 3 ? null : (
					<Questions
						title="Terceiro ano"
						questions={questions?.filter(q => q.year === 3)}
						getAnswers={answers => setAnswers(answers)}
						defaultAnswers={answers}
					/>
				)}
				<button
					onClick={saveRating}
					disabled={sending}
					className={classNames(
						'px-8 py-2 text-white rounded-md self-center',
						'transition-opacity hover:opacity-75',
						!sending ? 'bg-primary' : 'bg-gray-400 cursor-default'
					)}
				>
					{sending ? 'Salvando...' : 'Salvar'}
				</button>
			</div>
		</Modal>
	);
}

export default RateEvaluation;
