import React, { useState } from 'react';

import { useAuth } from '../../contexts/auth';
import classNames from '../../utils/classNames';
import { getEvaluationStatusColor, parseEvaluationStatus } from '../../utils/status';
import RateEvaluation from '../RateEvaluation/RateEvaluation';

function EvaluationCard({ evaluation }) {
	const [modalRating, setModalRating] = useState(false);

	const { user } = useAuth();

	return (
		<>
			<div className="flex flex-col shadow-md rounded-4 w-full h-auto bg-white rounded-lg drop-shadow-lg px-4 py-2">
				<a
					href={`/validacao/${evaluation.id}`}
					className="flex flex-col md:flex-row gap-4"
				>
					<div
						className="w-24 h-24 self-center rounded-full bg-cover bg-center bg-no-repeat"
						style={{
							backgroundImage: `url(${evaluation.planning.patient.profile_image})`,
						}}
					/>
					<div className="grid sm:grid-cols-2 gap-y-2 items-center  flex-1 justify-between">
						<div className="flex flex-col justify-evenly sm:justify-start">
							<p className="font-bold text-xl ml-2 self-start">
								{evaluation.planning.patient.name}
							</p>
							<p className="text-sm ml-2 self-start">
								<strong>Clínica: </strong>
								{evaluation.planning.patient.clinic.name}
							</p>
							<p className="text-sm ml-2 self-start">
								<strong>Dentista: </strong>
								{evaluation.planning.dentist.name}
							</p>
							<p className="text-sm ml-2 self-start">
								<strong>Consultas: </strong>
								{evaluation.totalConsults}
							</p>
							{evaluation.gestor && (
								<p className="text-sm ml-2 self-start">
									<strong>Solicitado por: </strong>
									{evaluation.gestor.name}
								</p>
							)}
						</div>
						<div className="flex flex-col sm:flex-row justify-evenly sm:justify-end w-full md:self-end">
							<div className="flex flex-col gap-2 sm:flex-row-3 sm:self-end">
								<div className="flex ml-2 sm:self-end">
									<strong className="text-sm sm:text-base">
										Data de Solicitação:{' '}
									</strong>
									<p className="text-sm sm:text-base ml-2">
										{new Date(evaluation.created_at).toLocaleDateString()}
									</p>
								</div>
								<div className="flex ml-2 gap-1 sm:self-end">
									<strong className="text-sm sm:text-base">
										Data do planejamento:{' '}
									</strong>
									<p className="text-sm sm:text-base">
										{new Date(
											evaluation.planning.created_at
										).toLocaleDateString()}
									</p>
								</div>
								{evaluation.rate === null ? null : (
									<div className="flex ml-2 gap-1 sm:self-end">
										<strong className="text-sm sm:text-base">
											Nota validação:{' '}
										</strong>
										<p className="text-sm sm:text-base">
											{evaluation.rate}
										</p>
									</div>
								)}
								<div className="flex justify-center flex-row-reverse md:flex-row align-middle ml-2 text-sm gap-1 sm:self-end">
									<p
										className={classNames(
											'rounded-full py-1 px-5 text-sm font-semibold text-white',
											getEvaluationStatusColor(evaluation.status, evaluation.rate !== null)
										)}
									>
										{parseEvaluationStatus(evaluation.status, evaluation.rate !== null)}
									</p>
								</div>
							</div>
						</div>
					</div>
				</a>

			</div>

			{user.role !== 1 ? null : (
				<RateEvaluation evaluationId={evaluation.id} year={evaluation.year} open={modalRating} onClose={() => setModalRating(false)} />
			)}
		</>
	);
}

export default EvaluationCard;
