import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import PlanningSelect from '../../components/PlanningSelect';
import classNames from '../../utils/classNames';

function Step1({ selected, questions, defaultValues, sending, onNext }) {
	const { register, handleSubmit, control, setValue, formState: { errors } } = useForm({ defaultValues });

	const onSubmit = useCallback(data => {
		const pageAnswers = JSON.parse(JSON.stringify({
			padrao_facial: data.padrao_facial,
			perfil_labios: data.perfil_labios,
			assimetria_labios: data.assimetria_labios,
			selamento_labial_passivo: data.selamento_labial_passivo,
			assimetria_facial: data.assimetria_facial,
			mordida_cruzada: data.mordida_cruzada,
			mordida_brodie: data.mordida_brodie,
			linha_media_superior: data.linha_media_superior,
			classificacao_dentaria: data.classificacao_dentaria,
			mordida_aberta: data.mordida_aberta,
			mordida_profunda: data.mordida_profunda,
			apinhamento: data.apinhamento,
			giros_dentarios: data.giros_dentarios
		}));
		onNext(pageAnswers);
	}, [selected, questions, onNext]);

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={`${selected === 1 ? '' : 'hidden'}`}>
			<div className="grid md:grid-cols-2 grid-cols-1 mt-6 gap-y-14 text-2xl justify-items-center">
				<PlanningSelect
					control={control}
					register={register}
					name="padrao_facial"
					defaultComplement={defaultValues['padrao_facial']}
					questions={questions['Padrão Facial']}
					label="Padrão Facial"
					errors={errors}
					setValue={setValue}
					isRequired='Campo Obrigatório'
				/>
				<PlanningSelect
					control={control}
					register={register}
					name="perfil_labios"
					defaultComplement={defaultValues['perfil_labios']}
					questions={questions['Perfil dos Lábios']}
					label="Perfil dos Lábios"
					errors={errors}
					setValue={setValue}
					isRequired='Campo Obrigatório'
				/>
				<PlanningSelect
					control={control}
					register={register}
					name="assimetria_labios"
					defaultComplement={defaultValues['assimetria_labios']}
					questions={questions['Assimetria dos lábios']}
					label="Assimetria dos lábios"
					errors={errors}
					setValue={setValue}
					isRequired='Campo Obrigatório'
				/>
				<PlanningSelect
					control={control}
					register={register}
					name="selamento_labial_passivo"
					defaultComplement={defaultValues['selamento_labial_passivo']}
					questions={questions['Selamento Labial Passivo']}
					label="Selamento Labial Passivo"
					errors={errors}
					setValue={setValue}
					isRequired='Campo Obrigatório'
				/>
				<PlanningSelect
					control={control}
					register={register}
					name="assimetria_facial"
					defaultComplement={defaultValues['assimetria_facial']}
					questions={questions['Assimetria Facial']}
					label="Assimetria Facial"
					errors={errors}
					setValue={setValue}
					isRequired='Campo Obrigatório'
				/>
				<PlanningSelect
					control={control}
					register={register}
					name="mordida_cruzada"
					defaultComplement={defaultValues['mordida_cruzada']}
					questions={questions['Mordida cruzada']}
					label="Mordida cruzada"
					errors={errors}
					setValue={setValue}
					isRequired='Campo Obrigatório'
				/>
				<PlanningSelect
					control={control}
					register={register}
					name="mordida_brodie"
					defaultComplement={defaultValues['mordida_brodie']}
					questions={questions['Mordida em Brodie']}
					label="Mordida em Brodie"
					errors={errors}
					setValue={setValue}
					isRequired='Campo Obrigatório'
				/>
				<PlanningSelect
					control={control}
					register={register}
					name="linha_media_superior"
					defaultComplement={defaultValues['linha_media_superior']}
					questions={questions['Linha Média Superior']}
					label="Linha Média Superior"
					errors={errors}
					setValue={setValue}
					isRequired='Campo Obrigatório'
				/>
				<PlanningSelect
					control={control}
					register={register}
					name="classificacao_dentaria"
					defaultComplement={defaultValues['classificacao_dentaria']}
					questions={questions['Classificação Dentária']}
					label="Classificação Dentária"
					errors={errors}
					setValue={setValue}
					isRequired='Campo Obrigatório'
				/>
				<PlanningSelect
					control={control}
					register={register}
					name="mordida_aberta"
					defaultComplement={defaultValues['mordida_aberta']}
					questions={questions['Mordida Aberta']}
					label="Mordida Aberta"
					errors={errors}
					setValue={setValue}
					isRequired='Campo Obrigatório'
				/>
				<PlanningSelect
					control={control}
					register={register}
					name="mordida_profunda"
					defaultComplement={defaultValues['mordida_profunda']}
					questions={questions['Mordida Profunda']}
					label="Mordida Profunda"
					errors={errors}
					setValue={setValue}
					isRequired='Campo Obrigatório'
				/>
				<PlanningSelect
					control={control}
					register={register}
					name="apinhamento"
					defaultComplement={defaultValues['apinhamento']}
					questions={questions['Apinhamento']}
					label="Apinhamento"
					errors={errors}
					setValue={setValue}
					isRequired='Campo Obrigatório'
				/>
				<div className="col-span-full w-full flex flex-col py-4 px-14 gap-4">
					<strong>Giros Dentários</strong>
					<input
						{...register('giros_dentarios.question')}
						type="hidden"
						defaultValue="Giros Dentários"
					/>
					<textarea
						{...register('giros_dentarios.answer', { required: 'Campo obrigatório' })}
						className="border-2 border-gray-300 rounded-md p-1"
					>
					</textarea>
					{errors.giros_dentarios?.answer && (
						<small className="text-red-400">{errors.giros_dentarios?.answer.message}</small>
					)}
				</div>
			</div>
			<div className="flex flex-col justify-center gap-x-10">
				<button
					type="submit"
					disabled={sending}
					className={classNames(
						'mt-10 py-2 px-36 self-center',
						'text-center text-xl lg:text-2xl rounded-lg text-admin-bc-white font-bold',
						'transition-colors duration-300',
						sending ? 'bg-gray-400 cursor-wait' : 'bg-primary-dark hover:bg-primary'
					)}
				>
					Próximo
				</button>
			</div>
		</form>
	);
}

export default Step1;
