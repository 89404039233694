import React, { useState } from 'react';
import { useEffect } from 'react';
import { Controller } from 'react-hook-form';

import classNames from '../utils/classNames';

function PlanningSelect({ control, register, name, label, questions, defaultComplement, errors, onChange: onChangeProp, setValue, isRequired }) {
	const [complement, setComplement] = useState(null);

	useEffect(() => {
		if (defaultComplement) {
			let { answer } = defaultComplement;
			let newComplement;

			questions?.forEach(([, options]) => {
				options?.forEach(opt => {
					if (opt.option == answer || answer === `${opt.optgroup} - ${opt.option}`) {
						newComplement = opt.complement?.length ? opt.complement : null;
					}
				});
			});
			if (!newComplement) {
				setValue(name, null);
				setValue(`${name}.answer`, answer);
				setValue(`${name}.question`, label);
			}

			setComplement(newComplement);
		}
	}, []);

	function handleChange(evt, onChange) {
		const { value } = evt.target;
		let _complement;

		questions?.forEach(([, options]) => {
			options?.forEach(opt => {
				if (opt.option == value || value === `${opt.optgroup} - ${opt.option}`) {
					_complement = opt.complement?.length ? opt.complement : null;
				}
			});
		});
		if (!_complement) {
			setValue(name, null);
			setValue(`${name}.answer`, value);
			setValue(`${name}.question`, label);
		}

		setComplement(_complement);

		onChange(evt);
		onChangeProp?.(_complement);
	}

	return (
		<div className="w-full flex flex-col py-4 px-14 gap-4">
			<strong>{label}</strong>
			<Controller
				control={control}
				name={`${name}.answer`}
				rules={{ required: isRequired ?? '' }}
				render={({ field: { onChange, ...field }, fieldState: { error } }) => (
					<div className="w-full flex flex-col">
						<input
							{...register(`${name}.question`)}
							type="hidden"
							defaultValue={label}
						/>
						<select
							{...field}
							onChange={evt => handleChange(evt, onChange)}
							className={classNames(
								'py-2 px-3',
								'border border-solid rounded-lg bg-primary-dark',
								'text-white text-lg lg:text-2xl focus:outline-none"'
							)}
						>
							<option className="bg-white text-gray-400" value="">Selecione uma opção</option>
							{questions?.map(([optgroup, options]) => {
								if (!optgroup) {
									return options.map((option) => (
										<option
											key={option.id}
											value={option.option}
											className="bg-white text-primary-dark"
										>
											{option.optgroup ? `${option.optgroup} - ` : ''}{option.option}
										</option>
									));
								}

								return (
									<optgroup key={optgroup} label={optgroup} className="bg-white text-primary-dark">
										{options.map((option) => (
											<option
												key={option.id}
												value={!option.option.includes(optgroup) ? `${optgroup} - ${option.option}` : option.option}
												className="bg-white text-primary-dark"
											>
												{option.optgroup ? `${option.optgroup} - ` : ''}{option.option}
											</option>
										))}
									</optgroup>
								);
							})}
						</select>
						{error && (
							<small className="text-red-400">{error.message}</small>
						)}
					</div>
				)}
			/>
			{complement && complement.map(input => (
				<React.Fragment key={input.id}>
					<label htmlFor={`${input.name}-${input.id}`} className={classNames(
						'flex gap-x-4',
						input.type === 'textarea' ? 'flex-col' : 'flex-row items-center',
					)}>
						<input
							{...register(`${name}.${input.name}.question`)}
							type="hidden"
							defaultValue={input.label}
						/>
						{input.type !== 'textarea' && (
							<input
								{...register(`${name}.${input.name}.answer`, { required: 'Campo obrigatório' })}
								type={input.type}
								id={`${input.name}-${input.id}`}
								step={input.step || '.01'}
								min={0}
								defaultValue={input.value}
								className={classNames(
									'border-2 border-gray-300 rounded-md px-4',
									['checkbox', 'radio'].includes(input.type) ? null : 'w-4/12',
								)}
								placeholder={input.placeholer || '...'}
								onChange={() => {
									setValue(`${name}.${input.name}.question`, input.label);
								}}
							/>
						)}
						<span>
							{input.label}
						</span>
						{input.type === 'textarea' && (
							<textarea
								{...register(`${name}.${input.name}.answer`, { required: 'Campo obrigatório' })}
								id={input.name}
								cols={2}
								placeholder={input.placeholder}
								className="border-2 border-gray-300 rounded-md p-1"
							></textarea>
						)}
					</label>
					{errors?.[name]?.[input.name]?.answer && (
						<small className="text-red-400 text-sm">{errors[name][input.name].answer.message}</small>
					)}
				</React.Fragment>
			))}
		</div>
	);
}

export default PlanningSelect;
