import React, { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import Header from '../../components/Header';
import Loader from '../../components/Loader';
import Menu from '../../components/Menu';
import { useAuth } from '../../contexts/auth';
import api from '../../services/api';
import classNames from '../../utils/classNames';

function Avaliacao() {
	const [loading, setLoading] = useState(false);
	const [moduleData, setModuleData] = useState();

	const { control, register, handleSubmit, formState: { errors } } = useForm();
	const { fields, append, remove } = useFieldArray({ name: 'questions', control });
	const { id_module } = useParams();
	const { addToast } = useToasts();
	const { signOut } = useAuth();
	const history = useHistory();

	useEffect(() => {
		async function loadVideo() {
			setLoading(true);
			try {
				const { data } = await api.get(`/manager/avaliacao/${id_module}`);
				setModuleData(data);
				data.questions.forEach(q => {
					append({
						id: q.id,
						question: q.value,
						answers: q.answers.map(a => ({
							id: a.id,
							value: a.value,
							is_correct: a.is_correct === 1,
						}))
					});
				});

			} catch (err) {
				if (err.response?.status === 401) {
					addToast('Você não tem permissão para acessar esta página.', {
						appearance: 'error',
						autoDismiss: true
					});

					signOut();
				}

				addToast(err.response?.data?.message ?? 'Essa videoaula não existe', {
					appearance: 'error',
					autoDismiss: true,
				});

				history.goBack();
			} finally {
				setLoading(false);
			}
		}

		if (id_module) {
			loadVideo();
		}
	}, []);

	const onSubmit = useCallback(async (data) => {
		if (data.questions.length < 1) {
			addToast('Adicione pelo menos 4 perguntas', {
				appearance: 'error',
				autoDismiss: true,
			});
			return;
		}

		setLoading(true);

		try {
			if (moduleData?.is_removable || moduleData?.questions?.length <= 0) {
				await api.post(`/manager/avaliacao/${id_module}`, {
					questions: data.questions.map(q => ({
						...q,
						answers: q.answers.filter(a => a.value.replace(/\s/g, '') !== ''),
					})),
				});
			} else {
				await api.put(`/manager/avaliacao/${id_module}`, {
					questions: data.questions.map(q => ({
						...q,
						answers: q.answers.filter(a => a.value.replace(/\s/g, '') !== ''),
					})),
				});
			}

			addToast('Avaliação salva com sucesso', {
				appearance: 'success',
				autoDismiss: true,
			});

			history.push('/videoaulas');
		} catch (err) {
			addToast(err.response?.data?.message ?? 'Erro ao salvar videoaula', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setLoading(false);
		}
	}, [moduleData, history, id_module]);

	return (
		<>
			<div className="flex">
				<Menu className="hidden xl:flex" />
				<div className=" w-full flex flex-col">
					<Header textHeader="Vídeoaulas | Adicionar avaliação" />
					<form onSubmit={handleSubmit(onSubmit)} className="w-full flex flex-col py-4 px-14 gap-4">
						<h2 className="text-gray-500 text-3xl leading-6">
							<small className='text-sm'>Módulo:</small>
							<br />
							<span>{moduleData?.title}</span>
						</h2>

						{fields.map((field, index) => (
							<fieldset key={field.id} className="p-4 shadow-lg rounded-md flex flex-col">
								{moduleData?.is_removable && (
									<button
										type='button'
										onClick={() => remove(index)}
										className={classNames(
											'px-6 py-2 text-lg ml-auto',
											'rounded-lg text-admin-bc-white font-bold',
											'bg-red-400 cursor-pointer',
											'hover:bg-gray-700 transition-colors duration-150'
										)}
									>
										Remover
									</button>
								)}
								<label className="flex flex-col">
									Pergunta {index + 1}:
									<input type="hidden" {...register(`questions.${index}.id`)} />
									<textarea
										{...register(`questions.${index}.question`, {
											required: 'Campo obrigatório'
										})}
										placeholder="Digite aqui a pergunta"
										className="mt-2 border rounded-md p-2 text-xl"
									>
									</textarea>
									{errors.questions?.[index]?.question && (
										<small className="text-red-500 text-sm">{errors.questions?.[index]?.question.message}</small>
									)}
								</label>
								<div className="ml-4 mt-4">
									<label className="flex flex-col mt-2">
										Resposta 1:
										<div className="flex gap-x-4">
											<input type="hidden" {...register(`questions.${index}.answers.0.id`)} />
											<textarea
												{...register(`questions.${index}.answers.0.value`, {
													required: 'Campo obrigatório'
												})}
												placeholder="Digite aqui a primeira resposta"
												className="mt-2 border rounded-md p-2 text-xl flex-1"
											>
											</textarea>
											<span className="flex items-center gap-x-2">
												<input type="checkbox" {...register(`questions.${index}.answers.0.is_correct`)} />
												Resposta correta
											</span>
										</div>
										{errors.questions?.[index]?.answer?.[0] && (
											<small className="text-red-500 text-sm">{errors.questions?.[index]?.answer[0].value.message}</small>
										)}
									</label>
									<label className="flex flex-col mt-2">
										Resposta 2:
										<div className="flex gap-x-4">
											<input type="hidden" {...register(`questions.${index}.answers.0.id`)} />
											<textarea
												{...register(`questions.${index}.answers.1.value`, {
													required: 'Campo obrigatório'
												})}
												placeholder="Digite aqui a primeira resposta"
												className="mt-2 border rounded-md p-2 text-xl flex-1"
											>
											</textarea>
											<span className="flex items-center gap-x-2">
												<input type="checkbox" {...register(`questions.${index}.answers.1.is_correct`)} />
												Resposta correta
											</span>
										</div>
										{errors.questions?.[index]?.answer?.[1] && (
											<small className="text-red-500 text-sm">{errors.questions?.[index]?.answer[1].value.message}</small>
										)}
									</label>
									<label className="flex flex-col mt-2">
										Resposta 3:
										<div className="flex gap-x-4">
											<input type="hidden" {...register(`questions.${index}.answers.2.id`)} />
											<textarea
												{...register(`questions.${index}.answers.2.value`)}
												placeholder="Digite aqui a primeira resposta"
												className="mt-2 border rounded-md p-2 text-xl flex-1"
											>
											</textarea>
											<span className="flex items-center gap-x-2">
												<input type="checkbox" {...register(`questions.${index}.answers.2.is_correct`)} />
												Resposta correta
											</span>
										</div>
									</label>
									<label className="flex flex-col mt-2">
										Resposta 4:
										<div className="flex gap-x-4">
											<input type="hidden" {...register(`questions.${index}.answers.3.id`)} />
											<textarea
												{...register(`questions.${index}.answers.3.value`)}
												placeholder="Digite aqui a primeira resposta"
												className="mt-2 border rounded-md p-2 text-xl flex-1"
											>
											</textarea>
											<span className="flex items-center gap-x-2">
												<input type="checkbox" {...register(`questions.${index}.answers.3.is_correct`)} />
												Resposta correta
											</span>
										</div>
									</label>
									<label className="flex flex-col mt-2">
										Resposta 5:
										<div className="flex gap-x-4">
											<input type="hidden" {...register(`questions.${index}.answers.4.id`)} />
											<textarea
												{...register(`questions.${index}.answers.4.value`)}
												placeholder="Digite aqui a primeira resposta"
												className="mt-2 border rounded-md p-2 text-xl flex-1"
											>
											</textarea>
											<span className="flex items-center gap-x-2">
												<input type="checkbox" {...register(`questions.${index}.answers.4.is_correct`)} />
												Resposta correta
											</span>
										</div>
									</label>
								</div>
							</fieldset>
						))}

						{moduleData?.is_removable && (
							<button
								type='button'
								onClick={() => append()}
								className={classNames(
									'p-3 w-48 text-2xl rounded-lg self-end',
									'text-admin-bc-white font-bold leading-6',
									'bg-primary cursor-pointer',
								)}
							>
								Adicionar pergunta
							</button>
						)}
						<button
							type="submit"
							disabled={loading}
							className={classNames(
								'self-center',
								'lg:w-80 h-14 lg:h-14 px-3 w-48',
								'flex items-center justify-center gap-x-4 text-center text-xl lg:text-2xl',
								'rounded-lg text-admin-bc-white font-bold',
								loading ? 'bg-gray-500 cursor-not-allowed' : 'bg-primary cursor-pointer',
							)} >
							<span>{loading ? 'Salvando...' : 'Salvar avaliação'}</span>
						</button>
					</form>
				</div>
			</div>
			<Loader show={loading} />
		</>
	);
}

export default Avaliacao;
