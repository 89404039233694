import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { ImClock } from 'react-icons/im';
import { useHistory, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import Dropdown from '../../components/Dropdown';
import AnswerEvaluationsModal from '../../components/evaluation/AnswerEvaluationsModal';
import SendFeedback from '../../components/evaluation/SendFeedback';
import Header from '../../components/Header';
import ListPhotosCompare from '../../components/ListPhotosCompare';
import Loader from '../../components/Loader';
import Menu from '../../components/Menu';
import RateEvaluation from '../../components/RateEvaluation/RateEvaluation';
import { useAuth } from '../../contexts/auth';
import useEvaluationQuestions from '../../hooks/useEvaluationsQuestions';
import useUpdatePatientStatus from '../../hooks/useUpdatePatientStatus';
import api from '../../services/api';
import classNames from '../../utils/classNames';
import { getAgeFromDate } from '../../utils/getAgeFromDate';
import { imagesToCompare } from '../../utils/imagesToCompare';
import {
	getEvaluationStatusColor,
	parseEvaluationStatus,
} from '../../utils/status';

function EvaluationDetail() {
	const [loading, setLoading] = useState(true);
	const [sendingFinished, setSendingFinished] = useState(false);
	const [evaluation, setEvaluation] = useState(null);
	const [planning, setPlanning] = useState(null);
	const [yearsImages, setYearsImages] = useState({
		year1: null,
		year2: null,
		year3: null,
	});
	const [planningImages, setPlanningImages] = useState([]);
	const [showValidateModal, setShowValidateModal] = useState(false);
	const [showAnswerModal, setShowAnswerModal] = useState(false);
	const [showFeedbackModal, setShowFeedbackModal] = useState(false);

	const history = useHistory();
	const { idEvaluation } = useParams();
	const { user, signOut } = useAuth();
	const { addToast } = useToasts();
	const { questions } = useEvaluationQuestions();
	const { onSubmit: onSubmitPatientStatus, sending } = useUpdatePatientStatus();

	const loadData = useCallback(async () => {
		setLoading(true);

		try {
			const { data: evaluationData } = await api.get(
				`/evaluations/${idEvaluation}`
			);
			const {
				evaluation: resEvaluation,
				planning: resPlanning,
				...yearsImage
			} = evaluationData;

			setEvaluation(resEvaluation);
			setPlanning(resPlanning);
			setPlanningImages(
				imagesToCompare(resPlanning, resPlanning, yearsImage?.year1)
			);
			setYearsImages({
				year1: !yearsImage?.year1
					? null
					: imagesToCompare(yearsImage.year1, resPlanning, yearsImage.year1),
				year2: !yearsImage?.year2
					? null
					: imagesToCompare(
							yearsImage.year2,
							yearsImage.year1,
							yearsImage.year2
					  ),
				year3: !yearsImage?.year3
					? null
					: imagesToCompare(
							yearsImage.year3,
							yearsImage.year2,
							yearsImage.year3
					  ),
			});
		} catch (error) {
			console.log(error);
			if (axios.isAxiosError(error)) {
				if ([401, 403].includes(error.response?.status)) {
					addToast('Você não tem permissão para acessar esta página.', {
						appearance: 'error',
						autoDismiss: true,
					});

					signOut();
					return;
				}

				addToast(
					error.response?.data?.message ?? 'Erro ao efetuar requisição',
					{
						appearance: 'error',
						autoDismiss: true,
					}
				);
				history.push('/validacao');
				return;
			}

			addToast('Erro ao carregar a validação', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		loadData();
	}, []);

	const handleFinishEvaluation = useCallback(async () => {
		setSendingFinished(true);

		try {
			const { data } = await api.put(`/evaluations/finish/${idEvaluation}`);

			addToast(data.message, {
				appearance: 'success',
				autoDismiss: true,
			});
			loadData();
		} catch (error) {
			if (error.response?.status === 401) {
				addToast('Você não tem permissão para isto.', {
					appearance: 'error',
					autoDismiss: true,
				});

				signOut();
			} else {
				addToast(
					error?.response?.data?.message ?? 'Erro ao finalizar a validação',
					{
						appearance: 'error',
						autoDismiss: true,
					}
				);
			}
		} finally {
			setSendingFinished(false);
		}
	}, [idEvaluation]);

	const isPatientActiveAndAdmin =
		planning?.patient?.is_active === 1 && [1, 2].includes(user?.role);
	return (
		<div>
			<Loader show={loading} />
			<div className="flex">
				<Menu className="hidden xl:flex" />
				<div className="w-full flex flex-col">
					<Header
						textHeader={`Validação ${evaluation?.year}° ano | ${planning?.patient?.clinic?.name} | ${planning?.patient?.name}`}
					/>
					<main className="w-full flex flex-col py-4 px-14">
						{user?.role !== 4 ? null : (
							<Link
								to="/validacao"
								className="flex items-center gap-x-4 text-3xl self-start"
							>
								<FaArrowLeft />
								<span className="">Voltar</span>
							</Link>
						)}
						{/* dados do paciente */}
						<div className="flex flex-col xl:flex-row gap-4 px-4 py-5 mt-6 min-w-full rounded-lg shadow-md">
							<div
								className={classNames(
									'bg-primary rounded-full h-32 w-32 self-center',
									'bg-center bg-no-repeat bg-cover'
								)}
								style={{
									backgroundImage: `url(${planning?.patient?.profile_image})`,
								}}
							/>
							<div className="flex flex-col text-xl w-full flex-1 items-between px-4">
								<p className="font-bold self-start">
									{planning?.patient?.name}
								</p>
								<div className="flex gap-2">
									<p className="">E-mail:</p>
									<p>{planning?.patient?.email}</p>
								</div>
								<div className="flex gap-x-2">
									<p className="">Sexo:</p>
									<p>{planning?.patient?.gender}</p>
								</div>
								<div className="flex gap-x-2">
									<p className="">Idade:</p>
									<p>
										{getAgeFromDate(new Date(planning?.patient?.birth_date))}
									</p>
								</div>
								<div className="flex gap-x-2">
									<p className="">Dentista Responsável:</p>
									<p>{planning?.dentist?.name}</p>
								</div>
								<div className="flex gap-x-2">
									<p className="">Total de consultas:</p>
									<p>{evaluation?.totalConsults}</p>
								</div>
							</div>
							<div className="flex flex-col text-xl justify-evenly px-4">
								<div className="flex gap-x-2">
									<p className="">Linha média:</p>
									<p>{planning?.patient?.upper_middle_line}</p>
								</div>
								<div className="flex gap-x-2">
									<p className="">Queixa principal:</p>
									<p>{planning?.patient?.complaint}</p>
								</div>
								{user?.role === 4 ? null : (
									<a
										href={`/paciente/${planning?.patient?.id}`}
										className="text-xl underline mt-2"
									>
										Ver histórico de planejamentos
									</a>
								)}
							</div>
						</div>
						{/* dados do paciente */}

						{/* status e horário */}
						<div className="flex gap-6 justify-between md:flex-row my-6 flex-col md:items-center">
							<div className="flex gap-6 justify-between md:flex-row flex-col">
								<div className="flex items-center text-xl font-bold text-center sm:text-left">
									{planning?.status === 'completed'
										? `Validação respondida em: ${new Date(
												evaluation?.answered_at
										  ).toLocaleString()}`
										: `Validação criada em: ${new Date(
												evaluation?.created_at
										  ).toLocaleString()}`}
									<ImClock className="mx-2" size={26} />
								</div>
							</div>
							<div className="flex gap-x-4">
								{planning?.patient?.is_active === 1 ? (
									<div
										className={classNames(
											'py-2 px-12 text-xl text-white rounded-full',
											getEvaluationStatusColor(
												evaluation?.status,
												evaluation?.rate !== null
											)
										)}
									>
										{parseEvaluationStatus(
											evaluation?.status,
											evaluation?.rate !== null
										)}
									</div>
								) : (
									<div className="py-2 px-12 text-xl text-white rounded-full bg-red-500">
										Paciente inativo
									</div>
								)}
								{user?.role === 4 ? null : (
									<Dropdown
										options={[
											evaluation?.status === 'Pending' &&
												user.role === 2 && {
													text: 'Responder',
													action: () => setShowAnswerModal(true),
												},
											evaluation?.status === 'Answered' &&
											[1, 3].includes(user.role)
												? {
														text: 'Enviar feedback',
														action: () => setShowFeedbackModal(true),
												  }
												: null,
											evaluation?.status === 'Answered' &&
											[1, 3].includes(user.role) &&
											evaluation?.rate === null
												? {
														text: 'Avaliar',
														action: () => setShowValidateModal(true),
												  }
												: null,
											planning?.patient?.is_active === 0 &&
												[1, 2].includes(user.role) && {
													text: sending ? 'Ativando...' : 'Reativar paciente',
													action: async () => {
														await onSubmitPatientStatus(
															planning?.patient?.id,
															true
														);
														await loadData();
													},
													disabled: sending,
												},
											isPatientActiveAndAdmin && {
												text: sending ? 'Desativando...' : 'Desativar paciente',
												action: async () => {
													await onSubmitPatientStatus(
														planning?.patient?.id,
														false
													);
													await loadData();
												},
												disabled: sending,
											},
											evaluation?.status === 'Finished' ||
											evaluation?.rate === null ||
											(isPatientActiveAndAdmin &&
												evaluation?.status === 'Answered')
												? null
												: {
														text: sendingFinished
															? 'Encerrando...'
															: 'Encerrar tratamento',
														action: handleFinishEvaluation,
														disabled: sendingFinished,
												  },
										]}
									/>
								)}
							</div>
						</div>
						{/* status e horário */}

						{user?.role === 4 ? null : (
							<>
								{/* observação do dentista */}
								<div className="flex flex-col gap-y-2 p-4 rounded-lg shadow-md">
									<p className="text-xl">Observação do dentista</p>
									<p className="whitespace-pre-wrap px-2">
										{evaluation?.dentistObs ?? '-- Sem observações --'}
									</p>
								</div>
								{/* observação do dentista */}

								{/* observação do dentista */}
								<div className="flex flex-col gap-y-2 p-4 rounded-lg shadow-md mt-6">
									<p className="text-xl">Resposta do consultos/gestor</p>
									<p className="whitespace-pre-wrap px-2">
										{evaluation?.feedback ?? '-- Sem resposta --'}
									</p>
									{!evaluation?.feedbackVideo ? null : (
										<video src={evaluation.feedbackVideo} controls></video>
									)}
								</div>
								{/* observação do dentista */}
							</>
						)}

						{/* fotos do planejamento */}
						<div className="min-w-full mt-6 flex flex-col p-4 gap-4 rounded-lg shadow-md">
							<p className="text-xl">Fotos iniciais do planejamento</p>
							<div className="text-xl px-10 mt-4 pb-12">
								<strong>Fotos:</strong>
								<div className="flex flex-wrap gap-4 md:gap-8 w-full mt-2">
									<ListPhotosCompare
										key="album-1"
										leftImageTitle="Foto inicial"
										rightImageTitle="Foto validação"
										list={planningImages}
									/>
								</div>
							</div>
						</div>
						{/* fotos do planejamento */}

						{/* fotos da validação */}
						{!yearsImages.year1 ? null : (
							<div className="min-w-full flex flex-col p-4 gap-4 rounded-lg shadow-md mt-6">
								<p className="text-xl">Fotos 1° ano da validação</p>
								<div className="text-xl px-10 mt-4 pb-12">
									<strong>Fotos:</strong>
									<div className="flex flex-wrap gap-4 md:gap-8 w-full mt-2">
										<ListPhotosCompare
											key="album-2"
											leftImageTitle="Foto inicial"
											rightImageTitle="Foto validação"
											list={yearsImages.year1}
										/>
									</div>
								</div>
							</div>
						)}
						{!yearsImages.year2 ? null : (
							<div className="min-w-full flex flex-col p-4 gap-4 rounded-lg shadow-md mt-6">
								<p className="text-xl">Fotos 2° ano da validação</p>
								<div className="text-xl px-10 mt-4 pb-12">
									<strong>Fotos:</strong>
									<div className="flex flex-wrap gap-4 md:gap-8 w-full mt-2">
										<ListPhotosCompare
											key="album-3"
											leftImageTitle="Foto 1° ano"
											rightImageTitle="Foto 2° ano"
											list={yearsImages.year2}
										/>
									</div>
								</div>
							</div>
						)}
						{!yearsImages.year3 ? null : (
							<div className="min-w-full flex flex-col p-4 gap-4 rounded-lg shadow-md mt-6">
								<p className="text-xl">Fotos 3° ano da validação</p>
								<div className="text-xl px-10 mt-4 pb-12">
									<strong>Fotos:</strong>
									<div className="flex flex-wrap gap-4 md:gap-8 w-full mt-2">
										<ListPhotosCompare
											key="album-3"
											leftImageTitle="Foto 2° ano"
											rightImageTitle="Foto 3° ano"
											list={yearsImages.year3}
										/>
									</div>
								</div>
							</div>
						)}
						{/* fotos da validação */}
					</main>
				</div>
			</div>

			<RateEvaluation
				evaluationId={idEvaluation}
				year={1}
				open={showValidateModal}
				onClose={() => setShowValidateModal(false)}
				questions={questions}
				onUpdated={() => loadData()}
			/>
			<AnswerEvaluationsModal
				evaluationId={idEvaluation}
				show={showAnswerModal}
				onClose={() => setShowAnswerModal(false)}
				onUpdated={() => loadData()}
			/>
			<SendFeedback
				evaluationId={idEvaluation}
				open={showFeedbackModal}
				onClose={() => setShowFeedbackModal(false)}
				onUpdated={() => loadData()}
				isFinished={evaluation?.status === 'Finished'}
			/>
		</div>
	);
}

export default EvaluationDetail;
